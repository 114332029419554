import React, {useEffect, useState} from "react";
import {database} from "../../../../common/firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobeEurope, faStoreAltSlash } from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "@material-ui/core";


export const OnlineMark = ({eventKey}) => {
    const [isOnline, setIsOnline] = useState(false);

    useEffect(() => {
        if (eventKey) {
            database.ref('events_online').child(eventKey).on('value', snap => {
                if (snap.exists()) {
                    setIsOnline(true);
                } else {
                    setIsOnline(false);
                }
            })
        }
    }, [eventKey])

    return isOnline ?
        <Tooltip title="Ist Online"><span><FontAwesomeIcon icon={faGlobeEurope} /></span></Tooltip> :
        <Tooltip title="Ist Offline"><span><FontAwesomeIcon icon={faStoreAltSlash} /></span></Tooltip>
}
