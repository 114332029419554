import React, {useEffect, useRef, useState} from 'react';
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faBars, faEdit, faEnvelope, faGlobeEurope, faPowerOff} from "@fortawesome/free-solid-svg-icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import {TeilnehmerDialog} from "./TeilnehmerDialog";
import {DateTime} from "luxon";
import {database} from "../../../../common/firebase";
import {MailDialog} from "./MailDialog";
import {Link} from "react-router-dom";

const Options = ({event, till, className}) => {
    const ref = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [openTeilnehmer, setOpenTeilnehmer] = useState(false);
    const [openMail, setOpenMail] = useState(false);
    const [isOnline, setIsOnline] = useState(false);

    useEffect(() => {
        if (event && event.key) {
            database.ref('events_online').child(event.key).on('value', snap => {
                if (snap.exists()) {
                    setIsOnline(true);
                } else {
                    setIsOnline(false);
                }
            })
        }
    }, [event])

    const handleClick = (event) => {
        event.preventDefault();
        setOpenMenu(true);
    };

    const handleClose = () => {
        setOpenMenu(false);
    };

    const openTeilnehmerDialog = () => {
        setOpenMenu(false);
        setOpenTeilnehmer(true);
    }

    const openMailDialog = () => {
        setOpenMenu(false);
        setOpenMail(true);
    }

    const toggleEvent = () => {
        if(isOnline){
            database.ref('events_online').child(event.key).set(null);
        }else{
            database.ref('events_online').child(event.key).set({
                name: event.name,
                vk_net: event.vk_net,
                subTitle: event.subTitle,
                eventDate: event.eventDate,
                year: event.year,
                till: event.till,
                thumbnail: event?.thumbnails?.[0] ?? null,
                image: event?.images?.[0] ?? null,
                number: event.number,
                hasShipping: !!event.hasShipping,
                shippingCost: event?.shippingCost ?? 0,
            });
        }
        setOpenMenu(false);
    }

    const handleChangeMail = (val) => {
        database.ref('events').child(event.year).child(event.key).update({mail: val});
    }

    return <div>
        <IconButton
            ref={ref}
            color={'primary'}
            aria-controls={'whisky-event-options'}
            aria-haspopup="true"
            onClick={handleClick}>
            <FontAwesomeIcon icon={faBars}/>
        </IconButton>
        <Menu
            id={'whisky-event-options'}
            anchorEl={ref.current}
            keepMounted
            disableScrollLock={true}
            open={openMenu}
            onClose={handleClose}
            className={className}
        >
            <MenuItem component={Link} to={`/admin/event/${event.year}/${event.key}`}>
                <ListItemIcon><FontAwesomeIcon icon={faEdit}/></ListItemIcon>
                <Typography variant="inherit">Bearbeiten</Typography>
            </MenuItem>
            <MenuItem onClick={openMailDialog}>
                <ListItemIcon><FontAwesomeIcon icon={faEnvelope}/></ListItemIcon>
                <Typography variant="inherit">Mail Vorschau</Typography></MenuItem>
            <MenuItem onClick={openTeilnehmerDialog}>
                <ListItemIcon><FontAwesomeIcon icon={faAddressBook}/></ListItemIcon>
                <Typography variant="inherit">Teilnehmerliste</Typography>
            </MenuItem>
            {DateTime.fromFormat(till, 'dd.LL.yyyy HH:mm') > DateTime.local() && <MenuItem onClick={toggleEvent}>
                {isOnline ?
                    <>
                        <ListItemIcon><FontAwesomeIcon icon={faPowerOff}/></ListItemIcon>
                        <Typography variant="inherit">Offline nehmen</Typography>
                    </> :
                    <>
                        <ListItemIcon><FontAwesomeIcon icon={faGlobeEurope}/></ListItemIcon>
                        <Typography variant="inherit">Online stellen</Typography>
                    </>}
            </MenuItem>}
        </Menu>

        <TeilnehmerDialog open={openTeilnehmer} handleClose={() => setOpenTeilnehmer(false)} eventKey={event.key}/>
        <MailDialog open={openMail} handleClose={() => setOpenMail(false)} whiskyEvent={event} changeMailContent={handleChangeMail}/>


    </div>
};

export default Options;
