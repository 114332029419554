import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    wrapperFooter: {
        width: '100%',
        minHeight: '200px',
        padding: '2em 2em 1em 2em',

        backgroundColor: '#333',

        borderTop: 'solid 1px #333',

        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        flexWrap: 'wrap',

    },
    wrapperFooterAdmin: {
        width: '100%',
        minHeight: '200px',
        padding: '2em 2em 1em 90px',

        backgroundColor: '#333',

        borderTop: 'solid 1px #333',

        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    linkElement: {
        color: '#af9175',
        textDecoration: 'none',
        paddingBottom: '0.5em',
        paddingTop: '0.5em',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',


        '&:hover': {
            color: '#fff'
        }
    },
    wrapperBlock: {
        color: '#af9175',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

        [theme.breakpoints.down('sm')]: {
            margin: "0 1em",

            '& $paymentHeader': {
                marginTop: '0.5em'
            }
        }
    },
    wrapperBlockEnd: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    linkIconElement: {
        color: '#af9175',
        textDecoration: 'none',
        fontSize: '2em',
        padding: '1em',

        '&:hover': {
            color: '#fff'
        }
    },
    paymentHeader: {
        color: '#af9175',
        marginTop: 0,
        textAlign: 'center',
    },
    paymentIconWrapper: {
        height: 85,
        width: 100,
        marginLeft: '0.5em',
        backgroundColor: '#af9175',
        borderRadius: '0.25em',
        color: '#333',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.25em',
        marginBottom: '0.5em',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
    },
    paymentIcon: {
        height: '100%',
        width: '100%',
        objectFit: 'contain'
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 16,
        flexWrap: 'wrap',

    }
}));
