import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    backTop: {
        position: 'fixed',
        bottom: '2em',
        right: '1em',
    },
    hidden: {
        display: 'none',
    }
}));