import React, {useEffect, useState} from 'react';
import {database} from "common/firebase";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {AppBar, Button, IconButton, Paper, Tab, Tabs} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import {EditBill} from "components";
import {makeStyles} from "@material-ui/core/styles";
import {DateTime} from "luxon";
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListOl} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

const useStyle = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    tabStyle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    hoverRow: {
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: '#eee',
        }
    },
    underline: {
        textDecoration: "underline",
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}))

const STATE_LIST = {
    payed: 'Bezahlt',
    pending: 'In Verarbeitung',
    waiting: 'Ausstehend',
    canceled: 'Abgebrochen'
};

const BillList = () => {
    const classes = useStyle();
    const [list, setList] = useState([]);
    const [productDisplayList, setProductDisplayList] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [listRe, setListRe] = useState([]);
    const [tab, setTab] = useState(0);
    const [edit, setEdit] = useState(false);
    const [selectedBill, setSelectedBill] = useState(null);
    const [yearList1, setYearList1] = useState([]);
    const [year1, setYear1] = useState(DateTime.local().toFormat('yy'));
    const [yearList2, setYearList2] = useState([]);
    const [year2, setYear2] = useState(DateTime.local().toFormat('yy'));

    useEffect(() => {
        database.ref('bills').child('REO').child(year1).on('value', snap => {
            const cacheList = [];
            if (snap.exists()) {
                snap.forEach(childSnap => {
                    cacheList.push({key: childSnap.key, ...childSnap.val()});
                });
            }
            setList(cacheList);
        })
    }, [year1]);

    useEffect(() => {
        database.ref('bills').child('RE').child(year2).on('value', snap => {
            const cacheList = [];
            if (snap.exists()) {
                snap.forEach(childSnap => {
                    cacheList.push({key: childSnap.key, ...childSnap.val()});
                });
            }
            setListRe(cacheList);
        })
    }, [year2]);

    useEffect(() => {
        database.ref('bills').child('RE').on('value', snap => {
            if (snap.exists()) {
                const list = []
                const currentYear = DateTime.local().toFormat('yy')
                snap.forEach(yearSnap => {
                    list.push(yearSnap.key);
                })
                if (list.indexOf(currentYear) === -1) list.push(currentYear);
                setYearList1(list.sort((a, b) => b - a));
            }
        })
        database.ref('bills').child('REO').on('value', snap => {
            if (snap.exists()) {
                const list = []
                const currentYear = DateTime.local().toFormat('yy')
                snap.forEach(yearSnap => {
                    list.push(yearSnap.key);
                })
                if (list.indexOf(currentYear) === -1) list.push(currentYear);
                setYearList2(list.sort((a, b) => b - a));
            }
        })
    }, []);

    const openEdit = bill => () => {
        setSelectedBill(bill);
        setEdit(true);
    }

    const handleClose = () => {
        setSelectedBill(null);
        setEdit(false);
    }

    const handleChangeTab = (event, newValue) => setTab(newValue);

    const createListAndDisplay = (e) => {
        const productList = list.flatMap(bill => bill.products).reduce((nextList, product) => {
            const index = nextList.findIndex(p => p.articleNumber === product.articleNumber);
            if (index === -1) {
                nextList.push(product);
                return nextList;
            }
            nextList[index].amount = parseInt(nextList[index].amount) + parseInt(product.amount);
            return nextList;
        }, []);
        setProductDisplayList(productList);
    }

    const filterFunctionProducts = (p) => {
        return p.articleNumber.toLowerCase().includes(searchString.toLowerCase()) ||
            p.fullName?.toLowerCase().includes(searchString.toLowerCase()) ||
            p.name?.toLowerCase().includes(searchString.toLowerCase());
    }

    return <div className={classes.otherFont}>
        <AppBar position="static" className={classes.appBar}>Rechnungen</AppBar>
        <Tabs
            value={tab}
            className={classes.otherFont + ' ' + classes.tabStyle}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Tabs for Detailed Information">

            <Tab value={0} label="Online-Rechnungen" id={"0"}/>
            <Tab value={1} label="Extra-Rechnungen" id={"1"}/>

        </Tabs>
        {tab === 0 &&
        <TableContainer component={Paper}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <span>Rechnungsjahr</span>
                            {yearList1.map((y, i) => (
                                <IconButton key={i} onClick={() => setYear1(y)} className={y === year1 ? classes.underline : ''}>{y}</IconButton>))}
                        </TableCell>
                        <TableCell align={"right"}>
                            <Tooltip title={"Artikel von Rechnungen auflisten"}>
                                <IconButton onClick={createListAndDisplay}>
                                    <FontAwesomeIcon icon={faListOl}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rechnungsnummer</TableCell>
                        <TableCell align="right">Vorname</TableCell>
                        <TableCell align="right">Nachname</TableCell>
                        <TableCell align="right">Rechnungssumme</TableCell>
                        <TableCell align="right">Positionsanzahl</TableCell>
                        <TableCell align="right">Rechnungsstatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((bill, index) => (
                        <TableRow key={index} className={classes.hoverRow} onClick={openEdit(bill)}>
                            <TableCell component="th" scope="row">
                                {bill.billNumber}
                            </TableCell>
                            <TableCell align="right">{bill.address.firstName}</TableCell>
                            <TableCell align="right">{bill.address.lastName}</TableCell>
                            <TableCell align="right">€ {parseFloat(bill.payed).toFixed(2)}</TableCell>
                            <TableCell align="right">{bill.products.length}</TableCell>
                            <TableCell align="right">{STATE_LIST[bill.state]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="left">Summen:</TableCell>
                        <TableCell align="right">Anzahl: {list.length}</TableCell>
                        <TableCell align="right"/>
                        <TableCell align="right">Einnahmen: {(list.filter(b => b.state === "payed")
                        .reduce((a, b) => a + parseFloat(b.payed), 0)).toFixed(2)}</TableCell>
                        <TableCell align="right">Flaschen: {list.filter(b => b.state === "payed")
                        .reduce((a, b) => a + b.products.reduce((aa, p) => aa + parseInt(p.amount), 0), 0)}</TableCell>
                        <TableCell align="right">Bezahlte: {list.filter(b => b.state === "payed").length}</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        }
        {tab === 1 &&
        <TableContainer component={Paper}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={6}>
                            <span>Rechnungsjahr</span>
                            {yearList2.map((y, i) => (
                                <IconButton key={i} onClick={() => setYear2(y)} className={y === year2 ? classes.underline : ''}>{y}</IconButton>))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rechnungsnummer</TableCell>
                        <TableCell align="right">Vorname</TableCell>
                        <TableCell align="right">Nachname</TableCell>
                        <TableCell align="right">Rechnungssumme</TableCell>
                        <TableCell align="right">Positionsanzahl</TableCell>
                        <TableCell align="right">Rechnungsstatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listRe.map((bill, index) => (
                        <TableRow key={index} className={classes.hoverRow} onClick={openEdit(bill)}>
                            <TableCell component="th" scope="row" className={classes.hoverRow}>
                                {bill.billNumber}
                            </TableCell>
                            <TableCell align="right">{bill.address.firstName}</TableCell>
                            <TableCell align="right">{bill.address.lastName}</TableCell>
                            <TableCell align="right">€ {parseFloat(bill.payed).toFixed(2)}</TableCell>
                            <TableCell align="right">{bill.products.length}</TableCell>
                            <TableCell align="right">{STATE_LIST[bill.state]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="left">Summen:</TableCell>
                        <TableCell align="right">Anzahl: {listRe.length}</TableCell>
                        <TableCell align="right"/>
                        <TableCell align="right">Einnahmen: {(listRe.filter(b => b.state === "payed")
                        .reduce((a, b) => a + parseFloat(b.payed), 0)).toFixed(2)}</TableCell>
                        <TableCell align="right">Flaschen: {listRe.filter(b => b.state === "payed")
                        .reduce((a, b) => a + b.products.reduce((aa, p) => aa + parseInt(p.amount), 0), 0)}</TableCell>
                        <TableCell align="right">Bezahlte: {listRe.filter(b => b.state === "payed").length}</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        }
        <div style={{height: 24}}/>
        <EditBill open={edit} handleClose={handleClose} bill={selectedBill}/>

        <Dialog className={classes.otherFont} open={productDisplayList.length > 0} onClose={() => setProductDisplayList([])} aria-labelledby="form-dialog-title"
                maxWidth={'md'} disableScrollLock={true}>
            <DialogTitle id="form-dialog-title">Alle Produkte aus den angezeigten Rechnungen</DialogTitle>
            <DialogContent>
                <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <TextField
                                    value={searchString}
                                    onChange={e => setSearchString(e.target.value)}
                                    variant={"outlined"}
                                    fullWidth
                                    label={"Filter"}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anzahl</TableCell>
                            <TableCell align="right">Artikelnummer</TableCell>
                            <TableCell align="right">Fullname</TableCell>
                            <TableCell align="right">Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productDisplayList.filter(filterFunctionProducts).sort((a,b) => a.articleNumber.localeCompare(b.articleNumber)).map((product, index) => (
                            <TableRow key={index}>
                                <TableCell align="right">{product.amount}</TableCell>
                                <TableCell align="right">{product.articleNumber}</TableCell>
                                <TableCell align="right">{product.fullName}</TableCell>
                                <TableCell align="right">{product.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setProductDisplayList([])} color="primary" variant={'outlined'}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </div>
};

export default BillList;
