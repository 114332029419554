import React, {useEffect, useState} from 'react';
import {Container, Box, makeStyles, TextField, InputAdornment, IconButton, Avatar, Typography, LinearProgress} from "@material-ui/core";
import NewsCard from "components/NewsCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {database} from "../../common/firebase";
import {DateTime} from "luxon";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    media: {
        height: 265
    },
    otherFont: {
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            textAlign: 'left',
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    input: {
        backgroundColor: '#fff',
    },
    searchBox: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    activeAvatar: {
        backgroundColor: theme.palette.secondary.main
    },
    yearRow:{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    }
}))

const News = ({unpublish}) => {
    const classes = useStyles();
    const [searchStringInput, setSearchStringInput] = useState('');

    const [newsFeed, setNewsFeed] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(DateTime.local().toFormat('yy'));
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setLoading(true);
        const ref = database.ref('newsFeed').child(selectedYear);
        const listener = ref.on('value', snap => {
            if(!snap.exists()) {
                setNewsFeed([]);
                return setLoading(false);
            }
            const list = [];
            snap.forEach( snapElement => {
                const element = snapElement.val();
                element.key = snapElement.key;
                element.year = selectedYear;
                list.push(element)
            });
            setNewsFeed(list.sort( (a,b) => a.timestamp > b.timestamp ? -1 : 1));
            setLoading(false);
        })

        return _ => {
            ref.off('value', listener);
        }

    }, [selectedYear])

    useEffect(() => {
        setNewsFeed([]);
        const ref = database.ref('newsFeed');
        const listener = ref.on('value', snap => {
            if(!snap.exists()) return;
            const list = [];
            snap.forEach( snapElement => {
                list.push(snapElement.key)
            });
            setYears(list);
        })

        return _ => {
            ref.off('value', listener);
        }
    }, [])

    const searchFilter = news => news.title.toLowerCase().includes(searchStringInput.toLowerCase()) || news.text.toLowerCase().includes(searchStringInput.toLowerCase());

    return <Container maxWidth={'md'}>
        <Box className={classes.searchBox}>
            <TextField
                className={classes.otherFont + ' ' + classes.input}
                id="search"
                label="News Durchsuchen"
                value={searchStringInput}
                type="text"
                fullWidth
                variant={"outlined"}
                onChange={(e) => setSearchStringInput(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon icon={faSearch}/>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
        { loading && <LinearProgress style={{marginBottom: '1em'}} /> }
        { !loading && newsFeed.filter(searchFilter).map( (news,i) => (<NewsCard unpublish={unpublish?.(news) ?? null} key={i} isOpen={i===0} news={news}  /> ))}
        <Box className={classes.otherFont}>
            <Typography variant={"body2"} color={"textSecondary"} component={"p"} >
                News aus einem anderem Jahr ansehen
            </Typography>
            <div className={classes.yearRow}>
                { years.length === 0 && <LinearProgress style={{marginBottom: '1em'}} /> }
                { years.map((year, i) => (
                    <IconButton key={i} onClick={() => setSelectedYear(year)}>
                        <Avatar className={ selectedYear === year ? classes.activeAvatar: classes.avatar }>
                            {year}
                        </Avatar>
                    </IconButton>
                )) }
            </div>
        </Box>
    </Container>
};

export default News;
