import React, {useEffect, useState} from "react";
import {useStyles} from "./styling";
import Container from "@material-ui/core/Container";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Helmet} from "react-helmet";
import {database} from "../../common/firebase";
import DbImage from "../../components/dbImage";
import Tooltip from "@material-ui/core/Tooltip";

function Home() {
    const {wrapperImgCarousel, imgContainer, newsContainer, newsContainerImage, wrapperMainPic, heightWrapper} = useStyles();

    const [header, setHeader] = useState();
    const [linkList, setLinkList] = useState([]);

    useEffect(() => {
        database.ref('pages/home/header').on('value', snap => {
            if (!snap.exists()) {
                database.ref('pages/home/header')
                .set({image: 'WelcomeHeader.jpg', alt: 'Link als Bild zum Whisky-Shop', link: '/whisky/', tooltip: 'Zum Shop'});
            }
            setHeader({...snap.val()});
        })
        database.ref('pages/home/linkElements').on('value', snap => {
            if (!snap.exists()) return;
            const cache = []
            snap.forEach(snapElement => {
                const element = {...snapElement.val()};
                cache.push(element);
            })
            setLinkList(cache);
        })
    }, [])

    return <div className={heightWrapper}>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - World Whisky WW OG - Übersicht</title>
            <meta name="description"
                  content="Whiskypezialitäten aus aller Welt, Raritätenwebshop, Individuelle Tastings, Worldwhisky, Irischer Whiskey, Eigenabfüllungen, Whiskykostprobenservice, WhiskyAbo und mehr"
            />
        </Helmet>
        {!!header && <div className={wrapperMainPic}>
            <div className={wrapperImgCarousel}>
                <Tooltip title={header.tooltip ?? ''}>
                    <Link to={header.link}>
                        <DbImage className={imgContainer} src={['images', 'home', header.image]} alt={header.alt}/>
                    </Link>
                </Tooltip>
            </div>
        </div>}
        <Container maxWidth={'md'}>
            <hr/>
            <Grid container spacing={1}>
                {linkList.map((element, i) => (
                    <Grid item sm={12} md={6} key={i}>
                        <Tooltip title={element.tooltip ?? ''}>
                            <Link to={element.link} className={newsContainer}>
                                <DbImage className={newsContainerImage} src={['images', 'home', element.image]} alt={element.alt}/>
                            </Link>
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
            <hr/>
        </Container>
    </div>
}

export default Home;


/*
 <>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - World Whisky WW OG - Übersicht</title>
            <meta name="description"
                  content="Whiskypezialitäten aus aller Welt, Raritätenwebshop, Individuelle Tastings, Worldwhisky, Irischer Whiskey, Eigenabfüllungen, Whiskykostprobenservice, WhiskyAbo und mehr"
            />
        </Helmet>
        {!!header && <div className={wrapperMainPic}>
            <div className={wrapperImgCarousel}>
                <Tooltip title={header.tooltip}>
                    <Link to={'/whisky/'}>
                        <img className={imgContainer} src={whisky1} alt={'Bild mit Whisky'}/>
                    </Link>
                </Tooltip>
            </div>
        </div>}
        <Container maxWidth={'md'}>
            <hr/>
            <Grid container spacing={1}>
                <Grid item sm={12} md={6}>
                    <Link to={'/whiskyabo/'} className={newsContainer}>
                        <img className={newsContainerImage} src={abo} alt={'Bild für link zu Abo'}/>
                    </Link>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Link to={'/events/'} className={newsContainer}>
                        <img className={newsContainerImage} src={event} alt={'Bild für link zu Events'}/>
                    </Link>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Link to={'/whisky/sample/'} className={newsContainer}>
                        <img className={newsContainerImage} src={lights} alt={'Bild für link zu Kostproben'}/>
                    </Link>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Link to={'/contact/form/'} className={newsContainer}>
                        <img className={newsContainerImage} src={tasting} alt={'Bild für link zu Tastings'}/>
                    </Link>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Link to={'whisky/config/'} className={newsContainer}>
                        <img className={newsContainerImage} src={konfigurator} alt={'Bild für link zu Konfigurator'}/>
                    </Link>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Link to={'/news/'} className={newsContainer}>
                        <img className={newsContainerImage} src={news} alt={'Bild für link zu News'}/>
                    </Link>
                </Grid>
            </Grid>
            <hr/>
        </Container>
    </>
 */
