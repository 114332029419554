import i18n from "i18next";
import deJson from "./de_i18n";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    de: deJson
};

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources,
    lng: "de",
    fallbackLng: "de",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;