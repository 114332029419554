import React, {useEffect, useState} from "react";
import {AppBar, Avatar, Card, CardContent, CardHeader, CircularProgress, makeStyles, Paper} from "@material-ui/core";
import {database} from "../../../common/firebase";
import {inject, observer} from "mobx-react";
import {getAmount} from "../../../common/statelessFunctions";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    grid: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        },
        display: 'grid',
        gridGap: theme.spacing(2),
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        padding: theme.spacing(2)
    }
}))

const AdminDashboard = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [countWaitingOrders, setCountWaitingOrders] = useState(null);
    const [countPayedOrders, setCountPayedOrders] = useState(null);
    const [countNewMessages, setCountNewMessages] = useState(null);
    const [countArticles, setCountArticles] = useState(null);
    const articles = store.articleStore.devSavedArticles.slice();


    useEffect(() => {
        //get Waiting Order count
        database.ref('orders').orderByChild('state').equalTo('waiting').on('value', snap => {
            setCountWaitingOrders(snap.numChildren());
        })

        // get Progress and Payed Orders
        database.ref('orders').orderByChild('state').startAt('p').endAt('p' + "\uf8ff").on('value', snap => {
            setCountPayedOrders(snap.numChildren());
        })

        // get new Messages
        database.ref('appointment').orderByChild('isRead').equalTo(false).on('value', snap => {
            setCountNewMessages(snap.numChildren());
        })

    }, []);

    useEffect(() => {
        //get Article count
        setCountArticles(articles.filter( article => !article.isArchived && getAmount(article) > 0).length);
    }, [articles]);

    return <Paper>
        <AppBar position="static" className={classes.appBar}>Dashboard</AppBar>
        <div className={classes.grid}>
            <Card>
                <CardHeader
                    title={"Offene Bestellungen"}
                    avatar={
                        <Avatar aria-label="open" className={classes.avatar}>
                            { countWaitingOrders || countWaitingOrders === 0 ? <span>{countWaitingOrders}</span> : <CircularProgress /> }
                        </Avatar>
                    }/>
                <CardContent>
                    Es warten {countWaitingOrders} Bestellungen auf eine Bezahlung.
                    <br />
                    <Link to={'/admin/orders/?id=waiting'}> Zu den Bestellungen </Link>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    title={"Bestellungen zum Bearbeiten"}
                    avatar={
                        <Avatar aria-label="todo" className={classes.avatar}>
                            { countPayedOrders || countPayedOrders === 0 ? <span>{countPayedOrders}</span> : <CircularProgress /> }
                        </Avatar>
                    }/>
                <CardContent>
                    Es gibt {countPayedOrders} Bestellungen, die noch bearbeitet gehören.
                    <br />
                    <Link to={'/admin/orders/?id=progress'}> Zu den Bestellungen </Link>
                </CardContent>
            </Card>

            <Card>
                <CardHeader
                    title={"Neue Anfragen"}
                    avatar={
                        <Avatar aria-label="questions" className={classes.avatar}>
                            { countNewMessages || countNewMessages === 0 ? <span>{countNewMessages}</span> : <CircularProgress /> }
                        </Avatar>
                    }/>
                <CardContent>
                    Es sind {countNewMessages} neue Anfragen vorhanden.
                    <br />
                    <Link to={'/admin/appointments/'}> Zu den Anfragen </Link>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    title={"Artikel Online"}
                    avatar={
                        <Avatar aria-label="online" className={classes.avatar}>
                            { countArticles || countArticles === 0 ? <span>{countArticles}</span> : <CircularProgress /> }
                        </Avatar>
                    }/>
                <CardContent>
                    Zurzeit sind {countArticles} online.
                    <br />
                    <Link to={'/admin/articles/'}> Zu den Artikel </Link>
                </CardContent>
            </Card>
        </div>
    </Paper>

}));

export default AdminDashboard;
