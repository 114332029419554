import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {Modal} from "../../../../components";
import {Avatar, Button, TableCell, TableRow, TextField, Typography} from "@material-ui/core";
import {useStyles} from "./styling";
import {EditWhisky} from "./editWhisky";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {EditDescription} from "./editDescription";
import {EditOnline, EditImages} from "components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {DateTime} from 'luxon';
import {typeList} from "common/constants";
import {ImagePickerModal} from "../../../../components/ImagePickerModal";
import DbImage from "../../../../components/dbImage";
import {database, storage} from "../../../../common/firebase";


export const EditArticleModal = inject('store')(observer(({store, open, handleToggleOpen, selectedArticle}) => {
    const classes = useStyles();
    const [articleNumber, setArticleNumber] = useState('');
    const [type, setType] = useState('whisky');
    const [typesL, setTypesL] = useState([]);
    const [producer, setProducer] = useState('');
    const [productType, setProductType] = useState('');
    const [distillery, setDistillery] = useState('');
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [volume, setVolume] = useState('');
    const [size, setSize] = useState('');
    const [alcVolume, setAlcVolume] = useState('');
    const [finish, setFinish] = useState('');
    const [origin, setOrigin] = useState('');
    const [region, setRegion] = useState('');
    const [peated, setPeated] = useState('');
    const [barrel, setBarrel] = useState('');
    const [grain, setGrain] = useState('');
    const [description, setDescription] = useState('');
    const [isOnline, setIsOnline] = useState(false);
    const [rating, setRating] = useState('');
    const [startDate, setStartDate] = useState(DateTime.local());
    const [images, setImages] = useState([]);
    const [thumbnailList, setThumbnailList] = useState([]);
    const [overviewImages, setOverviewImages] = useState([]);

    const [editImagesOpen, setEditImagesOpen] = useState(false);

    useEffect(() => {
        if (!!selectedArticle && open) {
            setArticleNumber(selectedArticle.articleNumber || store.articleStore.articleNumber);
            setType(selectedArticle.type || 'whisky');
            if (!!selectedArticle.type && !selectedArticle.types) {
                setTypesL(typeList.filter(tl => selectedArticle.type === tl.value))
            } else if (!!selectedArticle.types) {
                setTypesL(selectedArticle.types.flatMap(t => typeList.filter(tl => t === tl.value)) || []);
            }
            setProducer(selectedArticle.producer ?? '');
            setProductType(selectedArticle.productType ?? '');
            setDistillery(selectedArticle.distillery ?? '');
            setName(selectedArticle.name ?? '');
            setAge(selectedArticle.age ?? '');
            setVolume(selectedArticle.volume ?? '');
            setAlcVolume(selectedArticle.alcVolume ?? '');
            setFinish(selectedArticle.finish ?? '');
            setOrigin(selectedArticle.origin ?? '');
            setRegion(selectedArticle.region ?? '');
            setPeated(selectedArticle.peated ?? '');
            setBarrel(selectedArticle.barrel ?? '');
            setGrain(selectedArticle.grain ?? '');
            setDescription(selectedArticle.description ?? '');
            setIsOnline(selectedArticle.isOnline);
            setRating(selectedArticle.rating ?? '');
            if(!!selectedArticle.startDate && !isNaN(selectedArticle.startDate)) setStartDate(DateTime.fromMillis(selectedArticle.startDate));
            if(!!selectedArticle.startDate && isNaN(selectedArticle.startDate)) setStartDate(DateTime.fromFormat(selectedArticle.startDate, 'yyyy-LL-dd'))
            if(!selectedArticle.startDate) setStartDate(DateTime.local(9999))
            setImages(selectedArticle.images !== undefined && selectedArticle.images != null && Array.isArray(selectedArticle.images) ? [...selectedArticle.images] : []);
            setThumbnailList(selectedArticle.thumbnails !== undefined && selectedArticle.thumbnails != null && Array.isArray(selectedArticle.thumbnails) ? [...selectedArticle.thumbnails] : [])
            setOverviewImages(selectedArticle.overviewImages !== undefined && selectedArticle.overviewImages != null && Array.isArray(selectedArticle.overviewImages) ? [...selectedArticle.overviewImages] : [])
        } else if (open) {
            setProducer('');
            setProductType('');
            setDistillery('');
            setName('');
            setAge('');
            setVolume('');
            setAlcVolume('');
            setFinish('');
            setOrigin('');
            setRegion('');
            setPeated('');
            setBarrel('');
            setGrain('');
            setDescription('');
            setIsOnline(false);
            setRating('');
            setStartDate(DateTime.local(9999));
            setImages([]);
            setThumbnailList([]);
            setOverviewImages([])
        }
    }, [open, selectedArticle, store.articleStore.articleNumber]);

    useEffect(() => {
        const {articleNumber} = selectedArticle;
        if (!articleNumber || articleNumber === '') {
            setArticleNumber(store.articleStore.articleNumber);
        }
    }, [selectedArticle, store.articleStore.articleNumber]);

    useEffect(() => {
        if (isOnline) {
            if(!!selectedArticle?.startDate && !isNaN(selectedArticle.startDate)) return setStartDate(DateTime.fromMillis(selectedArticle.startDate));
            if(!!selectedArticle?.startDate && isNaN(selectedArticle.startDate)) return setStartDate(DateTime.fromFormat(selectedArticle.startDate, 'yyyy-LL-dd'))
            setStartDate(DateTime.local());
        } else {
            setStartDate(DateTime.local(9999));
        }
    }, [isOnline, selectedArticle])

    const getArticleName = () => {
        if (type === 'whisky') {
            let text = '';
            text += name ? name + ' - ' : '';
            text += producer ? producer + ' - ' : '';
            text += distillery ? distillery + ' - ' : '';
            text += age ? age + 'J. - ' : '';
            text += volume ? volume + 'L' : '';
            return text;
        } else {
            return name;
        }
    };

    const saveArticle = () => {
        const localImages = [...images];
        return saveArticleAfterDelete(localImages);
    };

    const saveArticleAfterDelete = (localImages) => {
        const types = typesL.map(t => t.value);

        store.articleStore.updateArticle({
            articleNumber, type, producer, distillery, name, age, volume, alcVolume, finish, origin, region, peated, barrel, grain, description, isOnline,
            rating, startDate: startDate.toFormat('yyyy-LL-dd'), images: localImages, size, productType, types, thumbnails: thumbnailList, overviewImages,
        });
        setImages([]);
        setThumbnailList([]);
        setOverviewImages([]);
        handleToggleOpen();
    }

    const deleteOldImg = url => {
        const nextImages = [...images];
        const index = images.indexOf(url);
        if(index > -1) nextImages.splice(index, 1);
        setImages(nextImages);
    }

    const handleChange = (event, value, element) => {
        if (value.length < 1) return;
        setTypesL(value);
    };

    const handleThumbnailClick = (nextThumbnailList) => {
        const changeList = nextThumbnailList.filter(thumbnail => !thumbnailList.includes(thumbnail));
        setThumbnailList(changeList);
    }

    const handleOverviewImagesClick = (nextOverviewImages) => {
        setOverviewImages(nextOverviewImages);
    }

    return <Modal title={"Artikel: " + getArticleName(selectedArticle)} open={open} handleClose={handleToggleOpen}
                  actions={<div>
                      <Button color={"primary"} variant={"contained"} onClick={saveArticle}>Save</Button>
                  </div>}>
        <form className={classes.root} noValidate autoComplete="off">
            <TextField
                className={classes.textFieldStyle}
                id="article-number"
                label="Artikel nummer"
                defaultValue={articleNumber}
                type="text"
                name={'articleNumber'}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled
                fullWidth
            />
            <br/>

            <Autocomplete
                id="type"
                size="medium"
                multiple
                value={typesL}
                limitTags={3}
                options={typeList}
                onChange={handleChange}
                getOptionLabel={(option) => option.label}
                style={{width: '100%'}}
                renderInput={(params) => <TextField {...params} label="Artikel Kategorien" variant="outlined"/>}
            />
            <div className={classes.infoCat}><FontAwesomeIcon icon={faInfoCircle}/>Schon alle Kategorien Gewählt?</div>

            <EditWhisky producer={producer} setProducer={setProducer} producersList={store.articleStore.producersList}
                        finish={finish} setFinish={setFinish} finishsList={store.articleStore.finishsList}
                        distillery={distillery} setDistillery={setDistillery} distilleriesList={store.articleStore.distilleriesList}
                        origin={origin} setOrigin={setOrigin} originsList={store.articleStore.originsList}
                        barrel={barrel} setBarrel={setBarrel} barrelList={store.articleStore.barrelList}
                        peated={peated} setPeated={setPeated} peatedList={store.articleStore.peatedList}
                        name={name} setName={setName}
                        alcVolume={alcVolume} setAlcVolume={setAlcVolume}
                        age={age} setAge={setAge}
                        grain={grain} setGrain={setGrain}
                        region={region} setRegion={setRegion}
                        volume={volume} setVolume={setVolume}
                        productType={productType} setProductType={setProductType} productTypeList={store.articleStore.productTypeList}
                        size={size} setSize={setSize}
                        classes={classes}
                        types={typesL}
            />

            <EditDescription setRating={setRating} description={selectedArticle.description} rating={selectedArticle.rating} setDescription={setDescription}
                             classes={classes}/>

            <div style={{padding: '1rem 0.5rem'}}>
                {thumbnailList.length > 0 && <Typography variant={"h6"}>Thumbnail</Typography>}
                {thumbnailList.map((image, i) => (
                    <Avatar key={i} variant={'rounded'} style={{display: 'inline-block', margin: '0.5rem', width: '4em', height: '4em'}}>
                        <DbImage src={['images', selectedArticle.articleNumber, image]} alt={image} style={{width: '100%', height: '100%'}} />
                    </Avatar>
                ))}
                {overviewImages.length > 0 && <Typography variant={"h6"}>Detail Bilder</Typography>}
                {overviewImages.map((image, i) => (
                    <Avatar key={i} variant={'rounded'} style={{display: 'inline-block', margin: '0.5rem', width: '5em', height: '5em'}}>
                        <DbImage src={['images', selectedArticle.articleNumber, image]} alt={image} style={{width: '100%', height: '100%'}}/>
                    </Avatar>
                ))}
                <br/>
                <Button color={"primary"} variant={"contained"} onClick={() => setEditImagesOpen(true)}>Bilder Bearbeiten</Button>
            </div>
            <ImagePickerModal
                open={editImagesOpen}
                handleClose={() => setEditImagesOpen(false)}
                subPath={selectedArticle.articleNumber}
                oldImages={images}
                deleteOldImg={deleteOldImg}
                selectedTypeOne={overviewImages} selectTypeOne={"Detail Bilder"} selectTypeOneCallback={handleOverviewImagesClick}
                selectedTypeTwo={thumbnailList} selectTypeTwo={"Thumbnail"} selectTypeTwoCallback={handleThumbnailClick}
            />
            <EditOnline isOnline={isOnline} startDate={startDate} setIsOnline={setIsOnline} setStartDate={setStartDate} classes={classes}/>

        </form>
    </Modal>
}));
