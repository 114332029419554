import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faWineBottle} from "@fortawesome/free-solid-svg-icons";
import {Button, Menu, MenuItem} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useStyles} from "./styling";
import DbImage from "../dbImage";

const ArticleObject = ({product, handleClickOnProduct, addToCart}) => {
        const {otherFont,textOneLine,  textTwoLines, textPosition, firstWrapper, articleObject, hoverEffect, productImgStyle, productImgStyleIcon, infoPrice, adminRow, menuBtn, price} = useStyles();
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const cleanPriceString = (price) => {
            if (Number.isInteger(parseFloat(price))) {
                return price + ',-';
            } else {
                return price;
            }
        };

        const getThumbnailTag = () => {
            if(!!product.thumbnail) return <DbImage className={productImgStyle} alt={`Whisky flaschen ${product.articleNumber}`} src={['images', product.articleNumber, product.thumbnail]} />
            if(!!product.image) return <img className={productImgStyle} alt={'whisky flaschen bild'} src={product.image}/>
            return <FontAwesomeIcon className={productImgStyleIcon} icon={faWineBottle}/>
        };

        return <div className={articleObject}>
            <div className={hoverEffect + ' ' + firstWrapper} onClick={handleClickOnProduct(product.articleNumber, product.name ?? '-')}>
                {getThumbnailTag()}
                <div className={textPosition}>
                    <div>
                        <div className={[textTwoLines, otherFont].join(' ')}>{product.name || '-'}</div>
                        <div className={[textTwoLines, otherFont].join(' ')}>{product.isSet ? 'WorldWhisky.at' : (product.producer || product.distillery || '-')}</div>
                    </div>
                    <div style={{marginTop: '0.25em'}}>
                        {!!product.vk_net && <div className={price}>€ {cleanPriceString(product.vk_net)}</div>}
                        <div className={textOneLine}>
                            {!!product.price_lt && product.price_lt !== -1 && <div className={infoPrice}>1L = € {cleanPriceString(product.price_lt)}</div>}
                            {!!product.price_lt && product.price_lt === -1 && !!product.size && product.size !== "" &&
                            <div className={infoPrice}>Menge: {product.size}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Button variant={'contained'} color={'primary'} onClick={addToCart(product)} className={otherFont}>in den Warenkorb</Button>
            </div>
        </div>
    }
;

export default ArticleObject;
