import AdminStore from "./adminStore";
import ArticleStore from "./articleStore";
import UserStore from "./userStore";
import WishListStore from "./wishListStore";
import CartStore from "./cartStore";
import OrderStore from "./orderStore";
import SnackBarHandle from "./snackBarHandler";
import BillStore from "./billStore";

const adminStore = new AdminStore();
const articleStore = new ArticleStore();
const billStore = new BillStore();
const userStore = new UserStore();
const wishListStore = new WishListStore();
const cartStore = new CartStore();
const orderStore = new OrderStore();
const snackBarHandle = new SnackBarHandle();
const isWw =  window.location.href.includes('world') ; //|| window.location.href.includes('localhost');

const Store = {
    adminStore,
    billStore,
    userStore,
    articleStore,
    wishListStore,
    cartStore,
    orderStore,
    snackBarHandle,
    isWw
};

export default Store;