import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useParams, useHistory} from "react-router-dom";
import {LinearProgress, Container, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import {useStyles} from "./styling";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();
const WhiskyDetailEdit = inject('store')(observer(({store}) => {
    const {
        headerBlockStyle, headerNameBlockStyle, headerPriceBlockStyle, headerElementStyle, subHeaderElementStyle, subHeaderElementSmallerStyle
    } = useStyles();
    const history = useHistory();
    const {id} = useParams();
    const [product, setProduct] = useState(null);

    const [name, setName] = useState('');
    const [producer, setProducer] = useState('');
    const [distillery, setDistillery] = useState('');
    const [age, setAge] = useState('');
    const [volume, setVolume] = useState('');
    const [grain, setGrain] = useState('');

    const {producersList, distilleriesList} = store.articleStore;

    useEffect(() => {
        try {
            if (!id) throw new Error('no id found');
            const dbRef = store.articleStore.getArticleRef(id);
            dbRef.on('value', snap => {
                if (!snap.val()) throw new Error('article not found error');
                setProduct(snap.val());
            });
        } catch (e) {
            console.error(e);
            history.push('/whisky/')
        }
    }, [id, history, store.articleStore]);

    useEffect(() => {
        if (!!product) {
            setName(product.name || '');
            setProducer(product.producer || '');
            setDistillery(product.distillery || '');
            setAge(product.age || '');
            setVolume(product.volume || '');
            setGrain(product.grain || '');
            product.vk_pre = product.vk_pre || 0;
        }
    }, [product, history, store.articleStore]);

    const getPriceNet = (tax) => {
      return Math.ceil( product.vk_pre * product.vk_pre * tax * 100 ) / 100;
    };

    return !product ? <LinearProgress/> : <Container maxWidth={"md"}>
        <div className={headerBlockStyle}>
            <div className={headerNameBlockStyle}>
                <div className={headerElementStyle}>
                    <TextField
                        id="article-name"
                        label="Name"
                        value={name}
                        type="string"
                        name={'name'}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                    /> -
                    <Autocomplete
                        value={producer}
                        onChange={(event, newValue) => {
                            if (!newValue) {
                                setProducer('');
                            } else if (typeof newValue === 'string') {
                                setProducer(newValue);
                            } else if (newValue && newValue.inputValue) {
                                setProducer(newValue.inputValue);
                            } else {
                                setProducer(newValue.title);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    title: `Add "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="article-producer"
                        options={producersList.map(a => ({title: a}))}
                        getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
                        freeSolo
                        autoHighlight
                        style={{minWidth: '200px'}}
                        renderOption={(option) => option.title}
                        renderInput={(params) => (
                            <TextField {...params} label="Hersteller" variant="standard"/>
                        )}
                    /> -
                    <Autocomplete
                        value={distillery}
                        onChange={(event, newValue) => {
                            if (!newValue) {
                                setDistillery('');
                            } else if (typeof newValue === 'string') {
                                setDistillery(newValue);
                            } else if (newValue && newValue.inputValue) {
                                setDistillery(newValue.inputValue);
                            } else {
                                setDistillery(newValue.title);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    title: `Add "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="article-distillery"
                        options={distilleriesList.map(a => ({title: a}))}
                        getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
                        freeSolo
                        autoHighlight
                        style={{minWidth: '200px'}}
                        renderOption={(option) => option.title}
                        renderInput={(params) => (
                            <TextField {...params} label="Distillery" variant="standard"/>
                        )}
                    /> -
                    <TextField
                        id="article-age"
                        label="Alter"
                        value={age}
                        type="number"
                        name={'age'}
                        onChange={(e) => setAge(e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">J</InputAdornment>,
                            inputProps: {min: 0, step: 1}
                        }}
                    /> -
                    <TextField
                        id="article-volume"
                        label="Menge"
                        value={volume}
                        type="number"
                        name={'volume'}
                        onChange={(e) => setVolume(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">L</InputAdornment>,
                            inputProps: {min: 0, step: 0.1}
                        }}
                        fullWidth
                    />
                </div>
                <div className={subHeaderElementStyle}>
                    <FormControl >
                        <InputLabel id="article-grain-label">Getreide</InputLabel>
                        <Select
                            labelId="article-grain-label"
                            id="article-grain"
                            name={'grain'}
                            MenuProps={{
                                disableScrollLock: true
                            }}
                            value={grain}
                            onChange={(e) => setGrain(e.target.value)}
                        >
                            <MenuItem value={'singleMalt'}>Single Malt</MenuItem>
                            <MenuItem value={'singleGrain'}>Single Grain</MenuItem>
                            <MenuItem value={'grain'}>Grain</MenuItem>
                            <MenuItem value={'blend'}>Blend</MenuItem>
                            <MenuItem value={'postStill'}>PostStill</MenuItem>
                        </Select>
                    </FormControl></div>
            </div>
            <div className={headerPriceBlockStyle}>
                <div className={headerElementStyle}>
                    {!!product && !!product.vk_pre && product.vk_pre !== 0 ?
                        <span>€{getPriceNet(0.2)}.-</span>:
                        <span onClick={() => console.log('test')}><FontAwesomeIcon icon={faPlus}/></span>
                    }
                </div>
                <div className={[subHeaderElementStyle,
                                 subHeaderElementSmallerStyle].join(' ')}>Preis {store.userStore.currentRole === 0b0010 ? 'exkl.' : 'inkl.'} Mwst / exkl.
                                                                          Versand
                </div>
            </div>
        </div>
    </Container>
}));

export default WhiskyDetailEdit
