import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import DbImage from "../../../components/dbImage";
import {database} from "../../../common/firebase";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BannerSliderDbImage, Modal} from "../../../components";
import {AppBar, Button, Paper, TextField} from "@material-ui/core";
import {ImagePickerModal} from "../../../components/ImagePickerModal";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles(theme => ({
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    wrapperImgCarousel: {
        width: '1270px',
        maxWidth: '100%',
        padding: '1em 24px 0'
    },
    textFieldStyle: {
        marginBottom: '1em'
    },
    imgContainer: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    newsContainer: {
        cursor: 'pointer',

    },
    newsContainerImage: {
        width: '100%',
        height: '100%',
        border: 'solid 2px #333',
        borderRadius: '0.1em',
        minHeight: 250,

        '&:hover': {
            borderColor: 'black',
            boxShadow: '0 0 5px 0px #333',
        }
    },
    plusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '4em',
        borderRadius: 0
    },
    modalClickableImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        minHeight: '75px',
        cursor: 'pointer',
    },
    wrapperMainPic: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    marginLeft: {
        marginRight: '0.25em'
    },
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    bannerRow:{
        display: 'flex',
        justifyContent: "center",
        minHeight: 465,
        marginTop: theme.spacing(2),
    },
    bannerWrapper:{
        maxWidth: 600,
        width: '100%'
    },
    tableWrapper:{
        marginBottom: '2em'
    },
    hoverRow:{
        cursor:'pointer',
        '&:hover':{
            backgroundColor: '#aaa'
        }
    }
}));

export const AdminEditBanner = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [linkList, setLinkList] = useState([]);

    const [elementToEdit, setElementToEdit] = useState();
    const [saving, setSaving] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        database.ref('pages/banner').on('value', snap => {
            if (!snap.exists()) return;
            const cache = []
            snap.forEach(snapElement => {
                const element = {...snapElement.val(), key: snapElement.key};
                cache.push(element);
            })
            setLinkList(cache);
        })
    }, [])

    const addImageLink = (event) => {
        database.ref('pages/banner').push({img: '', alt: 'Werbebild', link: ''});
    }

    const saveElementToEdit = () => {
        setSaving(true);
        const res = 'pages/banner';
        database.ref(res).child(elementToEdit.key).set(elementToEdit).then(res => {
            setSaving(false);
            setElementToEdit(null);
        }).catch(e => {
            store.snackBarHandle.messages('Beim speicher ist etwas schief gegangen');
        });
    }

    const deleteElementToEdit = () => {
        setSaving(true);
        database.ref('pages/banner').child(elementToEdit.key).set(null).then(res => {
            setSaving(false);
            setElementToEdit(null);
        }).catch(e => {
            store.snackBarHandle.messages('Beim löschen ist etwas schief gegangen');
        });
    }

    const handleChangeImageOfElementToEdit = (imgs) => {
        const changeList = imgs.filter(image => elementToEdit.img !== image);
        setImages(changeList);
        setElementToEdit({...elementToEdit, img: changeList[0]});
    }

    return <>
        <AppBar position="static" className={classes.appBar}>Banner bearbeiten</AppBar>
        <div className={classes.bannerRow}>
            <div className={classes.bannerWrapper}>
                <BannerSliderDbImage items={linkList} subPath={'banner'} />
            </div>
        </div>

        <TableContainer component={Paper} className={[classes.otherFont, classes.tableWrapper].join(' ')}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Bildname</TableCell>
                        <TableCell>Alternative Text</TableCell>
                        <TableCell>Link</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {linkList.map((element, index) => (
                        <TableRow onClick={() => setElementToEdit(element)} key={index} className={classes.hoverRow}>
                            <TableCell>{element.img}</TableCell>
                            <TableCell>{element.alt}</TableCell>
                            <TableCell>{element.link}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow onClick={addImageLink} className={classes.hoverRow}>
                        <TableCell colSpan={3} style={{textAlign: "center"}}> <FontAwesomeIcon icon={faPlus} /> </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Modal title={'Bearbeiten des Elementes'} size={'md'} handleClose={() => setElementToEdit(null)} open={!!elementToEdit} actions={
            <div>
                <Button className={[classes.otherFont, classes.marginLeft].join(' ')} color={"primary"} variant={"outlined"}
                        onClick={() => setElementToEdit(null)} disabled={saving}>Abbrechen</Button>
                { elementToEdit?.key !== 'header' && <Button className={[classes.otherFont, classes.marginLeft].join(' ')} color={"secondary"} variant={"contained"} onClick={deleteElementToEdit}
                        style={{marginRight: '0.25em'}} disabled={saving}>Löschen</Button> }
                <Button className={classes.otherFont} color={"primary"} variant={"contained"} onClick={saveElementToEdit}
                        disabled={saving}>{saving} Speichern</Button>
            </div>
        }>
            {!!elementToEdit && <div className={classes.otherFont}>
                <div onClick={() => setImages([elementToEdit.img])}>
                    <span>{elementToEdit.key}</span>
                    <span>Gewünschte dimensionen: 600 x 426</span>
                    <DbImage className={classes.modalClickableImage} src={['images', 'banner', elementToEdit.img]}/></div>
                <div><TextField
                    className={classes.textFieldStyle}
                    id="element-alt"
                    label="Alternative Text"
                    value={elementToEdit.alt}
                    helperText={'Text der angezeigt wird wenn das Bild nicht geladen werden kann.'}
                    type="text"
                    name={'alt'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setElementToEdit({...elementToEdit, alt: e.target.value})}
                    fullWidth
                /></div>
                <div><TextField
                    className={classes.textFieldStyle}
                    id="element-link"
                    label="Link"
                    value={elementToEdit.link}
                    placeholder={'/whiksy/'}
                    helperText={'Link für das Bild, auf diese Seite wird bei Klick weiter geleitet'}
                    type="text"
                    name={'link'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setElementToEdit({...elementToEdit, link: e.target.value})}
                    fullWidth
                /></div>
            </div>}
        </Modal>

        <ImagePickerModal
            open={images.length > 0}
            handleClose={() => setImages([])}
            subPath={'banner'}
            selectedTypeOne={images} selectTypeOne={"Auswahl"} selectTypeOneCallback={handleChangeImageOfElementToEdit}
        />
    </>
}))
