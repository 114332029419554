import {observable, action, decorate} from "mobx"

class WishListStore {
    messages = [];

    addMessages = message => {
        this.messages.push(message);
    };

    removeMessages = index => {
        this.messages.splice(index,1);
    }
}

decorate(WishListStore, {
    messages: observable,
    addMessages: action,
    removeMessages: action,
});

export default WishListStore;
