import React from "react";
import {ArticleList} from "components";
import {Helmet} from "react-helmet";

function Set() {
    return <>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - Geschenke für Genießer, individuell gefüllte Geschenkboxen anfragen unter +43 6645345917</title>
        </Helmet>
        <ArticleList type={'set'} /></>
}

export default Set;
