import {DateTime} from 'luxon'

export const typeList = [
    {label: 'Whisky', value: 'whisky'},
    {label: 'Whisk(e)y Verwandter', value: 'relative'},
    {label: 'Ein Pairing', value: 'pairing'},
    {label: 'Geschenk', value: 'present'},
    {label: 'Zubehör', value: 'accessory'},
    {label: 'Whisky Lights', value: 'lights'},
    {label: 'Tastingset', value: 'set'},
    {label: 'Kostprobe', value: 'sample'},

    {label: 'Rarität', value: 'rarity'},
    {label: 'Beginner', value: 'beginner'},
    {label: 'Fortgeschrittene', value: 'advanced'},
    {label: 'Kenner & Sammler', value: 'master'},
    {label: 'Spezialität', value: 'special'},
    {label: 'Einzelstücke', value: 'limit'},
];

export const emptyBatch = {
    date: DateTime.local().toFormat('yyyy-LL-dd'),
    amount: '',
    ek: 0,
    spread: 0,
    vk_pre: 0,
    vk_net: 0,
}
