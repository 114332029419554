import React, {useEffect, useState} from 'react';
import {database} from "common/firebase";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {AppBar, Paper, makeStyles, IconButton, Tooltip} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import {faCheckCircle, faCircle, faCogs} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}))

const AppointmentList = () => {
    const classes = useStyles();
    const [list, setList] = useState([]);

    useEffect( () => {
        database.ref('appointment').orderByKey().on('value', snap => {
            if(!snap.exists()) return;
            const cacheList = [];
            snap.forEach( childSnap => {
                const element = childSnap.val();
                element.key= childSnap.key;
                cacheList.push(element);
            });
            setList(cacheList);
        })
    },[]);

    const toggleIsRead = ap => e => {
        database.ref('appointment').child(ap.key).child('isRead').set(!ap.isRead);
    }

    return <div>
        <AppBar position="static" className={classes.appBar}>Anfragen</AppBar>
        <TableContainer component={Paper} className={classes.otherFont}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Anfragesteller</TableCell>
                        <TableCell align="right">Datum</TableCell>
                        <TableCell align="right">Tel</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Adresse</TableCell>
                        <TableCell align="right">Info</TableCell>
                        <TableCell align="right">Gelesen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((ap, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {ap.name}
                            </TableCell>
                            <TableCell align="right">{ap.datum}</TableCell>
                            <TableCell align="right">{ap.tel}</TableCell>
                            <TableCell align="right">{ap.email}</TableCell>
                            <TableCell align="right">{ap.address}</TableCell>
                            <TableCell align="right">{ap.info}</TableCell>
                            <TableCell align="right">
                                <Tooltip title={'Gibt an ob die nachricht schon gelesen wurde'}>
                                <IconButton onClick={toggleIsRead(ap)}>
                                {ap.isRead ? <FontAwesomeIcon icon={faCheckCircle}/> : <FontAwesomeIcon icon={faCircle} /> }
                                </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6} align="right">Hier sollen dann weiter Informationen stehen</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <div style={{height: 24}}/>
    </div>
};

export default AppointmentList;
