import React, {useState} from 'react';
import {inject, observer} from "mobx-react";
import {useHistory} from 'react-router-dom';
import {auth} from "common/firebase";

import {Box, Button, Container, InputAdornment, TextField} from "@material-ui/core";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {faEyeSlash, faGlassWhiskey, faUserCircle, faUserSecret} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useStyles} from "./styling";
import {RegisterModal} from "../../components";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import SecretUserModal from "../../components/SecretUserModal";

const CartLogin = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoginOpen, setIsLoginOpen] = useState();
    const toggleLogin = () => setIsLoginOpen(!isLoginOpen);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isSecretOpen, setIsSecretOpen] = useState(false);
    const [mail, setMail] = useState('');
    const [pw, setPw] = useState('');
    const [pwDisplay, setPwDisplay] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const [onReset, setOnReset] = useState(false);

    const submitRegister = () => {
        history.push('/warenkorb/payment')
    };

    const signIn = () => {
        setLoading(true);
        setError(null);
        auth.signInWithEmailAndPassword(mail, pw)
        .then((user) => {
            setTimeout(() => {
                setLoading(false);
                history.push('/warenkorb/payment');
            }, 1000);
        })
        .catch((error) => {
            setLoading(false);
            setError(error.code + ' : ' + error.message);
        });
    }

    const resetPasswordToggle = () => {
        setResetPasswordOpen(bool => !bool);
    }

    const resetPassword = async () => {
        setOnReset(true);
        try {
            if (mail === "") return store.snackBarHandle.addMessages("Die Email Adresse darf nicht leer sein!")
            await auth.sendPasswordResetEmail(mail);
            setResetPasswordOpen(bool => !bool);
            store.snackBarHandle.addMessages("E-Mail zurücksetzen erfolgreich gestartet, mail kommt demnächst");
        } catch (e) {
            console.log(e)
            store.snackBarHandle.addMessages("Fehler beim Zurücksetzen, ist ihre E-mail valide? Besitzen sie überhaupt einen Account mit dieser Mailadresse ?");
        } finally {
            setOnReset(false);
        }

    }

    return <Container maxWidth={'md'} style={{minHeight: 'calc(100vh - 450px)'}}>
        <h3 className={classes.otherFont}>
            Um die Bestellung abschließen zu können Benötigen wir noch ein paar Daten von Ihnen, haben sie Schon einen Account dann melden sie sich einfach an.
            <br/>
            Zum ersten mal auf dieser Seite dann Registrieren sie sich doch einfach, oder machen sie einfach als Gast weiter.
        </h3>

        <Box className={classes.boxStyle}>
            <TextField
                className={classes.otherFont + ' ' + classes.textField}
                style={{backgroundColor: resetPasswordOpen ? 'transparent' : 'white'}}
                variant={'outlined'}
                label={'E-Mail Adresse'}
                color={'primary'}
                value={mail}
                type={'mail'}
                disabled={resetPasswordOpen}
                fullWidth
                onChange={(e) => setMail(e.target.value)}/>
            <TextField
                className={classes.otherFont + ' ' + classes.textField}
                style={{backgroundColor: resetPasswordOpen ? 'transparent' : 'white'}}
                variant={'outlined'}
                label={'Passwort'}
                color={'primary'}
                value={pw}
                fullWidth
                error={!!error}
                helperText={error}
                disabled={resetPasswordOpen}
                type={pwDisplay ? 'text' : 'password'}
                onChange={(e) => setPw(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start" onClick={() => setPwDisplay(!pwDisplay)} style={{cursor: 'pointer'}}>
                            {!pwDisplay && <FontAwesomeIcon icon={faEye}/>}
                            {pwDisplay && <FontAwesomeIcon icon={faEyeSlash}/>}
                        </InputAdornment>
                    ),
                }}/>
            <Button onClick={signIn} className={classes.otherFont}
                    style={{marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                    variant={'contained'} color={'primary'} disabled={loading || resetPasswordOpen}>
                {loading && <FontAwesomeIcon icon={faGlassWhiskey} spin/>}
                Anmelden
            </Button>
            <StyledFirebaseAuth className={classes.otherFont} uiConfig={{
                ...store.userStore.getUiConfig(), ...{
                    callbacks: {
                        signInSuccessWithAuthResult: () => {
                            toggleLogin();
                            history.push('/warenkorb/payment');
                            return false;
                        }
                    }
                },
            }} firebaseAuth={auth}/>
        </Box>

        {!resetPasswordOpen && <Button onClick={resetPasswordToggle} className={classes.otherFont}
                                       style={{marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                                       variant={'text'} color={'secondary'} disabled={loading}>
            {loading && <FontAwesomeIcon icon={faGlassWhiskey} spin/>}
            Passwort vergessen ?
        </Button>}
        {resetPasswordOpen && <Box className={classes.boxStyle}>
            <TextField
                className={classes.otherFont + ' ' + classes.textField}
                variant={'outlined'}
                label={'E-Mail Adresse'}
                helperText={"E-mail Adresse für den Account zum zurücksetzen"}
                color={'primary'}
                value={mail}
                type={'mail'}
                fullWidth
                disabled={onReset}
                inputProps={{
                    style: {backgroundColor:  onReset ? 'transparent' : 'white'}
                }
                }
                onChange={(e) => setMail(e.target.value)}/>
            <Button onClick={resetPassword} className={classes.otherFont}
                    style={{marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                    variant={'contained'} color={'primary'} disabled={onReset}>
                Passwort zurücksetzen
            </Button>
            <Button onClick={resetPasswordToggle} className={classes.otherFont}
                    style={{marginLeft: '0.5em', marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                    variant={'contained'} color={'primary'} disabled={onReset}>
                Abbrechen
            </Button>
        </Box>}

        <hr/>

        <div className={classes.otherFont}>
            <h3> Noch keinen Account ?, dann Registrieren Sie sich einfach. </h3>
        </div>

        <Box className={classes.boxStyle + ' ' + classes.otherFont}>
            <Button style={{width: '100%', maxWidth: 220}} variant={'contained'} color={'primary'} onClick={() => setIsRegisterOpen(true)}>
                <FontAwesomeIcon style={{marginRight: '0.25em'}} icon={faUserCircle}/>Registrieren
            </Button>
            <RegisterModal isOpen={isRegisterOpen} handleClose={() => setIsRegisterOpen(false)} onSuccess={submitRegister}/>
        </Box>

        <div className={classes.otherFont}>
            <h3>oder lieber als Gast einkaufen? </h3>
        </div>

        <Box className={classes.boxStyle + ' ' + classes.otherFont}>
            <Button style={{width: '100%', maxWidth: 220}} variant={'contained'} color={'primary'} onClick={() => setIsSecretOpen(true)}>
                <FontAwesomeIcon style={{marginRight: '0.25em'}} icon={faUserSecret}/>Als Gast
            </Button>
            <SecretUserModal isOpen={isSecretOpen} handleClose={() => setIsSecretOpen(false)} onSuccess={submitRegister}/>
        </Box>


    </Container>
}));

export default CartLogin;
