import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    alignLeft: {
        textAlign: 'left',
    },
    alignRight: {
        textAlign: 'right',
        paddingRight: '0.5em',
    },
    smallerText: {
        fontSize: '0.85em',
    },
    boxStyle: {
        padding: '0.5em',
        border:'solid 1px #333',
        margin: '0.25em'
    },
    payNowBtn:{
        minWidth: 250,
        marginBottom: '1em',
    },
    overlay: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 999999999,
        backgroundColor: 'hsla(0,0%,0%,0.7)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.5em'
    },
    smallText:{
        fontSize: '0.85em',
        padding: '0.15em'
    },
    description: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    spacing: {
        margin: '0.25em'
    },
    amount:{
        padding: '0.25em',
    },
    payLaterBtn:{
        boxShadow: 'none',
        maxWidth: 750,
        fontSize: '1.25em',
        padding: '0.75em',
        marginBottom: '0.75em'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    spinner:{
        fontSize: '1.5em'
    },
    error:{
        fontWeight: 'bold',
        color: theme.palette.error.main,
        fontSize: '1.1em',
    },
    inputTextField: {
        width: '100%',
        margin: '0.4em 0',
    },
    addressFrom: {
        marginTop: '1em',
    },
    boxHeader: {
        textAlign: 'left',
    },
    containerHeight: {
        minHeight: 'calc(100vh - 437px)'
    },
    preferredAddress: {
        borderRadius: '0.25em',
        cursor: 'pointer',
        boxShadow: 'inset 0 0 4px 0 #333',
    },
    addressStyle: {
        cursor: 'pointer',
        margin: '0.25em',
        borderRadius: '0.25em',
        border: 'solid 1px #333',
        '&:hover': {
            boxShadow: 'inset 0 0 4px 2px #333',
            backgroundColor: 'hsla(0,10%, 90%, 0.5)',
        }
    },
    nextBtn: {
        paddingBottom: '1em',
    },
    email: {
        marginBottom: '0.75em'
    },
    textFieldStyle: {
        backgroundColor: '#fff',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    errorIcon:{
        color: theme.palette.error.main
    }
}));
