import {observable, action, decorate} from "mobx"

class AdminStore {
    tab = -1; // 4 => ArticleTab

    setTab = tab => this.tab = tab;
}

decorate(AdminStore, {
    tab: observable,
    setTab: action,
});

export default AdminStore;
