import React, {useEffect, useState} from "react";
import {Container, Select} from "@material-ui/core";

import {useStyles} from "./styling";
import {inject, observer} from "mobx-react";

import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlassWhiskey} from '@fortawesome/free-solid-svg-icons';
import {ArticleObject, SelectTypeModal} from "components";
import Typography from "@material-ui/core/Typography";
import {ScrollToTop} from 'components';
import Grid from "@material-ui/core/Grid";

function ArticleListFull({store, list, isDataLoading, isEmpty}) {
    const history = useHistory();
    const {loading} = useStyles();
    const [articleList, setArticleList] = useState([]);

    const {addMessages} = store.snackBarHandle;

    useEffect(() => {
        setArticleList(list.map(e => {
            if(!e) return e;
            e.thumbnail = e?.thumbnails?.[0] ?? null;
            e.image = e?.images?.[0] ?? null;
            e.fullName = e.name ?? e.producer ?? 'NONE';
            return e;
        }));
    }, [list]);

    const handleClickOnProduct = (id, name) => (e) => {
        history.push(`/whisky/article/${id}/${name.replace(' ', '_')}`)
    };

    const addToCart = (product) => () => {
        addMessages(`${product.articleNumber} zum Warenkorb hinzugefügt.`);
        store.cartStore.writeArticleInCart(product, 1, false);
    };

    return <Container maxWidth="md">
        {isDataLoading && <div className={loading}>
            <FontAwesomeIcon icon={faGlassWhiskey} spin/>
        </div>}
        {isEmpty && <div className={loading}>
            <Typography>Mit diesen Suchkriterien leider keine Artikel gefunden.</Typography>
        </div>}
        <Grid container spacing={2}>
            {articleList.map((product, index) =>
                <Grid key={index} item xs={6} sm={4} md={3}>
                <ArticleObject
                    product={product}
                    handleClickOnProduct={handleClickOnProduct}
                    addToCart={addToCart}
                    hasRights={store.userStore.currentRole >= 0b0100}
                />
                </Grid>
            )}
        </Grid>
        <ScrollToTop/>
    </Container>
}

export default inject('store')(observer(ArticleListFull));


