import React, {useEffect, useState} from 'react';
import {database, functions} from "common/firebase";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {IconButton, InputAdornment, Paper, Tab, Tabs, TextField, Tooltip, Button, AppBar} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBan, faBox, faCircle, faCogs, faDotCircle, faFileInvoice, faGlassWhiskey, faMoneyBillWave, faPen, faPlus, faReceipt, faRobot, faSearch, faTasks, faTimes,
    faTrash,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import {DateTime} from "luxon";
import Options from "./Options";
import {OrderDetails} from "./OrderDetails";
import OrderEdit from "../../../components/OrderEdit";
import LinearProgress from "@material-ui/core/LinearProgress";
import Collapse from "@material-ui/core/Collapse";
import Pagination from "@material-ui/lab/Pagination";
import {useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";

export const useStyles = makeStyles(theme => ({
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    tabStyle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    hoverRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#eee',
        }
    },
    smallPaddingCell: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}));

const orderStateIcon = {
    payed: faMoneyBillWave,
    waiting: faReceipt,
    delete: faTrash,
    storno: faBan,
    progress: faTasks,
    send: faBox,
    create: faFileInvoice,
    canceled: faBan,
}

const orderStateInfo = {
    payed: 'Wurde Bezahlt, noch nicht bearbeitet',
    waiting: 'Warten auf Bezahlung',
    delete: 'Wurde gelöscht',
    storno: 'Wurde Storniert',
    progress: 'Wird gerade Bearbeiten',
    send: 'Wurde Versendet',
    create: 'In Erstellung',
    canceled: 'Bezahlungsprozess wurde abgebrochen',
}

const OrderList = inject('store')(observer(({store}) => {
    const classes = useStyles()
    const location = useLocation();
    const [unzerId, setUnzerId] = useState("");
    const [paymentState, setPaymentState] = useState("");
    const [list, setList] = useState([]);
    const [tab, setTab] = useState('payed');
    const [filter, setFilter] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);

    const [editOrder, setEditOrder] = useState(null);
    const [editOpen, setEditOpen] = useState(false);

    const [years, setYears] = useState([]);
    const [filterYear, setFilterYear] = useState('');

    const [filterState, setFilterState] = useState('');

    const [loading, setLoading] = useState(true)

    const [page, setPage] = useState(1);
    const [pendingCounter, setPendingCounter] = useState(0);

    useEffect(() => {
        const list = []
        for (let i = 20; i <= parseInt(DateTime.local().toFormat('yy')); i++) {
            list.push(i);
        }
        setYears(list);
    }, [])

    useEffect(() => {
        setLoading(true);
        setPendingCounter(0);
        const ref = database.ref('orders');
        let listener1 = null;
        let listener2 = null;
        let listener3 = null;
        if (tab === 'all') {
            listener1 = ref.on('value', buildNoPendingListFromSnap)
        } else if (tab === 'payed') {
            listener2 = ref.orderByChild('state').startAt('p').endAt('p' + "\uf8ff").on('value', buildNoPendingListFromSnap)
        } else {
            listener3 = ref.orderByChild('state').equalTo(tab).on('value', snap => {
                const cacheList = [];
                if (snap.exists()) {
                    snap.forEach(childSnap => {cacheList.push(childSnap.val())});
                }
                setList(cacheList);
                setLoading(false);
            })
        }
        return () => {
            if (listener1) ref.off('value', listener1);
            if (listener2) ref.off('value', listener2);
            if (listener3) ref.off('value', listener3);
        }

    }, [tab]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const id = query.get('id');
        if(id) setTab(id);
    }, [location])

    const buildNoPendingListFromSnap = snap => {
        const cacheList = [];
        let i = 0
        if (snap.exists()) {
            snap.forEach(childSnap => {
                if (childSnap.val().state !== 'pending') cacheList.push(childSnap.val());
                if (childSnap.val().state === 'pending') i++;
            });
        }
        setList(cacheList);
        setPendingCounter(i);
        setLoading(false);
    }

    const handleChangeTab = (event, newValue) => {setTab(newValue); clearFilter();setFilterState('')};

    const clearFilter = (event) => setFilter('');

    const filterFunction = (order) => {
        const fullName = order.shippingAddress ? (order.shippingAddress?.firstName ?? '') + ' ' + (order.shippingAddress?.lastName ?? '') : ''
        return order.orderNumber.includes(filter) ||
            order?.shippingAddress?.firstName.toLowerCase().includes(filter.toLowerCase()) ||
            order?.shippingAddress?.lastName.toLowerCase().includes(filter.toLowerCase()) ||
            fullName.toLowerCase().includes(filter.toLowerCase());
    }

    const filterFunctionYear = (order) => DateTime.fromMillis(order.timestamp).toFormat('yy').includes(filterYear);
    const filterFunctionState = (order) => order.state.includes(filterState);

    const openDetails = (order) => () => {
        setSelectedOrder(order);
        setDetailsOpen(true);
    }
    const handleCloseDetails = () => {
        setSelectedOrder(null);
        setDetailsOpen(false);
    }

    const addOrder = () => {
        const newOrder = {state: 'create', timestamp: DateTime.local().toMillis(), isWw: store.isWw}
        const newRef = database.ref('orders').push(newOrder);
        newOrder.orderNumber = newRef.key;
        newRef.update(newOrder);
        setTab('create')
        setEditOpen(true);
        setSelectedOrder(newOrder);
    }

    const openEdit = (order) => () => {
        setEditOpen(true);
        setEditOrder(order);
    }

    const handleCloseEdit = () => {
        setEditOpen(false);
        setEditOrder(null);
    }

    const toggleOrder = (order) => () => {
        if (order.state === 'create') {
            openEdit(order)();
        } else if (order.state === 'waiting' && order.isManuel) {
            openEdit(order)();
        } else {
            openDetails(order)();
        }
    }

    const handleChange = (event, value) => {
        setPage(value);
    };

    const checkPaymentState = async () => {
        const res = await functions.httpsCallable('checkUnzerPayment')({id: unzerId})
        setPaymentState(JSON.stringify(res.data, null,2));
    }

    return <div>
        <AppBar position="static" className={classes.appBar}>Bestellungen</AppBar>
        <Tabs
            value={tab}
            className={classes.otherFont + ' ' + classes.tabStyle}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Tabs for Detailed Information"
        >
            <Tab value={'create'} label="WIP" id={"0"}/>
            <Tab value={'waiting'} label="Zahlung Offen" id={"1"}/>
            <Tab value={'payed'} label="Verpackung & Versand" id={"2"}/>
            {/*<Tab value={'progress'} label="In Bearbeitung" id={"3"}/>*/}
            <Tab value={'send'} label="Abgeschlossen" id={"3"}/>
            <Tab value={'all'} label="Alle" id={"4"}/>

        </Tabs>
        <TableContainer component={Paper} className={classes.otherFont}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={4} className={classes.smallPaddingCell}>
                            <Collapse in={tab === 'all'}>
                                <IconButton style={{textDecoration: (filterYear === '' ? 'underline' : 'none')}}
                                            onClick={() => setFilterYear('')}>ALL</IconButton>
                                {years.map((y, i) => (
                                    <IconButton key={i} style={{textDecoration: (filterYear === y ? 'underline' : 'none')}}
                                                onClick={() => setFilterYear(y)}>{y}</IconButton>
                                ))}
                            </Collapse>
                        </TableCell>
                        <TableCell colSpan={5} className={classes.smallPaddingCell}>
                            <Collapse in={tab === 'all' || tab === 'payed'}>
                                <div>
                                    <IconButton style={{textDecoration: (filterState === '' ? 'underline' : 'none')}}
                                                onClick={() => setFilterState('')}>ALL</IconButton>
                                    {Object.keys(orderStateIcon)
                                    .map((icon, i) => (tab === 'payed' ? ((icon === 'payed' || icon === 'progress') &&
                                            <Tooltip key={i} title={orderStateInfo[icon]}>
                                                <IconButton style={{border: (filterState === icon ? 'solid 1px' : 'none')}}
                                                            onClick={() => setFilterState(icon)}><FontAwesomeIcon icon={orderStateIcon[icon]}/></IconButton>
                                            </Tooltip>) :
                                            <Tooltip key={i} title={orderStateInfo[icon]}>
                                                <IconButton style={{border: (filterState === icon ? 'solid 1px' : 'none')}}
                                                            onClick={() => setFilterState(icon)}><FontAwesomeIcon icon={orderStateIcon[icon]}/></IconButton>
                                            </Tooltip>
                                    ))}
                                </div>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={8}>
                            <TextField
                                className={classes.otherFont}
                                variant={'outlined'}
                                label={'Durchsuche Bestellungen'}
                                color={'primary'}
                                autoComplete={'on'}
                                value={filter}
                                type={'text'}
                                fullWidth
                                onChange={(e) => setFilter(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" onClick={clearFilter} style={{cursor: 'pointer'}}>
                                            {filter === '' ?
                                                <FontAwesomeIcon icon={faSearch}/> :
                                                <FontAwesomeIcon icon={faTimes}/>
                                            }
                                        </InputAdornment>
                                    ),
                                }}/>
                        </TableCell>
                        <TableCell>
                            <Tooltip title="Neue Bestellung Anlegen">
                                <IconButton aria-label="filter list" onClick={addOrder}>
                                    <FontAwesomeIcon icon={faPlus} style={{fontSize: '0.6em', marginRight: '0.25em'}}/>
                                    <FontAwesomeIcon icon={faFileInvoice}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    {loading && <TableRow>
                        <TableCell colSpan={9}>
                            <LinearProgress/>
                        </TableCell>
                    </TableRow>}
                    <TableRow>
                        <TableCell>Datum</TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell align="right">Vorname</TableCell>
                        <TableCell align="right">Nachname</TableCell>
                        <TableCell align="right">E-Mail</TableCell>
                        <TableCell align="right">Adresse</TableCell>
                        <TableCell align="right">Produkte</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right"><FontAwesomeIcon icon={faCogs}/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading &&
                    list.filter(filterFunctionYear)
                    .filter(filterFunctionState)
                    .filter(filterFunction)
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .slice((page - 1) * 25, page * 25)
                    .map((order, index) => (
                        <TableRow key={index} className={classes.hoverRow}>
                            <TableCell component="th" scope="row" onClick={toggleOrder(order)}
                                       style={{width: 140}}>{order.timestamp ? DateTime.fromMillis(order.timestamp)
                            .toFormat('dd.LL.yy HH:mm') : ''}</TableCell>
                            <TableCell component="th" scope="row" onClick={toggleOrder(order)}>
                                <Tooltip title={order.orderNumber} classes={{popper: classes.otherFont}}>
                                    <div style={{width: 100, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{order.orderNumber}</div>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="right" onClick={toggleOrder(order)}>{order.shippingAddress ? order.shippingAddress.firstName : ''}</TableCell>
                            <TableCell align="right" onClick={toggleOrder(order)}>{order.shippingAddress ? order.shippingAddress.lastName : ''}</TableCell>
                            <TableCell align="right" onClick={toggleOrder(order)} style={{wordBreak: 'break-word'}}>{order.paymentEmail}</TableCell>
                            <TableCell align="right" onClick={toggleOrder(order)}>
                                <Typography component={'div'}>{order.shippingAddress ? order.shippingAddress.address1 : ''}</Typography>
                                <Typography component={'div'}>{order.shippingAddress ? order.shippingAddress.address2 : ''}</Typography>
                                <Typography
                                    component={'div'}>{order.shippingAddress ? order.shippingAddress.plz : ''}, {order.shippingAddress ? order.shippingAddress.region : ''}</Typography>
                                <Typography component={'div'}>{order.shippingAddress ? order.shippingAddress.land : ''}</Typography>
                            </TableCell>
                            <TableCell align="right">{order.products ? order.products.length : 0}</TableCell>
                            <TableCell align="right" onClick={toggleOrder(order)}>
                                <Tooltip title={orderStateInfo[order.state]}>
                                    <div>
                                        <FontAwesomeIcon icon={orderStateIcon[order.state]} spin={order.state === 'pending'}/>
                                    </div>
                                </Tooltip>
                                <Tooltip title={order.isManuel ? 'Erstellt bei Hand' : 'Erstellt bei System'}>
                                    {order.isManuel ? <div>
                                        <FontAwesomeIcon icon={faPen} style={{width:24}}/>
                                    </div> : <div>
                                        <FontAwesomeIcon icon={faRobot} style={{width:24}}/>
                                    </div>}
                                </Tooltip>
                                <Tooltip
                                    title={order.hasOwnProperty('isWw') ? (order.isWw ? 'Bestellung von World Whisky' : 'Bestellung von ST-Logistik') : 'Bestellung von World Whisky'}>
                                    {!order.hasOwnProperty('isWw') || order.isWw ? <div>
                                        <FontAwesomeIcon icon={faGlassWhiskey} style={{width:24}}/>
                                    </div> : <div>
                                        <FontAwesomeIcon icon={faTruck} style={{width:24}}/>
                                    </div>
                                    }
                                </Tooltip>
                            </TableCell>
                            <TableCell align="right"><Options order={order} openDetails={openDetails(order)} openEdit={openEdit(order)}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={9} align="right">Zurzeit werde {list.filter(filterFunctionYear).filter(filterFunctionState)
                        .filter(filterFunction).length} Bestellungen angezeigt</TableCell>
                    </TableRow>
                    {pendingCounter > 0 && <TableRow>
                        <TableCell colSpan={9} align="right">Zurzeit sind {pendingCounter} Bestellungen noch im Erstellungsprozess</TableCell>
                    </TableRow>}
                    {list.filter(filterFunctionYear).filter(filterFunctionState).filter(filterFunction).length > 25 && <TableRow>
                        <TableCell colSpan={9} style={{padding: '0.5em'}}>
                            <Pagination count={Math.ceil(list.filter(filterFunctionYear).filter(filterFunctionState).filter(filterFunction).length / 25)}
                                        variant="text" shape="rounded" size="large" page={page} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>}
                    {/*<TableRow>
                        <TableCell colSpan={9} >
                            <TextField placeholder={"id from unzer order to check"} fullWidth value={unzerId} onChange={(e) => setUnzerId(e.target.value)} variant={"outlined"} />
                            <Button variant={"outlined"} onClick={checkPaymentState}>SEND</Button>
                            <pre>{paymentState}</pre>
                        </TableCell>
                    </TableRow>*/}
                </TableFooter>
            </Table>
        </TableContainer>
        <div style={{height: 24}}/>

        <OrderDetails order={selectedOrder} open={detailsOpen} handleClose={handleCloseDetails}/>
        <OrderEdit order={editOrder} open={editOpen} handleClose={handleCloseEdit}/>
    </div>
}));

export default OrderList;
