import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button, ImageList, ImageListItem, MenuItem, Select, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import MarkdownEditor from "@uiw/react-markdown-editor";
import {database} from "../../../../common/firebase";
import {ImagePickerModal} from "../../../../components/ImagePickerModal";
import DbImage from "../../../../components/dbImage";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    deleteButton: {
        backgroundColor: '#900',
        color: '#eee'
    },
    inputRow: {
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    linkRow: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    linkInput: {
        marginBottom: theme.spacing(1)
    },
    listRoot: {
        display: 'flex',
        justifyContent: 'center',
    },
    imageList: {
        width: '100%',
        maxWidth: 650,
        padding: theme.spacing(2)
    },
    imgStyle:{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }
}))


function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments)
        }, ms)
    };
}

export const NewsEditDialog = ({news, open, handleClose}) => {
    const classes = useStyles();
    const [localNews, setLocalNews] = useState({
        title: '',
        text: '',
        links: [],
        images: [],
        youtubeLink: '',
    });

    const [editImagesOpen, setEditImagesOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const imageListRef = useRef();
    const [editImageMeta, setEditImageMeta] = useState(null);

    useEffect(() => {
        let debounceHandleResize = null;
        if (imageListRef.current) {
            debounceHandleResize = debounce(function handleResize() {
                setHeight(imageListRef.current.clientWidth / 4);
            }, 500)

            window.addEventListener('resize', debounceHandleResize)
        }

        return _ => { if (debounceHandleResize) window.removeEventListener('resize', debounceHandleResize); }
    }, []);

    useLayoutEffect(() => {
        if (imageListRef.current) {
            setHeight(imageListRef.current.clientWidth / 4);
        }
    }, [localNews])

    useEffect(() => {
        setLocalNews({...localNews, ...news});
    }, [news])

    const saveNews = e => {
        const ref = database.ref('newsFeedUnpublished').child(localNews.key);
        ref.update(localNews);
        handleClose();
    }

    const change = attr => e => {
        localNews[attr] = e.target.value;
        setLocalNews({...localNews});
    }

    const handleSetText = (a, b, val) => {
        localNews.text = val;
        setLocalNews({...localNews});
    }

    const changeLink = (i, attr) => e => {
        localNews.links[i][attr] = e.target.value;
        setLocalNews({...localNews});
    }

    const addNewLink = () => {
        localNews.links.push({
            url: '',
            text: '',
            internal: 'internal'
        });
        setLocalNews({...localNews});
    }

    const handleTitleImagesClick = (nextThumbnailList) => {
        if(nextThumbnailList.length === 0) return
        const list = nextThumbnailList.length > 1 ? [nextThumbnailList[1]] : nextThumbnailList;
        const index = localNews.images.findIndex(e => list.indexOf(e.id) !== -1);
        if (index === -1) {
            const newThumb = {
                id: list[0],
                author: "",
                cols: 1,
                img: list[0],
                rows: 1,
                title: "",
            }
            localNews.images.splice(0, 0, newThumb);
            return setLocalNews({...localNews});
        }
        const toMove = localNews.images.splice(index,1);
        localNews.images.splice(0,0, toMove[0]);
        setLocalNews({...localNews});
    }

    const handleGalleryImagesClick = (nextGalleryList) => {
        const removedList = localNews.images.filter(e => nextGalleryList.indexOf(e.id) > -1);
        if (removedList.length === nextGalleryList.length) {
            localNews.images = removedList
            return setLocalNews({...localNews});
        }
        localNews.images = nextGalleryList.map(id => {
            const imageElement = removedList.find(e => e.id === id)
            if (imageElement) return imageElement;
            return {
                id,
                author: "",
                cols: 1,
                img: id,
                rows: 1,
                title: "",
            }
        })
        return setLocalNews({...localNews});

    }
    const openEditImageItem = (i, img) => e => {
        img.position = i;
        setEditImageMeta({...img});
    }

    const setImageMeta = attr => e => {
        editImageMeta[attr] = e.target.value;
        setEditImageMeta({...editImageMeta});
    }

    const saveMeta = () => {
        const index = localNews.images.findIndex(e => e.id === editImageMeta.id);
        if(index === -1) return setEditImageMeta(null);
        localNews.images.splice(index,1);
        localNews.images.splice(editImageMeta.position,0, editImageMeta);
        setLocalNews({...localNews});
        setEditImageMeta(null);
    }

    return <Dialog className={classes.otherFont} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={'lg'} disableScrollLock={true}>
        <DialogTitle id="form-dialog-title">News Editieren</DialogTitle>
        <DialogContent>
            <div className={classes.inputRow}>
                <h6>Überschrift</h6>
                <TextField
                    className={classes.otherFont}
                    variant={'outlined'}
                    label={'Überschrift'}
                    color={'primary'}
                    value={localNews.title}
                    type={'text'}
                    fullWidth
                    onChange={change('title')}/>
            </div>
            <div className={classes.inputRow}>
                <h6>News Text</h6>
                {news && <MarkdownEditor
                    visible={false}
                    className={classes.otherFont}
                    value={news.text}
                    id={'text'}
                    onChange={handleSetText}
                    height={250}
                /> }
            </div>
            <div className={classes.inputRow}>
                <h6>Externe Links</h6>
                {localNews.links.map((link, i) => (
                    <div key={i} className={classes.linkRow}>
                        <h6>Link {i + 1}</h6>
                        <TextField
                            className={classes.otherFont + ' ' + classes.linkInput}
                            variant={'outlined'}
                            label={'Anzeige Text'}
                            color={'primary'}
                            value={link.text}
                            type={'text'}
                            fullWidth
                            onChange={changeLink(i, 'text')}/>
                        <TextField
                            className={classes.otherFont}
                            variant={'outlined'}
                            label={'Link'}
                            color={'primary'}
                            value={link.url}
                            type={'text'}
                            fullWidth
                            helperText={"https:// bei einer externen URL nicht vergessen !!!"}
                            onChange={changeLink(i, 'url')}/>
                        <Select
                            name={'internal'}
                            MenuProps={{
                                disableScrollLock: true
                            }}
                            value={link.internal}
                            onChange={changeLink(i, 'internal')}
                        >
                                <MenuItem value={'internal'}>Intern</MenuItem>
                                <MenuItem value={'external'}>Extern</MenuItem>
                        </Select>
                    </div>
                ))}
                <Button onClick={addNewLink}>Add New Link</Button>
            </div>
            <div className={classes.inputRow}>
                <h6>Bilder Galerie</h6>
                {localNews?.images && <div className={classes.listRoot}>
                    <ImageList ref={imageListRef} rowHeight={height} gap={2} className={classes.imageList} cols={4}>
                        {localNews.images.map((item, i) => (
                            <ImageListItem key={i} cols={parseInt(item.cols || 1)} rows={parseInt(item.rows || 1)} onClick={openEditImageItem(i, item)} style={{cursor: 'pointer'}}>
                                <DbImage src={['images','news', localNews.key, item.img || '']} alt={item.title || ''} className={classes.imgStyle}/>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>}
                <Button variant={"contained"} color={"primary"} onClick={() => setEditImagesOpen(true)}> Bilder hochladen </Button>
            </div>
            <div className={classes.inputRow}>
                <h6>YouTube Link zum einbinden von einen Video</h6>
                <TextField
                    className={classes.otherFont}
                    variant={'outlined'}
                    label={'Link'}
                    color={'primary'}
                    value={localNews.youtubeLink}
                    type={'text'}
                    fullWidth
                    onChange={change('youtubeLink')}/>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant={'outlined'}>
                Schließen
            </Button>
            <Button onClick={saveNews} color="primary" variant={'outlined'}>
                Speichern
            </Button>
        </DialogActions>

        <ImagePickerModal
            open={editImagesOpen}
            handleClose={() => setEditImagesOpen(false)}
            subPath={'news/' + localNews.key}
            selectedTypeOne={localNews?.images.filter((e, i) => i === 0).map(e => e.id) ?? []} selectTypeOne={"Title Bild"}
            selectTypeOneCallback={handleTitleImagesClick}
            selectedTypeTwo={localNews?.images.map(e => e.id)} selectTypeTwo={"Gallerie"} selectTypeTwoCallback={handleGalleryImagesClick}
        />

        <Dialog className={classes.otherFont} open={!!editImageMeta} onClose={() => setEditImageMeta(null)}>
            <DialogTitle>Bild daten Bearbeiten</DialogTitle>
            {editImageMeta && <DialogContent>
                <DbImage src={['images','news', localNews.key, editImageMeta.img || '']} alt={editImageMeta.title || ''} style={{width: height * editImageMeta.cols, height: height * editImageMeta.rows}}/>
                <div className={classes.inputRow}>
                    <h6>Position</h6>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'Position'}
                        color={'primary'}
                        value={editImageMeta.position}
                        type={'text'}
                        fullWidth
                        helperText={"Beginnt bei 0 zu Zählen und geht bis zur zahl " + (localNews.images.length - 1)}
                        onChange={setImageMeta('position')}/>
                </div>
                <div className={classes.inputRow}>
                    <h6>Alternativ text</h6>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'Alt Text'}
                        color={'primary'}
                        value={editImageMeta.title}
                        type={'text'}
                        fullWidth
                        onChange={setImageMeta('title')}/>
                </div>

                <div className={classes.inputRow}>
                    <h6>Bild Breite</h6>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'Breite 1-4'}
                        color={'primary'}
                        value={editImageMeta.cols}
                        type={'number'}
                        inputProps={{
                            max: 4,
                            min: 0
                        }}
                        fullWidth
                        onChange={setImageMeta('cols')}/>
                </div>

                <div className={classes.inputRow}>
                    <h6>Bild Breite</h6>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'Höhe 1-4'}
                        color={'primary'}
                        value={editImageMeta.rows}
                        type={'number'}
                        inputProps={{
                            max: 4,
                            min: 0
                        }}
                        fullWidth
                        onChange={setImageMeta('rows')}/>
                </div>
                <div className={classes.inputRow}>
                    <h6>Bild Link</h6>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'Link vom Bild'}
                        color={'primary'}
                        value={editImageMeta.link}
                        type={'string'}
                        fullWidth
                        onChange={setImageMeta('link')}/>
                </div>
                <div className={classes.inputRow}>
                    <h6>Interner Link</h6>
                    <Select
                        name={'internal'}
                        MenuProps={{
                            disableScrollLock: true
                        }}
                        value={editImageMeta.internal}
                        onChange={setImageMeta('internal')}
                    >
                        <MenuItem value={'internal'}>Intern</MenuItem>
                        <MenuItem value={'external'}>Extern</MenuItem>
                    </Select>
                </div>
                <DialogActions>
                    <Button onClick={() => setEditImageMeta(null)} variant={'outlined'}>
                        Schließen
                    </Button>
                    <Button onClick={saveMeta} color="primary" variant={'outlined'}>
                        Speichern
                    </Button>
                </DialogActions>
            </DialogContent>}
        </Dialog>

    </Dialog>
}
