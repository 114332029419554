import React, {useState} from 'react';
import {Modal} from "../index";
import {InputAdornment, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {auth, database} from "../../common/firebase";
import {inject, observer} from "mobx-react";
import Box from "@material-ui/core/Box";

const useStyle = makeStyles(theme => ({
    row: {
        padding: theme.spacing(1),
    },
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    errorText:{
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        color: theme.palette.error.main,
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}));

const SecretUserModal = inject('store')(observer(({store, isOpen, handleClose, onSuccess}) => {
    const classes = useStyle();
    const [mail, setMail] = useState('');
    const [mail2, setMail2] = useState('');
    const [pw, setPw] = useState('');
    const [pw2, setPw2] = useState('');
    const [pwDisplay, setPwDisplay] = useState(false);
    const [pwDisplay2, setPwDisplay2] = useState(false);
    const [localErrors, setLocalErrors] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [plz, setPLZ] = useState('');
    const [land, setLand] = useState('Österreich');
    const [region, setRegion] = useState('');
    const [tel, setTel] = useState('');

    const {addMessages} = store.snackBarHandle;

    const sendRegister = async (e) => {
        e.preventDefault();
        setLocalErrors([]);
        const newErrors = [];
        if (!validateEmail(mail)) newErrors.push(["E-Mail Adresse konnte nicht Validiert werden, verwenden sie eine Adresse im Format, info@worldwhisky.at "]);
        if (pw.length > 0 && pw.length < 6) newErrors.push(["Das Passwort hat zu wenig zeichen, es müssen mindestens 6 sein"]);
        if (mail !== mail2) newErrors.push(["E-Mail Adressen stimmen nicht überein"]);
        if (pw !== pw2) newErrors.push(["Passwörter stimmen nicht überein"]);
        if (firstName === '' || lastName === '' || address1 === '' || plz === '') newErrors.push(["Füllen sie alle benötigeten Rechnungsinformationen aus."]);
        if (newErrors.length > 0) {
            setLocalErrors([...newErrors]);
            return;
        }
        try {
            store.userStore.setLoadingUser(true);
            const response = await auth.createUserWithEmailAndPassword(mail, pw);
            const {user} = response;
            addMessages(`Nutzer mit ${mail} angelegt.`);
            const newAddress = {
                firstName,
                lastName,
                address1,
                address2,
                plz,
                land,
                region,
                preferred: true,
            };
            await database.ref(`userdata/${user.uid}/addresses`).set([newAddress]);
            if(tel !== '') await database.ref(`userdata/${user.uid}/phoneNumber`).set(tel);
            await database.ref(`userdata/${user.uid}/lastMailAddress`).set(mail);
            handleClose();
            if (onSuccess) onSuccess();
        } catch (erro) {
            if (erro.message.toLowerCase().includes('already in use')) {
                localErrors.push("Diese E-Mail Adresse wird bereits verwendet");
            } else {
                localErrors.push(erro.message);
            }
            setLocalErrors([...localErrors]);
        }
    };

    const toPaymentAsSecret = (e) => {
        e.preventDefault();
        setLocalErrors([]);
        const newErrors = [];
        if (!validateEmail(mail)) newErrors.push(["E-Mail Adresse konnte nicht Validiert werden, verwenden sie eine Adresse im Format, info@worldwhisky.at "]);
        if (mail !== mail2) newErrors.push(["E-Mail Adressen stimmen nicht überein"]);
        if (firstName === '' || lastName === '' || address1 === '' || plz === '') newErrors.push(["Füllen sie alle benötigeten Rechnungsinformationen aus."]);
        if (newErrors.length > 0) {
            setLocalErrors([...newErrors]);
            return;
        }
        store.userStore.setLoadingUser(true);
        auth.signInAnonymously()
        .then( async (res) => {
            const {user} = res;
            const newAddress = {
                firstName,
                lastName,
                address1,
                address2,
                plz,
                land,
                region,
                preferred: true,
            };
            if(tel !== '') await database.ref(`userdata/${user.uid}/phoneNumber`).set(tel);
            await database.ref(`userdata/${user.uid}/addresses`).set([newAddress]);
            await database.ref(`userdata/${user.uid}/isAnonymous`).set(user.isAnonymous);
            await database.ref(`userdata/${user.uid}/lastMailAddress`).set(mail);

            if (onSuccess) onSuccess();
        })
        .catch((error) => {
            newErrors.push([`${error.code}: ${error.message}`])
            setLocalErrors([...newErrors]);
        });

    }

    const validateEmail = (elementValue) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue);
    };


    return <Modal title={"Registrieren"} open={isOpen} handleClose={handleClose}>
        <p className={classes.otherFont}>Um die Bestellung durchzuführen benötigen wir ein paar Rechnungsdaten von Ihnen, bitte füllen sie alle Felder mit einem * aus. </p>

        <form onSubmit={toPaymentAsSecret}>
            <Box>
                <h3>Rechnungsdaten</h3>
                <div className={classes.row}>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'E-Mail Adresse'}
                        color={'primary'}
                        autoComplete={'on'}
                        value={mail}
                        type={'mail'}
                        fullWidth
                        required
                        onChange={(e) => setMail(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'E-Mail Adresse Wiederholen'}
                        color={'primary'}
                        autoComplete={'on'}
                        value={mail2}
                        type={'mail'}
                        fullWidth
                        required
                        error={mail.length > 0 && mail2.length > 0 && mail !== mail2}
                        helperText={mail.length > 0 && mail2.length > 0 && mail !== mail2 ? 'E-Mail Adressen stimmen nicht überein.' : null}
                        onChange={(e) => setMail2(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"text"} variant={"outlined"} label={"Vorname"} value={firstName} required
                               fullWidth onChange={(e) => setFirstName(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"text"} variant={"outlined"} label={"Nachname"} value={lastName} required
                               fullWidth onChange={(e) => setLastName(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"text"} variant={"outlined"} label={"Adresszeile 1"} value={address1} required
                               fullWidth onChange={(e) => setAddress1(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"text"} variant={"outlined"} label={"Adresszeile 2"} value={address2}
                               fullWidth onChange={(e) => setAddress2(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"text"} variant={"outlined"} label={"PLZ"} value={plz} required
                               fullWidth onChange={(e) => setPLZ(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"text"} variant={"outlined"} label={"Stadt / Ort"} value={region}
                               fullWidth onChange={(e) => setRegion(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"text"} variant={"outlined"} label={"Land"} value={land} disabled
                               fullWidth onChange={(e) => setLand(e.target.value)}/>
                </div>
                <div className={classes.row}>
                    <TextField className={classes.otherFont} type={"tel"} variant={"outlined"} label={"Telefonnummer"} value={tel}
                               fullWidth onChange={(e) => setTel(e.target.value)}/>
                </div>
            </Box>
            <Box>
                <p className={classes.otherFont}>Nach eingabe der Daten wären sie schon fast Registriert, wollen sie nicht noch einfach ein Passwort wählen ?</p>
                <div className={classes.row}>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'Passwort'}
                        color={'primary'}
                        autoComplete={'on'}
                        value={pw}
                        fullWidth
                        error={pw.length < 6 && pw.length > 0}
                        helperText={pw.length < 6 && pw.length > 0 ? 'Passwörter muss mindestend 6 Zeichen lang sein' : null}
                        type={pwDisplay ? 'text' : 'password'}
                        onChange={(e) => setPw(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" onClick={() => setPwDisplay(!pwDisplay)} style={{cursor: 'pointer'}}>
                                    {!pwDisplay && <FontAwesomeIcon icon={faEye}/>}
                                    {pwDisplay && <FontAwesomeIcon icon={faEyeSlash}/>}
                                </InputAdornment>
                            ),
                        }}/>
                </div>
                <div className={classes.row}>
                    <TextField
                        className={classes.otherFont}
                        variant={'outlined'}
                        label={'Passwort wiederholen'}
                        color={'primary'}
                        autoComplete={'on'}
                        value={pw2}
                        type={pwDisplay2 ? 'text' : 'password'}
                        fullWidth
                        error={pw.length > 0 && pw2.length > 0 && pw !== pw2}
                        helperText={pw.length > 0 && pw2.length > 0 && pw !== pw2 ? 'Passwörter stimmen nicht überein.' : null}
                        onChange={(e) => setPw2(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" onClick={() => setPwDisplay2(!pwDisplay2)} style={{cursor: 'pointer'}}>
                                    {!pwDisplay2 && <FontAwesomeIcon icon={faEye}/>}
                                    {pwDisplay2 && <FontAwesomeIcon icon={faEyeSlash}/>}
                                </InputAdornment>
                            ),
                        }}/>
                    {localErrors.length > 0 && <p className={classes.errorText} >{localErrors.map((e, i) => (<div key={i}>{e}</div>))}</p>}
                </div>
            </Box>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant={'contained'} color={'primary'} type={'submit'}
                        disabled={mail.length === 0 || mail !== mail2 }>Als Gast weiter</Button>
                <Button variant={'contained'} color={'primary'} onClick={sendRegister}
                        disabled={mail.length === 0 || pw.length === 0 || mail !== mail2 || pw !== pw2}>Registrieren und Weiter</Button>
            </div>
        </form>

    </Modal>
}));

export default SecretUserModal;