import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    tabStyle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    textField: {
        marginBottom: '0.75em',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    addArticleHover: {
        border: 'dashed 2px #333',
        borderRadius: '0.25em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        transition: 'border ease 0.5s',

        '&:hover': {
            border: 'solid 2px #333',
            transition: 'border ease 0.5s',
        }
    },
    tCFooter: {
        borderBottom: 'none',
        paddingBottom: 6,
        paddingTop: 6,
    },
    tCFooterTop: {
        borderTop: 'solid 2px #333',
        borderBottom: 'none',
        paddingBottom: 6,
        color: '#333',
        paddingTop: 6
    },
    tCFooterBottom: {
        borderTop: 'solid 1px #333',
        borderBottom: 'none',
        paddingBottom: 6,
        color: '#333',
        paddingTop: 6
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#ccc',
            cursor: 'pointer'
        }
    },
    boxStyle: {
        padding: '0.5em',
        minHeight: 350,
    },
    btnWrapper:{
        marginBottom: theme.spacing(2),
    }
}));
