import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import ReactMarkdown from "react-markdown";
import {database} from "common/firebase";
import {useStyles} from "./styling";
import {inject, observer} from "mobx-react";

const InfoPage = inject('store')(observer(({store, reference, header}) => {
    const {otherFont, blocksatz} = useStyles();
    const [text, setText] = useState('');

    useEffect( () => {
        database.ref(reference).child(store.isWw ? 'ww' : 'st').once( 'value', snap => setText(snap.val()));
    }, [reference, store.isWw]);

    return <Container maxWidth={'md'}>
        <h2> {header} </h2>
        <ReactMarkdown className={otherFont + ' ' + blocksatz} source={text} />
    </Container>
}));

export default InfoPage;