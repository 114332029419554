import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles( theme => ({
    wrapperMenuStyle:{
        maxWidth: '30%',
        backgroundColor: '#333',
        color: '#f5f5dc',
        margin: '1em 0',
        padding: '0.5em',
    },
    wrapperMenuStyleNew:{
        backgroundColor: '#333',
        color: '#f5f5dc',
        padding: '11px 1em'
    },
    dividerStyle:{
        backgroundColor: '#f5f5dc'
    },
    smallLinkText:{
        fontSize: '0.65em'
    },
    linkListElement:{
        '&:hover' :{
            textDecoration: 'underline'
        }
    },
    activeLinkText:{
        fontWeight:'bold',
    },
    headerLine:{
        textAlign: 'center',
        fontWeight:'bold',
        padding: '0.75em 0 0 0',
    },
    activeLinkTextUnderline:{
        textDecoration: 'underline',
    },
    wrapperAdvertisementStyle: {
        background: 'transparent',
        margin: '1em 0',
        width: '65%',
    },
    advertisementStyle:{
        width: '100%',
        objectFit: 'contained',
    },
    wrapperFirstLineStyle:{
        display: 'flex',
        width: '100%',
        justifyContent:'space-between'
    },
}));