import React, {useEffect, useState} from 'react'
import {database} from "../../../../common/firebase";

export const SoldCount = ({eventKey}) => {
    const [sold, setSold] = useState(0);

    useEffect(() => {
        if(eventKey){
            database.ref('events_attendees').child(eventKey).child('count').on('value', snap => {
                if (snap.exists()) {
                    const count = parseInt(snap.val());
                    setSold(count);
                }
            })
        }
    }, [eventKey]);

    return <span>{sold}</span>
}
