import React, {useCallback, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import {
    Button, TextField, FormControl, MenuItem, Select, InputLabel, Tabs, Tab, TableHead, InputAdornment, TableFooter,
    IconButton, AppBar
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Modal} from "components";
import {useStyles} from "./styling";
import {getRole, userFilterText} from './statelessFunctions';
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import {database} from "../../../common/firebase";

const UserAdminTable = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [tab, setTab] = useState('all');
    const [filter, setFilter] = useState('');
    const [selectedUser, setSelectedUser] = useState({uid: '0', displayName: 'none', role: 0b0000});
    const [populatedUserList, setPopulatedUserList] = useState([]);

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const rows = store.userStore.devSavedUsers;

    const filterFunction = useCallback((user) => {
        switch (tab) {
            case 'all' :
                return !user.isAnonymous;
            case 'anonymous' :
                return user.isAnonymous;
            default:
                return true;
        }
    }, [tab])

    useEffect(() => {
        setLoading(false);
        setPage(0);
    }, [tab]);

    useEffect(() => {
        database.ref('userdata').child('CLEANUP').once('value').then( snap => {
            if(snap.exists()){
                console.log(snap.val())
            }else{
                CLEANUP_ADDRESSES();
            }
        });
        Promise.all(rows.map(async row => {
            const snap = await database.ref('userdata').child(row.uid).once('value');
            const newUserData = {...row, ...(snap.exists() ? snap.val() : {})};
            // Check if addresses exist on object else return without preffered adress
            if(!newUserData.addresses || newUserData.addresses.length === 0) return newUserData;
            // If addressess exist find first with preferred, if no preferred use first address
            newUserData.preferredAddress = newUserData.addresses.find(address => address.preferred) ?? newUserData.addresses[0];
            return newUserData;
        })).then(res => setPopulatedUserList(res.filter(filterFunction)));
    }, [rows, filterFunction])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRole = (event) => {
        selectedUser.role = event.target.value;
        setSelectedUser(JSON.parse(JSON.stringify(selectedUser)));
    };


    const handleToggleOpen = (event) => {
        setOpen(!open);
    };

    const saveUser = user => event => {
        store.userStore.changeUserData(user);
        setOpen(false);
    };

    const handleEditUser = user => event => {
        setSelectedUser(JSON.parse(JSON.stringify(user)));
        setOpen(true);
    };

    const handleChangeTab = (event, newValue) => setTab(newValue);
    const clearFilter = () => {if (filter !== '') setFilter('')};

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, populatedUserList.filter(userFilterText(filter)).length - page * rowsPerPage);

    //**** CLEAN UP FUNCITON **** //
    const CLEANUP_ADDRESSES = () => {
        // Filter function to get same addresses
        function cleanUpFilter(element) {
            return current => element.address1 === current.address1 &&
                element.address2 === current.address2 &&
                element.firstName === current.firstName &&
                element.lastName === current.lastName &&
                element.land === current.land &&
                element.plz === current.plz &&
                element.region === current.region
        }

        // Loop to check every person
        for (const p of populatedUserList) {
            // Check if one of the following attributes exist on the person element
            if (!p.shippingAddress && !p.shippingAddresses && !p.paymentAddresses && !p.paymentAddress) continue;

            // Mere all list together
            const allAddresses = [...(p.shippingAddress ?? []), ...(p.shippingAddresses ?? []), ...(p.paymentAddresses ?? []), ...(p.paymentAddress ?? [])];
            // get only unique addresses
            const filtered = allAddresses.reduce((a, e) => a.filter(cleanUpFilter(e)).length === 0 ? [...a, e] : a, []).map(e => {e.preferred = false; return e});

            // set addresses data
            if (!p.addresses) {
                database.ref('userdata').child(p.uid).child("addresses").set(filtered);
            } else {
                const newAddresses = filtered.filter(element => p.addresses.filter(cleanUpFilter(element)).length === 0)
                database.ref('userdata').child(p.uid).child("addresses").set([...p.addresses, ...newAddresses]);
            }
            database.ref('userdata').child(p.uid).child("shippingAddress").set(null);
            database.ref('userdata').child(p.uid).child("shippingAddresses").set(null);
            database.ref('userdata').child(p.uid).child("paymentAddress").set(null);
            database.ref('userdata').child(p.uid).child("paymentAddresses").set(null);
        }
        database.ref('userdata').child('CLEANUP').set(Date.now());

    }

    return <div>
        <AppBar position="static" className={classes.appBar}>Benutzer</AppBar>
        <Tabs
            value={tab}
            className={classes.otherFont + ' ' + classes.tabStyle}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Tabs for Detailed Information"
        >
            <Tab value={'all'} label="Account Benutzer" id={"0"}/>
            <Tab value={'anonymous'} label="Anonyme Benutzer" id={"0"}/>
        </Tabs>
        <TableContainer component={Paper} className={classes.otherFont}>
            <Table style={{width: '100%'}} size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={9}>
                            <TextField
                                className={classes.otherFont}
                                variant={'outlined'}
                                label={'Durchsuche User'}
                                placeholder={'Durchsuche User nach Vor-, Nachname, Anzeigename oder E-Mail adresse'}
                                helperText={'Durchsuche User nach Vor-, Nachname, Anzeigename oder E-Mail adresse'}
                                color={'primary'}
                                autoComplete={'on'}
                                value={filter}
                                type={'text'}
                                fullWidth
                                onChange={(e) => setFilter(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" onClick={clearFilter} style={{cursor: 'pointer'}}>
                                            {filter === '' ?
                                                <FontAwesomeIcon icon={faSearch}/> :
                                                <FontAwesomeIcon icon={faTimes}/>
                                            }
                                        </InputAdornment>
                                    ),
                                }}/>
                        </TableCell>
                    </TableRow>
                    {loading && <TableRow>
                        <TableCell colSpan={9}>
                            <LinearProgress/>
                        </TableCell>
                    </TableRow>}
                    <TableRow>
                        <TableCell>Anzeigename</TableCell>
                        <TableCell>Vorname</TableCell>
                        <TableCell>Nachname</TableCell>
                        <TableCell>Adresse</TableCell>
                        <TableCell>E-Mail's</TableCell>
                        <TableCell>Telefonnummer</TableCell>
                        <TableCell>Geburstag</TableCell>
                        <TableCell><FontAwesomeIcon icon={faCogs}/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {populatedUserList
                    .filter(userFilterText(filter))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => {
                        return (
                            <TableRow
                                hover
                                onClick={handleEditUser(row)}
                                tabIndex={-1}
                                key={row.uid}
                            >
                                <TableCell component="th" scope="row">{row.displayName || '-'}</TableCell>
                                <TableCell>{row.preferredAddress ? row.preferredAddress.firstName : '-'}</TableCell>
                                <TableCell>{row.preferredAddress ? row.preferredAddress.lastName : '-'}</TableCell>
                                <TableCell>
                                    <div>{row.preferredAddress ? row.preferredAddress.address1 : '-'}</div>
                                    <div>{row.preferredAddress ? row.preferredAddress.address2 : ''}</div>
                                    <div>{row.preferredAddress ? row.preferredAddress.plz : ''}{row.preferredAddress ? ', ' : ''}{row.preferredAddress ? row.preferredAddress.region : ''}</div>
                                    <div>{row.preferredAddress ? row.preferredAddress.land : ''}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{row.email || '-'}</div>
                                    <div>{row.lastMailAddress || '-'}</div>
                                </TableCell>
                                <TableCell>{row.phoneNumber || '-'}</TableCell>
                                <TableCell>{row.birthday || '-'}</TableCell>
                                <TableCell>{getRole(row.role)}</TableCell>
                            </TableRow>
                        );
                    })}

                    {emptyRows > 0 && (
                        <TableRow style={{height: 33 * emptyRows}}>
                            <TableCell colSpan={8}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={8}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={populatedUserList.filter(userFilterText(filter)).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <Modal title={"Bearbeiten von Artikel"} open={open} handleClose={handleToggleOpen}
               actions={<Button color={"primary"} variant={"contained"} onClick={saveUser(selectedUser)}>Save</Button>}>
            <form className={classes.root} noValidate autoComplete="off">
                <TextField
                    id="article-name"
                    label="Uid"
                    defaultValue={selectedUser.uid}
                    type="text"
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <br/>
                <TextField
                    id="article-calories"
                    label="Anzeige Name"
                    defaultValue={selectedUser.displayName}
                    type="text"
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <br/>
                <FormControl className={classes.formControl} style={{width: '100%'}}>
                    <InputLabel id="input-role-label">Rolle</InputLabel>
                    <Select
                        labelId="input-role-label"
                        id="input-role"
                        value={selectedUser.role}
                        onChange={handleChangeRole}
                        MenuProps={{
                            disableScrollLock: true
                        }}
                        disabled={store.userStore.currentUser.uid === selectedUser.uid}
                    >
                        <MenuItem value={0b0001}>Kunde</MenuItem>
                        <MenuItem value={0b0010}>Wiederverkäufer</MenuItem>
                        <MenuItem value={0b0100}>Mitarbeiter</MenuItem>
                        <MenuItem value={0b1000}>Admin</MenuItem>
                    </Select>
                </FormControl>
            </form>
        </Modal>
    </div>
}))

export default UserAdminTable;
