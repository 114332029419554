import React from "react";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {getAmount} from "common/statelessFunctions";
import {inject, observer} from "mobx-react";
import Box from "@material-ui/core/Box";
import {useStyles} from "./styling";
import {Paper, TableContainer, Button, TableFooter, Tooltip} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faFileCsv, faGifts, faGlassWhiskey, faLongArrowAltLeft, faPrint, faWineBottle} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";


const PrintArticlesList = inject("store")(observer(({store}) => {
    const classes = useStyles();
    const history = useHistory()
    const articles = store.articleStore.devSavedArticles.slice().sort((a, b) => a.articleNumber > b.articleNumber ? 1 : -1);

    const filteredArticles = articles.filter(a => !a.isArchived)

    const back = () => {
        history.goBack();
    }

    const print = () => {
        window.print();
    }

    const download = () => {
        const fileData = [
            `Type;Nummer;Name;Stück`,
            ...filteredArticles.map( a => `${getTypeString(a)};${a.articleNumber};${a.name};${getAmount(a)}` )
        ];
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(fileData.join('\n')));
        element.setAttribute('download', `Artikelliste_${Date.now()}.csv`);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const getTypeString = (a) => {
        return a.isSet ?
            "Set" :
            a.types.indexOf('sample') > -1 ?
                "Sample":
                "Artikel"
    }

    return <Container maxWidth={'md'} className={classes.otherFont}>
        <Box display="block" displayPrint="none" className={classes.btnBox + ' ' + classes.noPrint}>
            <Button className={classes.btn} variant={'contained'} color={'primary'} onClick={back}>
                <Tooltip title={'Zurück'}>
                    <span><FontAwesomeIcon icon={faLongArrowAltLeft}/></span>
                </Tooltip>
            </Button>
            <Button className={classes.btn} variant={'contained'} color={'primary'} onClick={download}>
                <Tooltip title={'Download csv file'}>
                    <span><FontAwesomeIcon icon={faFileCsv}/></span>
                </Tooltip>
            </Button>
            <Button className={classes.btn} variant={'contained'} color={'primary'} onClick={print}>
                <Tooltip title={'Liste Drucken'}>
                    <span><FontAwesomeIcon icon={faPrint}/></span>
                </Tooltip>
            </Button>
        </Box>

        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Artikel Nummer <FontAwesomeIcon icon={faArrowDown}/></TableCell>
                        <TableCell>Artikel Name</TableCell>
                        <TableCell>Stück</TableCell>
                        <TableCell> - </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredArticles.map((article, i) => (<TableRow key={i}>
                        <TableCell align="left">
                            {article.isSet ?
                                <FontAwesomeIcon icon={faGifts}/> :
                                article.types.indexOf('sample') > -1 ?
                                    <FontAwesomeIcon icon={faGlassWhiskey}/> :
                                    <FontAwesomeIcon icon={faWineBottle}/>
                            }
                        </TableCell>
                        <TableCell align="left">{article.articleNumber}</TableCell>
                        <TableCell align="left">{article.name}</TableCell>
                        <TableCell align="left">{getAmount(article)}</TableCell>
                        <TableCell align="left"/>
                    </TableRow>))}
                    <TableRow>
                        <TableCell align="left" colSpan={5}>Es sind {articles.length} artikel in der Liste</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" colSpan={5}>Mit insgesamt {articles.reduce((a, e) => a + getAmount(e), 0)} Flaschen</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    </Container>
}))

export default PrintArticlesList;
