import * as moment from "moment";
import {DateTime} from 'luxon';

export const getProductName = (product, isShort) => {
    if (product.type === 'whisky') {
        let text = '';
        text += !isShort && product.producer ? product.producer + ' - ' : '';
        text += product.distillery ? product.distillery + ' - ' : '';
        text += product.name ? product.name + ' - ' : '';
        text += product.age ? product.age + 'J. - ' : '';
        text += product.volume ? product.volume + 'L' : '';
        return text;
    } else {
        return product.name;
    }
};

export const getAmount = (product) => {
    const {batches} = product;
    if(!batches) return 0;
    const batchesList = Object.keys(batches).map( key => batches[key]);
    return batchesList.filter(b => parseInt(b.currentAmount) > 0).reduce((a, b) => a + parseInt(b.currentAmount), 0);
};

export const getAmountRest = (product) => {
    const {batches} = product;
    if(!batches) return 0;
    const batchesList = Object.keys(batches).map( key => batches[key]);
    return batchesList.reduce((a, b) => {
        const {ad, tasting, sold} = getBatchData(b);
        return a + ad + tasting + sold;
    } , 0);
};

export const getSoldDataForProduct = (product) => {
    if(!product) return {amount: 0, currentAmount: 0, ad: 0, tasting: 0, sold: 0};
    const {batches} = product;
    if(!batches) return {amount: 0, currentAmount: 0, ad: 0, tasting: 0, sold: 0};
    const batchesList = Object.keys(batches).map( key => batches[key]);
     return batchesList.reduce((a, b) => {
         const {amount, currentAmount, ad, tasting, sold} = getBatchData(b);
         a.amount += amount;
         a.currentAmount += currentAmount;
         a.ad += ad;
         a.tasting+= tasting;
         a.sold += sold;
         return a;
     } , {amount: 0, currentAmount: 0, ad: 0, tasting: 0, sold: 0});
}

const getBatchData = b =>{
    const amount = !isNaN(b.amount) ? parseInt(b.amount) : 0;
    const currentAmount  = !isNaN(b.currentAmount) ? parseInt(b.currentAmount) : 0;
    const ad = !isNaN(b.ad) ? parseInt(b.ad) : 0;
    const tasting = !isNaN(b.tasting) ? parseInt(b.tasting) : 0;
    const sold = amount > 0 && amount >= ad+tasting+currentAmount ? amount - ad - tasting - currentAmount : 0;
    return {amount, currentAmount, ad, tasting, sold};
}

export const getActiveBatch = (batches) => {
    const batchesList = Object.keys(batches).map( key => { batches[key].key = key; return batches[key]});
    const hasActiveFlag = batchesList.filter(b => b.active);
    if(!!hasActiveFlag && hasActiveFlag.currentAmount > 0) return hasActiveFlag;

    const filterBatches = batchesList.filter(b => b.currentAmount > 0);
    const sortedBatches = filterBatches.sort((a, b) => moment(a).diff(moment(b), 'days'));
    return !!sortedBatches[0] ? sortedBatches[0] : null;
};

export const getProductGrain = (product) => {
    switch (product.grain) {
        case 'singleMalt' :
            return "Single Malt";
        case 'singleGrain' :
            return "Single Grain";
        case 'grain' :
            return "Grain";
        case 'blend' :
            return "Blend";
        case 'postStill' :
            return "PostStill";
        default:
            return "-";
    }
};

export const getProductPrice = (product, userRole) => {
    let foundBatch = null;
    for (const b of product.batches) {
        if (!!foundBatch) continue;
        if (parseInt(b.amount) > 0) foundBatch = b;
    }
    if (userRole === 0b0010) {
        return Math.ceil(foundBatch.vk_pre * 100) / 100;
    } else {
        return Math.ceil((foundBatch.vk_pre + (foundBatch.vk_pre * 0.2)) * 100) / 100;
    }
};

export const getProductPricePerLitre = (product, userRole) => {
    let foundBatch = null;
    for (const b of product.batches) {
        if (!!foundBatch) continue;
        if (parseInt(b.amount) > 0) foundBatch = b;
    }
    const price = userRole === 0b0010 ? foundBatch.vk_pre : (foundBatch.vk_pre + (foundBatch.vk_pre * 0.2));
    if (!product.volume) return Math.ceil(price * 100) / 100;
    const volume = parseFloat(product.volume);
    let ltPrice = 0;
    if (volume <= 1) {
        ltPrice = price + (price / (volume * 100)) * (100 - (volume * 100));
    } else {
        ltPrice = price - (price / (volume * 100)) * ((volume * 100) - 100);
    }
    return Math.ceil(ltPrice * 100) / 100;
};

export const articleListFilter = filterObject => product => {
    let isProductInFilter = Object.keys((filterObject)).length === 0;
    let isEmpty = true;

    const hasOriginAndRegion = !!filterObject['origin'] && !!filterObject['origin'].length > 0 && !!filterObject['region'] && !!filterObject['region'].length > 0;
    const productHasRegion = !!product['region'];

    for (const key of Object.keys(filterObject)) {
        if (filterObject[key].length === 0) {
            continue;
        } else if (isEmpty) isEmpty = false;
        if (!product[key]) continue;
        if (key === 'origin' && product[key] === "Schottland" && hasOriginAndRegion && productHasRegion) continue;
        if (filterObject[key].indexOf(product[key]) > -1) {
            isProductInFilter = true;
            break;
        }
    }
    return isEmpty || isProductInFilter;
};

export const getActivePrePriceFromBatch = (batches) => {
    const sortedBatches = batches.sort((a, b) => moment(a).diff(moment(b), 'days'));
    for (const current of sortedBatches) {
        if (current.amount > 0) return current.vk_pre;
    }
    return 0;
};

export const cleanPriceString = (price) => {
    if(isNaN(price)) return 0;
    if(Number.isInteger(parseFloat(price))) return `${price} .-`;
    return parseFloat(price).toFixed(2);
};

export const cleanPriceStringSpecial = (price) => {
    if (Number.isInteger(parseFloat(price))) {
        return price + '.00';
    } else {
        return parseFloat(price)?.toFixed(2) ?? '0.00';
    }
};

export const getSum = (products) => {
    if(!products) return 0
    return products.reduce((acc, element) => parseFloat(element?.vk_net  ?? 0) * parseFloat(element?.amount ?? 0) + acc, 0);
};

export const getSumInkShipping = (products,tax) => {
    const sum = getSum(products);
    const shipping = getShipping(products);
    const shippingAbo = getShippingAbo(products);
    return sum + shipping + shippingAbo;
};

export const getSumPre = (products,tax) => {
    const sum = getSum(products);
    const fullSum = (sum / (1 + tax));
    return Math.ceil(fullSum * 100) / 100;
};

export const getMWST = (products,tax) => {
    const sum = getSum(products);
    const sumPre = getSumPre(products,tax);
    return parseFloat((sum - sumPre).toFixed(2));
};

export const getShipping = products => {
    if(!products) return 0
    const sum = products.reduce( (a, p) => a + (isAbo(p.articleNumber) ? 0 : parseFloat(p.vk_net) * parseInt(p.amount) ) , 0);
    if (sum > 150) return 0;

    const shippingCostProducts = products.filter(p => !!p.shippingCost && !isNaN(p.shippingCost) && !isAbo(p.articleNumber));
    const weightProducts = products.filter(p => !!p.weight && !isNaN(p.weight) &&  !isAbo(p.articleNumber));

    const shippingCostSum = shippingCostProducts.reduce( (a,p) => a + (parseFloat(p.shippingCost) * parseInt(p.amount) ),0);
    const weightSum = weightProducts .reduce( (a,p) => a + (parseFloat(p.weight) * parseInt(p.amount) ), 0);

    return shippingCostSum + getShippingByWeight(weightSum);
}

export const getShippingAbo = products => {
    if(!products) return 0
    const aboProducts = products.filter( p => isAbo(p.articleNumber));
    return aboProducts.reduce( (a,p) =>  a + 4.9 * p.times , 0);
};

export const getShippingByWeight = weight => {
    if(weight >= 4) return 16  ;
    else if(weight >= 2) return 11.95;
    else if(weight >= 1) return 5.95;
    else if (weight > 0) return 4.9;
    else return 0;
};

export const getCoupon = (coupon, products) =>{
    if (!coupon) return 0
    const sum = getSumInkShipping(products, 0.2);
    if (!!coupon.condition && sum < coupon.condition) return 0;
    if (!!coupon.abs) return coupon.abs;
    if (!!coupon.rel) return parseFloat((sum * (coupon.rel / 100)).toFixed(2));
    if (!isNaN(coupon)) return parseFloat(coupon);
    return 0
}

export const makeCoupon = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const isAbo = articleNumber => articleNumber.includes('ABO');

export const hasAbo = products => {
    return products.filter( p => isAbo(p.articleNumber)).length > 0;
};

export const getAboInfos = (product) => {
    return [product.times, parseInt(product.articleNumber.slice(-3)[0])];
};

export const getProductSum = product => {
    return (parseFloat(product.vk_net) * parseFloat(product.amount)).toFixed(2);
}

export const isEvent = articleNumber => articleNumber.includes('EVENT');

export const hasEvent = products => {
    return products.filter( p => isEvent(p.articleNumber)).length > 0;
}

export const getEventList = products => {
    return products.filter( p => isEvent(p.articleNumber));
}

export const validateEmail = (elementValue) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
};

export const isTooYoung = date => DateTime.fromFormat(date, 'dd.MM.yyyy').diffNow('years').years > -18;

