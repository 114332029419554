import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    wrapperImgCarousel: {
        width: '1270px',
        maxWidth: '100%',
        padding: '1em 24px 0',
        //height: '30vh',
        //borderBottom: 'solid 1px #bbb',
    },
    imgContainer: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    newsContainer: {
        cursor: 'pointer',

    },
    newsContainerImage: {
        width:'100%',
        height: '100%',
        border: 'solid 2px #333',
        borderRadius: '0.1em',

        '&:hover':{
            borderColor: 'black',
            boxShadow: '0 0 5px 0px #333',
        }
    },
    wrapperMainPic: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    heightWrapper:{
        minHeight: 'calc( 100vh - 220px - 205px )'
    }
}));
