import React, {useEffect} from 'react';
import {
    Switch,
    Route,
    Link,
    useLocation,
} from "react-router-dom";
import './App.css';
import {Footer, Header, WhiskyConfigSelector, ProtectedRoute, CookieBanner} from "components";
import Home from "pages/Home"
import Whisky from "pages/Whisky"
import WhiskyDetail from "pages/WhiskyDetail"
import CartPage from "pages/Cart"

import Admin from "./pages/Admin";
import Relative from "./pages/Relativ";
import Accessory from "./pages/Accessory";
import Pairing from "./pages/Pairing";
import Present from "./pages/Present";
import WishList from "./pages/WishList";
import Lights from "./pages/Lights";
import TastingSet from "./pages/Set";
import InfoPage from "./pages/InfoPage";
import WhiskyFilter from "./pages/WhiskyFilter";
import CartLogin from "./pages/CartLogin";
import ContactForm from "./pages/ContactForm";
import News from "./pages/NewsFeed";
import WhiskyDetailEdit from "./pages/WhiskyDetailEdit";
import CartPayment from "./pages/CartPayment";
import CartOrder from "./pages/CartOrder";
import Container from "@material-ui/core/Container";
import formular from 'common/Widerrufsformular.pdf';
import formular2 from 'common/Widerrufsform_STLogistik.pdf';
import WhiskySearch from "./pages/WhiskySearch";
import WhiskyAbo from "./pages/WhiskyAbo";
import SnackBar from "./components/SnackBar";
import WhiskyEvents from "./pages/WhiskyEvents";
import PrintBill from "./pages/printBill";
import WhiskyEventDetail from "./pages/WhiskyEventDetail";
import {inject, observer} from "mobx-react";
import AgeCheckDialog from "./components/AgeCheckDialog";

function App({store}) {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <div className="App">
            {!pathname.includes('print') && !pathname.includes('admin') && <Header/>}
            {!pathname.includes('print') && !pathname.includes('admin') && <div style={{height: '220px'}}/>}
            <Route exact path={'/whisky/'} component={WhiskyConfigSelector}/>
            <Route exact path={'/whisky/beginner'} component={WhiskyConfigSelector}/>
            <Route exact path={'/whisky/pro'} component={WhiskyConfigSelector}/>
            <Route exact path={'/whisky/master'} component={WhiskyConfigSelector}/>
            <Route exact path={'/whisky/specials'} component={WhiskyConfigSelector}/>
            <Route exact path={'/whisky/rarity'} component={WhiskyConfigSelector}/>
            <Route exact path={'/whisky/limit'} component={WhiskyConfigSelector}/>
            <Route exact path={'/whisky/sample'} component={WhiskyConfigSelector}/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/EMAIL">

                </Route>
                <Route exact path="/whisky/" component={Whisky}/>
                <Route exact path="/whisky/config/" component={WhiskyFilter}/>
                <Route exact path="/whisky/beginner/" component={Whisky}/>
                <Route exact path="/whisky/pro/" component={Whisky}/>
                <Route exact path="/whisky/master/" component={Whisky}/>
                <Route exact path="/whisky/specials/" component={Whisky}/>
                <Route exact path="/whisky/rarity/" component={Whisky}/>
                <Route exact path="/whisky/limit/" component={Whisky}/>
                <Route exact path="/whisky/sample/" component={Whisky}/>
                <Route exact path="/whisky/search" component={WhiskySearch}/>
                <Route exact path="/relatives/" component={Relative}/>
                <Route exact path="/pairing/" component={Pairing}/>
                <Route exact path="/whisky_geschenke/" component={Present}/>
                <Route exact path="/accessories/" component={Accessory}/>
                <Route exact path="/tastings/" component={TastingSet}/>
                <Route exact path="/lights/" component={Lights}/>
                <Route exact path="/news/" component={News}/>
                <Route exact path="/contact/form/" component={ContactForm}/>
                <Route exact path="/events/" component={WhiskyEvents}/>
                <Route exact path="/event/:year/:id" component={WhiskyEventDetail}/>
                <Route exact path="/trips/">
                    <Container maxWidth={'md'} style={{minHeight: 'calc(100vh - 449px)'}}>
                        <h3>Noch keine Reisen vorhanden</h3>
                        <p>Noch sind keine Reisen vohanden welche sie Buchen könnten, das Team bemüht sich für sie bald welche zur verfügung stellen zu
                            können.</p>
                    </Container>
                </Route>
                <Route exact path="/whisky/article/:id/" component={WhiskyDetail}/>
                <Route exact path="/whisky/article/:id/:articleName" component={WhiskyDetail}/>

                <Route path="/whiskyabo/" component={WhiskyAbo}/>
                <Route path="/whiskynews/">Whiskynews</Route>
                <Route path="/favoriten/" component={WishList}/>
                <Route exact path="/warenkorb/" component={CartPage}/>
                <Route path="/warenkorb/login/" component={CartLogin}/>
                <Route path="/warenkorb/payment/" component={CartPayment}/>
                <Route path="/warenkorb/order/" component={CartOrder}/>

                <ProtectedRoute path="/admin/" level={0b1000}>
                    <Admin/>
                </ProtectedRoute>

                <ProtectedRoute path="/print/bill/:type/:year/:id" level={0b0100}>
                    <PrintBill/>
                </ProtectedRoute>

                <ProtectedRoute path="/print/bill/" level={0b0100}>
                    <PrintBill/>
                </ProtectedRoute>

                <Route path="/terms/">
                    <InfoPage reference={'AGB'} header={'Allgemeine Geschäftsbedingungen'}/>
                </Route>
                <Route path="/termsDelivery/">
                    <InfoPage reference={'delivery'} header={'Lieferkonditionen'}/>
                </Route>
                <Route path="/contact/">
                    <InfoPage reference={'contact'} header={'Impressum und Kontakt'}/>
                </Route>
                <Route path="/dataSecurity/">
                    <InfoPage reference={'security'} header={'Datenschutz'}/>
                </Route>
                <Route path="/termsWithdraw/">
                    <InfoPage reference={'retour'} header={'Wiederrufsrecht'}/>

                    { /*TODO change Pdf */}
                    <Container maxWidth={'md'} style={{textAlign: 'left'}}>
                        {store.isWw ?
                            <Link to={formular} target="_blank" download>Wiederrufsformular</Link> :
                            <Link to={formular2} target="_blank" download>Wiederrufsformular</Link>
                        }
                    </Container>
                </Route>
            </Switch>
            <SnackBar/>
            {!pathname.includes('print') && !pathname.includes('admin') && <Footer/>}
            {!pathname.includes('print') && !pathname.includes('admin') && <CookieBanner/>}
            {!pathname.includes('print') && !pathname.includes('admin') && <AgeCheckDialog/>}
            {/*<Disclaimer open={isInfoOpen} handleClose={closeInfo}/>*/}
        </div>
    );
}

export default inject('store')(observer(App));

