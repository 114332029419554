import React, {useRef} from 'react';
import MarkdownEditor from "@uiw/react-markdown-editor";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}));
export const EditDescription = ({description, rating, setDescription, setRating}) => {
    const classes = useStyles();
    const desRef = useRef('');
    const ratRef = useRef('');

    const handleSetDescription = (a, b, val) => {
        desRef.current = val;
    };

    const handleSaveDescription = () => {
        setDescription(desRef.current)
    };

    const handleSetRating = (a, b, val) => {
        ratRef.current = val;
    };

    const handleSaveRating = () => {
        setRating(ratRef.current)
    };

    return <div>
        <div>
            <label htmlFor={'description'}> Beschreibung </label>
            <MarkdownEditor
                visible={false}
                className={classes.otherFont}
                value={description}
                id={'description'}
                onChange={handleSetDescription}
                onBlur={handleSaveDescription}
                height={250}
            />
        </div>
        <br/>

        {!!rating && <div>
            <label htmlFor={'rating'}> Unsere Bewertung </label>
            <MarkdownEditor
                className={classes.otherFont}
                value={rating}
                id={'rating'}
                onChange={handleSetRating}
                onBlur={handleSaveRating}
                height={250}
            />
        </div>}
    </div>
};