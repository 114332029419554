import React, {useState} from 'react';
import {DropZone} from "components/index";
import {LinearProgress} from "@material-ui/core";
import {storage} from "common/firebase";
import {makeStyles} from "@material-ui/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    dropZoneStyle: {
        width: 100,
        height: 150,
        textAlign: 'center',
        margin: '0.5rem',
        padding: '0.5em',
        fontSize: '0.75em',
        transition: 'all ease 500ms',
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        border: 'solid 1px #333',
        borderRadius: '0',
    },
    dropZoneStyleOnDrag: {
        border: 'dashed 1px black',
        borderRadius: '0.5em',
    }
}));

const EditImages = ({images, onImagesChange, articleNumber, isSet}) => {
    const classes = useStyles();
    const [uploads, setUploads] = useState([]);

    const handleAcceptedFilesImages = acceptedFiles => {
        acceptedFiles.forEach(file => {
            const uploadTask = storage.ref(`images/${articleNumber}/${file.name}`).put(file);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const index = uploads.findIndex(u => u.name = file.name);
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    if (index > -1) {
                        uploads[index].progress = progress;
                        setUploads([...uploads]);
                    } else {
                        const list = [{name: file.name, progress: progress}];
                        setUploads(list);
                    }
                },
                error => console.error(error),
                () => {
                    storage
                    .ref("images")
                    .child(articleNumber)
                    .child(file.name)
                    .getDownloadURL()
                    .then(url => {
                        addUrlToImages(url, file.name);
                        const index = uploads.findIndex(u => u.name = file.name);
                        if (index > -1) uploads.splice(index, 1);
                        setUploads([...uploads]);
                    });
                })
        })
    };

    const addUrlToImages = (url) => {
        if(images){
            images.push(url);
            onImagesChange(images);
        }else{
            onImagesChange([url]);
        }
    };

    const removeImage = (index, url) => e => {
        if (images[index] === url) {
            if(!isSet) storage.refFromURL(url).delete().then();
            images.splice(index, 1);
        } else {
            const i = images.findIndex(u => u === url);
            if (i > -1) {
                if(!isSet) storage.refFromURL(url).delete().then();
                images.splice(index, 1);
            }
        }
        onImagesChange(images);
    };

    const toFront = index => () => {
        [images[index - 1], images[index]] = [images[index], images[index - 1]];
        onImagesChange(images)
    };

    const toBack = index => () => {
        [images[index + 1], images[index]] = [images[index], images[index + 1]];
        onImagesChange(images)
    };

    return <div>
        <div>Bilder</div>
        <div style={{display: 'flex'}}>
            {!!images && images.length > 0 && images.map((url, index) => (
                <div key={index} style={{display: 'flex', alignItem: 'center'}}>
                    {index !== 0 && <IconButton onClick={toFront(index)}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </IconButton>}
                    <img alt={'Missing, click to Delete'} style={{margin: '0.5rem', cursor: "pointer", border: index === 0 ? 'solid 2px #333' : 'solid 1px #333', '&:hover': {borderColor: 'black'}}} width={100}
                         height={150} key={index} src={url} onClick={removeImage(index, url)}/>
                    {index !== (images.length - 1) && <IconButton onClick={toBack(index)}>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </IconButton>}
                </div>
            ))}
            {uploads.length === 0 && <DropZone handleAcceptedFiles={handleAcceptedFilesImages}
                                               dropText={'Bilder hier einfach absetzen'}
                                               startText={'Um Bilder hinzuzufügen, einfach hier Klicken oder die Bilder auf das Feld ziehen.'}
                                               className={classes.dropZoneStyle}
                                               dragClassName={classes.dropZoneStyleOnDrag}/>}
        </div>
        {uploads.map((upload, key) => (
            <div key={key}>
                {upload.name}
                <LinearProgress variant="determinate" value={upload.progress}/>
            </div>
        ))}
    </div>
};

export default EditImages;