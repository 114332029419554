import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {Container, useTheme} from '@material-ui/core';
import {useStyles} from "./styling";
import {faLongArrowAltLeft, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import img from "common/images/SamplePack.png";
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const WhiskyAbo = inject('store')(observer(({store}) => {
    const {
        headerBlockStyle, headerNameBlockStyle, headerElementStyle, eyeCatcherBlockStyle, overviewBlockLinkStyle,
        headerPriceBlockStyle, subHeaderElementStyle, subHeaderElementSmallerStyle, headerElementStyleSmall, subHeaderElementStyleSmall,
        imageBlockStyle, overviewBlockStyle, inputArray, inputElement, inputActiveElement
    } = useStyles();

    const [amount, setAmount] = useState(2);
    const [times,setTimes] = useState(6);
    const [price, setPrice] = useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setPrice(( amount !== 5 ? amount !== 4 ? amount !== 3 ? amount !== 2 ? 9 : 17 : 23 : 32 : 39 ) * times);
    }, [amount, times])

    const addToCart = () => {
        const longTimes = ('0' + times).slice(-2);
        const newEntry = {
            articleNumber: `WWABO${amount}${longTimes}`,
            amount: 1,
            timestamp: new Date().getTime(),
            vk_net: price,
            image: null,
            thumbnail : null,
            weight: (amount * 0.2) || null,
            times: times,
        };
        store.cartStore.addEntryToCart(newEntry);
    };

    const clearNumber = number => {
        if (!number) return null;
        return number.toString().replace('.', ',') + (number.toString().includes('.') ? '' : ',-')
    };

    return <Container maxWidth={"md"}>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - Whiskyabonnement, Kostproben erhalten, Hometatsing</title>
        </Helmet>
        <Grid container spacing={1} className={headerBlockStyle}>
            <Grid item sm={12} md={9}>
                <div className={headerNameBlockStyle}>
                    <h5 className={headerElementStyle} style={{margin: 0}}>Whisky Abo</h5>
                </div>
            </Grid>
            <Grid item sm={12} md={3} style={{width: '100%'}}>
                <div className={headerPriceBlockStyle}>
                    <div className={matches ? headerElementStyleSmall : headerElementStyle}>€{clearNumber(price)}</div>
                    <div className={[(matches ? subHeaderElementStyleSmall : subHeaderElementStyle),
                                     subHeaderElementSmallerStyle].join(' ')}>
                        Preis inkl. Mwst / exkl. Versand
                    </div>
                </div>
            </Grid>
        </Grid>
        <hr style={{margin: '1.5em 0'}}/>
        <div className={eyeCatcherBlockStyle}>
            <div className={imageBlockStyle}>
                <p style={{fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif', textAlign: 'justify'}}>
                    Wir freuen wir uns als eine der ersten Firmen Österreichs unseren Whiskyfreunden das Whisk(e)yAbo anbieten zu dürfen.
                    Da es ständig Neuerscheinungen am Whiskymarkt gibt - auf die man natürlich neugierig wartet- ist es schwer zu Entscheiden ob man eine
                    Flasche Whisky kaufen will oder nicht.
                    Deswegen bieten wir unseren Kunden die Möglichkeit zum Ersten stets über Whiskynews informiert zu sein und die neuen Tropfen auch zu kennen
                    zu lernen.
                    In dem Abo sind aber mitunter auch Whiskys verpackt die man nicht mehr im Handel bekommt bzw. von denen es nurmehr sehr wenige Flaschen
                    gibt. Lasst Euch überraschen.
                    Ihr habt die Auswahl zwischen 3 Paketen die jeden Monatsbeginn oder 6x bzw. 4x im Jahr per Post an Euch geschickt werden.
                    Ein Abo gilt für die Dauer von einem Jahr. Sollte binnen bis 2 Monaten vor Ablauf keine Stornierung per Email erfolgen wird ein weiteres
                    Jahr verlängert.
                    Jedes Sample entspricht einer Menge von 4cl und natürlich liegt jeder Sendung auch ausführliches Infomaterial über den Whisk(e)y bei.
                </p>
                <p style={{fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif', textAlign: 'justify'}}>
                    Wenn du das Abo mit 2 oder 3 Whiskys bestellst, packen wir dir in der Regel mind. einen "peated" (getorften) Whisk(e)y dazu.
                    Wenn du das Abo mit 4 oder 5 Whiskys bestellst packen wir dir in der Regel einen "peated" und einen "unpeated" Whisky, sowie mind. einen
                    Whiskey (also einen Irischen) und mind. einen Worldwhisky - sprich nicht Irland oder Schottland - in dein Abo Paket.
                </p>
                <p style={{fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif'}}>
                    Viel Spass beim "Tasting" <br/>
                    Slaintè<br />
                    Euer Whiskydevil :-)<br />
                </p>
                <img src={img} alt={'Bild für eine Abo'} style={{width: '100%'}}/>
            </div>
            <div className={overviewBlockStyle}>
                <div>
                    <label style={{textAlign: 'left', width: '100%', display: 'inline-block'}}>Wie viele Samples möchtest du ?</label>
                    <div className={inputArray}>
                        <div className={inputElement + (amount === 1 ? ' ' + inputActiveElement : '')} onClick={() => setAmount(1)}>1</div>
                        <div className={inputElement  + (amount === 2 ? ' ' + inputActiveElement : '')} onClick={() => setAmount(2)}>2</div>
                        <div className={inputElement  + (amount === 3 ? ' ' + inputActiveElement : '')} onClick={() => setAmount(3)}>3</div>
                        <div className={inputElement  + (amount === 4 ? ' ' + inputActiveElement : '')} onClick={() => setAmount(4)}>4</div>
                        <div className={inputElement  + (amount === 5 ? ' ' + inputActiveElement : '')} onClick={() => setAmount(5)}>5</div>
                    </div>
                    <label style={{textAlign: 'left', width: '100%', display: 'inline-block'}}>Wie oft im Jahr soll die Post kommen ?</label>
                    <div className={inputArray}>
                        <div className={inputElement + (times === 12 ? ' ' + inputActiveElement : '')} onClick={() => setTimes(12)}>12</div>
                        <div className={inputElement + (times === 6 ? ' ' + inputActiveElement : '')} onClick={() => setTimes(6)}>6</div>
                        <div className={inputElement + (times === 4 ? ' ' + inputActiveElement : '')} onClick={() => setTimes(4)}>4</div>
                    </div>
                    <Button variant={'contained'} color={'primary'} onClick={addToCart}>In den Warenkorb</Button>
                    <hr/>
                    <Link to={'/termsDelivery/'} className={overviewBlockLinkStyle}>
                        <FontAwesomeIcon icon={faLongArrowAltRight}/> Versandkosteninfo <FontAwesomeIcon icon={faLongArrowAltLeft}/>
                    </Link>
                </div>
            </div>
        </div>
    </Container>
}));

export default WhiskyAbo
