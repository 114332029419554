import React, {useState} from "react";

import {useStyles} from "./styling";
import {inject, observer} from "mobx-react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons';
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";


function ArticleList() {
    const {
        hidden, backTop
    } = useStyles();
    const [showScroll, setShowScroll] = useState(false);

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400){
            setShowScroll(false)
        }
    };
    window.addEventListener('scroll', checkScrollTop);


    return<Hidden smUp>
            <div className={ showScroll ? backTop : hidden} onClick={scrollTop}>
                <Avatar style={{backgroundColor: '#333', color: '#f5f5dc'}}>
                    <FontAwesomeIcon icon={faChevronUp}/>
                </Avatar>
            </div>
        </Hidden>

}

export default inject('store')(observer(ArticleList));


