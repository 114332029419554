import {Configurator as WhiskyConfiguratorBanner} from "components/banners";
import {WhiskyConfigurator} from "components";
import React from "react";
import {Helmet} from "react-helmet";


const WhiskyFilter = () => {

    return <div>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - World Whisky WW OG - Shop</title>
            <meta name="description"
                  content="Whiskypezialitäten aus aller Welt, Raritätenwebshop, Individuelle Tastings, Worldwhisky, Irischer Whiskey, Eigenabfüllungen, Whiskykostprobenservice, WhiskyAbo und mehr"
            />
        </Helmet>
        <WhiskyConfiguratorBanner/>
        <WhiskyConfigurator/>
    </div>
};

export default WhiskyFilter;
