import React, {useEffect, useState} from "react";
import {ArticleList} from "components";
import {useLocation} from 'react-router-dom';
import {Helmet} from "react-helmet";

function Whisky() {
    const location = useLocation();
    const [filterName, setFilterName] = useState(null);

    useEffect(() => {
        const {pathname} = location;
        const mode = pathname.replace('/whisky/', '').replace('/', '');
        switch (mode) {
            case "beginner" :
                setFilterName('beginner');
                break;
            case "pro":
                setFilterName('advanced');
                break;
            case "master":
                setFilterName('master');
                break;
            case "specials":
                setFilterName('special');
                break;
            case "rarity":
                setFilterName('rarity');
                break;
            case "limit":
                setFilterName('limit');
                break;
            case "sample":
                setFilterName('sample');
                break;
            default:
                setFilterName('whisky');
                break;
        }
    }, [location]);

    return <>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - Whiskeyspezialitäten, Raritätenwebshop, Individuelle Tastings, Worldwhisky, Irischer Whiskey</title>

        </Helmet>
        <ArticleList type={filterName}/>
        </>
}

export default Whisky;
