import {observable, action, decorate} from "mobx"
import {auth, database} from "common/firebase";
import Store from "common/store";


class WishListStore {

    constructor() {
        auth.onAuthStateChanged(user => {
            //ToDo check Localstorage with uid!
            if (!!user) {
                const wishListRef = database.ref(`wishList/${user.uid}`);
                wishListRef.once('value', snap => {
                    let onlineWishList = snap.val() || [];
                    if (this.wishList.length > 0) {
                        for (const a of this.wishList) {
                            const i = onlineWishList.findIndex(ar => ar.articleNumber === a.articleNumber);
                            if (i === -1) {
                                onlineWishList.push(a);
                            } else {
                                onlineWishList[i] = a;
                            }
                        }
                    }
                    for (const a of onlineWishList) {
                        database.ref(`articles/${a.articleNumber}`).once('value', articleSnap => {
                            if(!!articleSnap.val()) this.addArticleToWishList(articleSnap.val(), false);
                            else this.removeArticleFromWishList(a);
                        });
                    }
                    this.saveWishList();
                });
            }
        });
    }

    wishList = [];

    addArticleToWishList = (article, save = true) => {
        article.image = article.images[0] || null;
        const index = this.wishList.findIndex(a => a.articleNumber === article.articleNumber);
        if (index > -1) {
            this.wishList[index] = article;
        } else {
            this.wishList = [...this.wishList, article];
        }
        if (save) this.saveWishList();
    };

    removeArticleFromWishList = (article, save = true) => {
        const index = this.wishList.findIndex(a => a.articleNumber === article.articleNumber);
        if (index > -1) this.wishList.splice(index, 1);
        this.wishList = [...this.wishList];
        if (save) this.saveWishList();
    };

    saveWishList = () => {
        if (Store.userStore.isSignedIn) {
            database.ref(`wishList/${Store.userStore.currentUser.uid}`).set(this.wishList)
            .catch(e => console.error(e));
        }
    };

    isInWishList = product => {
        const index = this.wishList.findIndex(a => a.articleNumber === product.articleNumber);
        return index > -1;
    };

    setStore = store => {
        if (!!store) {
            this.wishList = store.wishList || this.wishList;
        }
    }

}

decorate(WishListStore, {
    wishList: observable,
    addArticleToWishList: action,
    removeArticleFromWishList: action,
    setStore: action,
});

export default WishListStore;