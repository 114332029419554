import React, {useEffect, useState} from "react";
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, TableContainer, Table, TableHead, TableRow, TableBody, InputAdornment
} from "@material-ui/core";
import {database} from "common/firebase";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimes} from "@fortawesome/free-solid-svg-icons";


export const useStyles = makeStyles(theme => ({
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}));


function UserSearchDialog ({open, handleClose, selectAddress}) {
    const classes = useStyles()
    const [list, setList] = useState([]);
    const [searchString, setSearchString] = useState('');

    useEffect( () => {
        const ref = database.ref("userdata");
        ref.on("value", snap => {
            const nextList = [];
            if(!snap.exists()) return setList([]);
            snap.forEach(snapUser => {
                const userdata = snapUser.val();
                if(!userdata.addresses) return;
                userdata.addresses.forEach( a => {
                    a.email = userdata.lastMailAddress;
                    nextList.push(a);
                })
            })
            setList(nextList);
        })
    },[])

    const filterFunction = element => element.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
        element.lastName?.toLowerCase().includes(searchString.toLowerCase())||
        element.email?.toLowerCase().includes(searchString.toLowerCase());

    return <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth disableScrollLock={true} className={classes.otherFont}>
        <DialogTitle>Nach Usern suchen</DialogTitle>
        <DialogContent>
            <TextField
                variant={"outlined"}
                label={"Suchen"}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                fullWidth
                placeholder={"Nach Vorname, Nachname oder Email suchen"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={() => setSearchString('')}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </InputAdornment>
                    ),
                }}
            />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Vorname</TableCell>
                            <TableCell>Nachname</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Adresse</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.filter(filterFunction).map((element,i) => (<TableRow key={i}>
                            <TableCell>{element.firstName}</TableCell>
                            <TableCell>{element.lastName}</TableCell>
                            <TableCell>{element.email}</TableCell>
                            <TableCell>
                                <div>{element.address1}</div>
                                <div>{element.address2}</div>
                                <div>{element.plz}, {element.region}</div>
                                <div>{element.land}</div>
                            </TableCell>
                            <TableCell>
                                <Button variant={"contained"} color={"primary"} onClick={selectAddress(element)}>
                                    Übernehmen
                                </Button>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>

        </DialogContent>
        <DialogActions>
            <Button variant={"outlined"} color={"primary"} onClick={handleClose}>
                Schließen
            </Button>
        </DialogActions>
    </Dialog>
}

export default UserSearchDialog;
