import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import {database} from "common/firebase";

const GetEmail = inject('store')(observer(({userId}) => {
    const [email, setEmail] = useState(null);


    useEffect(() => {
        let ref = null;
        let listener = null;
        if(!!userId && !userId.includes('@')){
            console.log(userId);
            ref = database.ref('userdata').child(userId).child('lastMailAddress');
            listener = ref.on( 'value', snap => {
                console.log(snap.exists());
                if(!snap.exists()) {
                    setEmail(userId);
                    return;
                }
                setEmail(snap.val());
            })
        }
        if(!!userId && userId.includes('@')){
            setEmail(userId);
        }

        return () => {
            if(!!ref && !!listener) ref.off('value', listener);
        }
    }, [userId]);


    return email ? <span>{email}</span> : 'ALL';
}));

export default GetEmail;
