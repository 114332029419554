import {action, decorate, observable} from "mobx"
import {database} from "common/firebase";
import Store from "common/store";
import {getSumInkShipping} from "common/statelessFunctions";

const LOCAL_STORAGE_NAME = 'worldwhisky-coupon';


class OrderStore {

    constructor() {
        const coupon = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_NAME));
        if (!!coupon) {
            database.ref('coupons').child(coupon.code).once('value', snap => {
                if (!snap.exists()) return;
                this.setCoupon(snap.val());
            })
        }
    }

    lastOrder = {};
    myOrders = [];
    currentBill = [];
    myBills = [];
    coupon = null;

    addNewOrder = (order, save = true) => {
        if (save) {
            const ref = database.ref(`orders/`);
            const newChildRef = ref.push();
            order.orderNumber = newChildRef.key;
            newChildRef.set(order);
        }
        this.lastOrder = order;
        return order;
    };

    fetchOrder = key => {
        database.ref(`orders/${key}`).once('value', snap => {
            if (snap.val()) this.lastOrder = snap.val()
        })
    };

    fetchUnzerOrder = key => {
        database.ref(`unzerOrders/${key}`).once('value', snap => {
            if (snap.val()) this.lastOrder = snap.val()
        })
    };

    fetchOrderByUID = uid => {
        database.ref(`orders/`).orderByChild("user").isEqual(uid).once('value', snap => {
            if (snap.val()) this.myOrders = snap.val();
        })
    };

    createNewBill = async (address, products, email, shippingCost, payed, tax, coupon, withoutTax) => {
        const year = new Date().getFullYear().toString().substr(-2);
        const newBillRef = database.ref('bills/REO').child(year).push();
        const bill = {
            key: newBillRef.key, state: 'pending', timestamp: new Date().getTime(), address, products, email, shippingCost, payed, tax, coupon, withoutTax, year
        };
        await newBillRef.update(bill);
        this.currentBill = bill;
        return this.currentBill;
    };

    createNewOrder = async (order) => {
        order.state = 'pending';
        const orderRef = await database.ref('orders').push(order);
        const orderSnap = await orderRef.once('value');
        if (orderSnap.exists()) {
            const newOrder = orderSnap.val();
            newOrder.orderNumber = orderSnap.key;
            await orderRef.set(newOrder);
            this.lastOrder = newOrder;
            return newOrder;
        }
        return null;
    };

    updateLastOrderMailLocal = (mail) => this.lastOrder.paymentMail = mail;

    updateOrder = (order) => {
        database.ref('orders').child(order.orderNumber).update(order);
        this.lastOrder = order;
    };

    updateBill = (bill) => {
        database.ref('bills/REO').child(bill.year).child(bill.key).update(bill);
    };

    updateBillState = (year, key, state) => {
        database.ref(`bills/REO/${year}/${key}`).update({state}).then().catch(e => console.error(e));
    };

    setCoupon = coupon => {
        this.coupon = coupon;
        sessionStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(coupon));
    }

    handleAddCoupon = code => event => this.addCoupon(code);

    addCoupon = async (code) => {
        if (code !== '') {
            const snap = await database.ref('coupons').child(code).once('value');
            if (snap.exists()) {
                const data = snap.val();
                if (!data.used) {
                    if (!!data.for) {
                        if (!!Store.userStore.currentUser) {
                            const {currentUser} = Store.userStore;
                            if (data.for === currentUser.uid || data.for === currentUser.email) {
                                this.setCoupon(data);
                                Store.orderStore.setCoupon(data);
                            } else {
                                this.resetCoupon('Dieser Code ist auf einen Anderen User gebunden. Wenn dies ein Irrtum sein soltle wenden sie sich bei Office@worldwhisky.at');
                            }
                        } else {
                            this.resetCoupon('Dieser Code ist an einen User gebunden bitte melden sie sich an!');
                        }
                    } else {
                        this.setCoupon(data);
                        Store.orderStore.setCoupon(data);
                    }
                } else {
                    this.resetCoupon('Dieser Code wurde schon verwendet');
                }
            } else {
                this.resetCoupon('Dieser Code Existiert in userem System nicht');
            }
        } else {
            this.resetCoupon('Schreiben sie in das Rabatt Feld ihren Gutscheincode und drücken sie dann noch mal auf das Plus.');
        }
    };

    resetCoupon = message => {
        Store.snackBarHandle.addMessages(message);
        this.setCoupon(null);
        Store.orderStore.setCoupon(null);
    };

    getCoupon = (products) => {
        if (!this.coupon) return 0
        const sum = getSumInkShipping(products, 0.2);
        if (!!this.coupon.condition && sum < this.coupon.condition) return 0;
        if (!!this.coupon.abs) return this.coupon.abs;
        if (!!this.coupon.rel) return parseFloat((sum * (this.coupon.rel / 100)));
        return 0
    };

    useCoupon = coupon => {
        if (!coupon) return;
        if (coupon.multiUse) return;
        database.ref('coupons').child(coupon.code).update({used: true});
    };

    isCouponUsed = async coupon => {
        if (!coupon) return false;
        const snap = await database.ref('coupons').child(coupon.code).child('used').once('value');
        if (snap.exists()) return snap.val();
        return false;
    }

}

decorate(OrderStore, {
    lastOrder: observable,
    myOrders: observable,
    coupon: observable,
    addOrder: action,
    fetchOrder: action,
    createNewBill: action,
    createNewOrder: action,
    updateOrder: action,
    updateLastOrderMailLocal: action,
    updateBill: action,
    setCoupon: action,
    getCoupon: action,
    resetCoupon: action,
    addCoupon: action,
    handleAddCoupon: action
});

export default OrderStore;
