import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import DbImage from "../../../components/dbImage";
import {database} from "../../../common/firebase";
import {faLock, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "../../../components";
import {
    AppBar, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Collapse, Divider, IconButton, ImageList, ImageListItem, LinearProgress,
    TextField,
    Typography
} from "@material-ui/core";
import {ImagePickerModal} from "../../../components/ImagePickerModal";
import Tooltip from "@material-ui/core/Tooltip";
import NewsFeed from "pages/NewsFeed";
import {DateTime} from "luxon";
import NewsCard from "../../../components/NewsCard";
import ReactMarkdown from "react-markdown";
import {Link} from "react-router-dom";
import ImageBackdrop from "../../../components/ImageBackdrop";
import {NewsEditDialog} from "./NewsEditDialog";


const useStyles = makeStyles(theme => ({
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    wrapperImgCarousel: {
        width: '1270px',
        maxWidth: '100%',
        padding: '1em 24px 0'
    },
    textFieldStyle: {
        marginBottom: '1em'
    },
    imgContainer: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    newsContainer: {
        cursor: 'pointer',

    },
    newsContainerImage: {
        width: '100%',
        height: '100%',
        border: 'solid 2px #333',
        borderRadius: '0.1em',
        minHeight: 250,

        '&:hover': {
            borderColor: 'black',
            boxShadow: '0 0 5px 0px #333',
        }
    },
    plusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '4em',
        borderRadius: 0
    },
    modalClickableImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        minHeight: '75px',
        cursor: 'pointer',
    },
    wrapperMainPic: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    marginLeft: {
        marginRight: '0.25em'
    },
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    iconPlus: {
        fontSize: '1.5em',
    },
    relativeContainer: {
        position: 'relative'
    },
    headerText: {
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    root: {
        marginBottom: theme.spacing(2)
    },
}));

export const AdminEditNewsFeed = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [newsFeed, setNewsFeed] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editNews, setEditNews] = useState(null);

    useEffect(() => {
        setLoading(true);
        const ref = database.ref('newsFeedUnpublished');
        const listener = ref.on('value', snap => {
            if(!snap.exists()) {
                setNewsFeed([]);
                return setLoading(false);
            }
            const list = [];
            snap.forEach( snapElement => {
                const element = snapElement.val();
                element.key = snapElement.key;
                list.push(element)
            });
            setNewsFeed(list.sort( (a,b) => a.timestamp > b.timestamp ? -1 : 1));
            setLoading(false);
        })

        return _ => {
            ref.off('value', listener);
        }

    }, [])

    const openCreateFeedback = e => {
        const ref = database.ref('newsFeedUnpublished');
        const element = {timestamp: Date.now(), images: [], links: []}
        const newChildRef = ref.push(element);
        element.key = newChildRef.key;
        setEditNews(element);
    }

    const toggleEdit = news => e => {
        setEditNews(news);
    }

    const deleteNews = news => e => {
        if (window.confirm('Willst du diesen NewsFeed wirklich Löschen ?')) {
            const ref = database.ref('newsFeedUnpublished');
            ref.child(news.key).set(null);
        }
    }

    const publish = news => e => {
        if (window.confirm('Willst du diesen NewsFeed wiklich veröffentlichen ?')) {
            const refFeed = database.ref('newsFeed');
            const refFeedUnpublished = database.ref('newsFeedUnpublished');
            news.timestamp = Date.now();
            refFeed.child(DateTime.local().toFormat('yy')).child(news.key).set(news);
            refFeedUnpublished.child(news.key).set(null);
        }

    }

    const unpublish = news => e => {
        if (window.confirm('Willst du diesen NewsFeed wiklich zurückziehen ?')) {
            const refFeed = database.ref('newsFeed');
            const refFeedUnpublished = database.ref('newsFeedUnpublished');
            refFeed.child(DateTime.local().toFormat('yy')).child(news.key).set(null);
            refFeedUnpublished.child(news.key).set(news);
        }
    }


    return <>
        <AppBar position="static" className={classes.appBar}>News-Feed bearbeiten</AppBar>
        <Container maxWidth={'md'}>
            <h4>In Arbeit</h4>
            { loading && <LinearProgress style={{marginBottom: '1em'}} /> }
            <Card  className={classes.root}>
                <CardContent>
                        <CardActionArea className={classes.relativeContainer} onClick={openCreateFeedback}>
                            <Typography gutterBottom variant={"h5"} component={"h2"} className={classes.headerText}>
                                <span>Neue News Hinzufügen</span>
                                <FontAwesomeIcon icon={faPlus} className={classes.iconPlus}/>
                            </Typography>
                        </CardActionArea>
                </CardContent>
            </Card>
            { !loading && newsFeed.map( (news,i) => (<NewsCard key={i} news={news} toggleEdit={toggleEdit(news)} publish={publish(news)} callDelete={deleteNews(news)} /> ))}
            <Divider />
            <h4>Online</h4>
            <NewsFeed unpublish={unpublish}/>
        </Container>
        <NewsEditDialog open={!!editNews} handleClose={ e => setEditNews(null)} news={editNews} save={(e) => console.log('save')} />

    </>
}))
