import React from 'react';
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {useStyles} from "./styling";
import {Container} from "@material-ui/core";
import {ArticleObject} from "../../components";

const WishList = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const history = useHistory();
    const {addMessage} = store.snackBarHandle;

    const handleClickOnProduct = (id, name) => (e) => {
        history.push(`/whisky/article/${id}/${name.replace(' ', '_')}`)
    };

    const addToCart = (product) => () => {
        store.cartStore.writeArticleInCart(product, 1, false);
        addMessage(`${product.articleNumber} zum Warenkorb hinzugefügt`);
    };

    return <Container maxWidth="md">
        <div className={classes.gridProductStyle + ' ' + classes.gridMinHeight}>
            {store.wishListStore.wishList.length === 0 && <div> Du hast noch keine Produkte im deiner Liste</div>}
            {store.wishListStore.wishList.map((product, index) => (
                <ArticleObject key={index} product={product} handleClickOnProduct={handleClickOnProduct} addToCart={addToCart} hasRights={false} />
            ))}
        </div>
    </Container>
}));


export default WishList;
