import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'common/i18n';
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import Store from "common/store";
import {Provider} from "mobx-react";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import 'mobx-react-lite/batchingForReactDom';

import {createTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

Sentry.init({
    dsn: "https://5cc5021098e24899a0a75972b5060f85@o469421.ingest.sentry.io/5498859",
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

const theme = createTheme({
    palette: {
        primary: {
            light: '#555',
            dark: '#111',
            main: '#333',
            contrastText: '#f7f7e5',
        },
        secondary: {
            light: '#4a1',
            dark: '#280',
            main: '#390',
            contrastText: '#eee',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            'Cobaissi', '-apple-system', 'sans-serif'
        ].join(','),
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
            }
        }
    }
});


ReactDOM.render(
    <>
        <Provider store={Store}>
            <Router>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <App/>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </Router>
        </Provider>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
