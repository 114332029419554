import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import DbImage from "../../../components/dbImage";
import {database} from "../../../common/firebase";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "../../../components";
import {AppBar, Button, TextField} from "@material-ui/core";
import {ImagePickerModal} from "../../../components/ImagePickerModal";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    wrapperImgCarousel: {
        width: '1270px',
        maxWidth: '100%',
        padding: '1em 24px 0'
    },
    textFieldStyle: {
        marginBottom: '1em'
    },
    imgContainer: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    newsContainer: {
        cursor: 'pointer',

    },
    newsContainerImage: {
        width: '100%',
        height: '100%',
        border: 'solid 2px #333',
        borderRadius: '0.1em',
        minHeight: 250,

        '&:hover': {
            borderColor: 'black',
            boxShadow: '0 0 5px 0px #333',
        }
    },
    plusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '4em',
        borderRadius: 0
    },
    modalClickableImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        minHeight: '75px',
        cursor: 'pointer',
    },
    wrapperMainPic: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    marginLeft: {
        marginRight: '0.25em'
    },
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}));

export const AdminEditStartPage = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [header, setHeader] = useState();
    const [linkList, setLinkList] = useState([]);

    const [elementToEdit, setElementToEdit] = useState();
    const [saving, setSaving] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        database.ref('pages/home/header').on('value', snap => {
            if (!snap.exists()) database.ref('pages/home/header').set({image: 'WelcomeHeader.jpg', alt: 'Link als Bild zum Whisky-Shop', link: '/whisky/', tooltip: 'Zum Shop'});
            setHeader({...snap.val(), key: 'header'});
        })
        database.ref('pages/home/linkElements').on('value', snap => {
            if (!snap.exists()) return;
            const cache = []
            snap.forEach(snapElement => {
                const element = {...snapElement.val(), key: snapElement.key};
                cache.push(element);
            })
            setLinkList(cache);
        })
    }, [])

    const addImageLink = (event) => {
        database.ref('pages/home/linkElements').push({image: '', alt: '', link: '', tooltip: ''});
        console.log('new element');
    }

    const saveElementToEdit = () => {
        setSaving(true);
        console.log(elementToEdit);
        const res = elementToEdit.key === 'header' ? 'pages/home' : 'pages/home/linkElements';
        database.ref(res).child(elementToEdit.key).set(elementToEdit).then(res => {
            setSaving(false);
            setElementToEdit(null);
        }).catch(e => {
            store.snackBarHandle.messages('Beim speicher ist etwas schief gegangen');
        });
    }

    const deleteElementToEdit = () => {
        setSaving(true);
        if(elementToEdit.key === 'header') return store.snackBarHandle.messages('Header kann nicht gelöscht werden!');
        database.ref('pages/home/linkElements').child(elementToEdit.key).set(null).then(res => {
            setSaving(false);
            setElementToEdit(null);
        }).catch(e => {
            store.snackBarHandle.messages('Beim löschen ist etwas schief gegangen');
        });
    }

    const handleChangeImageOfElementToEdit = (imgs) => {

        const changeList = imgs.filter(image => elementToEdit.image !== image);
        console.log(changeList, elementToEdit.image);
        setImages(changeList);
        setElementToEdit({...elementToEdit, image: changeList[0]});
    }

    return <>
        <AppBar position="static" className={classes.appBar}>Start-Seite bearbeiten</AppBar>
        <div className={classes.wrapperMainPic}>
            <div className={classes.wrapperImgCarousel} onClick={() => setElementToEdit(header)}>
                {!!header && <DbImage className={classes.imgContainer} src={['images', 'home', header.image]} alt={header.alt}/>}
            </div>
        </div>
        <Container maxWidth={'md'}>
            <hr/>
            <Grid container spacing={1}>
                {linkList.map((element, i) => (
                    <Grid item sm={12} md={6} key={i}>
                        <Tooltip title={element.tooltip}>
                        <div className={classes.newsContainer} onClick={() => setElementToEdit(element)}>
                            <DbImage className={classes.newsContainerImage} src={['images', 'home', element.image]} alt={element.alt}/>
                        </div>
                        </Tooltip>
                    </Grid>
                ))}
                <Grid item sm={12} md={6}>
                    <div className={classes.newsContainer} onClick={addImageLink} style={{cursor: 'pointer'}}>
                        <div className={[classes.newsContainerImage, classes.plusContainer].join(' ')}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <hr/>
        </Container>
        <Modal title={'Bearbeiten des Elementes'} size={'md'} handleClose={() => setElementToEdit(null)} open={!!elementToEdit} actions={
            <div>
                <Button className={[classes.otherFont, classes.marginLeft].join(' ')} color={"primary"} variant={"outlined"}
                        onClick={() => setElementToEdit(null)} disabled={saving}>Abbrechen</Button>
                { elementToEdit?.key !== 'header' && <Button className={[classes.otherFont, classes.marginLeft].join(' ')} color={"secondary"} variant={"contained"} onClick={deleteElementToEdit}
                        style={{marginRight: '0.25em'}} disabled={saving}>Löschen</Button> }
                <Button className={classes.otherFont} color={"primary"} variant={"contained"} onClick={saveElementToEdit}
                        disabled={saving}>{saving} Speichern</Button>
            </div>
        }>
            {!!elementToEdit && <div className={classes.otherFont}>
                <div onClick={() => setImages([elementToEdit.image])}>
                    <span>{elementToEdit.key}</span>
                    <span>Gewünschte dimensionen: {elementToEdit?.key !== 'header' ? '500 x 280' : '1350 x 250'}</span>
                    <DbImage className={classes.modalClickableImage} src={['images', 'home', elementToEdit.image]}/></div>
                <div><TextField
                    className={classes.textFieldStyle}
                    id="element-alt"
                    label="Alternative Text"
                    value={elementToEdit.alt}
                    helperText={'Text der angezeigt wird wenn das Bild nicht geladen werden kann.'}
                    type="text"
                    name={'alt'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setElementToEdit({...elementToEdit, alt: e.target.value})}
                    fullWidth
                /></div>
                <div><TextField
                    className={classes.textFieldStyle}
                    id="element-tooltip"
                    label="Info Text"
                    value={elementToEdit.tooltip}
                    helperText={'Text der angezeigt wird wenn man mit der Maus über das Bild hovert.'}
                    type="text"
                    name={'tooltip'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setElementToEdit({...elementToEdit, tooltip: e.target.value})}
                    fullWidth
                /></div>
                <div><TextField
                    className={classes.textFieldStyle}
                    id="element-link"
                    label="Link"
                    value={elementToEdit.link}
                    placeholder={'/whiksy/'}
                    helperText={'Link für das Bild, auf diese Seite wird bei Klick weiter geleitet'}
                    type="text"
                    name={'link'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setElementToEdit({...elementToEdit, link: e.target.value})}
                    fullWidth
                /></div>
            </div>}
        </Modal>

        <ImagePickerModal
            open={images.length > 0}
            handleClose={() => setImages([])}
            subPath={'home'}
            selectedTypeOne={images} selectTypeOne={"Auswahl"} selectTypeOneCallback={handleChangeImageOfElementToEdit}
        />
    </>
}))
