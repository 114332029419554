import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    headerBlockStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1em 2em 0 2em',

    },
    headerNameBlockStyle: {
        textAlign: 'left',
    },
    headerPriceBlockStyle: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    headerElementStyle: {
        fontSize: '2em',
    },
    headerElementStyleSmall: {
        fontSize: '1.5em',
        textAlign: 'center'
    },
    subHeaderElementStyle: {
        fontSize: '1.2em',
    },
    subHeaderElementSmallerStyle: {
        fontSize: '0.75em'
    },
    subHeaderElementStyleSmall: {
        fontSize: '1em',
        textAlign: 'center'
    },
    eyeCatcherBlockStyle: {
        display: 'flex',
    },
    overviewLikeBlockStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '2em',
        color: '#333',
        cursor: 'pointer',
        '&:hover': {
            color: '#390',
        }
    },
    overviewLikeBlockInfoTextStyle: {
        marginTop: '1em',
        fontSize: '0.5rem',
    },
    overviewBlockInfoGridStyle: {
        display: 'Grid',
        width: '100%',
        gridTemplateColumns: '1fr 2fr',
        gridTemplateRows: 'auto',
        paddingLeft: '2em',
        paddingBottom: '1em',
        gridRowGap: '1em',
        gridColumnGap: '1em',
        textAlign: 'left',

    },
    overviewBlockLinkStyle: {
        color: '#333',
        fontSize: '0.75rem',
        textDecoration: 'none',

        '&:hover': {
            color: '#000',
            textDecoration: 'underline',
        }
    },
    imageBlockStyle: {
        width: '65%',
        padding: '0.5em 2em 2em 0',
        textAlign: 'left'
    },
    selectedImageStyle: {
        width: '100%',
        height: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectedImageElementStyle: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    imageListStyle: {},
    imageListElementStyle: {
        maxWidth: 150,
        maxHeight: 200,
        border: 'solid 1px #333',
        backgroundColor: '#fff',
        margin: '1em',
        cursor: 'pointer',
        objectFit: 'contain',
        '&:hover': {
            borderColor: '#000',
        }
    },
    overviewBlockStyle: {
        width: '35%',
    },
    detailBlockStyle: {
        marginTop: '2em',
    },
    detailBlockInfoStyle: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        height: 400,
        overflow: 'auto',
        textAlign: 'left',
        padding: '1em',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    likeBtnLiked: {
        color: '#390',

        '&:hover': {
            color: '#333',
        }
    },
    inputArray: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '0.5em'
    },
    inputElement: {
        width: 40,
        height: 40,
        display: 'flex',
        margin: '0.25em',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'solid 1px #333',
        borderRadius: '0.25em',
        cursor: 'pointer',
        '&:hover':{
            boxShadow: '0 0 8px 0 #333'
        }
    },
    inputActiveElement: {
        boxShadow: 'inset 0 0 4px 0 #333'
    }
}));