import React, {useEffect, useMemo, useState} from 'react';
import {Modal} from "../index";
import {
    Button, FormControl, InputAdornment, MenuItem, Select, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow, TextField, Tooltip
} from "@material-ui/core";
import {database} from "../../common/firebase";
import Box from "@material-ui/core/Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEuroSign, faPercentage, faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faCheckCircle, faCircle} from "@fortawesome/free-regular-svg-icons";
import InputLabel from "@material-ui/core/InputLabel";
import TableCell from "@material-ui/core/TableCell";
import {DatePicker} from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import {inject, observer} from "mobx-react";
import {emptyBatch} from "common/constants";

const getVK = (ek, spread, tax) => {
    const vk_pre = parseFloat((ek + (ek * spread)).toFixed(2));
    const vk_net = parseFloat((vk_pre + (vk_pre * tax)).toFixed(2));
    return [vk_pre, vk_net];
};

const getSpread = (ek, vk_net, tax) => {
    const vk_pre = parseFloat((vk_net / (1 + tax)).toFixed(2));
    const spread = parseFloat(((vk_pre / ek) * 100 - 100).toFixed(2));
    return [vk_pre, spread];
};

const BatchModal = inject('store')(observer(({store, isOpen, toggleOpen, articleNumber}) => {
    const [batches, setBatches] = useState();
    const [editBatch, setEditBatches] = useState();
    const [isSet, setIsSet] = useState(false);
    const [batchSet, setBatchSet] = useState([]);
    const [newBatch, setNewBatch] = useState(JSON.parse(JSON.stringify(emptyBatch)));
    const maxSetSize = useMemo(() => batchSet.length > 0 ? batchSet.reduce(
        (a, b) => a !== -1 ? a > b.currentAmount ? b.currentAmount : a : b.currentAmount, -1)
        : 0
        , [batchSet]);

    useEffect(() => {
        if (articleNumber) {
            const baseRef = database.ref('batches');
            // Get Batch for Article
            baseRef.child(articleNumber).on('value', snap => {
                // create new Empty Batch
                const localNewBatch = JSON.parse(JSON.stringify(emptyBatch));
                // Loop over Batches
                snap.forEach(sb => {
                    const batch = sb.val();
                    setIsSet(!!batch.set);
                    // Check if Batch has Set attribute
                    if (!!batch.set) {
                        // Loop over articles in Set
                        batch.set.forEach(product => {
                            let artNum = null
                            // Check if Product is String or Object
                            if (!!product.articleNumber) {
                                // if Object set artNum to Product article number
                                artNum = product.articleNumber;
                            } else {
                                // else product self should be article number
                                artNum = product;
                            }
                            // Get Batch for this Article
                            baseRef.child(artNum).once('value', subSnap => {
                                // Loop over all Batches
                                subSnap.forEach(ssb => {
                                    const batch = ssb.val();
                                    // check if Batch is Active
                                    if (batch.active) {
                                        // Set articleNumber and Key for safty reasons
                                        batch.articleNumber = artNum
                                        batch.key = ssb.key;
                                        // check if bach ek exist and add it to newBatch ek
                                        if (!!batch.ek && !isNaN(batch.ek)) localNewBatch.ek += parseFloat(batch.ek);
                                        // set newBatch and add this batch to list.
                                        setNewBatch({...localNewBatch});
                                        setBatchSet(b => [...b, batch]);
                                    }
                                });
                            })
                        })
                    }
                })
                if (snap.exists()) {
                    const batches = snap.val();
                    setBatches(batches);
                } else {
                    setBatches(null);
                }
            })
        }
    }, [articleNumber]);

    const setBatchAttributeDate = value => {
        newBatch.date = value;
        setNewBatch(JSON.parse(JSON.stringify(newBatch)));
    }

    const setBatchAttribute = event => {
        const {value, name} = event.target;
        newBatch[name] = value;

        let vk_pre, vk_net, spread;
        if (name === 'ek' && newBatch['ek'] !== '' && newBatch['vk_net'] !== '') {
            [vk_pre, spread] = getSpread(parseFloat(newBatch['ek']), parseFloat(newBatch['vk_net']), 0.2)
        } else if (name === 'ek' && newBatch['ek'] !== '' && newBatch['spread'] !== '') {
            [vk_pre, vk_net] = getVK(parseFloat(newBatch['ek']), parseFloat(newBatch['spread']) / 100, 0.2)
        } else if (name === 'vk_net' && newBatch['ek'] !== '') {
            [vk_pre, spread] = getSpread(parseFloat(newBatch['ek']), parseFloat(newBatch['vk_net']), 0.2)
        } else if (name === 'spread' && newBatch['ek'] !== '') {
            [vk_pre, vk_net] = getVK(parseFloat(newBatch['ek']), parseFloat(newBatch['spread']) / 100, 0.2)
        }

        newBatch['vk_pre'] = vk_pre || newBatch['vk_pre'];
        newBatch['vk_net'] = vk_net || newBatch['vk_net'];
        newBatch['spread'] = spread || newBatch['spread'];

        setNewBatch(JSON.parse(JSON.stringify(newBatch)));
    };

    const addBatch = event => {
        const {date, amount, ek, spread, vk_net, vk_pre} = newBatch;
        const active = !batches || Object.keys(batches).length <= 0;
        const reserved = 0;
        const currentAmount = amount;
        const ref = database.ref(`batches/${articleNumber}`);
        const newChildRef = ref.push();
        newChildRef.set({
            date, amount, ek, spread, vk_pre, vk_net, active, reserved, currentAmount,
            set: batchSet.length > 0 ? batchSet.map(b => ({articleNumber: b.articleNumber, batch: b.key})) : null
        });
        if (active) database.ref(`articles/${articleNumber}/activeBatch`).set(newChildRef.key);
        if (batchSet.length > 0) {
            batchSet.forEach(b => {
                store.articleStore.createTransaction(b.articleNumber, amount, "newArticle", b.key, b.ek ? b.ek : 0, b.ek ? b.ek : 0);
            })
        }
        setNewBatch(JSON.parse(JSON.stringify(emptyBatch)));
    };

    const handleEditBatch = (key) => (event) => {
        setEditBatches(key);
    };

    const handleDeleteBatch = (key) => (event) => {
        database.ref(`batches/${articleNumber}/${key}`).set(null);
    };

    const saveBatch = (key) => event => {
        if (parseInt(batches[key].currentAmount) !== parseInt(batches[key].amount)) {
            store.snackBarHandle.addMessages("Der Aktuelle Lagerstand wurde auf die Menge umgeschrieben, dadurch ist die Anzahl der verkauften artikel auf 0 Zurückgesetzt worden.")
            batches[key].currentAmount = batches[key].amount;
        }
        database.ref(`batches/${articleNumber}/${key}`).set(batches[key]);
        setEditBatches(null);
    };

    const handleChangeDate = (key) => (value) => {
        batches[key].date = value;
        setBatches(JSON.parse(JSON.stringify(batches)));
    }

    const handleChange = (key) => event => {
        const {value, name} = event.target;
        batches[key][name] = value;

        let vk_pre, vk_net, spread;
        if (name === 'ek' && batches[key]['ek'] !== '' && batches[key]['vk_net'] !== '') {
            [vk_pre, spread] = getSpread(parseFloat(batches[key]['ek']), parseFloat(batches[key]['vk_net']), 0.2)
        } else if (name === 'ek' && batches[key]['ek'] !== '' && batches[key]['spread'] !== '') {
            [vk_pre, vk_net] = getVK(parseFloat(batches[key]['ek']), parseFloat(batches[key]['spread']) / 100, 0.2)
        } else if (name === 'vk_net' && batches[key]['ek'] !== '') {
            [vk_pre, spread] = getSpread(parseFloat(batches[key]['ek']), parseFloat(batches[key]['vk_net']), 0.2)
        } else if (name === 'spread' && batches[key]['ek'] !== '') {
            [vk_pre, vk_net] = getVK(parseFloat(batches[key]['ek']), parseFloat(batches[key]['spread']) / 100, 0.2)
        }

        batches[key]['vk_pre'] = vk_pre || batches[key]['vk_pre'];
        batches[key]['vk_net'] = vk_net || batches[key]['vk_net'];
        batches[key]['spread'] = spread || batches[key]['spread'];

        setBatches(JSON.parse(JSON.stringify(batches)));
    };

    const setActive = key => event => {
        const lastActive = Object.keys(batches).find(key => batches[key].active);
        if (!batches[key].active) {
            batches[key].active = true;
            database.ref(`batches/${articleNumber}/${key}/active`).set(true);
            database.ref(`articles/${articleNumber}/activeBatch`).set(key);
            if (!!lastActive) {
                batches[lastActive].active = !batches[lastActive].active;
                database.ref(`batches/${articleNumber}/${lastActive}/active`).set(false);
            }
            setBatches(JSON.parse(JSON.stringify(batches)));
        } else {
            database.ref(`batches/${articleNumber}/${key}/active`).set(false);
            database.ref(`articles/${articleNumber}/activeBatch`).set(null);
        }

    };

    return <Modal title={"Lieferungen organisieren"} open={isOpen} handleClose={toggleOpen}
                  actions={<Button color={"primary"} variant={"contained"} onClick={toggleOpen}>Close</Button>}>
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">Aktiv</TableCell>
                            <TableCell component="th">Lieferdatum</TableCell>
                            <TableCell component="th">Menge</TableCell>
                            <TableCell component="th">Einkauf <FontAwesomeIcon icon={faEuroSign}/></TableCell>
                            <TableCell component="th">Marge <FontAwesomeIcon icon={faPercentage}/></TableCell>
                            <TableCell component="th">Verkauf <FontAwesomeIcon icon={faEuroSign}/></TableCell>
                            <TableCell component="th">Optionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!batches && Object.keys(batches).map((id, key) => (<TableRow key={key}>
                            <TableCell>
                                <Tooltip
                                    title={parseInt(batches[id].currentAmount) !== 0 ? batches[id].active ? 'Nicht mehr Verwenden' : 'Verwenden' : 'Deaktiviert'}>
                                        <span>
                                        <IconButton
                                            color={"primary"}
                                            variant={"outlined"}
                                            disabled={parseInt(batches[id].currentAmount) === 0}
                                            onClick={setActive(id)}>
                                                {!batches[id].active && <FontAwesomeIcon icon={faCircle}/>}
                                            {batches[id].active && <FontAwesomeIcon icon={faCheckCircle}/>}
                                        </IconButton>
                                        </span>
                                </Tooltip>

                            </TableCell>
                            <TableCell>
                                <DatePicker label={'Lieferdatum'}
                                            disabled={id !== editBatch || parseInt(batches[id].amount) !== parseInt(batches[id].currentAmount)}
                                            fullWidth
                                            inputVariant={'outlined'}
                                            format="dd.MM.yyyy"
                                            value={batches[id].date}
                                            onChange={handleChangeDate(id)}/>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id={"batch-amount" + key}
                                    label="Menge"
                                    fullWidth
                                    defaultValue={batches[id].amount}
                                    type="number"
                                    variant={'outlined'}
                                    name={'amount'}
                                    disabled={id !== editBatch || isSet}
                                    onChange={handleChange(id) || parseInt(batches[id].amount) !== parseInt(batches[id].currentAmount)}
                                    InputProps={{
                                        inputProps: {min: 0, step: 1}
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id={"batch-ek" + key}
                                    label="EK"
                                    fullWidth
                                    defaultValue={batches[id].ek}
                                    type="number"
                                    variant={'outlined'}
                                    name={'ek'}
                                    disabled={id !== editBatch}
                                    onChange={handleChange(id)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faEuroSign}/></InputAdornment>,
                                        inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id={"batch-fee" + key}
                                    label="Marge"
                                    fullWidth
                                    defaultValue={batches[id].spread}
                                    type="number"
                                    variant={'outlined'}
                                    name={'spread'}
                                    disabled={id !== editBatch}
                                    onChange={handleChange(id)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faPercentage}/></InputAdornment>,
                                        inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id={"batch-vk" + key}
                                    label="VK"
                                    fullWidth
                                    value={batches[id].vk_net}
                                    type="number"
                                    variant={'outlined'}
                                    name={'vk_net'}
                                    disabled={id !== editBatch}
                                    onChange={handleChange(id)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faEuroSign}/></InputAdornment>,
                                        inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                {id === editBatch && <Tooltip title="Speichern">
                                        <span>
                                        <IconButton
                                            aria-label="Speichern"
                                            color={"primary"}
                                            variant={"outlined"}
                                            onClick={saveBatch(id)}>
                                            <FontAwesomeIcon icon={faSave}/>
                                        </IconButton>
                                        </span>
                                </Tooltip>}
                                {id !== editBatch && <Tooltip title="Bearbeiten">
                                        <span>
                                        <IconButton
                                            color={"primary"}
                                            variant={"outlined"}
                                            disabled={parseInt(batches[id].currentAmount) === 0}
                                            onClick={handleEditBatch(id)}>
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </IconButton>
                                        </span>
                                </Tooltip>}
                                <Tooltip title="Löschen">
                                        <span>
                                        <IconButton
                                            color={"primary"}
                                            variant={"outlined"}
                                            disabled={parseInt(batches[id].amount) !== parseInt(batches[id].currentAmount) || batches[id].active}
                                            onClick={handleDeleteBatch(id)}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </IconButton>
                                        </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell/>
                            <TableCell>
                                <DatePicker label={'Lieferdatum'}
                                            fullWidth
                                            inputVariant={'outlined'}
                                            format="dd.MM.yyyy"
                                            value={newBatch.date}
                                            onChange={setBatchAttributeDate}/>
                            </TableCell>
                            <TableCell>
                                {isSet ?
                                    <FormControl fullWidth variant={"outlined"}>
                                        <InputLabel id="new-batch-amount">Menge</InputLabel>
                                        <Select
                                            id={'new-batch-amount-id'}
                                            label={'new-batch-amount'}
                                            name={'amount'}
                                            MenuProps={{
                                                disableScrollLock: true
                                            }}
                                            value={newBatch.amount}
                                            onChange={setBatchAttribute}
                                        >
                                            {Array.from({length: maxSetSize + 1}, (_, i) => i).map((v, k) => (
                                                <MenuItem key={k} value={v}>{v}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> :
                                    <TextField
                                        id="new-batch-amount"
                                        label="Menge"
                                        value={newBatch.amount}
                                        type="number"
                                        variant={'outlined'}
                                        fullWidth
                                        name={'amount'}
                                        onChange={setBatchAttribute}
                                        InputProps={{
                                            inputProps: {min: 0, step: 1}
                                        }}
                                    />
                                }
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="new-batch-ek"
                                    label="EK"
                                    value={newBatch.ek}
                                    fullWidth
                                    variant={'outlined'}
                                    type="number"
                                    name={'ek'}
                                    onChange={setBatchAttribute}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faEuroSign}/></InputAdornment>,
                                        inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="new-batch-fee"
                                    label="Marge"
                                    value={newBatch.spread}
                                    fullWidth
                                    variant={'outlined'}
                                    type="number"
                                    name={'spread'}
                                    onChange={setBatchAttribute}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faPercentage}/></InputAdornment>,
                                        inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="new-batch-vk"
                                    label="VK"
                                    value={newBatch.vk_net}
                                    fullWidth
                                    variant={'outlined'}
                                    type="number"
                                    name={'vk_net'}
                                    onChange={setBatchAttribute}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faEuroSign}/></InputAdornment>,
                                        inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Tooltip title="Hinzufügen">
                                        <span>
                                        <IconButton
                                            color={"primary"}
                                            variant={"outlined"}
                                            disabled={newBatch.date === '' || newBatch.amount === '' || newBatch.ek === '' || newBatch.spread === ''}
                                            onClick={addBatch}>
                                            <FontAwesomeIcon icon={faPlus}/>

                                        </IconButton>
                                        </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    </Modal>
}));

export default BatchModal;
