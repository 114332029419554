import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    textFieldStyle:{
        marginBottom: '1em'
    },
    textFieldInlineDateStyle:{
        marginBottom: '1em',
        marginRight: '0.5em',
        width: '20%',
    },
    textFieldInlineNumberStyle:{
        marginBottom: '1em',
        marginRight: '0.5em',
        width: '16%',
        textAlign: 'right',
    },
    formControl:{
        width: '100%',
        marginBottom: '1em'
    },
    dropZoneStyle:{
        width: 100,
        height: 150,
        textAlign: 'center',
        margin: '0.5rem',
        padding: '0.5em',
        fontSize: '0.75em',
        transition: 'all ease 500ms',
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        border: 'solid 1px #333',
        borderRadius: '0',
    },
    dropZoneStyleOnDrag: {
        border: 'dashed 1px black',
        borderRadius: '0.5em',
    },
    otherFont: {
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    infoCat:{
        fontWeight: 'bold',
        width: '100%',
        marginTop: '0.5em'
    }
}));