import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import Button from "@material-ui/core/Button";
import {database} from "common/firebase";
import {makeCoupon} from "common/statelessFunctions";
import {AppBar, DialogActions, DialogContent, InputAdornment, Paper} from "@material-ui/core";
import {Modal} from "../../../components";
import TextField from "@material-ui/core/TextField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEuroSign, faPercentage, faPlus, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useStyles} from "./styling";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import GetEmail from "./getEmail";

const CouponList = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [couponList, setCouponList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [code, setCode] = useState('');
    const [amount, setAmount] = useState(0);
    const [type, setType] = useState('abs');
    const [description, setDescription] = useState('');
    const [condition, setCondition] = useState(0);
    const [conditionDescription, setConditionDescription] = useState('');

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    useEffect(() => {
        const ref = database.ref('coupons');
        const listener1 = ref.on('child_added', snap => {
            const coupon = snap.val();
            if(coupon.code !== snap.key) coupon.code = snap.key;
            setCouponList( list => [...list, coupon]);
        });
        const listener2 = ref.on('child_removed', snap => {
            setCouponList( list => {
                const index = list.findIndex((c) => c.code === snap.key);
                list.splice(index, 1)
                return [...list]
            });
        })
        return () => {
            ref.off('child_added', listener1);
            ref.off('child_removed', listener2);
        }
    }, []);

    const creatCoupon = () => {
        if (couponList.map(c => c.code).indexOf(code.toUpperCase()) === -1) {
            const coupon = {};
            coupon.code = code.toUpperCase();
            coupon.used = false;
            coupon[type] = amount;
            coupon.description = description;
            coupon.timestamp = new Date().getTime();
            coupon.condition = condition;
            coupon.conditionDescription = conditionDescription;
            database.ref(`coupons/${code.toUpperCase()}`).set(coupon).then( () =>{
                setCode('');
                setAmount(0);
                setType('abs');
                setDescription('');
                setCondition(0);
                setConditionDescription('');
            });
            setIsModalOpen(false);
        } else {
            store.snackBarHandle.addMessages('Code Bereist verwendet');
        }
    };

    const createCode = () => {
        const couponCode = makeCoupon(8);
        if (couponList.map(c => c.code).indexOf(couponCode) === -1) {
            setCode(couponCode)
        } else {
            createCode();
        }
    };
    const deleteCode = coupon => e => {
        database.ref('coupons').child(coupon.code).set(null).then();
    };

    const changeType = () => {
        if (type === 'abs') {
            setType('rel');
        } else {
            setType('abs');
        }
    };

    return <div className={classes.otherFont}>
        <AppBar position="static" className={classes.appBar}>Gutscheine</AppBar>
        <TableContainer component={Paper} className={classes.otherFont}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table" className={classes.otherFont}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} align="right"><Button variant={'contained'} color={'primary'} onClick={toggleModal}>Neuen Gutschein
                            anlegen</Button></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Gutschein Code</TableCell>
                        <TableCell align="right">Wert</TableCell>
                        <TableCell align="right">Beschreibung</TableCell>
                        <TableCell align="right">Bedingung</TableCell>
                        <TableCell align="right">Für</TableCell>
                        <TableCell align="right">Verwendet</TableCell>
                        <TableCell align="right">Optionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {couponList.map((c, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {c.code}
                            </TableCell>
                            <TableCell align="right">{c.abs || c.rel} {!!c.abs ? <FontAwesomeIcon icon={faEuroSign}/> :
                                <FontAwesomeIcon icon={faPercentage}/>}</TableCell>
                            <TableCell align="right">{c.description}</TableCell>
                            <TableCell align="right">{c.conditionDescription}</TableCell>
                            <TableCell align="right">{<GetEmail userId={c.for}/> || 'ALL'}</TableCell>
                            <TableCell align="right">{c.used ? 'Ja' : 'Nein'}</TableCell>
                            <TableCell align="right"><DeleteCheckIcon handleDelete={deleteCode(c)} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <div style={{height: 24}}/>

        <Modal open={isModalOpen} handleClose={toggleModal} title={'Neuen Gutschein anlegen'} className={classes.otherFont}>
            <DialogContent className={classes.otherFont}>
                <TextField
                    className={classes.textField}
                    id="coupon-code"
                    label="Gutschein Code"
                    variant={'outlined'}
                    value={code}
                    type={"text"}
                    fullWidth
                    onChange={(e) => setCode(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" onClick={createCode} style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className={classes.textField}
                    id="coupon-amount"
                    label="Wert"
                    variant={'outlined'}
                    value={amount}
                    type={'number'}
                    fullWidth
                    onChange={(e) => setAmount(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" onClick={changeType} style={{cursor: 'pointer'}}>
                                {type === 'abs' && <FontAwesomeIcon icon={faEuroSign}/>}
                                {type === 'rel' && <FontAwesomeIcon icon={faPercentage}/>}
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className={classes.textField}
                    id="coupon-description"
                    label="Beschreibung"
                    variant={'outlined'}
                    value={description}
                    multiline
                    fullWidth
                    onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                    className={classes.textField}
                    id="coupon-condition"
                    label="Mindest Einkaufswert"
                    variant={'outlined'}
                    value={condition}
                    type="number"
                    multiline
                    fullWidth
                    onChange={(e) => setCondition(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {type === 'abs' && <FontAwesomeIcon icon={faEuroSign}/>}
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className={classes.textField}
                    id="coupon-condition-description"
                    label="Beschreibung für Einkaufswert"
                    variant={'outlined'}
                    value={conditionDescription}
                    multiline
                    fullWidth
                    onChange={(e) => setConditionDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={creatCoupon} variant={'contained'} color="primary" className={classes.otherFont}>
                    Gutschein anlegen
                </Button>
            </DialogActions>
        </Modal>
    </div>
}));

export default CouponList;

const DeleteCheckIcon = ({handleDelete}) => {
    const [deleteCheck, setDeleteCheck] = useState(false);

    const callDelete = () => {
        setDeleteCheck(false);
        handleDelete();
    };

    return deleteCheck ? <span>
            <IconButton onClick={callDelete}><FontAwesomeIcon icon={faCheck}/></IconButton>
            <IconButton onClick={() => setDeleteCheck(false)}><FontAwesomeIcon icon={faTimes}/></IconButton>
        </span> :
        <IconButton onClick={() => setDeleteCheck(true)}><FontAwesomeIcon icon={faTrash}/></IconButton>
};
