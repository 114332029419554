import {makeStyles} from "@material-ui/core/styles";

const drawerWidth = 310;

export const useStyles = makeStyles(theme => ({
        otherFont: {
            '& *': {
                fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
            }
        },
        rootContainer: {
            minHeight: '100vh',
            paddingBottom: ' 4em',
            paddingLeft: theme.spacing(11) + 1,
            '@media print':{
                padding:0,
            }
        },
        blocksatz: {
            textAlign: 'justify',
        },
        dropzone: {
            width: '100%',
            height: 150,
            border: 'solid 2px dashed',
            borderRadius: '0.25em',
        },
        dragOverZone: {
            backgroundColor: 'hsla(0,0%,10%, 0.3)',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(9) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        drawerPaper: {
            width: drawerWidth,
        },
        active: {
            '& *': {
                color: theme.palette.secondary.main,
            }
        },
        noPrint: {
            '@media print': {
                display: 'none'
            }
        }
    }))
;
