import React, {useEffect, useState} from "react";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {makeStyles} from "@material-ui/core/styles";
import {DateTime} from "luxon";

const useStyles = makeStyles( theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}));

const AgeCheckDialog = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const ageCheck = sessionStorage.getItem("ageCheck");
        if(ageCheck){ setIsOpen(false)}
    }, []);

    const handleClose = () => {
        sessionStorage.setItem("ageCheck", DateTime.local().toMillis().toString(10));
        setIsOpen(false);
    };

    return <Dialog
        className={classes.otherFont}
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}
        disableScrollLock={true}
    >
        <DialogTitle id="alert-dialog-title">Alters Bestätigung</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Um auf dieser Webseite einkaufen zu dürfen müssen sie mindesens 18 Jahre alt sein.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Link component={Button} href="https://www.oesterreich.gv.at/themen/jugendliche/jugendrechte/3/Seite.1740250.html" rel="noopener" color={'primary'} autoFocus>
                ICH BIN NOCH NICHT 18
            </Link>
            <Button onClick={handleClose} variant={'contained'} color={'primary'}>
                ICH BIN 18+
            </Button>
        </DialogActions>
    </Dialog>
};

export default AgeCheckDialog;

