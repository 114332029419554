import React, {useEffect, useState} from "react";
import {Container} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {database} from "../../common/firebase";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";
import {DateTime} from "luxon";
import {Helmet} from "react-helmet";
import {faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";
import Avatar from "@material-ui/core/Avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DbImage from "../../components/dbImage";
import {cleanPriceString} from "../../common/statelessFunctions";

const useStyles = makeStyles(theme => ({
    header: {
        fontSize: '1.5em',
    },
    subHeader: {
        fontSize: '0.85em',
    },
    btnWrapper: {
        display: 'inline-block',
        marginTop: 8,
        marginBottom: 4,
        marginLeft: '0.5em',
    },
    gridWrapper: {
        padding: '1em',
        cursor: 'pointer',
        color: '#333',
        textDecoration: 'none',
        display: 'block',
        '&:hover':{
            backgroundColor: 'hsla(0, 100%, 0%, 0.2)',
            boxShadow: '0px 0px 3px 2px hsla(0, 100%, 0%, 0.2)'
        }
    }
}))

const WhiskyEvents = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [eventsList, setEventsList] = useState([]);

    useEffect(() => {
        database.ref('events_online').on('value', snap => {
            if (snap.exists()) {
                const list = [];
                snap.forEach((s) => {
                    const event = s.val();
                    event.key = s.key;
                    list.push(event);
                })
                setEventsList(list);
            }
        })
    }, [])

    const addToCart = e => _ => {
        _.preventDefault();
        store.cartStore.writeEventInCart(e);
    }

    const getThumbnailTag = (whiskyEvent) =>{
        if(whiskyEvent.thumbnail) return <Avatar variant={"rounded"} style={{width: '3rem', height: '3rem', marginRight: '0.5rem'}}><DbImage style={{width: '100%', height: '100%'}} src={['images', whiskyEvent.number, whiskyEvent.thumbnail]} alt={"Vorschau Bild"} /></Avatar>
        if(whiskyEvent.images) return <Avatar variant={"rounded"} src={whiskyEvent.image} alt={"Vorschau Bild"} style={{width: '3rem', height: '3rem', marginRight: '0.5rem'}} />
        return <Avatar variant={"rounded"} style={{width: '3rem', height: '3rem', marginRight: '0.5rem'}}><FontAwesomeIcon icon={faGlassWhiskey} /> </Avatar>
    }

    const correctNumber = input => {
        if(!isNaN(input)) return parseFloat(input);
        return 0;
    }

    return <Container maxWidth={'md'} style={{minHeight: 'calc(100vh - 449px)', textAlign: 'left'}}>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - Whiskyveranstaltungen, Onlinetastings, Degustationen, Masterclasses</title>
        </Helmet>
        {eventsList.map((e, k) => (
            <React.Fragment key={k}>
                <Link className={classes.gridWrapper} to={`/event/${e.year}/${e.key}`}>
                    <Grid container spacing={2}>
                        <Grid item md={8} xs={12}>
                            <div style={{display: 'inline-block'}}>
                                {getThumbnailTag(e)}
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <div className={classes.header}>{e.name}</div>
                                <div className={classes.subHeader}>{e.subTitle}</div>
                            </div>
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <span className={classes.header}>{DateTime.fromFormat(e.eventDate,'dd.LL.yyyy HH:mm').toFormat('dd.LL.yyyy')}</span>
                        </Grid>
                        <Grid item md={2} sx={12}>
                            <div className={classes.header}>€ {
                                e.hasShipping ?
                                cleanPriceString(correctNumber(e.vk_net) + correctNumber(e.shippingCost)):
                                cleanPriceString(correctNumber(e.vk_net))
                            }</div>
                            <div className={classes.subHeader}>Preis ink. Mwst. {e.hasShipping ? 'und Versand' : ''}</div>
                        </Grid>
                        <Grid item md={9} xs={12}/>
                        <Grid item md={3} sx={12}>
                            <div className={classes.btnWrapper}>
                                <Button variant={'contained'} color={'primary'} onClick={addToCart(e)}>In den Warenkorb</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Link>
                { k !== eventsList.length-1 && <hr/> }

            </React.Fragment>
        ))}
    </Container>
}));

export default WhiskyEvents;
