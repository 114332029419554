import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {makeStyles} from '@material-ui/core'
import {Link} from "react-router-dom";
import monthlyWhisky from "../../common/images/slider/WhiskyDesMonats3_21.jpg";
import monthlyWhisky2 from "../../common/images/slider/Advertisement_Bud Spencer.jpg";
import monthlyWhisky3 from "../../common/images/slider/WhiskyKostproben.jpg";
import monthlyWhisky4 from "../../common/images/slider/Flachmann_Aktion.jpg";

const useStyles = makeStyles(theme => ({
    advertisementStyle: {
        width: '100%',
        objectFit: 'contained',
    },
}))

function BannerSlider(props) {
    const items = props.items ?? [
        {
            link: '/whisky/article/WW000069/',
            img: monthlyWhisky,
            alt: 'Whisky W000069'
        },
        {
            link: '/whisky/article/WW000150/',
            img: monthlyWhisky2,
            alt: 'Whisky WW000150'
        },
        {
            link: '/whiskyabo/',
            img: monthlyWhisky3,
            alt: 'Whisky Kostproben'
        },
        {
            link: '/whisky/search?searchString=starward&code=STARWARDFLACHMANN',
            img: monthlyWhisky4,
            alt: 'Whisky Aktion'
        },
    ]

    return (
        <Carousel animation={'slide'} timeout={100} interval={5000} indicators={items.length > 1} navButtonsAlwaysInvisible={items.length <= 1}>
            {
                items.map((item, i) => <Item key={i} item={item}/>)
            }
        </Carousel>
    )
}

export default BannerSlider;

function Item({item}) {
    const classes = useStyles();
    return (
        <Link to={item.link}>
            <img className={classes.advertisementStyle} src={item.img} alt={item.alt}/>
        </Link>
    )
}
