import React, {useEffect, useState} from "react";
import {Container} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {database} from "../../common/firebase";
import {makeStyles} from "@material-ui/styles";
import { useParams} from 'react-router-dom';
import {faEdit, faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import whisky from "common/images/CantinettaTasting.png"
import Button from "@material-ui/core/Button";
import ReactMarkdown from "react-markdown";
import {DateTime} from "luxon";
import DbImage from "../../components/dbImage";
import {cleanPriceString} from "../../common/statelessFunctions";

const useStyles = makeStyles(theme => ({
    header: {
        fontSize: '1.5em',
    },
    subHeader: {
        fontSize: '0.85em',
    },
    btnWrapper: {
        display: 'inline-block',
        marginTop: 8,
        marginBottom: 4,
        marginLeft: '0.5em',
    },
    gridWrapper: {
        padding: '1em',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'hsla(0, 100%, 0%, 0.2)',
            boxShadow: '0px 0px 3px 2px hsla(0, 100%, 0%, 0.2)'
        }
    },
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    blocksatz: {
        textAlign: 'justify',
    },
    sideBar: {
        paddingTop: '1em',
    }
}))

const WhiskyEventDetail = inject('store')(observer(({store}) => {
    const {year, id} = useParams()
    const classes = useStyles();
    const [event, setEvent] = useState(null);
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [reservedCount, setReservedCount] = useState(0);

    useEffect(() => {
        database.ref('events').child(year).child(id).on('value', snap => {
            if (snap.exists()) {
                const event = snap.val();
                event.key = snap.key;
                setEvent(event);
                database.ref('events_attendees').child(event.key).child('count').on('value', snap => {
                    if (snap.exists()) {
                        setAttendeesCount(parseInt(snap.val()));
                    }
                });
                database.ref(`articleCart/${event.number}`).on('value', snap => {
                    const data = snap.val();
                    let sum = 0;
                    if (data) {
                        for (const key of Object.keys(data)) {
                            sum += data[key];
                        }
                        setReservedCount(sum);
                    }
                });
            }
        })
    }, [year, id])

    const addToCart = _ => {
        _.preventDefault();
        store.cartStore.writeEventInCart(event);
    }

    const getImageTag = () =>{
        if(event.overviewImages && event.overviewImages.length > 0) return event.overviewImages.map((img,i) => (<DbImage key={i} style={{width: '100%'}} src={['images', event.number, img]} alt={'Info Bild'} />));
        if(event.images && event.images.length > 0) return  event.images.map((img,i) => (<img key={i} src={img} alt={'Info Bild'} style={{width: '100%'}}/>));
        return <img src={whisky} alt={'Info Bild'} style={{width: '100%'}}/>
    }

    const correctNumber = input => {
        if(!isNaN(input)) return parseFloat(input);
        return 0;
    }

    return <Container maxWidth={'md'} style={{minHeight: 'calc(100vh - 449px)', textAlign: 'left'}}>
        {!event ?
            <FontAwesomeIcon icon={faGlassWhiskey} spin/> :
            <>
                <Grid container spacing={2}>
                    <Grid item md={10} xs={12}>
                        <div className={classes.header}>{event.name}</div>
                        <div className={classes.subHeader}>{event.subTitle}</div>
                    </Grid>
                    <Grid item md={2} sx={12}>
                        <div className={classes.header}>€ {
                            event.hasShipping ?
                                cleanPriceString(correctNumber(event.vk_net) + correctNumber(event.shippingCost) ):
                                cleanPriceString(correctNumber(event.vk_net))
                        }</div>
                        <div className={classes.subHeader}>Preis ink. Mwst. {event.hasShipping ? 'und Versand' : ''}</div>
                    </Grid>
                </Grid>
                <hr/>
                <Grid container spacing={2}>
                    <Grid item md={9} xs={12}>
                        {event.description && <ReactMarkdown className={classes.otherFont + ' ' + classes.blocksatz} source={event.description}/> }
                        {getImageTag()}
                    </Grid>
                    <Grid item md={3} sx={12}>
                        <Grid container spacing={2} className={classes.sideBar}>
                            <Grid item xs={12} className={classes.subHeader}>Event Datum:</Grid>
                            <Grid item xs={12} className={classes.header}>{DateTime.fromFormat(event.eventDate, 'dd.LL.yyyy HH:mm')
                            .toFormat('dd.LL.yyyy HH:mm')}</Grid>
                            <Grid item xs={12} className={classes.subHeader}>Buchbar bis:</Grid>
                            <Grid item xs={12} className={classes.header}>{DateTime.fromFormat(event.till, 'dd.LL.yyyy HH:mm').toFormat('dd.LL.yyyy HH:mm')}</Grid>
                            {event.capacity > 0 && <>
                                <Grid item xs={12} className={classes.subHeader}>Freie Plätze:</Grid>
                                <Grid item xs={12} className={classes.header}>{event.capacity - attendeesCount - reservedCount} / {event.capacity}</Grid>
                            </>}
                            {event.location && <Grid item xs={12} className={classes.subHeader}>Veranstaltungsort</Grid>}
                            {event.location && <Grid item xs={12} className={classes.subHeader}>
                                {event.location_link ? <a href={event.location_link} target={'blank'}>{event.location}</a> : <span>{event.location}</span>}
                            </Grid>}
                            {event.capacity > 0 ? (event.capacity - attendeesCount - reservedCount > 0 ?
                                    <Grid item xs={12}><Button variant={'contained'} color={'primary'} onClick={addToCart}>In den Warenkorb</Button></Grid> :
                                    <Grid item xs={12}><Button variant={'contained'} color={'primary'} disabled>Ausverkauft</Button></Grid>
                            ):
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>Buchbar über:</Grid>
                                        { event.bookingMail && <Grid item xs={12}><a href={'mailto:'+event.bookingMail}>{event.bookingMail}</a></Grid>}
                                        { event.bookingTel && <Grid item xs={12}><a href={'tel:'+event.bookingTel}>{event.bookingTel}</a></Grid>}
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        }
    </Container>
}));

export default WhiskyEventDetail;
