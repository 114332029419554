import React, {useCallback, useEffect, useRef, useState} from "react";
import {Container, IconButton, InputAdornment, TextField} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {database} from "common/firebase";
import {makeStyles} from "@material-ui/styles";
import {Link, useHistory, useParams} from 'react-router-dom';
import {faBan, faEdit, faEuroSign, faGlassWhiskey, faTimes, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ReactMarkdown from "react-markdown";
import {DateTime} from "luxon";
import Drawer from "@material-ui/core/Drawer";
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import {DescriptionDialog} from "./DescriptionDialog";
import {DateTimePicker} from "@material-ui/pickers";
import {ImagePickerModal} from "../../../components/ImagePickerModal";
import DbImage from "../../../components/dbImage";
import {cleanPriceString} from "../../../common/statelessFunctions";
import {DeleteDialog} from "./DeleteDialog";

const drawerWidth = 380;
const useStyles = makeStyles(theme => ({
    header: {
        fontSize: '1.5em',
    },
    subHeader: {
        fontSize: '0.85em',
    },
    clickableElement: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    btnWrapper: {
        display: 'inline-block',
        marginTop: 8,
        marginBottom: 4,
        marginLeft: '0.5em',
    },
    gridWrapper: {
        padding: '1em',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'hsla(0, 100%, 0%, 0.2)',
            boxShadow: '0px 0px 3px 2px hsla(0, 100%, 0%, 0.2)'
        }
    },
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    blocksatz: {
        textAlign: 'justify',
    },
    sideBar: {
        paddingTop: '1em',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: 230,
        bottom: 220,
        height: 'auto',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
        padding: '0.5em 0.5em 0.75em',
    },
    firstLine: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '0.75em',
    },
    closeIcon: {
        position: "absolute",
        top: '0.25em',
        right: '0.25em',
    },
    closingWrapper:{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0
    },
    headerCloseIcon: {
        fontSize: '1.25rem',
        alignSelf: 'center'
    },
    textFieldStyle: {
        marginBottom: '1em',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    btn: {
        fontSize: '1.1rem',
        marginBottom: '1em',
    },
    switchStyle: {
        color: '#333',
        marginBottom: '1em',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',

        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        },

        "& .Mui-focused": {
            color: "black",
            fontWeight: "bold"
        }
    },
    clickableImg: {
        width: '100%',
        cursor: 'pointer',
        marginBottom: '1em',
        '&:hover': {
            boxShadow: '0 0 2px 2px #333',
        }
    },
    dateTimePicker: {
        marginBottom: '1em'
    },
    clickable: {
        cursor: 'pointer',
    },
    btnRow: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    btnLast: {
        fontSize: '1.1rem',
        marginLeft: '0.5em'
    },
    btnLastDelete:{
        fontSize: '1.1rem',
        marginLeft: '0.5em',
        backgroundColor: '#900',
        color: '#eee'
    },
    imgMissingWrapper: {
        width: '100%',
        height: 250,
        border: 'solid 1px #333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '3em',
        color: '#ccc',
        marginTop: '1em',
        '&:hover': {
            boxShadow: '0 0 2px 2px #333',
            color: '#333',

        }
    },
    editIcon: {
        color: '#ccc',
        fontSize: '1.5em',
        '&:hover': {
            color: '#333',
        }
    }

}))

const AdminWhiskyEventDetail = inject('store')(observer(({store}) => {
    const {year, id} = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [event, setEvent] = useState(null);
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [reservedCount, setReservedCount] = useState(0);
    const [open, setOpen] = useState(true);
    const [desOpen, setDesOpen] = useState(false);
    const [imgOpen, setImgOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const [eventDate, setEventDate] = useState(null);
    const [till, setTill] = useState(null);

    const toRef = useRef(null);
    const nameRef = useRef(null);
    const subTitleRef = useRef(null);
    const priceRef = useRef(null);
    const shippingRef = useRef(null);
    const tillRef = useRef(null);
    const eventDateRef = useRef(null);
    const capacityRef = useRef(null);
    const locationRef = useRef(null);
    const mailRef = useRef(null);
    const telRef = useRef(null);

    const toEditClick = useCallback(to => e => {
        if (open) {
            if (to === 'name') {
                nameRef.current.focus();
                //nameRef.current.input.selectionStart = nameRef.current.input.selectionEnd = event.name.length;
            } else if (to === 'subtitle') {
                subTitleRef.current.focus();
                //subTitleRef.current.input.selectionStart = subTitleRef.current.input.selectionEnd = event.subTitle.length;
            } else if (to === 'price') {
                priceRef.current.focus();
                //priceRef.current.input.selectionStart = priceRef.current.input.selectionEnd = event.vk_net;
            } else if (to === 'shipping') {
                shippingRef.current.focus();
            } else if (to === 'till') {
                tillRef.current.focus();
            } else if (to === 'eventDate') {
                eventDateRef.current.focus();
            } else if (to === 'capacity') {
                capacityRef.current.focus();
            } else if (to === 'location') {
                locationRef.current.focus();
            } else if (to === 'bookingMail') {
                mailRef.current.focus();
            } else if (to === 'bookingTel') {
                telRef.current.focus();
            }
        } else {
            toRef.current = to;
            setOpen(true);
        }
    }, [toRef, nameRef, subTitleRef, priceRef, shippingRef, tillRef, eventDateRef, capacityRef, locationRef, mailRef, telRef, open])

    useEffect(() => {
        database.ref('events').child(year).child(id).on('value', snap => {
            if (snap.exists()) {
                const event = snap.val();
                event.key = snap.key;
                event.images = event.images ?? [];
                setEvent(event);
                setEventDate(DateTime.fromFormat(event.eventDate, 'dd.LL.yyyy HH:mm'));
                setTill(DateTime.fromFormat(event.till, 'dd.LL.yyyy HH:mm'));
                database.ref('events_attendees').child(event.key).child('count').on('value', snap => {
                    if (snap.exists()) {
                        setAttendeesCount(parseInt(snap.val()));
                    }
                });
                database.ref(`articleCart/${event.number}`).on('value', snap => {
                    const data = snap.val();
                    let sum = 0;
                    if (data) {
                        for (const key of Object.keys(data)) {
                            sum += data[key];
                        }
                        setReservedCount(sum);
                    }
                });
            }
        })
    }, [year, id])

    useEffect(() => {
        if (open) { toEditClick(toRef.current)() }
    }, [open, toEditClick]);

    const handleChange = (e) => {
        const {value, name} = e.target;
        event[name] = value;
        setEvent({...event});
    }

    const handleChangeText = name => value => {
        event[name] = value;
        setEvent({...event});
    }

    const handleToggle = (e) => {
        const {name, checked} = e.target;
        event[name] = checked;
        setEvent({...event});
    }

    const handleChangeCapacity = number => e => {
        if (number > 0) {
            event.capacity = 0;
        } else {
            event.capacity = 1;
        }
        setEvent({...event});
    }

    const handleDateChange = name => value => {
        event[name] = value.toFormat('dd.LL.yyyy HH:mm');
        if (name === 'till') {
            setTill(value);
        } else if (name === 'eventDate') {
            setEventDate(value);
        }
        setEvent({...event});
    }

    const saveEvent = () => {
        database.ref('events').child(event.year).child(event.key).update(event);
        const smallEvent = {
            eventDate: event.eventDate,
            thumbnail: event?.thumbnails?.[0] ?? null,
            image: event?.images?.[0] ?? null,
            name: event.name,
            number: event.number,
            subTitle: event.subTitle,
            till: event.till,
            vk_net: event.vk_net,
            hasShipping: !!event.hasShipping,
            shippingCost: event.shippingCost ?? 0,
            year: event.year,
        }
        database.ref('events_online').child(event.key).update(smallEvent);
        history.push('/admin/')
    }

    const handleOpenImages = () => {
        setImgOpen(true);
    }

    const handleOverviewImagesClick = (list) => {
        event.overviewImages = list;
        setEvent({...event});
    }

    const handleThumbnailImagesClick = (list) => {
        if (!event.thumbnails) {
            event.thumbnails = list;
            return setEvent({...event});
        }
        const changeList = list.filter(thumbnail => !event.thumbnails.includes(thumbnail));
        event.thumbnails = changeList;
        setEvent({...event});
    }

    const deleteOldImg = url => {
        const index = event.images.indexOf(url);
        if (index > -1) event.images.splice(index, 1);
        setEvent({...event});
    }

    const getImageTag = () => {
        if (event.overviewImages && event.overviewImages.length > 0) {
            return event.overviewImages.map((img, i) => (
                <DbImage key={i} className={classes.clickableImg} src={['images', event.number, img]} alt={'Info Bild'} onClick={() => setImgOpen(true)}/>));
        }
        if (event.images && event.images.length > 0) {
            return event.images.map((img, i) => (
                <img key={i} className={classes.clickableImg} src={img} alt={'Info Bild'} onClick={() => setImgOpen(true)}/>));
        }
        return <div className={classes.imgMissingWrapper} onClick={handleOpenImages}>
            <FontAwesomeIcon icon={faEdit}/>
        </div>
    }

    const correctNumber = input => {
        if (!isNaN(input)) return parseFloat(input);
        return 0;
    }

    const triggerDelete = () => {
        setDeleteModalOpen(true);
    }

    const handleDelete = () => {
        database.ref('events').child(event.year).child(event.key).set(null);
        database.ref('events_online').child(event.key).set(null);
        history.push('/admin/')
    }

    return <Container maxWidth={'md'} style={{minHeight: 'calc(100vh - 449px)', textAlign: 'left'}} className={classes.otherFont}>
        {!event ?
            <FontAwesomeIcon icon={faGlassWhiskey} spin/> :
            <>
                <Grid container spacing={2}>
                    <Grid item md={10} xs={12}>
                        <div className={[classes.header, classes.clickableElement].join(' ')} onClick={toEditClick('name')}>
                            {event.name !== "" ? event.name : <span className={classes.editIcon}>Event Name <FontAwesomeIcon icon={faEdit}/></span>}
                        </div>
                        <div className={[classes.subHeader, classes.clickableElement].join(' ')} onClick={toEditClick('subtitle')}>
                            {event.subTitle !== "" ? event.subTitle : <span className={classes.editIcon}>Sub Header <FontAwesomeIcon icon={faEdit}/></span>}
                        </div>
                    </Grid>
                    <Grid item md={2} sx={12}>
                        <div className={[classes.header, classes.clickableElement].join(' ')} onClick={toEditClick('price')}>
                            € {
                            event.hasShipping ?
                                cleanPriceString(correctNumber(event.vk_net) + correctNumber(event.shippingCost)) :
                                cleanPriceString(correctNumber(event.vk_net))
                        }
                        </div>
                        <div className={[classes.subHeader, classes.clickableElement].join(' ')} onClick={toEditClick('shipping')}>Preis ink.
                            Mwst. {event.hasShipping ? 'und Versand' : ''}</div>
                    </Grid>
                </Grid>
                <hr/>
                <Grid container spacing={2}>
                    <Grid item md={9} xs={12}>
                        <div onClick={() => setDesOpen(true)}>
                            {event.description !== "" ?
                                <ReactMarkdown className={[classes.otherFont, classes.blocksatz, classes.clickableElement].join(' ')}
                                               source={event.description}/> :
                                <span className={classes.editIcon}>Event Beschreibung <FontAwesomeIcon icon={faEdit}/></span>
                            }

                        </div>
                        {getImageTag()}
                    </Grid>
                    <Grid item md={3} sx={12}>
                        <Grid container spacing={2} className={classes.sideBar}>
                            <Grid item xs={12} className={classes.subHeader}>Event Datum:</Grid>
                            <Grid item xs={12} className={[classes.header, classes.clickableElement].join(' ')}
                                  onClick={toEditClick('eventDate')}>{DateTime.fromFormat(event.eventDate, 'dd.LL.yyyy HH:mm')
                            .toFormat('dd.LL.yyyy')}</Grid>
                            <Grid item xs={12} className={classes.subHeader}>Buchbar bis:</Grid>
                            <Grid item xs={12} className={[classes.header, classes.clickableElement].join(' ')}
                                  onClick={toEditClick('till')}>{DateTime.fromFormat(event.till, 'dd.LL.yyyy HH:mm').toFormat('dd.LL.yyyy')}</Grid>
                            {event.capacity > 0 && <>
                                <Grid item xs={12} className={classes.subHeader}>Freie Plätze:</Grid>
                                <Grid item xs={12} className={[classes.header, classes.clickableElement].join(' ')}
                                      onClick={toEditClick('capacity')}>{event.capacity - attendeesCount - reservedCount} / {event.capacity}</Grid>
                            </>}
                            {event.location && <Grid item xs={12} className={[classes.subHeader, classes.clickableElement].join(' ')}
                                                     onClick={toEditClick('location')}>Veranstaltungsort:</Grid>}
                            {event.location &&
                            <Grid item xs={12} className={classes.subHeader}>
                                {event.location_link ? <a href={event.location_link} target={'_blank'} rel="noopener noreferrer">{event.location}</a> :
                                    <span>{event.location}</span>}
                            </Grid>}
                            {event.capacity > 0 ?
                                <Grid item xs={12}><Button fullWidth variant={'contained'} color={'primary'}>In den Warenkorb</Button></Grid> :
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className={[classes.subHeader, classes.clickableElement].join(' ')}
                                              onClick={toEditClick('bookingMail')}>Buchbar über:</Grid>
                                        {event.bookingMail && <Grid item xs={12} className={classes.clickableElement}><a
                                            href={'mailto:' + event.bookingMail}>{event.bookingMail}</a></Grid>}
                                        {event.bookingTel && <Grid item xs={12} className={classes.clickableElement}><a
                                            href={'tel:' + event.bookingTel}>{event.bookingTel}</a></Grid>}
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12}><Button fullWidth variant={'contained'} color={'primary'}
                                                       onClick={() => setOpen(!open)}>{open ? 'Zumachen' : 'Öffnen'}</Button></Grid>
                            <Grid item xs={12}><Button fullWidth component={Link} variant={'contained'} color={'primary'} to={'/admin/whiskyevents/'}>Zurück</Button></Grid>
                            <Grid item xs={12}><Button fullWidth variant={'contained'} color={'primary'} onClick={saveEvent}>Speichern</Button></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="right"
                >

                    <div className={classes.firstLine}>
                        <div className={classes.headerCloseIcon}>Bearbeiten:</div>
                        <IconButton onClick={() => setOpen(false)}><FontAwesomeIcon icon={faTimes}/></IconButton>
                    </div>
                    <form noValidate autoComplete={'off'}>
                        <TextField
                            id="event-number"
                            className={classes.textFieldStyle}
                            label="Eventnummer"
                            variant={'outlined'}
                            defaultValue={event.number}
                            type="text"
                            name={'eventNumber'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            disabled
                        />
                        <TextField
                            id="event-name"
                            inputRef={nameRef}
                            className={classes.textFieldStyle}
                            label="Event Name / Header"
                            variant={'outlined'}
                            value={event.name}
                            type="text"
                            name={'name'}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        <TextField
                            id="event-name"
                            inputRef={subTitleRef}
                            className={classes.textFieldStyle}
                            label="Event Subheader"
                            variant={'outlined'}
                            value={event.subTitle}
                            type="text"
                            name={'subTitle'}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        <TextField
                            id="event-price"
                            inputRef={priceRef}
                            className={classes.textFieldStyle}
                            label="Verkaufs Preis (brutto)"
                            variant={'outlined'}
                            value={event.vk_net}
                            type="number"
                            name={'vk_net'}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faEuroSign}/></InputAdornment>,
                                inputProps: {min: 0, step: 1}
                            }}
                        />
                        <FormControlLabel
                            className={classes.switchStyle}
                            control={
                                <Switch
                                    inputRef={shippingRef}
                                    checked={event.hasShipping}
                                    onChange={handleToggle}
                                    name="hasShipping"
                                    color="secondary"
                                />
                            }
                            label={event.hasShipping ? 'inkl. Versand' : 'exl. Versand'}
                        />
                        <TextField
                            id="event-shipping-price"
                            className={classes.textFieldStyle}
                            label="Versandkosten"
                            variant={'outlined'}
                            helperText={"Wird je nach Einstellung zu dem Gesamt Preis gerechnet."}
                            value={event.shippingCost}
                            type="number"
                            name={'shippingCost'}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faEuroSign}/></InputAdornment>,
                                inputProps: {min: 0, step: 1}
                            }}
                        />
                        <DateTimePicker
                            autoOk
                            ampm={false}
                            disablePast
                            className={classes.dateTimePicker}
                            format="dd.MM.yyyy HH:mm"
                            value={eventDate}
                            inputVariant={'outlined'}
                            onChange={handleDateChange('eventDate')}
                            label="Event Datum"
                            fullWidth
                            inputProps={{
                                ref: eventDateRef
                            }}
                        />
                        <DateTimePicker
                            autoOk
                            ampm={false}
                            disablePast
                            className={classes.dateTimePicker}
                            format="dd.MM.yyyy HH:mm"
                            value={till}
                            inputVariant={'outlined'}
                            onChange={handleDateChange('till')}
                            label="Buchbar bis"
                            fullWidth
                            inputProps={{
                                ref: tillRef
                            }}
                        />
                        <TextField
                            id="event-capacity"
                            inputRef={capacityRef}
                            className={classes.textFieldStyle}
                            label="Besucheranzahl"
                            variant={'outlined'}
                            value={event.capacity}
                            type="number"
                            name={'capacity'}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" className={classes.clickable} onClick={handleChangeCapacity(event.capacity)}>
                                    {event.capacity === 0 ?
                                        <FontAwesomeIcon icon={faUser}/> :
                                        <FontAwesomeIcon icon={faBan}/>
                                    }
                                </InputAdornment>,
                                inputProps: {min: 0, step: 1}
                            }}
                        />
                        <TextField
                            id="event-ort"
                            inputRef={locationRef}
                            className={classes.textFieldStyle}
                            label="Event Veranstaltungsort"
                            variant={'outlined'}
                            value={event.location}
                            type="text"
                            name={'location'}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        <TextField
                            id="event-ort"
                            className={classes.textFieldStyle}
                            label="Event Veranstaltungsort Link"
                            variant={'outlined'}
                            value={event.location_link}
                            type="text"
                            name={'location_link'}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        {event.capacity === 0 &&
                        <>
                            <TextField
                                id="event-mail"
                                inputRef={mailRef}
                                className={classes.textFieldStyle}
                                label="Event Buchungsmailadresse"
                                variant={'outlined'}
                                value={event.bookingMail}
                                type="mail"
                                name={'bookingMail'}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                id="event-tel"
                                inputRef={telRef}
                                className={classes.textFieldStyle}
                                label="Event Buchungstelefonnummer"
                                variant={'outlined'}
                                value={event.bookingTel}
                                type="tel"
                                name={'bookingTel'}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </>
                        }
                    </form>
                    <Button className={classes.btn} variant={'contained'} color={'primary'} onClick={() => setDesOpen(true)}>Beschreibung bearbeiten</Button>
                    <Button className={classes.btn} variant={'contained'} color={'primary'} onClick={() => setImgOpen(true)}>Bilder bearbeiten</Button>

                    <div className={classes.btnRow}>
                        <Button component={Link} className={classes.btnLast} variant={'contained'} color={'primary'} to={'/admin/'}>Zurück</Button>
                        <Button className={classes.btnLast} variant={'contained'} color={'primary'} onClick={saveEvent}>Speichern</Button>
                        <Button className={classes.btnLastDelete} variant={'contained'} onClick={triggerDelete}>Löschen</Button>
                    </div>
                </Drawer>

                <DescriptionDialog open={desOpen} handleClose={() => setDesOpen(false)} description={event.description}
                                   changeDescription={handleChangeText('description')}/>
                <ImagePickerModal
                    open={imgOpen}
                    handleClose={() => setImgOpen(false)}
                    subPath={event.number}
                    oldImages={event.images}
                    deleteOldImg={deleteOldImg}
                    selectedTypeOne={event.overviewImages ?? []} selectTypeOne={"Detail Bilder"} selectTypeOneCallback={handleOverviewImagesClick}
                    selectedTypeTwo={event.thumbnails ?? []} selectTypeTwo={"Thumbnail"} selectTypeTwoCallback={handleThumbnailImagesClick}
                />
                <DeleteDialog open={isDeleteModalOpen} handleClose={() => setDeleteModalOpen(false)} handleDelete={handleDelete} />
            </>
        }
    </Container>
}));

export default AdminWhiskyEventDetail;
