import React from "react";
import Container from "@material-ui/core/Container";
import konfigurator from 'common/res/Konfigurator_Icon.jpg'
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";


const Configurator = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return <Container maxWidth={'md'} >
        <img style={matches ? {width: '100%', padding:'12px 0 0 0'}: {width: '100%', padding:'12px 24px 0 24px'}} src={konfigurator} alt={'Configurator bild'}/>
    </Container>
};

export default Configurator;