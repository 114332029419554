import React, {useEffect, useState} from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import MarkdownEditor from "@uiw/react-markdown-editor";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    deleteButton:{
        backgroundColor: '#900',
        color: '#eee'
    },
}))

export const DeleteDialog = ({open, handleClose, handleDelete, element}) => {
    const classes = useStyles();

    const handleOnDelete = () => {
        handleDelete();
        handleClose();
    }

    return <Dialog className={classes.otherFont} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={'md'} disableScrollLock={true}>
        <DialogTitle id="form-dialog-title">Löschen</DialogTitle>
        <DialogContent>
             <p>Wollen sie dieses Email ({element?.mail ?? 'none'}) wirklich löschen ?</p>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleOnDelete} className={classes.deleteButton} variant={'outlined'}>
                Löschen
            </Button>
            <Button onClick={handleClose} color="primary" variant={'outlined'}>
                Schließen
            </Button>
        </DialogActions>
    </Dialog>
}
