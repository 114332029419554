import React, {useEffect, useRef, useState} from "react";
import {Container, useTheme} from "@material-ui/core";
import {useStyles} from "./styling";
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {database} from "common/firebase";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlassWhiskey, faWineBottle} from '@fortawesome/free-solid-svg-icons';
import {ArticleObject} from "components";
import {useInView} from "react-intersection-observer";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Grid from "@material-ui/core/Grid";

const PAGE_SIZE = 12;

function ArticleList({store, type, missingText}) {
    const {ref, inView} = useInView({
        root: null,
        rootMargin: "5px",
        threshold: 1.0
    });
    const history = useHistory();
    const {loading} = useStyles();
    const [pageSize, setPageSize] = useState(12);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [endOfList, setEndOfList] = useState(false);
    const [articleList, setArticleList] = useState([]);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const typeRef = useRef('');

    const {addMessages} = store.snackBarHandle;

    useEffect(() => {
        if (inView && !isDataLoading && !endOfList && pageSize <= articleList.length) {
            setIsDataLoading(true);
            setPageSize(pageSize + PAGE_SIZE);
        }
        if (endOfList) setIsDataLoading(false);
    }, [inView, articleList.length, isDataLoading, pageSize]);

    useEffect(() => {
        setIsDataLoading(true);
        if (!type) return;
        let currentPageSize = pageSize;
        if (typeRef.current !== type) {
            typeRef.current = type;
            currentPageSize = 12;
            setPageSize(12);
        }
        database.ref(`articles_${type}/`)
        .orderByChild('order_by')
        .limitToFirst(currentPageSize)
        .once('value', snap => {
            const list = [];
            snap.forEach((e) => {
                const obj = e.val();
                obj.articleNumber = e.key;
                list.push(obj);
            });
            setArticleList(list);
            if (matches && currentPageSize <= 12) window.scrollTo({top: 600, behavior: 'smooth'});
            if (list.length < currentPageSize-1) setEndOfList(true);
        }).then(_ => {
            setIsDataLoading(false);
        });

    }, [type, pageSize, matches]);

    const handleClickOnProduct = (id, name) => (e) => {
        history.push(`/whisky/article/${id}/${name.replace(' ', '_')}`)
    };

    const addToCart = (product) => () => {
        addMessages(`${product.articleNumber} zum Warenkorb hinzugefügt.`);
        store.cartStore.writeArticleInCart(product, 1, false);
    };

    return <Container maxWidth="md">
        {articleList.length === 0 && isDataLoading ?
            <FontAwesomeIcon icon={faGlassWhiskey} spin/> :
            (articleList.length === 0 && <div> {missingText || 'Noch Keine Produkte vorhanden.'} </div>)}
        <Grid container spacing={2}>
            {articleList.map((product, index) =>
                <Grid item xs={6} sm={4} md={3} key={index}>
                    <ArticleObject
                        key={index}
                        product={product}
                        handleClickOnProduct={handleClickOnProduct}
                        addToCart={addToCart}
                        hasRights={store.userStore.currentRole >= 0b0100}
                    />
                </Grid>
            )}
        </Grid>

        <div className={loading} ref={ref}>
            {isDataLoading ? <FontAwesomeIcon icon={faGlassWhiskey} spin/> : (pageSize <= articleList.length && <FontAwesomeIcon icon={faWineBottle}/>)}
        </div>
    </Container>
}

export default inject('store')(observer(ArticleList));


