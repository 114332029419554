import React from 'react';
import MarkdownEditor from "@uiw/react-markdown-editor";

export const EditDescription = ({classes, description, rating, setDescription,setRating}) => {

    return <div>
        <div>
            <label htmlFor={'description'}> Beschreibung </label>
            <MarkdownEditor
                className={classes.otherFont}
                value={description}
                id={'description'}
                onChange={(a, b, value) => setDescription(value)}
                height={250}
            />
        </div>
        <br/>

        <div>
            <label htmlFor={'rating'}> Unsere Bewertung </label>
            <MarkdownEditor
                className={classes.otherFont}
                value={rating}
                id={'rating'}
                onChange={(a, b, value) => setRating(value)}
                height={250}
            />
        </div>
    </div>
};