import {makeStyles} from "@material-ui/core/styles";

const drawerWidth = 240;
export const useStyles = makeStyles(theme => ({
    fixedHeader: {
        position: 'fixed !important',
        left: 0,
    },
    scrollingHeader: {
        margin: 0
    },
    headerStyling: {
        top: 0,
        width: '100%',

        borderBottom: 'solid 1px #333',
        backgroundColor: '#f5f5dc',

        display: 'flex',
        flexDirection: 'column',

        position: 'absolute',
        zIndex: 999,
    },
    wrapperTopHeaderStyling: {
        display: 'flex',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        }
    },
    wrapperLogoStyle: {
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            order: '1',
        }
    },
    logoStyle: {
        maxWidth: 300,
        maxHeight: 114,
        marginTop: '1.5em',
        marginBottom: '1em',

        [theme.breakpoints.down('sm')]: {
            maxWidth: "min( calc( 400px - 2em ), calc( 100% - 2em ))",
        }
    },
    wrapperNavigationStyle: {
        borderTop: 'solid 1px #333',
        padding: '0.5em 1em',
        textAlign: 'left',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        overflow: 'hidden',

        position: 'relative',
    },
    wrapperNewsletterStyle: {
        minWidth: 300,
        width: 'calc( ( 100% - 300px ) / 2 )',
        textAlign: 'left',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            order: '3',
        }
    },
    wrapperNewsletterPadding: {
        padding: '1em',
    },
    btnNewsletterStyle: {
        backgroundColor: '#333',
        color: '#f5f5dc',
        borderRadius: 'none',
        border: '1px solid #000',

        '&:hover': {
            backgroundColor: '#000',
            color: '#f5f5dc',
            border: '1px solid #000',

        }
    },
    wrapperSearchStyle: {
        padding: '1.5em 0.5em 1em 1em',

        minWidth: 300,
        width: 'calc( ( 100% - 300px ) / 2 )',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            order: '2',
        }
    },
    iconTopSearchStyle: {
        cursor: 'pointer',
        fontSize: '2em',
        color: '#333',

        '&:hover': {
            color: 'black',
        }
    },
    iconTopSearchStyleDrawer: {
        cursor: 'pointer',
        fontSize: '2em',
        color: '#f5f5dc',

        '&:hover': {
            color: 'white',
        }
    },
    linkElementStyle: {
        padding: '0.5em 1.5em',
        color: '#f5f5dc',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        borderLeft: 'solid 1px #bbb',

        '&:first-child': {
            borderLeft: 'none',
        },

        '&:hover': {
            color: '#fff',
            textDecoration: 'underline',
        }
    },
    linkElementActiveStyle: {
        fontSize: '1.3em',
        fontWeight: 'bold',
    },
    chevronLeft: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,

        fontSize: '1.5em',
        cursor: 'pointer',
        padding: '0.5em',

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
        }
    },
    chevronRight: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,

        fontSize: '1.5em',
        cursor: 'pointer',
        padding: '0.5em',

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
        }
    },
    wrapperScrollingStyle: {},
    wrapperNavigationScrollStyle: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#333',
        color: '#f5f5dc',
        borderTop: 'solid 1px #000',
        boxShadow: '0 2px 2px 0 #333',
    },
    wrapperIconTop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '1.75em',
        color: '#333',
        textDecoration: 'none',
    },
    wrapperTopSearchStyle: {
        display: 'flex',
        width: 247,
        justifyContent: 'flex-end',
    },
    iconTopSearchLabel: {
        fontSize: '0.75em',
        color: '#333',
        textDecoration: 'none',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
    },
    searchTextFieldStyle: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        backgroundColor: '#fff',

        '&:hover': {
            borderColor: '#333'
        },
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: '#333',
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#333',
        color: '#f5f5dc'
    },
    iconBtnStyle: {
        color: '#f5f5dc'
    },
    btnRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    boxStyle: {
        padding: '0.5em',
        margin: '0.25em',
        textAlign: 'center'
    },
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    registerBtn: {
        margin: '0.25em'
    },
    textField: {
        margin: '0.5em 0',
    }
}));
