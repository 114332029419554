import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, InputAdornment, Typography} from "@material-ui/core";
import Modal from "../Modal";
import {database} from "../../common/firebase";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {getCoupon, getMWST, getProductSum, getShipping, getShippingAbo, getSum, getSumPre, isAbo} from "../../common/statelessFunctions";
import Tooltip from "@material-ui/core/Tooltip";
import {faEuroSign, faPlus, faSync, faTrash} from "@fortawesome/free-solid-svg-icons";
import TableFooter from "@material-ui/core/TableFooter";
import Table from "@material-ui/core/Table";
import {inject, observer} from "mobx-react";
import {DateTime} from "luxon";
import {useStyles} from "./styling";
import {AddProductModal} from "./AddProductModal";
import UserSearchDialog from "../../pages/Admin/OrderList/UserSearchDialog";

const OrderEdit = inject('store')(observer(({store, order, open, handleClose}) => {
    const classes = useStyles()
    const [localOrder, setLocalOrder] = useState(null);
    const {devSavedArticles} = store.articleStore;
    const [openSelectionModal, setOpenSelectionModal] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [isAddressInputOpen, setIsAddressInputOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    useEffect(() => {
        setIsAddressInputOpen(false);
        if (order) {
            let nextOrder = JSON.parse(JSON.stringify(order));
            const paymentAddress = {
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                plz: '',
                region: '',
                land: 'Österreich'
            };
            const products = [];
            const coupon = 0;
            if (!!nextOrder.paymentEmail) setIsAddressInputOpen(true);
            if (!!nextOrder.paymentAddress) setIsAddressInputOpen(true);
            if (!nextOrder.paymentAddress) nextOrder = {...nextOrder, paymentAddress};
            if (!nextOrder.products) nextOrder = {...nextOrder, products};
            if (!nextOrder.coupon) nextOrder = {...nextOrder, coupon};
            setLocalOrder({...nextOrder});
        }

        database.ref('events_online').on('value', snap => {
            if (snap.exists()) {
                const list = [];
                snap.forEach((s) => {
                    const event = s.val();
                    event.key = s.key;
                    list.push(event);
                })
                setEventsList(list);
            }
        })
    }, [order])

    const handleSaveAndClose = () => {
        localOrder.isManuel = true;
        localOrder.isWw = store.isWw;
        if (!localOrder.shippingAddress) localOrder.shippingAddress = localOrder.paymentAddress;
        if (!localOrder.timestamp) localOrder.timestamp = DateTime.local().toMillis();
        database.ref('orders').child(localOrder.orderNumber).update(localOrder).then(() => {
            handleClose();
        });
    }

    const addShippingAddress = () => {
        const shippingAddress = {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            plz: '',
            region: '',
            land: 'Österreich'
        }
        setLocalOrder({...localOrder, shippingAddress});
    }

    const syncShippingAddress = () => {
        localOrder.shippingAddress = localOrder.paymentAddress;
        setLocalOrder({...localOrder});
    }

    const addArticleToOrder = (product) => {
        if (!localOrder.attendees) localOrder.attendees = [];
        if (localOrder && product) {
            if (!localOrder.products) localOrder.products = [];
            if (product.articleNumber.includes('EVENT')) {
                if (localOrder?.paymentAddress?.firstName && localOrder?.paymentAddress?.lastName && localOrder?.paymentEmail) {
                    for (let i = 0; i < product.amount; i++) {
                        localOrder.attendees.push({
                            firstName: localOrder.paymentAddress.firstName,
                            lastName: localOrder.paymentAddress.lastName,
                            email: localOrder.paymentEmail,
                            event: product.key,
                            eventName: product.name,
                            year: product.year,
                        })
                    }
                    localOrder.products.push(product);
                    setLocalOrder({...localOrder});
                    return setBillData(localOrder)
                }
                store.snackBarHandle.addMessages("Bitte zuerst Vorname, Nachname und E-Mail-Adresse angeben für eine Event.")
            } else {
                localOrder.products.push(product);
                setLocalOrder({...localOrder});
                setBillData(localOrder)
            }

        }
    }

    const removeArticleFromBill = (index) => e => {
        localOrder.products.splice(index, 1);
        setLocalOrder({...localOrder});
        setBillData(localOrder)
    }

    const setBillData = (orderData, shipping) => {
        const billData = {
            paymentDay: orderData?.billData?.paymentDay || 'Wir bitten um umgehende Überweisung',
            timestamp: DateTime.local().toMillis(),
        };
        if (orderData.products) {
            const {products} = orderData;
            billData.billNumber = 'BR' + DateTime.local().toFormat('LLyy') + orderData.orderNumber.slice(-5).toUpperCase();
            billData.tax = getMWST(products, 0.2);
            billData.shippingCost = shipping ? parseFloat(shipping) : getShipping(products) + getShippingAbo(products);
            billData.withoutTax = getSumPre(products, 0.2);
            billData.coupon = getCoupon(orderData.coupon, products);
            billData.payed = getSum(products, 0.2) + billData.shippingCost - getCoupon(orderData.coupon, products);
        } else if (shipping) {
            billData.shippingCost = parseFloat(shipping);
        }
        setLocalOrder({...orderData, billData});
    }

    const selectAddress = addressBlock => event => {
        console.log(addressBlock);
        localOrder.paymentEmail = addressBlock.email;
        localOrder.paymentAddress.firstName = addressBlock.firstName;
        localOrder.paymentAddress.lastName = addressBlock.lastName;
        localOrder.paymentAddress.address1 = addressBlock.address1;
        localOrder.paymentAddress.address2 = addressBlock.address2;
        localOrder.paymentAddress.plz = addressBlock.plz;
        localOrder.paymentAddress.region = addressBlock.region;
        localOrder.paymentAddress.land = addressBlock.land;
        localOrder.shippingAddress = null;
        setLocalOrder({...localOrder});
        setIsAddressInputOpen(true);
        setIsSearchOpen(false)
    }

    return localOrder && <Modal open={open} handleClose={handleClose} title={"Bestellungbearbeiten"}
                                actions={
                                    <div>
                                        <Button color={"primary"} variant={"contained"} onClick={handleClose} style={{marginRight: '0.25em'}}>Abbrechen</Button>
                                        <Button color={"primary"} variant={"contained"} onClick={handleSaveAndClose}>Speichern</Button>
                                    </div>
                                }>
        <div>
            <TextField
                className={classes.textField}
                id="id"
                label="Bestellungsnummer"
                variant={'outlined'}
                defaultValue={localOrder.orderNumber}
                type={"text"}
                fullWidth
                disabled
            />
            <Grid container spacing={1} className={[classes.otherFont, classes.btnWrapper].join(' ')}>
                <Grid item md={6}>
                    <Button variant={"contained"} color={"primary"} fullWidth size={"large"} onClick={() => setIsSearchOpen(true)}>
                        Kunden Suchen
                    </Button>
                </Grid>
                {!isAddressInputOpen &&
                <Grid item md={6}>
                    <Button variant={"contained"} color={"primary"} fullWidth size={"large"} onClick={() => setIsAddressInputOpen(true)}>
                        Kunden manuell eingeben
                    </Button>
                </Grid>}
            </Grid>

            {isAddressInputOpen && <TextField
                className={classes.textField}
                id="email"
                label="E-Mail Adresse"
                variant={'outlined'}
                defaultValue={localOrder.paymentEmail}
                type={"text"}
                fullWidth
                onChange={(e) => {
                    localOrder.paymentEmail = e.target.value;
                    setLocalOrder({...localOrder})
                }}
            />}
            {isAddressInputOpen && <Grid container spacing={1}>
                <Grid item md={6}>
                    <Box>
                        <h3>Rechnungsadresse</h3>
                        <TextField
                            className={classes.textField}
                            id="firstNameR"
                            label="Vorname"
                            variant={'outlined'}
                            value={localOrder.paymentAddress.firstName}
                            type={"text"}
                            fullWidth
                            onChange={(e) => {
                                localOrder.paymentAddress.firstName = e.target.value;
                                setLocalOrder({...localOrder})
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            id="lastNameR"
                            label="Nachname"
                            variant={'outlined'}
                            value={localOrder.paymentAddress.lastName}
                            type={"text"}
                            fullWidth
                            onChange={(e) => {
                                localOrder.paymentAddress.lastName = e.target.value;
                                setLocalOrder({...localOrder})
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            id="address1R"
                            label="Adress Zeile 1"
                            variant={'outlined'}
                            value={localOrder.paymentAddress.address1}
                            type={"text"}
                            fullWidth
                            onChange={(e) => {
                                localOrder.paymentAddress.address1 = e.target.value;
                                setLocalOrder({...localOrder})
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            id="address2R"
                            label="Adress Zeile 2"
                            variant={'outlined'}
                            value={localOrder.paymentAddress.address2}
                            type={"text"}
                            fullWidth
                            onChange={(e) => {
                                localOrder.paymentAddress.address2 = e.target.value;
                                setLocalOrder({...localOrder})
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            id="plzR"
                            label="Postleitzahl"
                            variant={'outlined'}
                            value={localOrder.paymentAddress.plz}
                            type={"text"}
                            fullWidth
                            onChange={(e) => {
                                localOrder.paymentAddress.plz = e.target.value;
                                setLocalOrder({...localOrder})
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            id="regionR"
                            label="Stadt / Region"
                            variant={'outlined'}
                            value={localOrder.paymentAddress.region}
                            type={"text"}
                            fullWidth
                            onChange={(e) => {
                                localOrder.paymentAddress.region = e.target.value;
                                setLocalOrder({...localOrder})
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            id="landR"
                            label="Land"
                            variant={'outlined'}
                            value={localOrder.paymentAddress.land}
                            type={"text"}
                            fullWidth
                            onChange={(e) => {
                                localOrder.paymentAddress.land = e.target.value;
                                setLocalOrder({...localOrder})
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item md={6}>
                    {localOrder.shippingAddress ? <Box>
                            <h3 style={{display: 'flex'}}>Lieferadresse <Tooltip title={'mit Rechnungsadresse Synchonisieren'}><span><IconButton
                                onClick={syncShippingAddress} style={{padding: '0 12px', display: 'inline-block'}}><FontAwesomeIcon
                                icon={faSync}/></IconButton></span></Tooltip></h3>
                            <TextField
                                className={classes.textField}
                                id="firstName"
                                label="Vorname"
                                variant={'outlined'}
                                value={localOrder.shippingAddress.firstName}
                                type={"text"}
                                fullWidth
                                onChange={(e) => {
                                    localOrder.shippingAddress.firstName = e.target.value;
                                    setLocalOrder({...localOrder})
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                id="lastName"
                                label="Nachname"
                                variant={'outlined'}
                                value={localOrder.shippingAddress.lastName}
                                type={"text"}
                                fullWidth
                                onChange={(e) => {
                                    localOrder.shippingAddress.lastName = e.target.value;
                                    setLocalOrder({...localOrder})
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                id="address1"
                                label="Adress Zeile 1"
                                variant={'outlined'}
                                value={localOrder.shippingAddress.address1}
                                type={"text"}
                                fullWidth
                                onChange={(e) => {
                                    localOrder.shippingAddress.address1 = e.target.value;
                                    setLocalOrder({...localOrder})
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                id="address2"
                                label="Adress Zeile 2"
                                variant={'outlined'}
                                value={localOrder.shippingAddress.address2}
                                type={"text"}
                                fullWidth
                                onChange={(e) => {
                                    localOrder.shippingAddress.address2 = e.target.value;
                                    setLocalOrder({...localOrder})
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                id="plz"
                                label="Postleitzahl"
                                variant={'outlined'}
                                value={localOrder.shippingAddress.plz}
                                type={"text"}
                                fullWidth
                                onChange={(e) => {
                                    localOrder.shippingAddress.plz = e.target.value;
                                    setLocalOrder({...localOrder})
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                id="region"
                                label="Stadt / Region"
                                variant={'outlined'}
                                value={localOrder.shippingAddress.region}
                                type={"text"}
                                fullWidth
                                onChange={(e) => {
                                    localOrder.shippingAddress.region = e.target.value;
                                    setLocalOrder({...localOrder})
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                id="land"
                                label="Land"
                                variant={'outlined'}
                                value={localOrder.shippingAddress.land}
                                type={"text"}
                                fullWidth
                                onChange={(e) => {
                                    localOrder.shippingAddress.land = e.target.value;
                                    setLocalOrder({...localOrder})
                                }}
                            />
                        </Box> :
                        <Box>
                            <h3>Lieferadresse</h3>
                            <Typography>Wird beim Speichern automatisch aus der Rechnungsaddresse übernommen.</Typography>
                            <Typography>Zu ändern auf Icon klicken</Typography>
                            <IconButton onClick={addShippingAddress}><FontAwesomeIcon icon={faEdit}/></IconButton>
                        </Box>}
                </Grid>
            </Grid>}
            {isAddressInputOpen && <h3>Produkte</h3>}
            {isAddressInputOpen && <Table style={{width: '100%', borderSpacing: 0}} className={classes.otherFont}>
                <TableHead>
                    <TableRow>
                        <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Pos.</TableCell>
                        <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Bezeichnung</TableCell>
                        <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Menge</TableCell>
                        <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Preis</TableCell>
                        <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Gesamt</TableCell>
                        <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Options</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!localOrder.products && localOrder.products.map((product, index) => (
                        <TableRow key={index}>
                            <TableCell style={{padding: '0.5em 0 0.5em 1em'}}>{index + 1}.</TableCell>
                            <TableCell>{isAbo(product.articleNumber) ? product.articleNumber : (product.name || product.articleNumber)}</TableCell>
                            <TableCell>{product.amount}</TableCell>
                            <TableCell>€ {product.vk_net}</TableCell>
                            <TableCell>€ {getProductSum(product)}</TableCell>
                            <TableCell>
                                <Tooltip title={'remove'}>
                                    <IconButton onClick={removeArticleFromBill(index)}><FontAwesomeIcon icon={faTrash}/></IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow onClick={() => setOpenSelectionModal(true)} className={classes.hoverRow}>
                        <TableCell>{localOrder.products.length + 1}.</TableCell>
                        <TableCell colSpan={5}>
                            <div>Neue Zeile mit Artikel, Event oder Freitext hinzufügen <FontAwesomeIcon icon={faPlus}/></div>
                        </TableCell>
                    </TableRow>
                </TableBody>
                {!!localOrder.billData &&
                <TableFooter>
                    {!!localOrder.billData.withoutTax ?
                        <TableRow>
                            <TableCell colSpan={3} className={classes.tCFooterTop}/>
                            <TableCell className={classes.tCFooterTop}>Netto:</TableCell>
                            <TableCell className={classes.tCFooterTop}>€ {(localOrder.billData.withoutTax).toFixed(2)}</TableCell>
                            <TableCell className={classes.tCFooterTop}/>
                        </TableRow> :
                        <TableRow>
                            <TableCell colSpan={3} className={classes.tCFooterTop}/>
                            <TableCell className={classes.tCFooterTop}>Netto:</TableCell>
                            <TableCell className={classes.tCFooterTop}>€ 0,00</TableCell>
                            <TableCell className={classes.tCFooterTop}/>
                        </TableRow>
                    }
                    {!!localOrder.billData.tax ?
                        <TableRow>
                            <TableCell colSpan={3} className={classes.tCFooter}/>
                            <TableCell className={classes.tCFooter}>20 % Ust:</TableCell>
                            <TableCell className={classes.tCFooter}>€ {(localOrder.billData.tax).toFixed(2)}</TableCell>
                        </TableRow> :
                        <TableRow>
                            <TableCell colSpan={3} className={classes.tCFooter}/>
                            <TableCell className={classes.tCFooter}>20 % Ust:</TableCell>
                            <TableCell className={classes.tCFooter}>€ 0,00</TableCell>
                        </TableRow>
                    }
                    <TableRow>
                        <TableCell colSpan={3} className={classes.tCFooter}/>
                        <TableCell className={classes.tCFooter}>Lieferkosten:</TableCell>
                        <TableCell className={classes.tCFooter}>
                            <TextField
                                id="article-shipping"
                                value={localOrder.billData.shippingCost}
                                variant={'outlined'}
                                type="number"
                                name={'shipping'}
                                onChange={(e) => {
                                    setBillData(localOrder, e.target.value);
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FontAwesomeIcon icon={faEuroSign}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} className={classes.tCFooter}/>
                        <TableCell className={classes.tCFooter}>Rabatte:</TableCell>
                        <TableCell className={classes.tCFooter}>
                            <TextField
                                id="article-coupon"
                                value={localOrder.coupon}
                                variant={'outlined'}
                                type="number"
                                name={'coupon'}
                                onChange={(e) => {
                                    localOrder.coupon = e.target.value;
                                    setLocalOrder({...localOrder});
                                    setBillData(localOrder, localOrder.billData.shippingCost);
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FontAwesomeIcon icon={faEuroSign}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    {!!localOrder.billData.payed ?
                        <TableRow>
                            <TableCell colSpan={3} className={classes.tCFooterBottom}/>
                            <TableCell className={classes.tCFooterBottom}>Brutto:</TableCell>
                            <TableCell className={classes.tCFooterBottom}>€ {(localOrder.billData.payed).toFixed(2)}</TableCell>
                            <TableCell className={classes.tCFooterBottom}/>
                        </TableRow> :
                        <TableRow>
                            <TableCell colSpan={3} className={classes.tCFooterBottom}/>
                            <TableCell className={classes.tCFooterBottom}>Brutto:</TableCell>
                            <TableCell className={classes.tCFooterBottom}>€ 0,00</TableCell>
                            <TableCell className={classes.tCFooterBottom}/>
                        </TableRow>
                    }
                </TableFooter>}
            </Table>}
            {!!localOrder.billData &&
            <div>
                <h3>Weiter Rechnungsdetails</h3>
                <TextField
                    className={classes.textField}
                    id="date"
                    label="Rechnungsdatum"
                    variant={'outlined'}
                    defaultValue={DateTime.fromMillis(localOrder.billData.timestamp || DateTime.local().toMillis()).toFormat('dd.LL.yyyy')}
                    type={"text"}
                    fullWidth
                    disabled
                /><TextField
                className={classes.textField}
                id="reference"
                label="Referenznummer"
                variant={'outlined'}
                value={localOrder.billData.billNumber}
                type={"text"}
                fullWidth
                disabled
            />
                <TextField
                    className={classes.textField}
                    id="uid"
                    label="UID"
                    variant={'outlined'}
                    value={'ATU75631247'}
                    type={"text"}
                    fullWidth
                    disabled
                />
                <TextField
                    className={classes.textField}
                    id="paymentDay"
                    label="Zahlungsnachricht"
                    variant={'outlined'}
                    value={localOrder.billData.paymentDay}
                    type={"text"}
                    fullWidth
                    onChange={(e) => {
                        localOrder.billData.paymentDay = e.target.value;
                        setLocalOrder({...localOrder})
                    }}
                />

            </div>
            }

        </div>
        <AddProductModal open={openSelectionModal}
                         handleClose={() => setOpenSelectionModal(false)}
                         articles={devSavedArticles}
                         nextProduct={addArticleToOrder}
                         eventsList={eventsList}
                         email={localOrder?.paymentEmail}
                         firstName={localOrder?.paymentAddress?.firstName}
                         lastName={localOrder?.paymentAddress?.lastName}/>
        <UserSearchDialog open={isSearchOpen} handleClose={() => setIsSearchOpen(false)} selectAddress={selectAddress}/>


    </Modal>
}));

export default OrderEdit;
