import React, {useState} from "react";
import {Container, TextField} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import {useStyles} from "./styling";
import {inject, observer} from "mobx-react";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch} from "@fortawesome/free-solid-svg-icons";
import {functions, database} from "common/firebase";
import Typography from "@material-ui/core/Typography";
import ArticleListFull from "../ArticleListFull";
import Hidden from "@material-ui/core/Hidden";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const WhiskyConfigurator = inject('store')(observer(({store}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        labelStyleBottom, labelStyle, labelWrapperStyle, inputRowStyle, inputRowStyleSmall, autoCompleteWrapper, autoCompleteWrapperSmall,
        colorWrapperSmall, colorInputSmall, searchBtnSmall
    } = useStyles();
    const {barrelList, peatedList, originsList, finishsList, distilleriesList} = store.articleStore;
    const [filterObject, setFilterObject] = useState({});
    const [loading, setLoading] = React.useState(false);
    const [didFirstSearch, setDidFirstSearch] = React.useState(false);

    const [articleList, setArticleList] = useState([]);

    const handleChange = key => (event, value, element) => {
        filterObject[key] = value;
        setFilterObject({...filterObject});
    };

    const handleChangeObject = key => (event, value, element) => {
        filterObject[key] = value.map(v => v.value);
        setFilterObject({...filterObject});
    };

    const getArticles = async () => {
        setLoading(true);
        setArticleList([]);
        setDidFirstSearch(true);
        try {
            let cleanedFilter = {};
            for (const key of Object.keys(filterObject)) {
                if (filterObject[key].length > 0) cleanedFilter[key] = filterObject[key];
            }
            const res = await functions.httpsCallable('filterArticles')(cleanedFilter);
            console.log(res.data);
            setArticleList(res.data.map(article => ({
                    ...article,
                    thumbnail: article.thumbnails?.[0] ?? null,
                    image: article.images?.[0] ?? null,
                    fullName: article.name ?? article.producer ?? 'NONE',
            })));
        } catch (error) {
            console.error(error);
            //todo add Error Handler
        } finally {
            setLoading(false);
        }
    };

    return <Container maxWidth="md" style={{marginBottom: '5em'}}>
        <Container className={matches ? colorWrapperSmall : ''}>
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyle}>
                            <Typography>Mälzung</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Autocomplete
                        id="Peated"
                        size="small"
                        multiple
                        limitTags={2}
                        options={peatedList}
                        style={{width: 300}}
                        onChange={handleChange('peated')}
                        renderInput={(params) => <TextField className={colorInputSmall} {...params} label="Mälzung" variant="outlined"/>}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option !== 'Peated' ? option !== 'Unpeated' ? option : 'Unpeated / Ungetorft' : 'Peated / Getorft / Rauchig'}
                            </React.Fragment>
                        )}
                    />
                </div>
            </div>
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyle}>
                            <Typography>Basisfass</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Autocomplete
                        id="Barrel"
                        size="small"
                        multiple
                        limitTags={2}
                        options={barrelList}
                        style={{width: 300}}
                        onChange={handleChange('barrel')}
                        renderInput={(params) => <TextField className={colorInputSmall} {...params} label="BASIS FASS" variant="outlined"/>}
                    />
                </div>
            </div>
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyle}>
                            <Typography>Getreide</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Autocomplete
                        id="grain"
                        size="small"
                        multiple
                        limitTags={2}
                        options={grains}
                        getOptionLabel={(option) => option.label}
                        onChange={handleChangeObject('grain')}
                        style={{width: 300}}
                        renderInput={(params) => <TextField className={colorInputSmall} {...params} label="GETREIDE" variant="outlined"/>}
                    />
                </div>
            </div>
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyle}>
                            <Typography>Land</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Autocomplete
                        id="land"
                        size="small"
                        multiple
                        limitTags={2}
                        options={originsList}
                        onChange={handleChange('origin')}
                        style={{width: 300}}
                        renderInput={(params) => <TextField className={colorInputSmall} {...params} label="LAND" variant="outlined"/>}
                    />
                </div>
            </div>
            {!!filterObject.origin && filterObject.origin.indexOf('Schottland') > -1 &&
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyle}>
                            <Typography>Region</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Autocomplete
                        id="REGION"
                        size="small"
                        multiple
                        limitTags={2}
                        options={regions}
                        getOptionLabel={(option) => option.label}
                        onChange={handleChangeObject('region')}
                        style={{width: 300}}
                        renderInput={(params) => <TextField className={colorInputSmall} {...params} label="REGION" variant="outlined"/>}
                    />
                </div>
            </div>
            }
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyle}>
                            <Typography>Distillery</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Autocomplete
                        id="DISTILLERY"
                        size="small"
                        multiple
                        limitTags={2}
                        options={distilleriesList}
                        onChange={handleChange('distillery')}
                        style={{width: 300}}
                        renderInput={(params) => <TextField className={colorInputSmall} {...params} label="DISTILLERY" variant="outlined"/>}
                    />
                </div>
            </div>
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyle}>
                            <Typography>Finish</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Autocomplete
                        id="FINISH"
                        size="small"
                        multiple
                        limitTags={2}
                        options={finishsList}
                        onChange={handleChange('finish')}
                        style={{width: 300}}
                        renderInput={(params) => <TextField className={colorInputSmall} {...params} label="FINISH" variant="outlined"/>}
                    />
                </div>
            </div>
            <div className={matches ? inputRowStyleSmall : inputRowStyle}>
                <Hidden smDown>
                    <div className={labelWrapperStyle}>
                        <div className={labelStyleBottom}>
                            <Typography>Start</Typography
                            ></div>
                    </div>
                </Hidden>
                <div className={matches ? autoCompleteWrapperSmall : autoCompleteWrapper}>
                    <Button className={matches ? searchBtnSmall : ''} variant={'outlined'} color={'primary'} onClick={getArticles}><FontAwesomeIcon
                        icon={faSearch}/> Suchen</Button>
                </div>
            </div>
        </Container>
        <ArticleListFull list={articleList} isDataLoading={loading}
                         isEmpty={!loading && didFirstSearch && Object.keys(filterObject).length !== 0 && articleList.length === 0}/>
    </Container>
}));

export default WhiskyConfigurator;

const regions = [
    {value: 'highlands', label: 'Highlands'},
    {value: 'speyside', label: 'Speyside'},
    {value: 'lowlands', label: 'Lowlands'},
    {value: 'islands', label: 'Islands'},
    {value: 'islay', label: 'Islay'},
];
const grains = [
    {value: 'singleMalt', label: 'Single Malt'},
    {value: 'singleGrain', label: 'Single Grain'},
    {value: 'grain', label: 'Grain'},
    {value: 'blend', label: 'Blend'},
    {value: 'postStill', label: 'PostStill'},
];
