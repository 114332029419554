import React, {useCallback, useEffect, useState} from 'react';
import {
    AppBar,
    IconButton, InputAdornment, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, TextField,
    Tooltip,
} from '@material-ui/core';
import {PlusOneOutlined} from '@material-ui/icons';
import {useStyles} from './styling'
import {EditArticleModal} from "./EditArticleModal";
import Moment from 'react-moment';
import {getAmount, getAmountRest, getSoldDataForProduct} from "common/statelessFunctions";
import {inject, observer} from "mobx-react";
import {AdminExtraMenu} from "../../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs, faExclamationTriangle, faGifts, faGlassWhiskey, faPrint, faSearch, faTimes, faWineBottle} from "@fortawesome/free-solid-svg-icons";
import {EditArticleSetModal} from "components";
import LinearProgress from "@material-ui/core/LinearProgress";
import {articleTextFilter} from "./statelessFunctions";
import {useHistory} from "react-router-dom";

const ArticlesAdminTable = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const history = useHistory();
    const rows = store.articleStore.devSavedArticles.slice().sort((a, b) => a.articleNumber < b.articleNumber ? 1 : -1);
    const newArticle = store.articleStore.newArticle;

    const [tab, setTab] = useState('articles');
    const [filter, setFilter] = useState('');
    const [selectedArticle, setSelectedArticle] = useState(newArticle);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [open, setOpen] = useState(false);
    const [openSet, setOpenSet] = useState(false);

    const filterFunction = useCallback((article) => {
        switch (tab) {
            case 'articles':
                return !article.isArchived;
            case 'stock' :
                return getAmount(article) > 0;
            case 'empty' :
                return getAmount(article) <= 0;
            case 'archived' :
                return article.isArchived;
            case 'all' :
                return true;
            default :
                return;
        }

    }, [tab])

    useEffect(() => {
        setLoading(false);
        setPage(0);
    }, [tab]);

    useEffect(() => {
        setPage(0);
    }, [filter])

    const addArticle = () => {
        newArticle.articleNumber = store.articleStore.articleNumber;
        setSelectedArticle(newArticle);
        setOpen(true);
    }

    const addSet = () => {
        newArticle.articleNumber = store.articleStore.articleNumber;
        setOpenSet(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleToggleOpen = (event) => {
        setOpen(!open);
    };

    const handleEditArticle = article => event => {
        setSelectedArticle(article);
        if (article.isSet) {
            setOpenSet(true);
        } else {
            setOpen(true);
        }
    };

    const getStringForRow = row => {
        const {amount, currentAmount, ad, tasting, sold} = getSoldDataForProduct(row);
        return `Eingekauft ${amount}; Verkauft: ${sold}; Entwendet: ${ad + tasting}; Lagerstand Rest: ${currentAmount}`;
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.filter(filterFunction).filter(articleTextFilter(filter)).length - page * rowsPerPage);

    const handleChangeTab = (event, newValue) => setTab(newValue);
    const clearFilter = () => {if (filter !== '') setFilter('')};
    return <div>
        <AppBar position="static" className={classes.appBar}>Unsere Produkte</AppBar>
        <Tabs
            value={tab}
            className={classes.otherFont + ' ' + classes.tabStyle}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Tabs for Detailed Information"
        >
            <Tab value={'articles'} label="Artikel" id={"0"}/>
            <Tab value={'stock'} label="Vorhanden" id={"1"}/>
            <Tab value={'empty'} label="Leer" id={"2"}/>
            <Tab value={'archived'} label="Archiviert" id={"3"}/>
            <Tab value={'all'} label="Alle" id={"4"}/>
        </Tabs>
        <TableContainer component={Paper} className={classes.otherFont} style={{marginBottom: '2em'}}>
            <Table style={{width: '100%'}} size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={6}>
                            <TextField
                                className={classes.otherFont}
                                variant={'outlined'}
                                label={'Durchsuche Artikel'}
                                placeholder={'Durchsuche Artikel nach Nummer, Name, Hersteller oder Distillery'}
                                helperText={'Durchsuche Artikel nach Nummer, Name, Hersteller oder Distillery'}
                                color={'primary'}
                                autoComplete={'on'}
                                value={filter}
                                type={'text'}
                                fullWidth
                                onChange={(e) => setFilter(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" onClick={clearFilter} style={{cursor: 'pointer'}}>
                                            {filter === '' ?
                                                <FontAwesomeIcon icon={faSearch}/> :
                                                <FontAwesomeIcon icon={faTimes}/>
                                            }
                                        </InputAdornment>
                                    ),
                                }}/>
                        </TableCell>
                        <TableCell colSpan={2}>
                            <Tooltip title="Neues Arktikel Set">
                                <IconButton aria-label="add set" onClick={addSet}>
                                    <PlusOneOutlined/>
                                    <FontAwesomeIcon icon={faGifts}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Neuen Arktikel hinzufügen">
                                <IconButton aria-label="add article" onClick={addArticle}>
                                    <PlusOneOutlined/>
                                    <FontAwesomeIcon icon={faWineBottle}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Artikel Liste drucken">
                                <IconButton aria-label="print article" onClick={() => history.push('/admin/print/articles/')}>
                                    <FontAwesomeIcon icon={faPrint}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    {loading && <TableRow>
                        <TableCell colSpan={9}>
                            <LinearProgress/>
                        </TableCell>
                    </TableRow>}
                    <TableRow>
                        <TableCell>Kat.</TableCell>
                        <TableCell>Nummer.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Lagerstand</TableCell>
                        <TableCell>Verkauft</TableCell>
                        <TableCell>Online</TableCell>
                        <TableCell>Start Datum</TableCell>
                        <TableCell><FontAwesomeIcon icon={faCogs}/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                    .filter(filterFunction)
                    .filter(articleTextFilter(filter))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {

                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.articleNumber}
                            >
                                <TableCell align="left" onClick={handleEditArticle(row)}>
                                    {!!row.images && row.images.length > 0 &&
                                    <Tooltip title={'Alte Bilder vorhanden'}>
                                        <span>
                                            <FontAwesomeIcon icon={faExclamationTriangle}/>
                                        </span>
                                    </Tooltip>}
                                    {!row.isSet ?
                                        row.types.indexOf('sample') > -1 ?
                                            <Tooltip title={'Kostprobe'}>
                                                <span><FontAwesomeIcon icon={faGlassWhiskey}/></span>
                                            </Tooltip> :
                                            <Tooltip title={'Whisky / Produkt'}>
                                                <span><FontAwesomeIcon icon={faWineBottle}/></span>
                                            </Tooltip> :
                                        <Tooltip title={'Geschenkspaket'}>
                                            <span><FontAwesomeIcon icon={faGifts}/></span>
                                        </Tooltip>
                                    }
                                </TableCell>
                                <TableCell align="left" onClick={handleEditArticle(row)}>{row.articleNumber}</TableCell>
                                <TableCell align="left" onClick={handleEditArticle(row)}>{row.name}</TableCell>
                                <TableCell align="left" onClick={handleEditArticle(row)}>
                                    <Tooltip title={getStringForRow(row)}>
                                        <span>{getAmount(row)}</span>
                                    </Tooltip></TableCell>
                                <TableCell align="left" onClick={handleEditArticle(row)}>
                                    <Tooltip title={getStringForRow(row)}>
                                        <span>{getAmountRest(row)}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="left" onClick={handleEditArticle(row)}>{row.isOnline && !!row.activeBatch ? 'Ja' : 'Nein'}</TableCell>
                                <TableCell align="right" onClick={handleEditArticle(row)}>{row.startDate ?
                                    <Moment date={row.startDate} format={'DD.MM.yyyy'}/> : 'missing'}</TableCell>
                                <TableCell align="right">
                                    <AdminExtraMenu articleNumber={row.articleNumber}
                                                    isSet={row.isSet}
                                                    isArchived={row.isArchived}
                                                    isSample={row.types.indexOf('sample') > -1}
                                                    isOffline={!row.isOnline || !row.activeBatch}
                                                    maxFlasks={getSoldDataForProduct(row).currentAmount}/>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 33 * emptyRows}}>
                            <TableCell colSpan={8}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={8}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.filter(filterFunction).filter(articleTextFilter(filter)).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <EditArticleModal open={open} handleToggleOpen={handleToggleOpen} selectedArticle={selectedArticle}/>
        <EditArticleSetModal open={openSet} handleClose={() => setOpenSet(false)} articleNumber={selectedArticle.articleNumber}
                             editArticle={selectedArticle}/>
    </div>

}))

export default ArticlesAdminTable;
