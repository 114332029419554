import React from "react";
import {ArticleList} from "components";
import {Helmet} from "react-helmet";

function Pairing() {
    return <>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - Schokolade die zu Whisky passt</title>
        </Helmet>
        <ArticleList type={'pairing'} />
        </>
}

export default Pairing;
