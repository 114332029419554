import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {makeStyles} from '@material-ui/core'
import {Link} from "react-router-dom";
import DbImage from "../dbImage";

const useStyles = makeStyles(theme => ({
    advertisementStyle: {
        width: '100%',
        objectFit: 'contained',
    },
    linkHeight: {
        minHeight: 426
    }
}))

function BannerSliderDbImage({items, subPath}) {
    const displayedItems = items ?? []

    // TODO make Timeout and Interval configurable
    return (
        <Carousel animation={'slide'} timeout={200} interval={7000} indicators={displayedItems.length > 1} navButtonsAlwaysInvisible={displayedItems.length <= 1}>
            {
                displayedItems.map((item, i) => <Item key={i} item={item} subPath={subPath}/>)
            }
        </Carousel>
    )
}

export default BannerSliderDbImage;

function Item({item, subPath}) {
    const classes = useStyles();
    return (
        <Link to={item.link} className={classes.linkHeight}>
            <DbImage className={classes.advertisementStyle} src={['images',subPath,item.img]} alt={item.alt}/>
        </Link>
    )
}
