import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {database, functions} from "../../common/firebase";

import {Box, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
}));

const CartOrder = inject('store')(observer(({store}) => {
    const history = useHistory();
    const classes = useStyles();
    const {search} = useLocation();
    const [loading, setLoading] = useState(true);

    const {lastOrder} = store.orderStore;

    useEffect(() => {
        setLoading(true);
        const query = new URLSearchParams(search);
        const unzer = query.get('unzer');
        if (unzer && Object.keys(lastOrder).length === 0) {
            database.ref('unzerOrders').child(unzer).once('value', async snap => {
                if (snap.exists()) {
                    const order = await store.orderStore.createNewOrder(snap.val());
                    const res = await functions.httpsCallable('checkUnzerPayment')({id: unzer})
                    if (res?.data?.state?.id) {
                        switch (res.data.state.id) {
                            case 1: {
                                order.billData.paymentDay = "Bezahlt"
                                order.billData.isPayed = true;
                                order.billData.paymentMethod = "card";
                                order.state = 'payed';

                                store.orderStore.updateOrder(order);
                                store.cartStore.clearCart();

                                database.ref('unzerOrders').child(unzer).set(null).then().catch(error => Sentry.captureException(error));
                                break;
                            }
                            case 2: {
                                store.orderStore.fetchUnzerOrder(unzer);
                                store.snackBarHandle.addMessages('Die Zahlung worde vom Kreditkarten Abgebrochen');
                                history.push('/warenkorb/payment/');
                                break;
                            }
                            default:
                                store.orderStore.fetchUnzerOrder(unzer);
                                Sentry.captureMessage(`Fehler bei Unzer mit id ${res.data.state.id}, name: ${res.data.state.name}`)
                                store.snackBarHandle.addMessages('Mit der Zahlung ist etwas Schiefgegangen, bitte kontaktieren sie uns zu überprüfung');
                                history.push('/warenkorb/payment/');
                        }
                    }else{
                        Sentry.captureMessage(`Fehler bei Unzer, keinen Status von der API bekommen`)
                        store.snackBarHandle.addMessages('Beim Zahlen ist ein Fehler bei Kreditkarten Institut aufgetreten. Bitte melden sie sich bei usn.');
                        history.push('/warenkorb/');
                    }
                }else{
                    Sentry.captureMessage(`Fehler bei Unzer, Order nicht in der DB gefunden`)
                    store.snackBarHandle.addMessages('Das System hat einen Fehler erkannt, es konnte keine Bestellung angelegt werden, melden sie sich bitte bei uns.');
                    history.push('/warenkorb/');
                }
                setLoading(false);
            })

        } else if (Object.keys(lastOrder).length === 0) {
            setLoading(false);
            history.push('/whisky/');
        } else {
            setLoading(false);
        }
    }, [lastOrder, history, search])

    if (loading || (Object.keys(store.orderStore.lastOrder).length > 0 && store.orderStore.lastOrder.state !== "payed" && store.orderStore.lastOrder.state !== "waiting")) {
        return <Container maxWidth={'md'} className={classes.otherFont}><Box style={{minHeight: 'calc(100vh - 449px)'}}>
            <h1><FontAwesomeIcon icon={faGlassWhiskey} spin/> Daten werden verarbeitet</h1>
        </Box></Container>
    }

    return <Container maxWidth={'md'} className={classes.otherFont}>
        {Object.keys(store.orderStore.lastOrder).length > 0 ?
            <Box style={{
                textAlign: 'left', minHeight: 'calc(100vh - 449px)', border: 'solid 1px #333', padding: '0.5em', borderRadius: '0.1em', margin: '1em 0'
            }}>
                <h2>
                    Vielen Dank für Ihre Bestellung!
                </h2>
                <h3>
                    Eine Email mit allen Zahlungsdetails wurde Ihnen soeben zugesandt.
                    Bei Fragen wenden Sie sich bitte an unser <a href="mailto:office@worldwhisky.at">Office</a>.
                </h3>
                <h4>Bestellungsdetails</h4>
                <div style={{paddingLeft: '0.5em'}}>
                    <h5 style={{textDecoration: 'underline'}}>Bestellungsnummer</h5>
                    <div style={{paddingLeft: '0.5em'}}>{store.orderStore.lastOrder.orderNumber}</div>
                    <h5 style={{textDecoration: 'underline'}}>Lieferadresse:</h5>
                    <div style={{paddingLeft: '0.5em'}}>
                        <div>
                            <span></span><span>{store.orderStore.lastOrder.shippingAddress.firstName} {store.orderStore.lastOrder.shippingAddress.lastName}</span>
                        </div>
                        <div><span>{store.orderStore.lastOrder.shippingAddress.address1}</span></div>
                        <div><span>{store.orderStore.lastOrder.shippingAddress.address2}</span></div>
                        <div><span>{store.orderStore.lastOrder.shippingAddress.plz}</span></div>
                        <div><span>{store.orderStore.lastOrder.shippingAddress.region}</span></div>
                        <div><span>{store.orderStore.lastOrder.shippingAddress.land}</span></div>
                    </div>
                    <h5 style={{textDecoration: 'underline'}}>Bestellliste:</h5>
                    <table style={{paddingLeft: '0.5em'}}>
                        <thead>
                        <tr>
                            <th>Anzahl</th>
                            <th>Name</th>
                            <th>Artikelnummer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {store.orderStore.lastOrder.products.map((product, index) => (
                            <tr key={{index}}>
                                <td>{product.amount}</td>
                                <td>{product.name}</td>
                                <td>{product.articleNumber}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Box> :
            <Box style={{minHeight: 'calc(100vh - 449px)'}}>
                <h3>Sie haben gerade keine Bestellung abgeschlossen! Klicken sie <Link to={'/whisky/'}>hier</Link> um zum Shop zu gelangen</h3>
            </Box>
        }

    </Container>
}));

export default CartOrder;
