import React from "react";
import PropTypes from 'prop-types';
import {DialogContent, DialogActions, Button} from "@material-ui/core";
import {Modal} from 'components/index';
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    }
}));

Disclaimer.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

function Disclaimer ({open, handleClose}) {
    const classes = useStyle();
    return <Modal className={classes.otherFont} open={open} handleClose={handleClose} title={'Information über Development Seite'}>
        <DialogContent className={classes.otherFont}>
            Dies ist eine Development-Seite alle Inhalte dieser Seite sind rein zur entwicklungszwecken hergestellt worden und spiegeln in keinster weise
            die orginale Seite <a href={'https://worldwhisky.at'}>Worldwhisky</a> wieder. Weiters werden Hier daten anderst verarbeitet und gespeichert als auf der
            Orginalseite.
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Verstanden
            </Button>
        </DialogActions>
        </Modal>
}

export default Disclaimer;
