import React from 'react';
import Button from '@material-ui/core/Button';
import {DatePicker} from "@material-ui/pickers";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DateTime} from "luxon";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}))

export function AgeInputDialog ({open, submitBirthdate}) {
    const classes = useStyles();
    const [birthdate, setBirthdate] = React.useState(DateTime.local())

    const onHandleClose = () => {
        submitBirthdate(birthdate);
    }

    return <Dialog className={classes.otherFont} open={open} disableEscapeKeyDown={true} disableScrollLock={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Bestätigung des Alters</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte geben sie ihr Geburtsdatum ein.
                    </DialogContentText>
                    <DatePicker label={'Gebursdatum'} fullWidth  inputVariant={'outlined'} format="dd.MM.yyyy" value={birthdate} openTo={'year'}
                                helperText={"Geburtsdatum für Rechtmäßigkeit des Kaufes"}
                                onChange={setBirthdate}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHandleClose} color="primary" variant={"contained"}>
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
}
