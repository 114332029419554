import React, {useEffect, useRef, useState} from "react";
import {DropZone, Modal} from "../index";
import {
    Avatar, Button, ButtonGroup, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography
} from "@material-ui/core";
import {storage} from "../../common/firebase";
import {makeStyles} from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DbImage from "../dbImage";
import {faCheckCircle, faCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faExclamationTriangle, faRetweet, faTrash, faUnlink} from "@fortawesome/free-solid-svg-icons";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    dropZoneStyle: {
        width: '100%',
        height: 200,
        textAlign: 'center',
        margin: '0.5rem',
        padding: '0.5em',
        fontSize: '2em',
        transition: 'all ease 500ms',
        display: 'inline-flex',
        alignItems: 'center',
        cursor: "pointer",
        justifyContent: 'center',
        flexWrap: 'wrap',
        border: 'dashed 2px #999',
        borderRadius: '0.5rem',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        },
        '&:hover': {
            color: '#333',
            borderColor: '#1e88e5'
        }
    },
    dropZoneStyleOnDrag: {
        border: 'dashed 2px black',
        borderRadius: '0.5rem',
    }
}));

export const ImagePickerModal = inject('store')(observer(({
                                                              store,
                                                              open, subPath, oldImages, deleteOldImg, isSet,
                                                              selectTypeOne, selectTypeOneCallback, selectedTypeOne,
                                                              selectTypeTwo, selectTypeTwoCallback, selectedTypeTwo,
                                                              selectTypeThree, selectTypeThreeCallback, selectedTypeThree,
                                                              handleClose
                                                          }) => {
    const classes = useStyles();
    const [uploads, setUploads] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const newUploads = useRef([]);
    const isFetchRunning = useRef(false);
    const root = storage.ref(`images/${subPath}/`);
    const timerRef = useRef();

    useEffect(() => {
        setLoading(true);
        if (uploads.length === 0 || open) {
            fetchData();
        }
    }, [uploads, open])

    const fetchData = () => {
        if (isFetchRunning.current) return
        isFetchRunning.current = true;
        root.listAll().then(res => {
            if (res.items.length === 0) {
                setLoading(false);
                isFetchRunning.current = false;
            }
            res.items.forEach((itemRef, index, array) => {
                itemRef.getMetadata().then(resM => {
                    setImages(imagesCall => {
                        const index = imagesCall.findIndex(i => i.name === itemRef.name);
                        if (index === -1) {
                            itemRef.size = resM.size;
                            imagesCall.push(itemRef);
                        }
                        if (imagesCall.length === res.items.length) console.log(imagesCall);
                        return [...imagesCall];
                    })
                    if (index === array.length - 1) {
                        const cacheUploads = [...newUploads.current];
                        if (newUploads.current.length > 0) {
                            for (const name of newUploads.current) {
                                if (res.items.filter(img => img.name.includes(name)).length > 1) {
                                    const index = cacheUploads.indexOf(name);
                                    if (index > -1) cacheUploads.splice(index, 1);
                                } else {
                                    if (timerRef.current) clearTimeout(timerRef.current);
                                    timerRef.current = setTimeout(fetchData, 1500);
                                    break;
                                }
                            }
                            newUploads.current = cacheUploads;
                            if (cacheUploads.length === 0) setLoading(false);
                        } else {
                            setLoading(false);
                        }
                        isFetchRunning.current = false;
                    }
                })
            });
        }).catch(e => {
            setLoading(false);
            isFetchRunning.current = false;
            store.snackBarHandle.addMessages('Beim laden der Bilder ist ein Fehler aufgetretet.')
            console.error('Fehler beim Erhalten aller Bilder für ' + subPath, e);
        });
    }

    const onHandleClose = (event) => {
        handleClose(event);
    }

    const handleToggleItem = (nameList, imageName, cbFunction) => event => {
        if (nameList === null || nameList === undefined) return cbFunction([imageName]);
        const list = [...nameList];
        const index = list?.indexOf(imageName);

        if (index > -1) {
            list.splice(index, 1);
            return cbFunction(list);
        }

        list.push(imageName)
        return cbFunction(list);
    }

    const handleAcceptedFilesImages = acceptedFiles => {
        acceptedFiles.forEach(uploadImageProcess);
    };

    const uploadImageProcess = file => {
        const uploadRef = storage.ref(`images/${subPath}/${file.name}`)
        const uploadTask = uploadRef.put(file);
        uploadTask.on(
            "state_changed",
            snapshot => {
                const index = uploads.findIndex(u => u.name = file.name);
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                if (index > -1) {
                    uploads[index].progress = progress;
                    setUploads([...uploads]);
                } else {
                    const element = {name: file.name, progress: progress};
                    setUploads(l => [...l, element]);
                }
            },
            error => console.error(error),
            () => {
                const fileNameWithoutType = file.name.substring(0, file.name.lastIndexOf('.'));
                if (newUploads.current.indexOf(file.name) === -1) newUploads.current.push(fileNameWithoutType);
                const index = uploads.findIndex(u => u.name = file.name);
                if (index > -1) uploads.splice(index, 1);
                setUploads([...uploads]);
            })
    }

    const getImageSizeAsString = (size) => {
        if (size > 1e9) return `${(size / 1e9).toFixed(2)} GB`;
        if (size > 1e6) return `${(size / 1e6).toFixed(2)} MB`;
        if (size > 1e3) return `${(size / 1e3).toFixed(2)} KB`;
        return `${size} B`;
    }

    const isImageInList = (nameList, imageName) => {
        return nameList?.indexOf(imageName) > -1;
    }

    const reloadImg = (url) => (event) => {
        if (!urlIncludes(url)) return store.snackBarHandle.addMessages('Bild ist leider auf einer andern Domain abgelegt bitte Admin Kontaktieren');
        const name = url.match(/.*\/.*%2F(.*)\?/)[1];
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
            blob.name = name;
            uploadImageProcess(blob);
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const downloadImg = (url) => (event) => {
        if (!urlIncludes(url)) return store.snackBarHandle.addMessages('Bild ist leider auf einer andern Domain abgelegt bitte Admin Kontaktieren');
        const name = url.match(/.*\/.*%2F(.*)\?/)[1];
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
            blob.name = name;
            downloadProcessImg(blob, name);
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const downloadProcessImg = (blob, name) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    const downloadImgByName = (name) => (event) => {

        root.child(name).getDownloadURL()
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
                downloadProcessImg(blob, name);
            };
            xhr.open('GET', url);
            xhr.send();
        })
        .catch((error) => {
            console.log('ERROR:::', error);
        });
    }

    const urlIncludes = (url) => {
        const projectId = process.env.REACT_APP_STOREAGE_BUCKET;
        if (!projectId) return false;
        return url.includes(projectId);
    }

    const deleteImgByUrl = (url) => async (event) => {
        try {
            if (!isSet && !url.includes(subPath)) await storage.refFromURL(url).delete();
        } catch (e) {
            console.error(e);
            store.snackBarHandle.addMessages('Fehler beim löschen vom Bild')
        }
        deleteOldImg(url);
    }

    const deleteImgByName = (name) => async (event) => {
        try {
            await root.child(name).delete();
            const index = images.findIndex(i => i.name === name);
            if (index > -1) {
                images.splice(index, 1);
                setImages([...images]);
            }
        } catch (e) {
            console.error(e);
            store.snackBarHandle.addMessages('Beim Löschen ist ein Fehler aufgetreten');
        }

        if (!!selectedTypeOne) unlink(name, selectedTypeOne, selectTypeOneCallback)();
        if (!!selectedTypeTwo) unlink(name, selectedTypeTwo, selectTypeTwoCallback)();
        if (!!selectedTypeThree) unlink(name, selectedTypeThree, selectTypeThreeCallback)();
    }

    const unlink = (name, nameList, cbFunction) => event => {
        const list = [...nameList];
        const index = list?.indexOf(name);
        if (index > -1) {
            list.splice(index, 1);
            cbFunction(list);
        }
    }

    return <Modal title={'Bilder verwalten'} open={open} handleClose={onHandleClose} size={'md'}
                  actions={<ButtonGroup><Button color={"primary"} variant={"outlined"} onClick={handleClose}>Schließen</Button></ButtonGroup>}>
        <TableContainer className={classes.otherFont}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant={"head"}>Position</TableCell>
                        <TableCell variant={"head"}>Preview</TableCell>
                        <TableCell variant={"head"}>Name</TableCell>
                        <TableCell variant={"head"}>Size</TableCell>
                        {selectTypeOne && <TableCell variant={"head"}>{selectTypeOne}</TableCell>}
                        {selectTypeTwo && <TableCell variant={"head"}>{selectTypeTwo}</TableCell>}
                        {selectTypeThree && <TableCell variant={"head"}>{selectTypeThree}</TableCell>}
                        <TableCell variant={"head"}>Löschen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <MissingImagesRows list={selectedTypeOne} images={images} unlink={unlink} cbFunction={selectTypeOneCallback} colSpan={1}/>
                    <MissingImagesRows list={selectedTypeTwo} images={images} unlink={unlink} cbFunction={selectTypeTwoCallback} colSpan={2}/>
                    <MissingImagesRows list={selectedTypeThree} images={images} unlink={unlink} cbFunction={selectTypeThreeCallback} colSpan={3}/>
                    {images.length === 0 &&
                    <TableRow>
                        <TableCell colSpan={10}>
                            <Typography variant={"h6"} style={{width: '100%', textAlign: 'center'}}>
                                Noch keine Bilder vorhanden
                            </Typography>
                        </TableCell>
                    </TableRow>}
                    {images.slice().sort((a, b) => {
                        const indexA = selectedTypeOne.indexOf(a.name);
                        const indexB = selectedTypeOne.indexOf(b.name);
                        if (indexA === -1) return 1;
                        if (indexB === -1) return -1;
                        return indexA - indexB;
                    }).map((image, i) => (
                        <TableRow key={i}>
                            <TableCell variant={"body"}>{i + 1}</TableCell>
                            <TableCell variant={"body"}>
                                <Tooltip title={'Zum Herunterladen hier drücken'}>
                                    <Avatar style={{width: '5em', height: '5em', cursor: 'pointer'}} variant={"rounded"}
                                            onClick={downloadImgByName(image.name)}>
                                        <DbImage src={['images', subPath, image.name]} alt={image.name} style={{width: '100%', height: '100%'}}/>
                                    </Avatar>
                                </Tooltip>
                            </TableCell>
                            <TableCell variant={"body"}>{image.name}</TableCell>
                            <TableCell variant={"body"}>{getImageSizeAsString(image.size)}</TableCell>
                            {selectTypeOne && <TableCell variant={"body"}>
                                <Tooltip title={isImageInList(selectedTypeOne, image.name) ? 'Abwählen' : 'Auswählen'}>
                                    <IconButton onClick={handleToggleItem(selectedTypeOne, image.name, selectTypeOneCallback)}>
                                        <FontAwesomeIcon icon={isImageInList(selectedTypeOne, image.name) ? faCheckCircle : faCircle}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>}
                            {selectTypeTwo && <TableCell variant={"body"}>
                                <Tooltip title={isImageInList(selectedTypeTwo, image.name) ? 'Abwählen' : 'Auswählen'}>
                                    <IconButton onClick={handleToggleItem(selectedTypeTwo, image.name, selectTypeTwoCallback)}>
                                        <FontAwesomeIcon icon={isImageInList(selectedTypeTwo, image.name) ? faCheckCircle : faCircle}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>}
                            {selectTypeThree && <TableCell variant={"body"}>
                                <Tooltip title={isImageInList(selectedTypeThree, image.name) ? 'Abwählen' : 'Auswählen'}>
                                    <IconButton onClick={handleToggleItem(selectedTypeThree, image.name, selectTypeThreeCallback)}>
                                        <FontAwesomeIcon icon={isImageInList(selectedTypeThree, image.name) ? faCheckCircle : faCircle}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>}
                            <TableCell variant={"body"}>
                                <Tooltip title={'Löschen'}>
                                    <IconButton onClick={deleteImgByName(image.name)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                    <OldImagesRows oldImages={oldImages} urlIncludes={urlIncludes} deleteImgByUrl={deleteImgByUrl} downloadImg={downloadImg}
                                   reloadImg={reloadImg}/>
                    {loading && <TableRow>
                        <TableCell colSpan={7}>
                            <LinearProgress/>
                        </TableCell>
                    </TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={7}>
                            {uploads.length === 0 && <DropZone handleAcceptedFiles={handleAcceptedFilesImages}
                                                               dropText={'Bilder hier einfach absetzen'}
                                                               startText={'Um Bilder hinzuzufügen, einfach hier Klicken oder die Bilder auf das Feld ziehen.'}
                                                               className={classes.dropZoneStyle}
                                                               dragClassName={classes.dropZoneStyleOnDrag}/>}
                            {uploads.map((upload, key) => (
                                <div key={key}>
                                    {upload.name}
                                    <LinearProgress variant="determinate" value={upload.progress}/>
                                </div>
                            ))}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>

    </Modal>

}))


const MissingImagesRows = ({list, images, unlink, cbFunction, colSpan}) => {
    return !!list ? list.map((name, i) => (
        images.slice().findIndex(i => i.name === name) === -1 && <TableRow key={i}>
            <TableCell>
                <Tooltip title={'Kein Bild Gefunden'}>
                    <span>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x"/>
                    </span>
                </Tooltip>
            </TableCell>
            <TableCell variant={"body"}>
                <Avatar style={{width: '5em', height: '5em'}} variant={"circle"}>
                    <DbImage alt={name} style={{width: '100%', height: '100%'}}/>
                </Avatar>
            </TableCell>
            <TableCell>
                {name}
            </TableCell>
            <TableCell colSpan={colSpan}/>
            <TableCell colSpan={3}>
                <Tooltip title={'Kaputen Link entfernen'}>
                    <IconButton onClick={unlink(name, list, cbFunction, true)}>
                        <FontAwesomeIcon icon={faUnlink}/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )) : null;
}

const OldImagesRows = ({oldImages, urlIncludes, downloadImg, deleteImgByUrl, reloadImg}) => {
    return !!oldImages && oldImages.length > 0 ? <>
        <TableRow>
            <TableCell variant={"head"}>Info</TableCell>
            <TableCell variant={"head"}>Bild</TableCell>
            <TableCell variant={"head"} colSpan={2}>Hinterlegte URL</TableCell>
            <TableCell variant={"head"}>Löschen</TableCell>
            <TableCell variant={"head"}>Aktualisieren</TableCell>
            <TableCell variant={"head"}/>
        </TableRow>
        {oldImages.map((url, i) => (
            <TableRow key={i}>
                <TableCell>
                    {
                        !urlIncludes(url) &&
                        <Tooltip title={'Bild ist auf einer andern Domain abgelegt'}>
                                        <span>
                                            <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'}/>
                                        </span>
                        </Tooltip>
                    }
                    <Tooltip title={"Altes Bild"}>
                                    <span>
                                        <FontAwesomeIcon icon={faBan} size={'2x'}/>
                                    </span>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={'Zum Herunterladen hier drücken'}>
                        <Avatar src={url} alt={'old img'} variant={"square"} style={{cursor: 'pointer'}} onClick={downloadImg(url)}/>
                    </Tooltip>
                </TableCell>
                <TableCell colSpan={2}>
                    {url}
                </TableCell>
                <TableCell>
                    <Tooltip title={'Bild Löschen'}>
                        <IconButton onClick={deleteImgByUrl(url, i)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={'Konvertieren'}>
                        <IconButton onClick={reloadImg(url)}>
                            <FontAwesomeIcon icon={faRetweet}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell colSpan={3}/>
            </TableRow>
        ))}
    </> : null;
}
