import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {auth, database} from "common/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {inject, observer} from "mobx-react";

import {Badge, Box, Button, Drawer, Hidden, InputAdornment, List, ListItem, ListItemText, TextField} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars, faChevronLeft, faChevronRight, faEyeSlash, faGlassWhiskey, faSearch, faShoppingBasket, faSignOutAlt, faUserCircle, faUserShield
} from "@fortawesome/free-solid-svg-icons";
import {faEye, faThumbsUp} from "@fortawesome/free-regular-svg-icons";

import logo from 'common/res/logoWW_1.png';

import {useStyles} from "./styling";
import {Modal, RegisterModal} from "components";
import {makeCoupon} from "common/statelessFunctions";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";


const Header = inject('store')(observer(({store}) => {
    const {
        fixedHeader, scrollingHeader, headerStyling, logoStyle, wrapperNavigationStyle, wrapperNewsletterStyle, wrapperSearchStyle,
        wrapperTopSearchStyle, wrapperBottomSearchStyle, wrapperLogoStyle, wrapperTopHeaderStyling,
        iconTopSearchStyle, linkElementStyle, chevronLeft, chevronRight, wrapperScrollingStyle,
        wrapperNavigationScrollStyle, btnNewsletterStyle, wrapperIconTop, iconTopSearchLabel,
        searchTextFieldStyle, linkElementActiveStyle, wrapperNewsletterPadding, drawer, drawerPaper, btnRow, iconBtnStyle,
        iconTopSearchStyleDrawer, otherFont, textField, boxStyle,
    } = useStyles();
    const location = useLocation();
    const history = useHistory();
    const topHeaderRef = useRef(null);
    const bottomHeaderRef = useRef(null);
    const [headerStylingClasses, setHeaderStylingClasses] = useState(`${headerStyling} ${scrollingHeader}`);
    const [headerStyleObject, setHeaderStyleObject] = useState({});
    const [scrollLeftElement, setScrollLeftElement] = useState(0);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
    const [newsletterMail, setNewsletterMail] = useState('');
    const [newsletterFirstName, setNewsletterFirstName] = useState('');
    const [newsletterLastName, setNewsletterLastName] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const refMouseHover = useRef(null);

    const [mail, setMail] = useState('');
    const [pw, setPw] = useState('');
    const [pwDisplay, setPwDisplay] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const [onReset, setOnReset] = useState(false);

    const signIn = () => {
        setLoading(true);
        setError(null);
        auth.signInWithEmailAndPassword(mail, pw)
        .then((user) => {
            setTimeout(() => {
                setLoading(false);
                toggleLogin();
            }, 1000);
        })
        .catch((error) => {
            setLoading(false);
            setError(error.code + ' : ' + error.message);
        });
    }

    useEffect(() => {
        setIsOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        const onScroll = e => {
            const {scrollTop} = e.target.documentElement;
            const {clientHeight} = topHeaderRef.current;
            if (scrollTop >= clientHeight + 2) {
                setHeaderStylingClasses(`${fixedHeader} ${headerStyling}`);
                setHeaderStyleObject({top: -(clientHeight + 2)});
            } else {
                setHeaderStylingClasses(`${headerStyling} ${scrollingHeader}`);
                setHeaderStyleObject({});
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
            clearInterval(refMouseHover.current);
        }
    }, [fixedHeader, scrollingHeader, headerStyling]);

    useEffect(() => {
        if (!!store.userStore.currentUser) {
            database.ref('userdata').child(store.userStore.currentUser.uid).child('newsletter').once('value', snap => {
                if (snap.exists()) setHasSubscription(snap.val());
            })
        }
    }, [store.userStore.currentUser]);

    const isLeft = () => {
        if (!!bottomHeaderRef.current) {
            return scrollLeftElement === 0;
        } else {
            return true;
        }
    };

    const isRight = () => {
        if (!!bottomHeaderRef.current) {
            return scrollLeftElement === bottomHeaderRef.current.scrollWidth - bottomHeaderRef.current.clientWidth;
        } else {
            return false;
        }
    };

    const scrollLeft = e => {
        if (!!bottomHeaderRef.current) bottomHeaderRef.current.scrollLeft -= 70;
        if (bottomHeaderRef.current.scrollLeft !== scrollLeftElement) setScrollLeftElement(bottomHeaderRef.current.scrollLeft);
    };

    const scrollRight = e => {
        if (!!bottomHeaderRef.current) bottomHeaderRef.current.scrollLeft += 70;
        if (bottomHeaderRef.current.scrollLeft !== scrollLeftElement) setScrollLeftElement(bottomHeaderRef.current.scrollLeft);
    };

    const mountScroll = direction => e => {
        const scrolling = (direction) => () => {
            const {scrollLeft, scrollWidth, clientWidth} = bottomHeaderRef.current;
            if (!!bottomHeaderRef.current) bottomHeaderRef.current.scrollLeft += direction ? 10 : -10;
            if (scrollLeft !== scrollLeftElement) setScrollLeftElement(bottomHeaderRef.current.scrollLeft);
            if (bottomHeaderRef.current.scrollLeft === scrollWidth - clientWidth || bottomHeaderRef.current.scrollLeft === 0) {
                unMountScroll();
            }
        };
        refMouseHover.current = setInterval(scrolling(direction), 50);
    };

    const unMountScroll = e => {
        clearInterval(refMouseHover.current);
    };

    const toggleLogin = e => setIsLoginOpen(!isLoginOpen);
    const toggleNewsletter = e => setIsNewsletterOpen(!isNewsletterOpen);

    const signOut = e => auth.signOut();

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    const linkTo = url => e => history.push(url);

    const addToNewsletter = async () => {
        const snap = await database.ref('newsletterMailingList').once('value');
        if (snap.exists()) {
            const data = snap.val();
            const mailingList = Object.keys(data).map(k => data[k].mail);
            if (mailingList.indexOf(newsletterMail) === -1) {
                setNewsletterDatabase({
                    firstName: newsletterFirstName,
                    lastName: newsletterLastName,
                    mail: newsletterMail,
                    timestamp: Date.now(),
                })
            } else {
                store.snackBarHandle.addMessages(`${newsletterMail} breits eingeschrieben`);
            }
        } else {
            setNewsletterDatabase({
                firstName: newsletterFirstName,
                lastName: newsletterLastName,
                mail: newsletterMail,
                timestamp: Date.now(),
            })
        }
        setIsNewsletterOpen(false);
        setHasSubscription(true);
    };

    const setNewsletterDatabase = (newsletterData) => {
        store.snackBarHandle.addMessages(`${newsletterMail} zum Newsletter Angemeldet`);
        createCoupon();
        if (!!store.userStore.currentUser) {
            newsletterData.userId = store.userStore.currentUser.uid;
            database.ref('newsletterMailingList').push(newsletterData);
            database.ref('userdata').child(store.userStore.currentUser.uid).child('newsletter').set(true);
        } else {
            database.ref('newsletterMailingList').push(newsletterData);
        }
    };

    const createCoupon = () => {
        const code = makeCoupon(8);
        const used = false;
        const description = `Newsletter Gutschein für ${newsletterMail}`;
        const timestamp = new Date().getTime();
        const coupon = {
            code,
            used,
            description,
            timestamp,
            abs: 5,
            condition: 50,
            conditionDescription: 'Mindest einkaufswert von 50 €',
            to: newsletterMail,
            for: !!store.userStore.currentUser ? store.userStore.currentUser.uid : newsletterMail,
        };
        database.ref('coupon').once('value', snap => {
            if (snap.exists()) {
                const couponList = Object.keys(snap.val());
                if (couponList.map(c => c.code).indexOf(code.toUpperCase()) === -1) {
                    database.ref(`coupons/${code}`).set(coupon);
                } else {
                    createCoupon()
                }
            } else {
                database.ref(`coupons/${code}`).set(coupon);
            }
        });

    };

    const startSearch = (e) => {
        history.push({
            pathname: '/whisky/search',
            search: `?searchString=${searchText}`
        });
        e.preventDefault();
    };

    const handleToggleRegister = (e) => {
        if (isLoginOpen) setIsLoginOpen(false);
        setIsRegisterOpen(!isRegisterOpen);
    };

    const resetPasswordToggle = () => {
        setResetPasswordOpen(bool => !bool);
    }

    const resetPassword = async () => {
        setOnReset(true);
        try {
            if (mail === "") return store.snackBarHandle.addMessages("Die Email Adresse darf nicht leer sein!")
            await auth.sendPasswordResetEmail(mail);
            setResetPasswordOpen(bool => !bool);
            store.snackBarHandle.addMessages("E-Mail zurücksetzen erfolgreich gestartet, mail kommt demnächst");
        } catch (e) {
            console.log(e)
            store.snackBarHandle.addMessages("Fehler beim Zurücksetzen, ist ihre E-mail valide? Besitzen sie überhaupt einen Account mit dieser Mailadresse ?");
        } finally {
            setOnReset(false);
        }

    }
    return <header className={headerStylingClasses} style={headerStyleObject}>
        <div ref={topHeaderRef} className={wrapperTopHeaderStyling}>
            <Hidden smDown>
                <div className={[wrapperNewsletterStyle, !hasSubscription ? wrapperNewsletterPadding : ''].join(' ')}>
                    {!hasSubscription &&
                    <Button
                        color={"primary"}
                        variant={'contained'}
                        className={btnNewsletterStyle + ' ' + otherFont}
                        onClick={toggleNewsletter}>
                        Newsletter Abonnieren <br/> 5€ Kassieren
                    </Button>
                    }
                </div>
            </Hidden>
            <div className={wrapperLogoStyle}>
                <Link to={'/'}><img className={logoStyle} src={logo} alt={'worldwhisky logo'}/></Link>
            </div>
            <Hidden smDown>
                <div className={wrapperSearchStyle}>
                    <div className={wrapperTopSearchStyle}>
                        {store.userStore.isSignedIn && store.userStore.currentRole >= 0b1000 &&
                        <Box className={wrapperIconTop} component={Link} to={'/admin/'}>
                            <FontAwesomeIcon className={iconTopSearchStyle} icon={faUserShield}/>
                            <span className={iconTopSearchLabel}>Admin</span>
                        </Box>}
                        {store.userStore.isSignedIn && !(store.userStore.currentUser && store.userStore.currentUser.isAnonymous) &&
                        <div className={wrapperIconTop} onClick={linkTo('/favoriten/')}>
                            <Badge color="secondary" badgeContent={store.wishListStore.wishList.length} invisible={store.wishListStore.wishList.length === 0}>
                                <FontAwesomeIcon className={iconTopSearchStyle} icon={faThumbsUp}/>
                            </Badge>
                            <span className={iconTopSearchLabel}>Favoriten</span>
                        </div>}
                        <div className={wrapperIconTop}>
                            {store.userStore.isSignedIn ?
                                <FontAwesomeIcon className={iconTopSearchStyle} icon={faSignOutAlt} onClick={signOut}/> :
                                <FontAwesomeIcon className={iconTopSearchStyle} icon={faUserCircle} onClick={toggleLogin}/>}
                            {store.userStore.isSignedIn ?
                                <span className={iconTopSearchLabel}>Abmelden</span> :
                                <span className={iconTopSearchLabel}>Anmelden</span>}
                        </div>

                    </div>
                    <div className={wrapperBottomSearchStyle}>
                        <form noValidate autoComplete="on" onSubmit={startSearch}>
                            <TextField
                                id="whisky-search-input"
                                type="text"
                                placeholder={'Suche'}
                                variant="outlined"
                                className={searchTextFieldStyle}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" onClick={startSearch} style={{cursor: 'pointer'}}>
                                            <FontAwesomeIcon icon={faSearch}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </form>
                    </div>
                </div>
            </Hidden>
        </div>
        <div className={wrapperNavigationScrollStyle}>
            <Hidden smDown>
                <div ref={bottomHeaderRef} className={wrapperNavigationStyle}>
                    <Link className={[linkElementStyle, isActive('/whisky/') ? linkElementActiveStyle : ''].join(' ')} to={'/whisky/'}>Whisk(e)y-Shop</Link>
                    <Link className={[linkElementStyle, isActive('/whisky/sample/') ? linkElementActiveStyle : ''].join(' ')}
                          to={'/whisky/sample/'}>Whisk(e)ysamples</Link>
                    <Link className={[linkElementStyle, isActive('/relatives/') ? linkElementActiveStyle : ''].join(' ')}
                          to={'/relatives/'}>Whisk(e)yverwandte</Link>
                    <Link className={[linkElementStyle, isActive('/whiskyabo/') ? linkElementActiveStyle : ''].join(' ')} to={'/whiskyabo/'}>Whisk(e)yAbo</Link>
                    <Link className={[linkElementStyle, isActive('/events/') ? linkElementActiveStyle : ''].join(' ')} to={'/events/'}>Events</Link>
                    <Link className={[linkElementStyle, isActive('/tastings/') ? linkElementActiveStyle : ''].join(' ')} to={'/tastings/'}>Tasting-Sets</Link>
                    <Link className={[linkElementStyle, isActive('/pairing/') ? linkElementActiveStyle : ''].join(' ')} to={'/pairing/'}>Schokolade</Link>
                    <Link className={[linkElementStyle, isActive('/whisky_geschenke/') ? linkElementActiveStyle : ''].join(' ')} to={'/whisky_geschenke/'}>Geschenke</Link>
                    <Link className={[linkElementStyle, isActive('/warenkorb/') ? linkElementActiveStyle : ''].join(' ')} to={'/warenkorb/'}>
                        <Badge color="secondary" badgeContent={store.cartStore.cart.length} invisible={store.cartStore.cart.length === 0}>
                            <FontAwesomeIcon style={{marginRight: '0.5em', fontSize: '1.25em'}} icon={faShoppingBasket}/>
                            <span>Warenkorb</span>
                        </Badge>
                    </Link>
                    {/*<Link className={[linkElementStyle, isActive('/whisky/specials/') ? linkElementActiveStyle : ''].join(' ')} to={'/whisky/specials/'}>Spezialitäten</Link>*/}
                    {/*<Link className={[linkElementStyle, isActive('/news/') ? linkElementActiveStyle : ''].join(' ')} to={'/news/'}>News</Link>*/}
                    {/*<Link className={[linkElementStyle, isActive('/whisky/accessories/') ? linkElementActiveStyle : ''].join(' ')} to={'/whisky/accessories/'}>Zubehör</Link>*/}
                    {/*<Link className={[linkElementStyle, isActive('/trips/') ? linkElementActiveStyle : ''].join(' ')} to={'/trips/'}>Reisen</Link>*/}
                </div>
                <div className={wrapperScrollingStyle}>
                    {!isLeft() && <span className={chevronLeft} onClick={scrollLeft} onMouseEnter={mountScroll(false)} onMouseLeave={unMountScroll}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </span>}
                    {!isRight() && <span className={chevronRight} onClick={scrollRight} onMouseEnter={mountScroll(true)} onMouseLeave={unMountScroll}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </span>}
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={btnRow}>
                    <IconButton className={iconBtnStyle} onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faBars} />
                    </IconButton>
                    <IconButton className={iconBtnStyle} component={Link} to={'/warenkorb/'}>
                        <Badge color="secondary" badgeContent={store.cartStore.cart.length} invisible={store.cartStore.cart.length === 0}>
                            <FontAwesomeIcon icon={faShoppingBasket}/>
                        </Badge>
                    </IconButton>
                </div>
            </Hidden>
        </div>

        <Drawer
            className={drawer}
            variant="persistent"
            anchor="left"
            open={isOpen}
            classes={{
                paper: drawerPaper,
            }}
        >
            <IconButton className={iconBtnStyle} onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </IconButton>
            {!hasSubscription &&
            <ListItem>
                <Button
                    color={"primary"}
                    variant={'contained'}
                    className={btnNewsletterStyle}
                    onClick={toggleNewsletter}>
                    Newsletter Abonieren <br/> 5€ Kassieren
                </Button>
            </ListItem>
            }
            <Divider/>
            <form noValidate autoComplete="on" onSubmit={startSearch}>
                <TextField
                    id="whisky-search-input-drawer"
                    type="text"
                    placeholder={'Suche'}
                    variant="outlined"
                    className={searchTextFieldStyle}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" onClick={startSearch} style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
            <Divider/>
            <List>
                {store.userStore.isSignedIn && store.userStore.currentRole >= 0b1000 &&
                <ListItem button component={Link} to="/admin/"><ListItemText primary={'Admin'}/></ListItem>
                }
                <ListItem button component={Link} to="/whisky/"><ListItemText primary={'Whisk(e)y-Shop'}/></ListItem>
                <ListItem button component={Link} to="/whisky/sample/"><ListItemText primary={'Whisk(e)ysamples'}/></ListItem>
                <ListItem button component={Link} to="/relatives/"><ListItemText primary={'Whisk(e)yverwandte'}/></ListItem>
                <ListItem button component={Link} to="/whiskyabo/"><ListItemText primary={'Whisk(e)yAbo'}/></ListItem>
                <ListItem button component={Link} to="/pairing/"><ListItemText primary={'Schokolade'}/></ListItem>
                <ListItem button component={Link} to="/events/"><ListItemText primary={'Events'}/></ListItem>
                <ListItem button component={Link} to="/tastings/"><ListItemText primary={'Tasting-Sets'}/></ListItem>
                <ListItem button component={Link} to="/whisky_geschenke/"><ListItemText primary={'Geschenke'}/></ListItem>
            </List>
            <Divider/>
            <List>
                {store.userStore.isSignedIn && <ListItem button component={Link} to="/favoriten/">
                    <ListItemIcon className={iconBtnStyle}>
                        <Badge color="secondary" badgeContent={store.wishListStore.wishList.length} invisible={store.wishListStore.wishList.length === 0}>
                            <FontAwesomeIcon className={iconTopSearchStyleDrawer} icon={faThumbsUp}/>
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={'Favoriten'}/>
                </ListItem>}
                <ListItem button component={Link} to="/warenkorb/">
                    <ListItemIcon className={iconBtnStyle}>
                        <Badge color="secondary" badgeContent={store.cartStore.cart.length} invisible={store.cartStore.cart.length === 0}>
                            <FontAwesomeIcon className={iconTopSearchStyleDrawer} icon={faShoppingBasket}/>
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={'Warenkorb'}/>
                </ListItem>
                {store.userStore.isSignedIn ?
                    <ListItem button onClick={signOut}>
                        <ListItemIcon className={iconBtnStyle}><FontAwesomeIcon className={iconTopSearchStyleDrawer} icon={faSignOutAlt}/></ListItemIcon>
                        <ListItemText primary={'Abmelden'}/>
                    </ListItem> :
                    <ListItem button onClick={toggleLogin}>
                        <ListItemIcon className={iconBtnStyle}><FontAwesomeIcon className={iconTopSearchStyleDrawer} icon={faUserCircle}/></ListItemIcon>
                        <ListItemText primary={'Anmelden'}/>
                    </ListItem>
                }
            </List>
        </Drawer>

        <Modal title={"Anmelden / Registrieren"} open={isLoginOpen} handleClose={toggleLogin}>
            <Box className={boxStyle}>
                <TextField
                    className={otherFont + ' ' + textField}
                    variant={'outlined'}
                    label={'E-Mail Adresse'}
                    color={'primary'}
                    value={mail}
                    disabled={resetPasswordOpen}
                    type={'mail'}
                    fullWidth
                    onChange={(e) => setMail(e.target.value)}/>
                <TextField
                    className={otherFont + ' ' + textField}
                    variant={'outlined'}
                    label={'Passwort'}
                    color={'primary'}
                    value={pw}
                    disabled={resetPasswordOpen}
                    fullWidth
                    error={!!error}
                    helperText={error}
                    type={pwDisplay ? 'text' : 'password'}
                    onChange={(e) => setPw(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" onClick={() => setPwDisplay(!pwDisplay)} style={{cursor: 'pointer'}}>
                                {!pwDisplay && <FontAwesomeIcon icon={faEye}/>}
                                {pwDisplay && <FontAwesomeIcon icon={faEyeSlash}/>}
                            </InputAdornment>
                        ),
                    }}/>
                <Button onClick={signIn} className={otherFont}
                        style={{marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                        variant={'contained'} color={'primary'} disabled={loading || resetPasswordOpen}>
                    {loading && <FontAwesomeIcon icon={faGlassWhiskey} spin/>}
                    Anmelden
                </Button>
            </ Box>
            <StyledFirebaseAuth className={otherFont} uiConfig={{
                ...store.userStore.getUiConfig(), ...{
                    callbacks: {
                        signInSuccessWithAuthResult: (currentUser, credential, redirectUrl) => {
                            toggleLogin();
                            return false;
                        }
                    }
                },
            }} firebaseAuth={auth}/>
            {!resetPasswordOpen &&
            <Box className={boxStyle}>
                <Button onClick={resetPasswordToggle} className={otherFont}
                        style={{marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                        variant={'text'} color={'secondary'} disabled={loading}>
                    {loading && <FontAwesomeIcon icon={faGlassWhiskey} spin/>}
                    Passwort vergessen ?
                </Button>
            </Box>
            }
            {resetPasswordOpen && <Box className={boxStyle}>
                <TextField
                    className={otherFont + ' ' + textField}
                    variant={'outlined'}
                    label={'E-Mail Adresse'}
                    helperText={"E-mail Adresse für den Account zum zurücksetzen"}
                    color={'primary'}
                    value={mail}
                    type={'mail'}
                    fullWidth
                    disabled={onReset}
                    onChange={(e) => setMail(e.target.value)}/>
                <Button onClick={resetPassword} className={otherFont}
                        style={{marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                        variant={'contained'} color={'primary'} disabled={onReset}>
                    Passwort zurücksetzen
                </Button>
                <Button onClick={resetPasswordToggle} className={otherFont}
                        style={{marginLeft: '0.5em', marginTop: '0.5em', maxWidth: 220, width: '100%'}}
                        variant={'contained'} color={'primary'} disabled={onReset}>
                    Abbrechen
                </Button>
            </Box>}
            <Grid container spacing={1} alignItems={'center'}>
                <Grid item xs={5}>
                    <hr/>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'center'}}>
                    Oder
                </Grid>
                <Grid item xs={5}>
                    <hr/>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', paddingBottom: '1.5em'}}>
                    <Button className={otherFont} variant={'contained'} color={'primary'} onClick={handleToggleRegister}> Registrieren </Button>
                </Grid>
            </Grid>

        </Modal>
        <RegisterModal isOpen={isRegisterOpen} handleClose={() => setIsRegisterOpen(false)}/>
        <Modal title={"Newsletter Abo"} open={isNewsletterOpen} handleClose={toggleNewsletter}>
            <p className={otherFont}>
                Wenn du immer auf dem neuesten Stand bleiben willst was Whisk(e)y und unsere Produkte angeht, abonniere doch unseren
                Newsletter.
                Nicht nur das dieser Gratis ist, du bekommst auch noch 5€* für einen einkauf Geschenkt.
            </p>
            <form noValidate autoComplete={'on'} onSubmit={addToNewsletter} className={otherFont}>
                <TextField
                    id="email-address"
                    type="text"
                    placeholder={'Vorname'}
                    variant="outlined"
                    value={newsletterFirstName}
                    onChange={(e) => setNewsletterFirstName(e.target.value)}
                    fullWidth
                    className={otherFont}
                />
                <TextField
                    id="email-address"
                    type="text"
                    placeholder={'Nachname'}
                    variant="outlined"
                    value={newsletterLastName}
                    onChange={(e) => setNewsletterLastName(e.target.value)}
                    fullWidth
                    className={otherFont}
                    style={{marginTop: '0.5em'}}
                />
                <TextField
                    id="email-address"
                    type="text"
                    placeholder={'E-Mail Adresse'}
                    variant="outlined"
                    value={newsletterMail}
                    onChange={(e) => setNewsletterMail(e.target.value)}
                    fullWidth
                    className={otherFont}
                    style={{marginTop: '0.5em'}}
                />
            </form>
            <div>
                <Button style={{marginTop: '0.5em'}}
                        variant={'contained'}
                        color={'primary'}
                        onClick={addToNewsletter}
                        className={otherFont}>
                    Abonnieren
                </Button>
            </div>
            <p className={otherFont}>* Du erhälst in kürze eine E-Mail mit deinem Gutschein Code.</p>
        </Modal>

    </header>
}));

export default Header;
