import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {green} from '@material-ui/core/colors';
import {Checkbox} from "@material-ui/core";


export const useStyles = makeStyles(theme => ({
    wrapperMenuStyle: {
        maxWidth: '30%',
        backgroundColor: '#333',
        color: '#f5f5dc',
        margin: '1em 1em 1em',
        padding: '1em',
    },
    dividerStyle: {
        backgroundColor: '#f5f5dc'
    },
    wrapperSelectStyle: {
        padding: 'calc(1em + 8px) 0.5em',
        margin: '1em',
    },
    rowStyle: {
        height: '48px',
        padding: '8px 16px',
    },
    gridProductStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr))',
        gridAutoRows: '460px',
        gridGap: '3em 1.5em',
        marginTop: '5em',
        marginBottom: '5em',
    },
    rowHeightBig:{
      gridAutoRows: 500,
    },
    gridMinHeight: {
        minHeight: '15vh'
    },
    inputRowStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    inputRowStyleSmall: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    labelWrapperStyle: {
        padding: '12px 8px 4px 12px',
        backgroundColor: '#333',
        marginRight: '3em',
        width: 170,
        alignSelf: 'stretch',
        textAlign: 'left',
        display: 'flex',
    },
    labelStyle: {
        color: '#f5f5dc',
        borderBottom: 'solid 1px #f5f5dc',
        padding: '4px 8px 8px 8px',
        width: '100%',
        alignSelf: 'flex-end',
    },
    labelStyleBottom: {
        color: '#f5f5dc',
        padding: '4px 8px 8px 8px',
        width: '100%',
        alignSelf: 'flex-end',
    },
    autoCompleteWrapper:{
        display: 'flex',
        alignItem: 'center'
    },
    autoCompleteWrapperSmall:{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5em 0',
        width: '100%'
    },
    colorWrapperSmall:{
        backgroundColor:'#333',
        padding: '1em 1em',
    },
    colorInputSmall:{
        backgroundColor: '#f5f5dc',
        borderRadius: '0.5em'
    },
    searchBtnSmall:{
        backgroundColor: 'transparent',
        borderColor: '#f5f5dc',
        color: '#f5f5dc'
    }
}));

export const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);