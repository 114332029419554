import {observable, action, decorate} from "mobx"
import {database} from "common/firebase";
import {getActiveBatch} from "common/statelessFunctions";
import moment from "moment";
import {DateTime} from "luxon";
import Store from "common/store";

class ArticleStore {

    constructor() {
        const articlesRef = database.ref('articles/');
        const producersRef = database.ref('producers/');
        const originsRef = database.ref('origins/');
        const distilleriesRef = database.ref('distilleries/');
        const finishsRef = database.ref('finishs/');
        const barrelsRef = database.ref('barrels/');
        const peatesdRef = database.ref('peateds/');
        const productTypesRed = database.ref('productTypes/');

        articlesRef.on('child_added', snap => {
            database.ref(`batches/${snap.val().articleNumber}`).once('value', batch_snap => {
                this.updateLocalArticleList(snap.val(), batch_snap.val());
                if (!!snap && !!snap.val() && !!snap.val().articleNumber) {
                    const num = parseInt(snap.val().articleNumber.slice(-6));
                    if (!isNaN(num)) this.setArticleNumber('WW' + ('000000' + (num + 1)).slice(-6));
                }
            });
        });

        articlesRef.on('child_changed', snap => {
            database.ref(`batches/${snap.val().articleNumber}`).once('value', batch_snap => {
                this.updateLocalArticleList(snap.val(), batch_snap.val());
            });
        });

        database.ref(`batches`).on('child_changed', snap => {
            if(!snap.exists()) return;
            const batches = snap.val();
            const key = snap.key;
            this.updateBatchForArticle(key, batches);
        });

        producersRef.on('child_added', snap => {this.addProducer(snap.val())});
        originsRef.on('child_added', snap => {this.addOrigin(snap.val())});
        distilleriesRef.on('child_added', snap => {this.addDistillery(snap.val())});
        finishsRef.on('child_added', snap => {this.addFinish(snap.val())});
        barrelsRef.on('child_added', snap => {this.addBarrel(snap.val())});
        peatesdRef.on('child_added', snap => {this.addPeated(snap.val())});
        productTypesRed.on('child_added', snap => {this.addProductType(snap.val())});
    }

    articleNumber = 'WW000000';
    newArticle = {
        articleNumber: '',
        producer: '',
        productType: '',
        origin: '',
        description: '',
        name: '',
        distillery: '',
        region: '',
        rating: '',
        age: 0,
        finish: '',
        alcVolume: 0,
        type: 'whisky',
        volume: 0,
        images: [],
        isOnline: false,
        startDate: DateTime.local().toFormat('yyyy-LL-dd'),
        peated: '',
        barrel: '',
        grain: '',
        batches: [],
        isPresent: false,
        isSpecial: false,
        isRarity: false,
        isBeginner: false,
        isAdvanced: false,
        isProfi: false,
        size: '',
    };
    devSavedArticles = [];
    producersList = [];
    originsList = [];
    distilleriesList = [];
    finishsList = [];
    barrelList = [];
    peatedList = [];
    productTypeList = [];

    setArticleNumber = articleNumber => {
        this.articleNumber = articleNumber;
    };

    getArticleWithNumber = async (articleNumber) => {
        let article = this.devSavedArticles.find(a => a.articleNumber === articleNumber);
        if(!article){
           const snap = await database.ref('articles').child(articleNumber).once('value');
           if(!snap.exists()) return null;
           return snap.val()
        }
        return article;
    };

    getArticleRef = (articleNumber) => {
        return database.ref(`articles/${articleNumber}`);
    };

    addBarrel = barrel => {
        this.barrelList = [...this.barrelList, barrel].sort((a, b) => a.localeCompare(b));
    };

    addPeated = peated => {
        this.peatedList = [...this.peatedList, peated].sort((a, b) => a.localeCompare(b));
    };

    addProducer = producer => {
        this.producersList = [...this.producersList, producer].sort((a, b) => a.localeCompare(b));
    };

    addOrigin = origin => {
        this.originsList = [...this.originsList, origin].sort((a, b) => a.localeCompare(b));
    };

    addDistillery = distillery => {
        this.distilleriesList = [...this.distilleriesList, distillery].sort((a, b) => a.localeCompare(b));
    };

    addFinish = finish => {
        this.finishsList = [...this.finishsList, finish].sort((a, b) => a.localeCompare(b));
    };

    addProductType = productType => {
        this.productTypeList = [...this.productTypeList, productType].sort((a, b) => a.localeCompare(b));
    };

    updateLocalArticleList = (article, batches) => {
        if (!article) return;
        article.batches = batches;
        const index = this.devSavedArticles.findIndex(a => a.articleNumber === article.articleNumber);
        if (index === -1) {
            this.devSavedArticles.push(article);
        } else {
            this.devSavedArticles[index] = article;
        }
        this.devSavedArticles = [...this.devSavedArticles];
    };

    updateArticle = article => {
        if (article.producer !== '') {
            const producerRef = article.producer.replace(/[./#$[\]]+/gm, '_');
            database.ref(`producers/${producerRef}`).set(article.producer);
        }
        if (article.origin !== '') {
            const originRef = article.origin.replace(/[./#$[\]]+/gm, '_');
            database.ref(`origins/${originRef}`).set(article.origin);
        }
        if (article.distillery !== '') {
            const distilleryRef = article.distillery.replace(/[./#$[\]]+/gm, '_');
            database.ref(`distilleries/${distilleryRef}`).set(article.distillery);
        }
        if (article.finish !== '') {
            const finishRef = article.finish.replace(/[./#$[\]]+/gm, '_');
            database.ref(`finishs/${finishRef}`).set(article.finish);
        }
        if (article.barrel !== '') {
            const barrelRef = article.barrel.replace(/[./#$[\]]+/gm, '_');
            database.ref(`barrels/${barrelRef}`).set(article.barrel);
        }
        if (article.peated !== '') {
            const peatedRef = article.peated.replace(/[./#$[\]]+/gm, '_');
            database.ref(`peateds/${peatedRef}`).set(article.peated);
        }
        if (article.productType !== '') {
            const productTypeRef = article.productType.replace(/[./#$[\]]+/gm, '_');
            database.ref(`productTypes/${productTypeRef}`).set(article.productType);
        }

        database.ref(`articles/${article.articleNumber}`).update(article);
    };

    updateArticleSet = article => {
        database.ref(`articles/${article.articleNumber}`).update(article);
    };

    updateBatchForArticle = (key, batches) => {
        const index = this.devSavedArticles.findIndex( a => a.articleNumber === key);
        if(index === -1) return;
        this.devSavedArticles[index].batches = batches;
        this.devSavedArticles = [...this.devSavedArticles];
    };

    createArticle = async types => {
        const newArticle = {
            articleNumber: this.articleNumber,
            types: types.map(t => t.value),
        };
        await database.ref(`articles/${this.articleNumber}`).set(newArticle);
        return newArticle.articleNumber;
    };

    createSample = (articleNumber, amount, count) => {
        const newArticleNumber = this.articleNumber;
        database.ref(`articles/${articleNumber}`).once('value', snap => {
            const article = snap.val();
            const newArticle = JSON.parse(JSON.stringify(article));
            if (article) {
                newArticle.articleNumber = article.sample || newArticleNumber;
                article.sample = newArticle.articleNumber;
                newArticle.volume = 0.04;
                newArticle.types = ['whisky','sample'];
                database.ref(`articles/${newArticle.articleNumber}`).set(newArticle).then();

                database.ref(`batches/${articleNumber}`).once('value', snap => {
                    database.ref(`batches/${newArticleNumber}`).once('value', sampleSnap => {
                        let sampleBatches = sampleSnap.val();
                        const batches = snap.val();
                        const activeBatch = getActiveBatch(batches);
                        const newBatch = JSON.parse(JSON.stringify(activeBatch));
                        const batchCurrentAmount = !!activeBatch.currentAmount ? parseInt(activeBatch.currentAmount) : parseInt(activeBatch.amount);
                        const localAmount = batchCurrentAmount >= amount ? amount : batchCurrentAmount;

                        activeBatch.currentAmount = batchCurrentAmount - localAmount;

                        newBatch.amount = localAmount * count;
                        newBatch.currentAmount = newBatch.amount;
                        newBatch.date = moment().format('yyyy-MM-DD');
                        newBatch.reserved = 0;
                        newBatch.ek = activeBatch.ek / count;
                        newBatch.vk_pre = activeBatch.vk_pre / count;
                        newBatch.vk_net = activeBatch.vk_net / count; // ToDo Implement calculation on all components then remove.

                        if (!!sampleBatches) {
                            sampleBatches.push(sampleBatches);
                        } else {
                            sampleBatches = [newBatch];
                        }

                        const ref = database.ref(`transaction/`);
                        const newChildRef = ref.push();
                        // now it is appended at the end of data at the server
                        newChildRef.set({
                            articleNumber: article.articleNumber, amount: amount, vk_net: activeBatch.vk_net || 0, ek: activeBatch.vk_net || 0, type: 'sample',
                            createDate: Date.now()
                        }).then();
                        database.ref(`batches/${articleNumber}`).set(batches).then();
                        database.ref(`batches/${newArticleNumber}`).set(sampleBatches).then();

                        article.vk_net = activeBatch.vk_net;
                        newArticle.vk_net = newBatch.vk_net;
                        database.ref(`articles/${article.articleNumber}`).set(article).then();
                        database.ref(`articles/${newArticle.articleNumber}`).set(newArticle).then();
                    }).then();
                }).then();
            }
        }).then();
    };

    addAdTransaction = (articleNumber, amount, type) => {
        database.ref(`batches/${articleNumber}`).once('value', snap => {
            const batches = snap.val();
            const activeBatch = getActiveBatch(batches);

            const ref = database.ref(`transaction/`);
            const newChildRef = ref.push();
            // now it is appended at the end of data at the server
            newChildRef.set({articleNumber: articleNumber, amount: amount, vk_net: 0, ek: activeBatch.ek || 0, type: type, createDate: Date.now(), batch: activeBatch.key}).then();
        }).then();
    };

    createTransaction = (articleNumber, amount = 0, type = "sold", batchKey, vk_net = 0, ek = 0) => {
        if(!articleNumber) return;
        database.ref('transaction').push({
            articleNumber,
            amount,
            vk_net,
            ek,
            type,
            batch: batchKey || null,
        });
    };

    archiveArticle = async (articleNumber, reset) => {
        await database.ref('articles').child(articleNumber).update({isArchived: !reset});
    }

    releaseSet = async (articleNumber) => {
        const snapArticle = await database.ref('articles').child(articleNumber).once('value');
        if(snapArticle.exists()){
            const setArticle = snapArticle.val();
            const batchId = setArticle.activeBatch;

            const snapBatch = await database.ref('batches').child(articleNumber).child(batchId).once('value');
            if(snapBatch.exists()){
                const batch = snapBatch.val();
                batch.set.forEach( article => {
                    this.createTransaction(article.articleNumber, batch.currentAmount, 'restock', article.batch);
                });
                this.createTransaction(articleNumber, batch.currentAmount, "release", batchId, batch.ek, batch.ek);
                batch.active = false;
                await database.ref('batches').child(articleNumber).child(snapBatch.key).update({active: false});
                Store.snackBarHandle.addMessages(`Artikel ${articleNumber} wurde aufgelöst`);
            }else{
                Store.snackBarHandle.addMessages(`Artikel ${articleNumber} konnte nicht aufgelöst werden`);
            }
        }else{
            Store.snackBarHandle.addMessages(`Artikel ${articleNumber} konnte nicht aufgelöst werden`);
        }
    }
}

decorate(ArticleStore, {
    articleNumber: observable,
    devSavedArticles: observable,
    producersList: observable,
    originsList: observable,
    distilleriesList: observable,
    finishsList: observable,
    barrelList: observable,
    peatedList: observable,
    addProducer: action,
    addOrigin: action,
    addDistillery: action,
    addFinish: action,
    updateBatchForArticle: action,
    setArticleNumber: action,
    createArticle: action,
    updateLocalArticleList: action,
    updateArticle: action,
});

export default ArticleStore;
