import React, {useEffect, useState} from 'react'
import {database} from "../../../common/firebase";
import {Button, TextField} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {useStyles} from "../styling";
import {Modal} from "../../../components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Collapse from "@material-ui/core/Collapse";

export const AddAddress = inject('store')(observer(({store, databaseRef, title, isOpen, toggleOpen, handleSelectAddress, addressList}) => {
    const classes = useStyles();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [plz, setPLZ] = useState('');
    const [land, setLand] = useState('Österreich');
    const [region, setRegion] = useState('');
    const [isCollapse, setIsCollapse] = useState(true);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [addressListLocal, setAddressListLocal] = useState([]);
    const {currentUser} = store.userStore;

    useEffect(() => {
        if (addressList && addressList.length > 0) {
            setAddressListLocal([...addressList]);
            setSelectedAddress(addressList.filter(a => a.preferred)[0]);
        }
    }, [addressList]);

    const saveUserData = () => {
        const newAddress = {
            firstName,
            lastName,
            address1,
            address2,
            plz,
            land,
            region,
            preferred: true,
        };
        // set new Address as Preferred
        addressListLocal.filter(a => a.preferred).forEach(a => a.preferred = false);
        const list = [...addressListLocal, newAddress];
        setAddressListLocal(list);

        // Clear Form and save new List
        clearFrom();
        if(currentUser.isAnonymous){
            database.ref(`userdata/${currentUser.uid}/`).update({isAnonymous: currentUser.isAnonymous})
            database.ref(`userdata/${currentUser.uid}/${databaseRef}`).set(list).then( () => {
                // Handle select new address
                handleSelectAddress(newAddress);
                toggleOpen();
            });
        }else{
            database.ref(`userdata/${currentUser.uid}/${databaseRef}`).set(list).then( () => {
                // Handle select new address
                setSelectedAddress(newAddress);
                handleSelectAddress(newAddress);
                toggleOpen();
            });
        }

    };

    const handleSelectAddressLocal = () => {
        clearFrom();
        const {currentUser} = store.userStore;
        database.ref(`userdata/${currentUser.uid}/${databaseRef}`).set(addressListLocal).then();
        handleSelectAddress(selectedAddress);
        toggleOpen();
    };

    const selectThisAddress = (address, index) => () => {
        setIsCollapse(true);
        addressListLocal.filter(a => a.preferred).forEach(a => a.preferred = false);
        addressListLocal[index].preferred = true;
        setSelectedAddress(address);
        setAddressListLocal([...addressListLocal]);
    };

    const openAdd = () => {
        setIsCollapse(false);
    };

    const handleToggleClose = event => {
        clearFrom();
        setIsCollapse(true);
        toggleOpen(event);
    };

    const clearFrom = () => {
        setIsCollapse(true);
        setFirstName('');
        setLastName('');
        setAddress1('');
        setAddress2('');
        setPLZ('');
        setLand('Österreich');
        setRegion('');
    };

    const handleClickBtn = (isNewAddress) => () => {
        if(isNewAddress){
            saveUserData();
        }else{
            handleSelectAddressLocal()
        }
    }

    return currentUser && <Modal title={title} open={isOpen} handleClose={handleToggleClose}
                  actions={<Button color={"primary"} variant={"contained"} onClick={handleClickBtn(!isCollapse || currentUser.isAnonymous)}>{ isCollapse && !currentUser.isAnonymous ? 'Auswahl Bestätigen' : 'Speichern' }</Button>}>

        {!currentUser.isAnonymous && <Grid container spacing={2}>
            {!!addressListLocal && addressListLocal.map((address, index) => (
                <Grid key={index} item sm={5} className={classes.addressStyle + ' ' + classes.otherFont + ' ' + (address.preferred ? classes.preferredAddress : '')} onClick={selectThisAddress(address, index)}>
                    <Typography>{address.firstName} {address.lastName}</Typography>
                    <Typography className={classes.smallerText}>{address.address1}</Typography>
                    <Typography className={classes.smallerText}>{address.address2}</Typography>
                    <Typography className={classes.smallerText}>{address.land}</Typography>
                    <Typography className={classes.smallerText}>{address.plz}</Typography>
                    <Typography className={classes.smallerText}>{address.region}</Typography>
                </Grid>
            ))}
            <Grid item sm={5} onClick={openAdd} className={(classes.addressStyle)}>
                <Typography>Um eine neue Addresse hinzuzufügen klicken sie hier</Typography>
                <Typography><FontAwesomeIcon icon={faPlus}/></Typography>
            </Grid>
        </Grid> }
        <Collapse in={!isCollapse || currentUser.isAnonymous}>
            <form className={classes.addressFrom + ' ' + classes.otherFont}>
                <TextField className={classes.inputTextField} type={"text"} variant={"outlined"} label={"Vorname"} value={firstName} required
                           onChange={(e) => setFirstName(e.target.value)}/>
                <TextField className={classes.inputTextField} type={"text"} variant={"outlined"} label={"Nachname"} value={lastName} required
                           onChange={(e) => setLastName(e.target.value)}/>
                <TextField className={classes.inputTextField} type={"text"} variant={"outlined"} label={"Adresszeile 1"} value={address1} required
                           onChange={(e) => setAddress1(e.target.value)}/>
                <TextField className={classes.inputTextField} type={"text"} variant={"outlined"} label={"Adresszeile 2"} value={address2}
                           onChange={(e) => setAddress2(e.target.value)}/>
                <TextField className={classes.inputTextField} type={"text"} variant={"outlined"} label={"PLZ"} value={plz} required
                           onChange={(e) => setPLZ(e.target.value)}/>
                <TextField className={classes.inputTextField} type={"text"} variant={"outlined"} label={"Stadt / Ort"} value={region}
                           onChange={(e) => setRegion(e.target.value)}/>
                <TextField className={classes.inputTextField} type={"text"} variant={"outlined"} label={"Land"} value={land} required disabled
                           onChange={(e) => setLand(e.target.value)}/>
            </form>
        </Collapse>
    </Modal>
}));