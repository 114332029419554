import React, {useEffect, useState} from "react";
import {Container, Divider, List, ListItem, ListItemText} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import {useStyles} from "./styling";
import sampleInfo from 'common/images/Sampleinfo.jpg';
import Grid from "@material-ui/core/Grid";
import {BannerSlider} from "../index";
import BannerSliderDbImage from "../BannerSliderDbImage";
import {database} from "../../common/firebase";


function WhiskyConfigSelector() {
    const location = useLocation();
    const {
        dividerStyle, smallLinkText, activeLinkTextUnderline, linkListElement, advertisementStyle, headerLine, wrapperMenuStyleNew
    } = useStyles();
    const [mode, setMode] = useState('');
    const [bannerList, setBannerList] = useState(null);

    useEffect(() => {
        database.ref('pages/banner').on('value', snap => {
            if (!snap.exists()) return;
            const cache = []
            snap.forEach(snapElement => {
                const element = {...snapElement.val(), key: snapElement.key};
                cache.push(element);
            })
            setBannerList(cache);
        })
    }, []);

    useEffect(() => {
        const {pathname} = location;
        setMode(pathname.replace('/whisky/', '').replace('/', ''));
    }, [location]);


    const pathHas = text => {
        const {pathname} = location;
        return pathname.includes(text);
    }

    return <Container maxWidth="md">
        <Grid container spacing={2} style={{marginTop: '1em'}}>
            <Grid item xs={12} md={4}>
                <div className={wrapperMenuStyleNew}>
                    <List>
                        <ListItem dense={true}>
                            <ListItemText classes={{primary: headerLine}} primary={'FINDE DEINEN WHISK(E)Y ...'}/>
                        </ListItem>
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement} button component={Link} to="/whisky/config" dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>MIT DEM</span> KONFIGURATOR</span>}/>
                        </ListItem>
                        {/*<Divider className={dividerStyle}/>
                    <ListItem button dense={true}>
                        <ListItemText primary={<span><span className={smallLinkText}>NACH</span> LAND</span>}/>
                    </ListItem> */}
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement + (mode === 'beginner' ? ' ' + activeLinkTextUnderline : '')} button component={Link}
                                  to="/whisky/beginner" dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>FÜR</span> EINSTEIGER</span>}/>
                        </ListItem>
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement + (mode === 'pro' ? ' ' + activeLinkTextUnderline : '')} button component={Link} to="/whisky/pro"
                                  dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>FÜR</span> FORTGESCHRITTENE</span>}/>
                        </ListItem>
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement + (mode === 'master' ? ' ' + activeLinkTextUnderline : '')} button component={Link}
                                  to="/whisky/master"
                                  dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>FÜR</span> KENNER & SAMMLER</span>}/>
                        </ListItem>
                        <ListItem dense={true}>
                            <ListItemText classes={{primary: headerLine}} primary={'ODER DURCHSTÖBERE...'}/>
                        </ListItem>
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement + (mode === 'special' ? ' ' + activeLinkTextUnderline : '')} button component={Link}
                                  to="/whisky/specials"
                                  dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>Unsere </span> SPEZIALITÄTEN</span>}/>
                        </ListItem>
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement + (mode === 'rarity' ? ' ' + activeLinkTextUnderline : '')} button component={Link}
                                  to="/whisky/rarity"
                                  dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>Unsere </span> RARITÄTEN</span>}/>
                        </ListItem>
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement + (mode === 'limit' ? ' ' + activeLinkTextUnderline : '')} button component={Link}
                                  to="/whisky/limit"
                                  dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>Unsere </span> Einzelstücke</span>}/>
                        </ListItem>
                        <Divider className={dividerStyle}/>
                        <ListItem className={linkListElement + (mode === 'sample' ? ' ' + activeLinkTextUnderline : '')} button component={Link}
                                  to="/whisky/sample"
                                  dense={true}>
                            <ListItemText primary={<span><span className={smallLinkText}>Unsere </span> Kostproben</span>}/>
                        </ListItem>
                    </List>
                </div>
            </Grid>
            <Grid item sm={12} md={8}>
                {pathHas('sample') ?
                    <img className={advertisementStyle} src={sampleInfo} alt={'Kostproben Erklärung'}/>:
                    <BannerSliderDbImage subPath={'banner'} items={bannerList}/>
                }
            </Grid>
        </Grid>
    </Container>
}

export default WhiskyConfigSelector
