import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
    Button, Card, CardActionArea, CardActions, CardContent, Collapse, IconButton, ImageList, ImageListItem, makeStyles, Tooltip, Typography
} from "@material-ui/core";
import {Link} from "react-router-dom"
import {faEdit, faLock, faLockOpen, faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageBackdrop from "components/ImageBackdrop";
import ReactMarkdown from "react-markdown";
import DbImage from "../dbImage";
import ReactPlayer from "react-player";
import {DateTime} from "luxon";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    otherFont: {
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            textAlign: 'left',
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    headerText: {
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    iconMinus: {
        position: "absolute",
        fontSize: '1.5em',
        top: theme.spacing(2),
        right: theme.spacing(2)
    },
    iconPlus: {
        fontSize: '1.5em',
    },
    relativeContainer: {
        position: 'relative'
    },
    listRoot: {
        display: 'flex',
        justifyContent: 'center',
    },
    imageList: {
        width: '100%',
        maxWidth: 650,
        padding: theme.spacing(2)
    },
    cardMediaStyle: {
        display: 'block',
        width: '100%',
        objectFit: 'cover'
    },
    imgStyle:{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    timestamp:{
        fontSize: '0.4em',
        color: '#999'
    }

}))

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments)
        }, ms)
    };
}

const NewsCard = ({isOpen, news, toggleEdit, publish, unpublish, callDelete}) => {
    const classes = useStyles();
    const [isCollapse, setIsCollapse] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const imageListRef = useRef();
    const cardRef = useRef();

    const [height, setHeight] = useState(0);
    const [mediaHeight, setMediaHeight] = useState(0);

    useEffect(() => {
        let debounceHandleResize = null;
        if (imageListRef.current && cardRef.current) {
            debounceHandleResize = debounce(function handleResize() {
                setHeight(imageListRef.current.clientWidth / 4);
                setMediaHeight(cardRef.current.clientWidth / 3);
            }, 500)

            window.addEventListener('resize', debounceHandleResize)
        }

        return _ => { if (debounceHandleResize) window.removeEventListener('resize', debounceHandleResize); }
    }, []);

    useEffect(() => {
        if (isOpen) setIsCollapse(false);
    }, [isOpen])

    useLayoutEffect(() => {
        if (imageListRef.current && cardRef.current) {
            setHeight(imageListRef.current.clientWidth / 4);
            setMediaHeight(cardRef.current.clientWidth / 3);
        }
    }, [])

    const openBackdrop = (element) => _ => {
        setSelectedImage(element);
    }

    const handleCloseBackdrop = () => {
        setSelectedImage(null);
    }

    return <Card ref={cardRef} className={classes.root}>
        {news?.images?.[0] && <Collapse in={!isCollapse}>
            <CardActionArea onClick={() => setIsCollapse(!isCollapse)} className={classes.relativeContainer}>
                {news.images[0].img &&
                <DbImage className={classes.cardMediaStyle} src={['images', 'news', news.key, news.images[0].img]} alt={news.images[0].title} style={{height: mediaHeight}}/>}
                <FontAwesomeIcon icon={faMinus} className={classes.iconMinus}/>
            </CardActionArea>
        </Collapse>}
        <CardContent>
            <Collapse in={isCollapse}>
                <CardActionArea onClick={() => setIsCollapse(!isCollapse)} className={classes.relativeContainer}>
                    <Typography gutterBottom variant={"h5"} component={"h2"} className={classes.headerText}>
                        <span>
                            {news?.title && <span>{news.title}</span>} {news?.timestamp && <span className={classes.timestamp}>{DateTime.fromMillis(news.timestamp).toFormat('dd.LL.yyyy')}</span>}
                        </span>
                        <FontAwesomeIcon icon={faPlus} className={classes.iconPlus}/>
                    </Typography>
                </CardActionArea>
            </Collapse>
            <Collapse in={!isCollapse}>
                <Typography gutterBottom variant={"h5"} component={"h2"} className={classes.headerText}>
                    {news?.title && <span>{news.title}</span>} {news?.timestamp && <span className={classes.timestamp}>{DateTime.fromMillis(news.timestamp).toFormat('dd.LL.yyyy')}</span>}
                </Typography>
                {news?.text && <ReactMarkdown source={news.text} className={classes.otherFont}/>}

                {news?.images && <div className={classes.listRoot}>
                    <ImageList ref={imageListRef} rowHeight={height} gap={2} className={classes.imageList} cols={4}>
                        {news.images.map((item, i) => (
                            <ImageListItem key={i} cols={parseInt(item.cols || 1)} rows={parseInt(item.rows || 1)} onClick={openBackdrop(item)} style={{cursor: 'pointer'}}>
                                <DbImage src={['images', 'news', news.key, item.img]} alt={item.title} className={classes.imgStyle}/>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>}

                { news?.youtubeLink && news.youtubeLink !== '' && <div className={classes.listRoot}>
                    <ReactPlayer url={news.youtubeLink} />
                </div> }

            </Collapse>
        </CardContent>

        <Collapse in={!isCollapse}>
            {news?.links && <CardActions className={classes.otherFont}>
                {news.links.map((link, i) => (
                    link.internal === 'internal' ?
                            <Button key={i} component={Link} size={"small"} color={"primary"} to={link.url}>
                                {link.text}
                            </Button>:
                            <Button key={i} component={"a"} target={"_blank"} size={"small"} color={"primary"} href={link.url}>
                                {link.text}
                            </Button>
                ))}
            </CardActions>}
            {(!!publish || !!toggleEdit || !!callDelete || !!unpublish) && <CardActions>
                {publish && <Tooltip title={'Löschen!'}>
                    <IconButton onClick={e => { callDelete();}}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </IconButton>
                </Tooltip>}
                {publish && <Tooltip title={'Veröffentlichen '}>
                    <IconButton onClick={e => { publish();}}>
                        <FontAwesomeIcon icon={faLock}/>
                    </IconButton>
                </Tooltip>}
                {unpublish && <Tooltip title={'Zurückziehen'}>
                    <IconButton onClick={e => { unpublish();}}>
                        <FontAwesomeIcon icon={faLockOpen}/>
                    </IconButton>
                </Tooltip>}
                {toggleEdit && <Tooltip title={'Bearbeiten'}>
                    <IconButton onClick={e => { toggleEdit();}}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </IconButton>
                </Tooltip>}
                {toggleEdit && <Tooltip title={'close'}>
                    <IconButton onClick={() => setIsCollapse(!isCollapse)}>
                        <FontAwesomeIcon icon={faMinus}/>
                    </IconButton>
                </Tooltip>}
            </CardActions>}
        </Collapse>

        <ImageBackdrop isOpen={!!selectedImage} image={['images', 'news', news.key, selectedImage?.img]} alt={selectedImage?.title} handleClose={handleCloseBackdrop}/>
    </Card>
};

export default NewsCard;
