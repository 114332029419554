import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import {DatePicker} from "@material-ui/pickers";

const EditOnline = ({isOnline, setIsOnline, startDate, setStartDate}) => {
    return <div>
        <div style={{display: 'flex'}}>
            <FormControlLabel
                control={
                    <Switch
                        checked={isOnline}
                        onChange={(e) => setIsOnline(e.target.checked)}
                        name="isOnline"
                        color="secondary"
                    />
                }
                label={isOnline ? 'Online' : 'Offline'}
                style={{minHeight: 62}}
            />
            {isOnline && <DatePicker label={'Lieferdatum'} fullWidth inputVariant={'outlined'} format="dd.MM.yyyy" value={startDate}
                onChange={setStartDate}/>}
        </div>
    </div>
};

export default EditOnline;