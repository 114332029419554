import React, {useState} from "react";
import {Link, Route, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import { Container, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faShoppingBag, } from "@fortawesome/free-solid-svg-icons";

import {useStyles} from "./styling";
import WhiskyEventList from "./WhiskyEventList";
import TransactionList from "./transactionList";
import NewsletterList from "./NewsletterList";
import AppointmentList from "./AppointmentList";
import Articles from "./ArticlesAdminTable";
import BillList from "./BillList";
import CouponList from "./CouponList";
import OrderList from "./OrderList";
import UserAdminTable from "./UserAdminTable";
import {AdminEditStartPage} from "./AdminEditStartPage";
import {AdminEditNewsFeed} from "./AdminEditNewsFeed";
import {AdminEditBanner} from "./AdminEditBanner";
import AdminDashboard from "./Dashborad";
import {routes} from "./adminRouting";
import {Editor} from "./Editor";
import AdminWhiskyEventDetail from "./AdminWhiskyEventDetail";
import PrintArticlesList from "./printArticlesList";


const Admin = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const {pathname} = useLocation();
    const [openDrawer, setOpenDrawer] = useState(false);

    return <Container maxWidth="lg" className={classes.rootContainer}>
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, classes.otherFont, classes.noPrint, {
                [classes.drawerOpen]: openDrawer,
                [classes.drawerClose]: !openDrawer,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: openDrawer,
                    [classes.drawerClose]: !openDrawer,
                }),
            }}
        >
            <List>
                <Tooltip title={"öffnen/schließen"}>
                    <ListItem button onClick={() => setOpenDrawer(!openDrawer)}>
                        {openDrawer ?
                            <ListItemIcon style={{fontSize: '1.75em', justifyContent: 'center'}}><FontAwesomeIcon icon={faChevronLeft}/></ListItemIcon> :
                            <ListItemIcon style={{fontSize: '1.75em', justifyContent: 'center'}}><FontAwesomeIcon icon={faChevronRight}/></ListItemIcon>
                        }
                        <ListItemText primary={openDrawer ? "Öffnen" : "Schließen"}/>
                    </ListItem>
                </Tooltip>
                <Divider/>
                <Tooltip title={"zum Shop"}>
                    <ListItem component={Link} button to={"/whisky/"}>
                        <ListItemIcon style={{fontSize: '1.75em', justifyContent: 'center'}}><FontAwesomeIcon icon={faShoppingBag}/></ListItemIcon>
                        <ListItemText primary={"Zum Shop"}/>
                    </ListItem>
                </Tooltip>
                { routes.map((item, index) => (
                    item.isDivider ? <Divider key={index} /> :
                    <Tooltip title={item.title} key={index}>
                        <ListItem component={Link} button to={item.route} className={pathname === item.route ? classes.active : ''}>
                            <ListItemIcon style={{fontSize: '1.75em', justifyContent: 'center'}}><FontAwesomeIcon icon={item.icon}/></ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
        </Drawer>

        <Route exact path={"/admin/"} component={AdminDashboard} />
        <Route exact path={"/admin/orders/"} component={OrderList} />
        <Route exact path={"/admin/appointments/"} component={AppointmentList} />
        <Route exact path={"/admin/coupons/"} component={CouponList} />
        <Route exact path={"/admin/users/"} component={UserAdminTable} />
        <Route exact path={"/admin/articles/"} component={Articles} />
        <Route exact path={"/admin/whiskyevents/"} component={WhiskyEventList} />
        <Route exact path={"/admin/event/:year/:id/"} component={AdminWhiskyEventDetail} />
        <Route exact path={"/admin/bills/"} component={BillList} />
        <Route exact path={"/admin/newsletter/"} component={NewsletterList} />
        <Route exact path={"/admin/transaction/"} component={TransactionList} />
        <Route exact path={"/admin/terms/"} component={Editor} />
        <Route exact path={"/admin/termsDelivery/"} component={Editor} />
        <Route exact path={"/admin/dataSecurity/"} component={Editor} />
        <Route exact path={"/admin/termsWithdraw/"} component={Editor} />
        <Route exact path={"/admin/contact/"} component={Editor} />
        <Route exact path={"/admin/home/"} component={AdminEditStartPage} />
        <Route exact path={"/admin/news/"} component={AdminEditNewsFeed} />
        <Route exact path={"/admin/adversal/"} component={AdminEditBanner} />
        <Route exact path={"/admin/print/articles"} component={PrintArticlesList} />

    </Container>
}));

export default Admin;
