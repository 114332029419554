import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
        otherFont: {
            '& *': {
                fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
            }
        },
        blocksatz: {
            textAlign: 'justify',
        }
    }))
;