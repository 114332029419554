import React, {useEffect, useState} from "react";
import {ArticleListFull} from "components";
import {useHistory, useLocation} from 'react-router-dom';
import {functions, database} from "../../common/firebase";
import {InputAdornment, TextField} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";
import {makeStyles} from "@material-ui/core/";
import {inject, observer} from "mobx-react";
import DbImage from "../../components/dbImage";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    input: {
        backgroundColor: '#fff',
    },
    couponContainerSide: {
        width: '100%',
        display: 'flex',

        '& $couponImg': {
            width: '50%',
            padding: '0.5em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        '& $couponText': {
            width: '50%',
            fontSize: '1.5em',
            '& *': {
                fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif'
            },
        },
    },
    couponContainer: {},
    couponImg: {
        width: '100%'
    },
    couponImgTag: {
        width: '100%',
        height: 450,
        objectFit: 'contain',
    },
    couponText: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif'
        },
        fontSize: '2em',
        padding: '0.5em'
    },
    wrapper:{
        paddingTop: '1em',
        paddingBottom: '4em',
        minHeight: 'calc(100vh - 627px)'
    }
}));

const WhiskySearch = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [searchStringInput, setSearchStringInput] = useState('');
    const [searchObject, setSearchObject] = useState({});
    const [articleList, setArticleList] = useState([]);
    const [code, setCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const {coupon} = store.orderStore;

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const searchString = query.get('searchString');
        const code = query.get('code');
        setSearchStringInput(searchString);
        setSearchObject({searchString});
        setCode(code);
    }, [location.search]);

    useEffect(() => {
        if (!!searchObject.searchString && searchObject.searchString !== '') {
            functions.httpsCallable('filterArticlesString')(searchObject).then(response => {
                setArticleList(response.data.map(p => {
                    let weight = 0;
                    if (p.isSet) {
                        p.setVolume.forEach((v) => {
                            if (v > 0.5) {
                                weight += 1.5
                            } else if (v > 0.2) {
                                weight += 1
                            } else {
                                weight += 0.2
                            }
                        });
                    } else {
                        if (!!p.volume && parseFloat(p.volume) > 0.5) {
                            weight = 1.5
                        } else if (!!p.volume && parseFloat(p.volume) > 0.2) {
                            weight = 1
                        } else {
                            weight = 0.2
                        }
                    }
                    p.weight = weight;
                    return p;
                }));
                setLoading(false);
            });
        }
    }, [searchObject]);

    useEffect(() => {
        if (code) {
            store.orderStore.addCoupon(code);
        }
    }, [code]);

    const startSearch = (e) => {
        setLoading(true);
        const searchString = searchStringInput;
        setSearchObject({searchString});
        setArticleList([]);
        history.push({
            search: `?searchString=${searchStringInput}`
        });
        e.preventDefault();
    };

    return <Container maxWidth={'md'} className={classes.wrapper}>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - World Whisky WW OG - Shop</title>
            <meta name="description"
                  content="Whiskypezialitäten aus aller Welt, Raritätenwebshop, Individuelle Tastings, Worldwhisky, Irischer Whiskey, Eigenabfüllungen, Whiskykostprobenservice, WhiskyAbo und mehr"
            />
        </Helmet>
        {!coupon && <form onSubmit={startSearch} noValidate autoComplete="on">
            <TextField
                className={classes.otherFont + ' ' + classes.input}
                id="search"
                label="Suche"
                value={searchStringInput}
                type="text"
                fullWidth
                variant={"outlined"}
                onChange={(e) => setSearchStringInput(e.target.value)}
                onBlur={startSearch}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start" onClick={startSearch} style={{cursor: 'pointer'}}>
                            <FontAwesomeIcon icon={faSearch}/>
                        </InputAdornment>
                    ),
                }}
            />
        </form>}
        {!!code && !!coupon && coupon.information.option == "1" && <OptionOneCoupon coupon={coupon}/>}
        {!!code && !!coupon && coupon.information.option == "2" && <OptionTwoCoupon coupon={coupon}/>}
        <ArticleListFull list={articleList} isDataLoading={loading} isEmpty={articleList.length === 0 && !loading}/>
    </Container>

}));

export default WhiskySearch;

//TopBottom
const OptionOneCoupon = ({coupon}) => {
    const classes = useStyles();
    return <div className={classes.couponContainer}>
        <Typography variant={"h4"}>{coupon.information.title}</Typography>
        <div className={classes.couponImg}>
            {!!coupon.information?.image &&
            <DbImage className={classes.couponImgTag} src={['images', 'coupon', coupon.information.image]} alt={'Gutschein Bild'}/>
            }
        </div>
        <div className={classes.couponText}>
            {!!coupon.information?.description &&
                <ReactMarkdown source={coupon.information.description} />
            }
        </div>
    </div>
}

//SideBySide
const OptionTwoCoupon = ({coupon}) => {
    const classes = useStyles();
    return <div>
        <Typography variant={"h4"}>{coupon.information.title}</Typography>
        <div className={classes.couponContainerSide}>
            <div className={classes.couponImg}>
                {!!coupon.information?.image &&
                <DbImage className={classes.couponImgTag} src={['images', 'coupon', coupon.information.image]} alt={'Gutschein Bild'}/>
                }
            </div>
            <div className={classes.couponText}>
                {!!coupon.information?.description &&
                <ReactMarkdown source={coupon.information.description} />
                }
            </div>
        </div>
    </div>
}
