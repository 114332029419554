import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import Modal from "../Modal";
import {Button, Grid, InputAdornment, Collapse, Select, MenuItem, FormControl} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown, faChevronUp, faEquals, faEuroSign, faMinus, faPercentage, faPlus, faSearch, faTimes, faTrash, faWineBottle
} from "@fortawesome/free-solid-svg-icons";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {EditDescription} from "./editDescription";
import {makeStyles} from "@material-ui/styles";
import {EditImages, EditOnline} from "components";
import {DatePicker} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import {database, storage} from "common/firebase";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {typeList} from "common/constants";
import {ImagePickerModal} from "../ImagePickerModal";
import {DateTime} from "luxon";
import DbImage from "../dbImage";

const getOnlySpread = (ek, vk, tax) => {
    if (ek && vk && tax) {
        const local_vk_pre = vk / 1.2;
        let local_spread = parseFloat(((local_vk_pre / ek) * 100 - 100).toFixed(2));
        return Math.abs(local_spread);
    }
    return 0;
};

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        marginBottom: '1em'
    },
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}));

const EditArticleSetModal = inject('store')(observer(({store, open, handleClose, articleNumber, editArticle}) => {
    const warning = "Wenn man nachder Beschreibung noch mal Artikel hinzufügt oder Löscht wird die Beschreibung überschrieben.";
    const classes = useStyles();
    const {devSavedArticles} = store.articleStore;
    const [allArticles, setAllArticles] = useState([]);
    const [addedArticles, setAddedArticles] = useState([]);
    const [setName, setSetName] = useState('');
    const [searchString, setSearchString] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [filterError, setFilterError] = useState('');
    const [isArticleOpen, setIsArticleOpen] = useState(false);
    const [description, setDescription] = useState(warning);
    const [images, setImages] = useState([]);
    const [isOnline, setIsOnline] = useState(false);
    const [startDate, setStartDate] = useState(DateTime.local());
    const [types, setTypes] = useState([typeList.find(t => t.value === 'present')]);
    const [thumbnailList, setThumbnailList] = useState([]);
    const [overviewImages, setOverviewImages] = useState([]);

    const [editImages, setEditImages] = useState(false);
    const [editMode, setEditMode] = useState(false);

    //BATCH
    const [batches, setBatches] = useState([]);
    const [date, setDate] = useState(new Date());
    const [maxSetSize, setMaxSetSize] = useState(-1);
    const [currentSetSize, setCurrentSetSize] = useState(0);
    const [ek, setEk] = useState(0);
    const [vk, setVk] = useState(0);
    const [spread, setSpread] = useState(0);

    useEffect(() => {
        setAllArticles(devSavedArticles);
    }, [devSavedArticles]);

    useEffect(() => {
        if (!!editArticle && !!editArticle.isSet && open) {
            setEditMode(true);
            setIsArticleOpen(true);
            setSetName(editArticle.name);
            setDescription(editArticle.description);
            setImages(editArticle.images ?? []);
            setThumbnailList(editArticle.thumbnails ?? [])
            setOverviewImages(editArticle.overviewImages ?? [])
            setIsOnline(editArticle.isOnline);
            setStartDate(new Date(editArticle.startDate));
            setTypes(editArticle.types.map(t => typeList.find(tO => t === tO.value)))
            editArticle.set.forEach(artNum => database.ref('articles').child(artNum).once('value', snap => {
                if (!snap.exists()) return;
                const data = snap.val();
                setAddedArticles(addedArticles => [...addedArticles, data]);
            }))
        } else {
            setEditMode(false);
            setIsArticleOpen(false);
        }
    }, [editArticle, open]);

    const startSearch = (e) => {
        e.preventDefault();
        setFilterError('');
        if (searchString.length < 3) {
            setFilteredList([]);
            setFilterError('Um Effektive Filtern zu können werden mindestens 3 Zeichen benötigt');
            return;
        }
        setFilteredList(allArticles.filter((a) =>
            a.articleNumber.toLowerCase().includes(searchString.toLowerCase()) ||
            a.producer.toLowerCase().includes(searchString.toLowerCase()) ||
            a.distillery.toLowerCase().includes(searchString.toLowerCase()) ||
            a.name.toLowerCase().includes(searchString.toLowerCase())
        ))
    };

    const addArticle = article => async e => {
        addedArticles.push(article);
        if (!!article.activeBatch) {
            try {
                const activeBatch = await database.ref('batches').child(article.articleNumber).child(article.activeBatch).once('value');
                if (activeBatch.exists()) {
                    const b = activeBatch.val();
                    b.key = activeBatch.key;
                    if (maxSetSize === -1 || b.currentAmount < maxSetSize) setMaxSetSize(b.currentAmount);
                    const newEk = !!b.ek && !isNaN(b.ek) ? parseFloat((parseFloat(b.ek) + ek).toFixed(2)) : ek;
                    const newVk = !!b.vk_net && !isNaN(b.vk_net) ? parseFloat((parseFloat(b.vk_net) + vk).toFixed(2)) : vk;
                    setEk(newEk);
                    setVk(newVk);
                    setSpread(getOnlySpread(newEk, newVk, 0.2));
                    batches.push(b);
                    setBatches([...batches]);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setMaxSetSize(0);
        }
        if (!!article.images && article.images.length > 0) {
            images.push(article.images[0]);
            setImages([...images]);
        }
        if (!!article.overviewImages && article.overviewImages.length > 0) {
            storage.ref('images').child(article.articleNumber).child(article.overviewImages[0]).getDownloadURL().then(url => {
                const name = url.match(/.*\/.*%2F(.*)\?/)[1];
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    blob.name = name;
                    const uploadRef = storage.ref(`images/${articleNumber}/${blob.name}`)
                    uploadRef.put(blob);
                };
                xhr.open('GET', url);
                xhr.send();
            });
        }
        setDescription(getDescription(addedArticles));
        setAddedArticles([...addedArticles]);
    };

    const removeArticle = i => e => {
        if (!!addedArticles[i].activeBatch) {
            const bIndex = batches.findIndex(b => b.key === addedArticles[i].activeBatch);
            if (bIndex > -1) {
                if (batches[bIndex].currentAmount === maxSetSize && batches.length - 1 <= 0) {
                    setMaxSetSize(-1);
                } else if (batches[bIndex].currentAmount === maxSetSize) {
                    let cMaxSetSize = -1;
                    for (const b of batches) {
                        if (cMaxSetSize !== -1 && b.currentAmount < cMaxSetSize) {
                            cMaxSetSize = b.currentAmount;
                        } else {
                            cMaxSetSize = b.currentAmount
                        }
                    }
                    setMaxSetSize(cMaxSetSize);
                }
                const newEk = !!batches[bIndex].ek && !isNaN(batches[bIndex].ek) ? parseFloat((ek - parseFloat(batches[bIndex].ek)).toFixed(2)) : ek;
                const newVk = !!batches[bIndex].vk_net && !isNaN(batches[bIndex].vk_net) ? parseFloat((vk - parseFloat(batches[bIndex].vk_net)).toFixed(2)) : vk;
                setVk(newVk);
                setEk(newEk);
                setSpread(getOnlySpread(newEk, newVk, 0));
                batches.splice(bIndex, 1);
                setBatches([...batches]);
            }
        }
        if (!!addedArticles[i].images && addedArticles[i].images.length > 0) removeImage(addedArticles[i].images[0]);
        addedArticles.splice(i, 1);
        setDescription(getDescription(addedArticles));
        setAddedArticles([...addedArticles]);
    };

    const getDescription = products => {
        return warning + "\n\n" + products.reduce((a, p) => a + (p.description ? '\n\n' + '### ' + (p.producer || p.distillery) + ' ' + p.name + '\n\n' + p.description : ''), '')
    };

    const handleChange = event => {
        const {value, name} = event.target;

        if (name === 'ek') {
            setEk(parseFloat(value));
            if (vk !== 0) {
                setSpread(getOnlySpread(ek, parseFloat(value), 0.2))
            } else if (spread !== 0) {
                const local_vk = ek * (1 + spread / 100) * 1.2;
                setVk(local_vk);
            }
        }
        if (name === 'vk') {
            setVk(parseFloat(value));
            setSpread(getOnlySpread(ek, parseFloat(value), 0.2))
        }
        if (name === 'spread') {
            const local_vk = ek * (1 + spread / 100) * 1.2;
            setSpread(parseFloat(value));
            setVk(parseFloat(local_vk.toFixed(2)));
        }

    };

    const handleChangeTypes = (event, value, element) => {
        if (value.length < 1) return;
        console.log(value);
        setTypes(value);
    };

    const handleCloseAndClear = () => {
        setAddedArticles([]);
        setSetName('');
        setSearchString('');
        setFilteredList([]);
        setFilterError('');
        setIsArticleOpen(false);
        setDescription(warning);
        setImages([]);
        setIsOnline(false);
        setStartDate(DateTime.local());
        setThumbnailList([]);
        setOverviewImages([]);

        //BATCH
        setBatches([]);
        setDate(new Date());
        setMaxSetSize(-1);
        setCurrentSetSize(0);
        setEk(0);
        setVk(0);
        setSpread(0);

        handleClose();
    };

    const handleSave = () => {
        const newArticle = {
            articleNumber,
            producer: addedArticles.reduce((a, p) => a + (p.producer ? p.producer + ', ' : ''), '').slice(-2),
            productType: 'Geschenk',
            origin: addedArticles.reduce((a, p) => a + (p.origin ? p.origin + ', ' : ''), ''),
            description,
            name: setName,
            distillery: addedArticles.reduce((a, p) => a + (p.distillery ? p.distillery + ', ' : ''), ''),
            region: addedArticles.reduce((a, p) => a + (p.region ? p.region + ', ' : ''), ''),
            age: addedArticles.reduce((a, p) => a + (p.age ? p.age + ', ' : ''), ''),
            finish: addedArticles.reduce((a, p) => a + (p.finish ? p.finish + ', ' : ''), ''),
            alcVolume: addedArticles.reduce((a, p) => a + (p.alcVolume ? p.alcVolume + ', ' : ''), ''),
            types: types.map((t) => t.value),
            volume: addedArticles.reduce((a, p) => a + (p.volume ? p.volume + ', ' : ''), ''),
            images,
            isOnline,
            thumbnails: thumbnailList,
            overviewImages: overviewImages,
            startDate: startDate,
            peated: addedArticles.reduce((a, p) => a + (p.peated ? p.peated + ', ' : ''), ''),
            barrel: addedArticles.reduce((a, p) => a + (p.barrel ? p.distillery + ', ' : ''), ''),
            grain: addedArticles.reduce((a, p) => a + (p.grain ? p.grain + ', ' : ''), ''),
            isSet: true,
            set: addedArticles.map((p) => p.articleNumber),
            setVolume: addedArticles.map(p => p.volume)
        };
        const newBatch = {
            active: true,
            amount: currentSetSize,
            currentAmount: currentSetSize,
            date: date,
            ek,
            reserved: 0,
            spread,
            vk_net: vk,
            vk_pre: parseFloat((vk / 1.2).toFixed(2)),
            set: addedArticles.map((p) => ({articleNumber: p.articleNumber, batch: p.activeBatch})),
        };
        const {updateArticleSet, createTransaction} = store.articleStore;
        updateArticleSet(newArticle);
        if (currentSetSize > 0 && !editMode) {
            const ref = database.ref('batches').child(articleNumber).push(newBatch);
            newArticle.activeBatch = ref.key;
            for (const b of batches) {
                const foundArticleNumber = addedArticles.find(a => a.activeBatch === b.key).articleNumber;
                createTransaction(foundArticleNumber, currentSetSize, "newArticle", b.key, b.vk_net, b.ek);
            }
        }
        updateArticleSet(newArticle);
        handleCloseAndClear();
    };

    const handleThumbnailClick = (nextThumbnailList) => {
        const changeList = nextThumbnailList.filter(thumbnail => !thumbnailList.includes(thumbnail));
        setThumbnailList(changeList);
    }

    const removeImage = url => {
        const i = images.indexOf(url);
        if (i > -1) images.splice(i, 1);
        setImages([...images]);
    };

    const deleteOldImg = url => {
        const nextImages = [...images];
        const index = images.indexOf(url);
        if (index > -1) nextImages.splice(index, 1);
        setImages(nextImages);
    }

    const handleOverviewImagesClick = (nextOverviewImages) => {
        setOverviewImages(nextOverviewImages);
    }

    const getThumbnailTag = (a) => {
        if (!!a.thumbnails && a.thumbnails.length > 0) {
            return <Avatar> <DbImage src={['images', a.articleNumber, a.thumbnails[0]]}
                                     alt={`Thumbail für ${a.articleNumber}`}
                                     style={{width: '100%', height: '100%'}}/> </Avatar>
        }
        if (!!a.images && a.images.length > 0) return <Avatar src={a.images[0]}/>
        return <Avatar><FontAwesomeIcon icon={faWineBottle}/></Avatar>
    }

    return <Modal open={open} handleClose={handleCloseAndClear} title={"Neues Artikel Set hinzufügen"}
                  actions={<Button color={"primary"} variant={"contained"} onClick={handleSave}>Speichern</Button>}>
        <Grid container spacing={1} className={classes.otherFont}>
            <Grid item xs={12}>
                <TextField
                    value={articleNumber}
                    variant={'outlined'}
                    label={'Artikelnummer'}
                    disabled
                    fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={setName}
                    variant={'outlined'}
                    label={'Artikelname'}
                    autoFocus
                    onChange={(e) => setSetName(e.target.value)}
                    fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="type"
                    size="medium"
                    multiple
                    value={types}
                    limitTags={3}
                    options={typeList}
                    onChange={handleChangeTypes}
                    getOptionLabel={(option) => option.label}
                    style={{width: '100%'}}
                    renderInput={(params) => <TextField {...params} label="Artikel Set Kategorien" variant="outlined"/>}
                />
            </Grid>
            {!editMode &&
            <Grid item xs={12}>
                <form onSubmit={startSearch}>
                    <TextField
                        value={searchString}
                        variant={'outlined'}
                        label={'Artikel Suchen'}
                        onChange={(e) => setSearchString(e.target.value)}
                        fullWidth
                        error={filterError.length > 0}
                        helperText={filterError.length > 0 ? filterError : 'Es kann nach Artikelnummer, Hersteller, Distillery und Name Gesucht werden.'}
                        autoComplete={'on'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" onClick={startSearch} style={{cursor: 'pointer'}}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
                <div style={{height: 200, overflow: 'auto'}}>
                    <h5>Gefundene Artikel</h5>
                    {filteredList.length === 0 && <Grid container spacing={1} alignItems={'center'} style={{width: 'calc(100% + 4px)'}}>
                        <Grid item xs={12}>
                            <Typography>Kein Artikel Gefunden</Typography>
                        </Grid>
                    </Grid>
                    }
                    {filteredList.map((a, i) => (
                        <Grid key={i} container spacing={1} alignItems={'center'} style={{width: 'calc(100% + 4px)'}}>
                            <Grid item xs={2} md={1}>
                                {getThumbnailTag(a)}
                            </Grid>
                            <Grid item xs={10} md={2}>
                                {a.articleNumber}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {a.producer}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {a.distillery}
                            </Grid>
                            <Grid item xs={12} md={2}>
                                {a.name}
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton onClick={addArticle(a)}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </IconButton>
                            </Grid>
                            {!a.activeBatch && <Grid item xs={12}> Diesem Artikel ist zurzeit keiner Lieferung zugeordnert</Grid>}
                        </Grid>
                    ))}
                </div>
            </Grid>
            }
            <Grid item xs={12}>
                <Collapse in={isArticleOpen} collapsedHeight={60}>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item xs={10} md={10}>
                            <h5>Hinzugefügte Artikel: {addedArticles.length}</h5>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            {addedArticles.length > 0 && <IconButton onClick={() => setIsArticleOpen(!isArticleOpen)}>
                                {isArticleOpen ?
                                    <FontAwesomeIcon icon={faChevronUp}/> :
                                    <FontAwesomeIcon icon={faChevronDown}/>
                                }
                            </IconButton>}
                        </Grid>
                    </Grid>
                    {addedArticles.map((a, i) => (
                        <Grid key={i} container spacing={1} alignItems={'center'} style={{width: 'calc(100% + 4px)'}}>
                            <Grid item xs={2} md={1}>
                                {getThumbnailTag(a)}
                            </Grid>
                            <Grid item xs={10} md={2}>
                                {a.articleNumber}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {a.producer}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {a.distillery}
                            </Grid>
                            <Grid item xs={12} md={2}>
                                {a.name}
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton onClick={removeArticle(i)}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </IconButton>
                            </Grid>
                            {!a.activeBatch && <Grid item xs={12}> Diesem Artikel ist zurzeit keiner Lieferung zugeordnert</Grid>}
                        </Grid>
                    ))}
                </Collapse>
            </Grid>
            {!editMode && <Grid item xs={12}>
                <h5>Lieferung:</h5>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={6}><DatePicker label={'Lieferdatum'} fullWidth inputVariant={'outlined'} format="dd.MM.yyyy" value={date}
                                                  onChange={setDate}/></Grid>
                    <Grid item xs={6}>
                        {maxSetSize > -1 &&
                        <FormControl fullWidth variant={"outlined"}>
                            <InputLabel id="amount-id-label">Anzahl</InputLabel>
                            <Select
                                id={'amount-id'}
                                label={'amount-id-label'}
                                value={currentSetSize}
                                MenuProps={{
                                    disableScrollLock: true
                                }}
                                onChange={(e) => setCurrentSetSize(e.target.value)}
                                className={classes.number}
                            >
                                {Array.from({length: maxSetSize + 1}, (_, i) => i).map((v, k) => (
                                    <MenuItem key={k} value={v}>{v}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id={"batch-ek"}
                            variant={"outlined"}
                            label="Einkaufspreis"
                            value={ek}
                            type="number"
                            name={'ek'}
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faEuroSign}/>
                                </InputAdornment>,
                                inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id={"batch-spread"}
                            variant={"outlined"}
                            label="Marge"
                            value={spread}
                            type="number"
                            name={'spread'}
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                                startAdornment: (vk / 1.2) < ek ? <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faMinus}/>
                                </InputAdornment> : null,
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faPercentage}/>
                                </InputAdornment>,
                                inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}>
                        <FontAwesomeIcon icon={faEquals}/>
                    </Grid>
                    <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}> {(vk / 1.2).toFixed(2)}</Grid>
                    <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}> <FontAwesomeIcon icon={faTimes}/></Grid>
                    <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}> 20 <FontAwesomeIcon icon={faPercentage}/></Grid>
                    <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}> <FontAwesomeIcon icon={faEquals}/></Grid>
                    <Grid item xs={2}>
                        <TextField
                            id={"batch-vk"}
                            variant={"outlined"}
                            label="VK"
                            value={vk}
                            type="number"
                            name={'vk'}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faEuroSign}/>
                                </InputAdornment>,
                                inputProps: {min: 0, step: 1, style: {textAlign: 'right'}}
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>}
            <Grid item xs={12}>
                <EditDescription description={description} setDescription={(val) => {setDescription(val)}} classes={classes}/>
            </Grid>
            <Grid item xs={12}>
                <div style={{padding: '1rem 0.5rem'}}>
                    {thumbnailList.length > 0 && <Typography variant={"h6"}>Thumbnail</Typography>}
                    {thumbnailList.map((image, i) => (
                        <Avatar key={i} variant={'rounded'} style={{display: 'inline-block', margin: '0.5rem', width: '4em', height: '4em'}}>
                            <DbImage src={['images', articleNumber, image]} alt={image} style={{width: '100%', height: '100%'}}/>
                        </Avatar>
                    ))}
                    {overviewImages.length > 0 && <Typography variant={"h6"}>Detail Bilder</Typography>}
                    {overviewImages.map((image, i) => (
                        <Avatar key={i} variant={'rounded'} style={{display: 'inline-block', margin: '0.5rem', width: '5em', height: '5em'}}>
                            <DbImage src={['images', articleNumber, image]} alt={image} style={{width: '100%', height: '100%'}}/>
                        </Avatar>
                    ))}
                    <br/>
                    <Button color={"primary"} variant={"contained"} onClick={() => setEditImages(true)}>Bilder Bearbeiten</Button>
                </div>

                <ImagePickerModal
                    isSet
                    open={editImages}
                    handleClose={() => setEditImages(false)}
                    subPath={articleNumber}
                    oldImages={images}
                    deleteOldImg={deleteOldImg}
                    selectedTypeOne={overviewImages} selectTypeOne={"Detail Bilder"} selectTypeOneCallback={handleOverviewImagesClick}
                    selectedTypeTwo={thumbnailList} selectTypeTwo={"Thumbnail"} selectTypeTwoCallback={handleThumbnailClick}
                />
            </Grid>
            <Grid item xs={12}>
                <EditOnline startDate={startDate} setStartDate={setStartDate} setIsOnline={setIsOnline} isOnline={isOnline} classes={classes}/>
            </Grid>
        </Grid>
    </Modal>
}));

export default EditArticleSetModal;
