import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {useStyles} from "./styling";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import {DateTime} from "luxon";

const CookieBanner = () => {
    const {wrapperCookieBanner, closeIcon, linkStyle} = useStyles();
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const ageCheck = sessionStorage.getItem("cookieCheck");
        if(ageCheck){ setIsOpen(false)}
    }, [])

    const handleClose = () => {
        sessionStorage.setItem("cookieCheck", DateTime.local().toMillis().toString(10));
        setIsOpen(false);
    }

    return isOpen ? <div className={wrapperCookieBanner}>
        <IconButton className={closeIcon} onClick={() => setIsOpen(false)}><FontAwesomeIcon icon={faTimes} /></IconButton>
        <Typography component={'h3'}> Diese Seite verwendet Cookies zum speichern von Daten, für weitere Informationen klicken sie <Link to={'/dataSecurity/'} className={linkStyle}>HIER</Link> </Typography>
        <Button variant={'contained'} color={'primary'} onClick={handleClose}>Schließen</Button>
    </div> : null
};

export default CookieBanner;

