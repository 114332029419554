import React, {useEffect, useState} from 'react';
import {Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Select} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {inject, observer} from "mobx-react";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import BatchModal from "../BatchModal";
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}));

const AdminExtraMenu = ({store, articleNumber, isSet, isArchived, isOffline, isSample, maxFlasks, batch}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [isBatchOpen, setIsBatchOpen] = React.useState(false);
    const [flasks, setFlasks] = useState(1);
    const [glasses, setGlasses] = useState(16);
    const {addMessages} = store.snackBarHandle;

    useEffect( () => {
        if(maxFlasks < 1){
            setFlasks(0);
        }
    }, [maxFlasks])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const toggleSample = () => setOpen(!open);

    const createSamples = () => {
        const amount = flasks;
        addMessages(`${glasses * flasks} Samples für ${articleNumber} erstellt`);
        store.articleStore.createSample(articleNumber, amount, glasses);
        setFlasks(maxFlasks === 1 ? 0 : 1);
        setOpen(false);
        setAnchorEl(null);
    };

    const createAd = () => {
        addMessages(`Weberbeflasche für ${articleNumber} abgezogen`);
        store.articleStore.addAdTransaction(articleNumber, 1, 'ad');
        setAnchorEl(null);
    };

    const createTasting = () => {
        addMessages(`Tastingflasche für ${articleNumber} abgezogen`);
        store.articleStore.addAdTransaction(articleNumber, 1, 'tasting');
        setAnchorEl(null);
    };

    const openBatch = () => {
        setIsBatchOpen(true);
        setAnchorEl(null);
    };

    const removeSet = () => {
      addMessages(`Geschneksset für ${articleNumber} wird aufgelöst`);
      store.articleStore.releaseSet(articleNumber);
      setAnchorEl(null);
    };

    const archiveArticle = (reset) => () => {
        addMessages(`Artikel ${articleNumber} wurde archiviert`);
        store.articleStore.archiveArticle(articleNumber, reset);
        setAnchorEl(null);
    }

    return <div>
        <IconButton
                color={'primary'}
                aria-controls={'whisky-options'}
                aria-haspopup="true"
                onClick={handleClick}>
            <FontAwesomeIcon icon={faBars}/>
        </IconButton>
        <Menu
            id={'whisky-options'}
            anchorEl={anchorEl}
            keepMounted
            disableScrollLock={true}
            open={!!anchorEl}
            onClose={handleClose}
            className={classes.otherFont}
        >
            { !isArchived && <MenuItem onClick={openBatch} className={classes.otherFont}>Lieferungen Bearbeiten</MenuItem> }
            { !isArchived && !isSet && <MenuItem onClick={createAd}>Werbeflasche nehmen</MenuItem> }
            { !isArchived && !isSet && <MenuItem onClick={createTasting}>Tastingflasche nehmen</MenuItem> }
            { !isArchived && isSet && <MenuItem onClick={removeSet}>Set Auflösen</MenuItem> }
            { !isArchived && !isSet && !isSample && maxFlasks > 0 && <MenuItem onClick={toggleSample}>Samples erstellen</MenuItem> }
            { !isArchived && isOffline && <MenuItem onClick={archiveArticle()}>Archivieren</MenuItem> }
            { isArchived && <MenuItem onClick={archiveArticle(true)}>In bestand aufnehmen</MenuItem> }
        </Menu>

        { maxFlasks > 0 && <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableScrollLock={true}>
            <DialogTitle id="form-dialog-title">Anzahl der verwendeten Flaschen</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.otherFont}>
                    Gib an wie viele Flaschen zu Sampel Flaschen umgewandelt werden!
                </DialogContentText>
                <FormControl fullWidth variant="outlined" className={classes.otherFont} margin="dense">
                    <InputLabel id="input-flask-label">Flaschen</InputLabel>
                    <Select
                        labelId="input-flask-label"
                        id="input-role"
                        value={flasks}
                        onChange={(e) => setFlasks(e.target.value)}
                    >
                        { Array.from(Array(maxFlasks+1)).map( (e,index) => <MenuItem key={index} value={index}>{index}</MenuItem> )}
                    </Select>
                </FormControl>
                <TextField
                    className={classes.otherFont}
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    id="amount"
                    label="zu Samples"
                    type="number"
                    helperText="Anzahl erstelle Sample pro Flasche"
                    value={glasses}
                    onChange={(event) => setGlasses(event.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary" className={classes.otherFont}>
                    Abbrechen
                </Button>
                <Button onClick={createSamples} variant="contained" color="primary" className={classes.otherFont}>
                    Durchführen
                </Button>
            </DialogActions>
        </Dialog> }

        <BatchModal articleNumber={articleNumber} isOpen={isBatchOpen} toggleOpen={() => setIsBatchOpen(!isBatchOpen)} />
    </div>
};

export default inject('store')(observer(AdminExtraMenu));
