import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {inject, observer} from "mobx-react";
import {List, Container, Box, Typography, Grid, Button, InputAdornment, Divider} from "@material-ui/core";
import {CartEntry} from './CartEntry';
import {useStyles} from "./styling";
import {Link} from "react-router-dom";
import {cleanPriceString} from "common/statelessFunctions";
import {getShipping, getShippingAbo, getSumPre, getMWST, getSumInkShipping} from "common/statelessFunctions";
import TextField from "@material-ui/core/TextField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign, faPlus} from "@fortawesome/free-solid-svg-icons";

const CartPage = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const history = useHistory();
    const {cart} = store.cartStore;
    const {coupon, handleAddCoupon, getCoupon} = store.orderStore;
    const [code, setCode] = useState('');

    useEffect(() => {
        if(!!coupon) {
            setCode(coupon.code);
        }
    }, [coupon]);

    const next = () => {

        const order = {
            products: store.cartStore.cart,
        };
        // now it is appended at the end of data at the Database
        store.orderStore.addNewOrder(order, false);

        if (store.userStore.isSignedIn) {
            history.push('/warenkorb/payment/');
        } else {
            history.push('/warenkorb/login/');
        }
    };
    const onKeyEnterCoupon = (event) => {
        if(event.keyCode !== 13) return;
        handleAddCoupon(event.target.value)();
    };

    if (cart.length <= 0) {
        return <Container maxWidth={'md'}>
            <h2> Mein Warenkorb </h2>
            <Box className={classes.paddingBottom + ' ' + classes.otherFont}>
                <Typography> Noch sind keine Artikel in deinem Warenkorb vorhanden. </Typography>
                <Typography> Gehe doch in unseren Shop um welche hinzuzufügen </Typography>
                <Link to={'/whisky'}>Hier geht es zum Shop</Link>
            </Box>
        </Container>;
    }
    return <Container maxWidth={'md'} align={'center'} className={classes.minHeight + ' ' + classes.otherFont}>
        <h2> Mein Warenkorb </h2>
        <List>
            {cart.map((entry, key) => (
                <CartEntry key={key} entry={entry}/>
            ))}
        </List>
        <Box>
            <Grid container>
                <Grid item xs={8} md={9} className={classes.alignRight}>
                    <Typography component={'div'}>Kosten:</Typography>
                </Grid>
                <Grid item xs={4} md={3} className={classes.alignRight}>
                    <Typography component={'div'} className={classes.paddingRight}><FontAwesomeIcon icon={faEuroSign} /><span className={classes.moneySpan}>{cleanPriceString(getSumPre(cart, 0.2))}</span></Typography>
                </Grid>

            </Grid>
            <Grid container>
                <Grid item xs={8} md={9} className={classes.alignRight}>
                    <Typography component={'div'}>Mwst:</Typography>
                </Grid>
                <Grid item xs={4} md={3} className={classes.alignRight}>
                    <Typography component={'div'} className={classes.paddingRight}><FontAwesomeIcon icon={faEuroSign} /><span className={classes.moneySpan}>{cleanPriceString(getMWST(cart, 0.2))}</span></Typography>
                </Grid>

            </Grid>
            <Grid container>
                <Grid item xs={8} md={9} className={classes.alignRight}>
                    <Typography component={'div'}>Versandkosten:</Typography>
                </Grid>
                <Grid item xs={4} md={3} className={classes.alignRight}>
                    <Typography component={'div'} className={classes.paddingRight}><FontAwesomeIcon icon={faEuroSign} /><span className={classes.moneySpan}>{cleanPriceString(getShipping(cart) + getShippingAbo(cart))}</span></Typography>
                </Grid>
            </Grid>
            <Divider style={{margin: '1em 0'}} />

            <Grid container>
                <Grid item xs={8} md={9} className={classes.alignRight}>
                    <Typography component={'div'}>Summe:</Typography>
                </Grid>
                <Grid item xs={4} md={3} className={classes.alignRight}>
                    <Typography component={'div'} className={classes.paddingRight}><FontAwesomeIcon icon={faEuroSign} /><span className={classes.moneySpan}> {cleanPriceString(getSumInkShipping(cart, 0.2))}</span></Typography>
                </Grid>
            </Grid>
            <Grid container alignItems={'center'}>
                <Grid item xs={12} md={5} className={classes.alignRight}>
                    <TextField
                        id="coupon"
                        label="Gutschein Code"
                        variant="outlined"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        onKeyDown={onKeyEnterCoupon}
                        type="text"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" style={{cursor: 'pointer'}} onClick={handleAddCoupon(code)}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={8} md={4} className={classes.alignRight}>
                    <Typography component={'div'}>Rabatte:</Typography>
                </Grid>
                <Grid item xs={4} md={3} className={classes.alignRight}>
                    <Typography component={'div'} className={classes.paddingRight}><FontAwesomeIcon icon={faEuroSign} /><span className={classes.moneySpan}> - {cleanPriceString(getCoupon(cart, 0.2))}</span></Typography>
                </Grid>
            </Grid>
            {!!coupon &&
            <Grid container>
                <Grid item xs={8} className={classes.alignLeft}>
                    <Typography component={'div'} className={classes.smallText}>{coupon.description}</Typography>
                    <Typography component={'div'} className={classes.smallText}>{coupon.conditionDescription}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.alignRight} />
            </Grid>
            }
            <Grid container>
                <Grid item xs={8} md={9} className={classes.alignRight}>
                    <Typography component={'div'}>Endsumme:</Typography>
                </Grid>
                <Grid item xs={4} md={3} className={classes.alignRight}>
                    <Typography component={'div'} className={classes.paddingRight}><FontAwesomeIcon icon={faEuroSign} /><span className={classes.moneySpan}>{cleanPriceString(getSumInkShipping(cart, 0.2) - getCoupon(cart, 0.2))}</span></Typography>
                </Grid>
            </Grid>
            <Divider style={{margin: '1em 0'}} />
        </Box>
        <Box className={classes.lastBox}>
            <Grid container>
                <Grid item xs={7} sm={9}/>
                <Grid item xs={5} sm={3}>
                    <Button fullWidth variant={'contained'} color={'primary'} onClick={next}> ZUR KASSE </ Button>
                </Grid>
            </Grid>
        </Box>
    </Container>
}));

export default CartPage;
