import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    moneySpan:{
        display: 'inline-block',
        minWidth: '60px',
        marginLeft: '0.5em'
    },
    row: {
        display: 'flex',
        padding: '0.5em',
        alignItems: 'center'
    },
    avatar: {
        height: '7em',
        width: '5em',
        margin: '0.25em'
    },
    spacing: {
        margin: '0.25em'
    },
    alignCenter: {
        display: 'flex',
        alignItems: ' center',
        justifyContent: 'center',
    },
    lastBox: {
        paddingBottom: '1em',
    },
    description: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    number: {
        fontSize: '1.5em',
    },
    alignRight: {
        textAlign: 'right',
    },
    alignLeft: {
        textAlign: 'left'
    },
    paddingRight: {
        paddingRight: '0.85em',
    },
    paddingBottom: {
        paddingBottom: '2em',
        minHeight: 'calc(100vh - 485px)',
    },
    minHeight: {
        minHeight: 'calc(100vh - 450px)',
    },
    smallText: {
        fontSize: '0.85em',
        padding: '0.15em'
    }
}));
