import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import {database} from "common/firebase";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
}))

export const TeilnehmerDialog = ({open, handleClose, eventKey}) => {
    const classes = useStyles();
    const [list, setList] = useState([]);

    useEffect(()=> {
        database.ref('events_attendees').child(eventKey).on('value', snap => {
            const newList = [];
            if(snap.exists()){
                snap.forEach( snapChild => {
                    if(snapChild.key !== 'count'){
                        newList.push(snapChild.val())
                    }
                })
                setList(newList);
            }
        })
    },[eventKey]);


    return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableScrollLock={true}>
        <DialogTitle id="form-dialog-title">Alle eingetragenen Teilnehmer</DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.otherFont}>
                {list.length > 0 ? list.map((attendee, i) =>(<span key={i}>{attendee.email}{i !== list.length-1 ? ';' : ''}</span>) ) : 'Noch keine Teilnehmer'}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Schließen
            </Button>
        </DialogActions>
    </Dialog>
}
