import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    formStyle:{
      paddingBottom: '2em',
    },
    infoText:{
        textAlign: 'justify'
    },
    textField: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        marginBottom: '0.75em',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    multiline: {
        minHeight: 95,
        alignItems: 'flex-start',
    },
    imgWrapperStyle:{
        float: 'left',
        marginRight: '2em',
        width: '50%',
        maxHeight: '100%',
    },
    imgStyle:{
        width: '100%',
        maxHeight: '100%',
        marginBottom: '5em',
    },
}));
