import React, {useEffect, useState} from 'react';
import {storage} from "common/firebase";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

const DbImage = ({src, alt, ...other}) => {
    const [localSrc, setLocalSrc] = useState(null);

    useEffect(() => {
        if (src && Array.isArray(src) && src.length > 0) {
            let ref = src.join('/');
            const url = sessionStorage.getItem(ref);
            if (url) {
                setLocalSrc(url);
            } else {
                storage.ref(ref).getDownloadURL().then((url) => {
                    setLocalSrc(url);
                    sessionStorage.setItem(ref, url);
                }).catch(e => {
                    setLocalSrc('-');
                    console.error(e);
                });
            }
        }
    }, [src]);

    if (!localSrc) return <FontAwesomeIcon icon={faSpinner} spin/>
    return <img src={localSrc} alt={alt} {...other}/>
}

export default DbImage;
