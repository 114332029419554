import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    textField: {
        marginBottom: '0.75em',
    },
    otherFont:{
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}));
