import React from 'react';
import {inject, observer} from "mobx-react";
import {IconButton, Snackbar} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}))

const SnackBar = inject('store')(observer(({store}) => {
    const classes = useStyles();
    return <div className={classes.otherFont}>
        {store.snackBarHandle.messages.map( (m,i) =>(
            <Snackbar
                key={i}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={true}
                onClose={(e) => store.snackBarHandle.removeMessages(i)}
                style={{bottom: 24 + i * (60)}}
                message={m}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={(e) => store.snackBarHandle.removeMessages(i)}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </IconButton>
                    </React.Fragment>
                }
            />
        ))}
    </div>
}));
export default SnackBar;
