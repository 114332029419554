import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {LinearProgress} from '@material-ui/core';
import {inject, observer} from "mobx-react";

const ProtectedRoute = inject('store')(observer(({store, children, level, ...rest}) => {
    const isLoading = !store.userStore.firebaseChecked;

    const isAuthenticated = store.userStore.isSignedIn && store.userStore.currentRole >= level;

    if (isLoading) return <LinearProgress/>;

    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ?
                    children :
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
            }
        />
    );
}));

export default ProtectedRoute;
