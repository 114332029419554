import { observable, action, decorate } from "mobx"
import firebase, {auth, database} from "common/firebase";


class BillStore {

    constructor () {}

    loadedBill = null;

    setBill = bill => {
        this.loadedBill = bill;
    }

}

decorate(BillStore, {
    loadedBill: observable,
    setBill: action,
});

export default BillStore;
