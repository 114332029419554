import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Menu, MenuItem, TextField,
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBan, faBars, faBox, faDownload, faEdit, faGlassWhiskey, faInfoCircle, faMoneyBill, faMoneyBillWave, faReceipt, faTasks, faTrash
} from "@fortawesome/free-solid-svg-icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import {database, functions} from "../../../../common/firebase";
import {makeStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons/faEnvelope";
import {DateTime} from "luxon";

export const useStyles = makeStyles(theme => ({
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}));

const optionsLabel = {
    payed: 'Wurde Bezahlt',
    waiting: 'Warte auf Bezahlung',
    delete: 'Löschen',
    storno: 'Stornieren',
    progress: 'Wird Bearbeitet',
    send: 'Wurde versendet',
    edit: 'Bearbeiten'
}

const optionsIcons = {
    payed: faMoneyBillWave,
    waiting: faReceipt,
    delete: faTrash,
    storno: faBan,
    progress: faTasks,
    send: faBox,
    edit: faEdit,
}

const Options = inject("store")(observer(({store, order, openDetails, openEdit}) => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [mailInputOpen, setMailInputOpen] = useState(false);
    const [mailSending, setMailSending] = useState(false);
    const [mail, setMail] = useState('');
    const [list, setList] = useState([]);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        const options = [];
        if (order.state === 'pending') {
            options.push('payed');
            options.push('waiting');
            options.push('delete');
        } else if (order.state === 'waiting') {
            if (order.isManuel) options.push('edit');
            options.push('payed');
            options.push('storno');
        } else if (order.state === 'payed') {
            options.push('progress');
            options.push('send');
            options.push('storno');
        } else if (order.state === 'progress') {
            options.push('send');
            options.push('storno');
        } else if (order.state === 'create') {
            options.push('edit');
            options.push('payed');
            options.push('waiting');
            options.push('delete');
        }

        setList(options);
    }, [order]);

    const handleClick = (event) => {
        event.preventDefault();
        setOpenMenu(true);
    };

    const handleClose = () => {
        setOpenMenu(false);
    };

    const handleNext = (next) => () => {
        if (next === 'edit') {
            openEdit();
        } else if (next === 'delete') {
            // ToDo Delete ?
            database.ref('orders').child(order.orderNumber).update({state: next});
        }else if (next === 'payed') {
            database.ref('orders').child(order.orderNumber).update({state: next});
            database.ref('orders').child(order.orderNumber).child('billData').update({isPayed: true});
        } else {
            database.ref('orders').child(order.orderNumber).update({state: next});
        }
        handleClose();
    };

    const handleOpenDetails = () => {
        openDetails();
        handleClose();
    };

    const handleOpenBill = () => {
        store.billStore.setBill({
            billNumber: order.billData.billNumber,
            isPayed: order.billData.isPayed,
            state: order.state,
            timestamp: order.timestamp,
            address: order.paymentAddress,
            products: order.products,
            email: order.paymentEmail,
            shippingCost: order.billData.shippingCost,
            payed: order.billData.payed,
            isWw: order.isWw,
            tax: order.billData.tax,
            coupon: order.coupon || null,
            withoutTax: order.billData.withoutTax,
            orderNumber: order.orderNumber,
            paymentDay: order.billData.paymentDay,
            paymentMethod: !order.prePaid ? !order.paypal ? 'card' : 'paypal' : 'prepaid',
            sendMail: order.sendMail,
            year: DateTime.fromMillis(order.timestamp).toFormat('yy')
        });
        history.push('/print/bill/');

    };

    const toggleSendMail = () => {
        setMail("");
        if(!mailInputOpen) setMail(order.paymentEmail);
        setMailInputOpen(!mailInputOpen);
    };

    const sendBillMailTo = () => {
        setMailSending(true);
        if (mail) {
            functions.httpsCallable('sendBillPdfToMailAddress')({order: order, email: mail}).then(res => {
                store.snackBarHandle.addMessages('Mail wurde versendet.');
                setMailInputOpen(false);
                setMailSending(false);
            }).catch(e => {
                console.error(e);
                store.snackBarHandle.addMessages('Mail konnte nicht ordnungsgemäß versendet werden.');
            });
        }
    };

    const downloadBill = () => {
        setDownloading(true);
        store.snackBarHandle.addMessages('Pdf wird am server erstellt und danach automatisch herunter geladen, dies kan einige Minuten dauern.');
        handleClose();
        functions.httpsCallable('createPdfForDownload')({order: order}).then(res => {
            function toArrayBuffer(buf) {
                const length = Object.keys(buf).length
                const ab = new ArrayBuffer(length);
                const view = new Uint8Array(ab);
                for (let i = 0; i < length; ++i) {
                    view[i] = buf[i];
                }
                return ab;
            }

            const blob = new Blob([toArrayBuffer(res.data)], {type: "application/pdf"});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            console.log(order);
            link.download = `Rechnung_${order.billData.billNumber}_${DateTime.fromMillis(order.billData.timestamp).toFormat('ddLLyyyy')}.pdf`;
            link.click();
            setDownloading(false);
        }).catch(e => {
            console.error(e);
            store.snackBarHandle.addMessages('Beim Erstellen des Files ist ein Fehler aufgetreten.');
            setDownloading(false);
        });
    };
    //['pending','waiting','payed', 'progress', 'create', 'storno', 'send', 'delete', 'edit']
    const isAllowedToLook = state => ['create', 'waiting', 'payed', 'progress', 'send'].indexOf(state) > -1
    const isAllowedToDownLoad = state => ['payed', 'progress', 'send'].indexOf(state) > -1
    const isAllowedToSend = state => ['waiting', 'payed', 'progress', 'send'].indexOf(state) > -1

    return <div>
        <IconButton
            ref={ref}
            color={'primary'}
            aria-controls={'whisky-event-options'}
            aria-haspopup="true"
            onClick={handleClick}>
            <FontAwesomeIcon icon={downloading ? faGlassWhiskey : faBars} spin={downloading}/>
        </IconButton>
        <Menu
            id={'whisky-event-options'}
            anchorEl={ref.current}
            keepMounted
            disableScrollLock={true}
            open={openMenu}
            onClose={handleClose}
            className={classes.otherFont}
        >
            <MenuItem onClick={handleOpenDetails}>
                <ListItemIcon><FontAwesomeIcon icon={faInfoCircle}/></ListItemIcon>
                <Typography variant="inherit">Details</Typography>
            </MenuItem>

            {isAllowedToLook(order.state) && <MenuItem onClick={handleOpenBill}>
                <ListItemIcon><FontAwesomeIcon icon={faMoneyBill}/></ListItemIcon>
                <Typography variant="inherit">{order?.billData?.isPayed || order.state === 'payed' ? 'Rechnung' : 'Auftrag'} Anschauen</Typography>
            </MenuItem>}

            {isAllowedToDownLoad(order.state) && <MenuItem onClick={downloadBill}>
                <ListItemIcon><FontAwesomeIcon icon={faDownload}/></ListItemIcon>
                <Typography variant="inherit">Rechnung Herunterladen</Typography>
            </MenuItem>}

            {isAllowedToSend(order.state) && <MenuItem onClick={toggleSendMail}>
                <ListItemIcon><FontAwesomeIcon icon={faEnvelope}/></ListItemIcon>
                <Typography variant="inherit">{order?.billData?.isPayed || order.state === 'payed' ? 'Rechnung' : 'Auftrag'} senden</Typography>
            </MenuItem>}

            {list.map((o, i) => (
                <MenuItem key={i} onClick={handleNext(o)}>
                    <ListItemIcon><FontAwesomeIcon icon={optionsIcons[o]}/></ListItemIcon><Typography variant="inherit">{optionsLabel[o]}</Typography>
                </MenuItem>
            ))}
        </Menu>


        <Dialog className={classes.otherFont} open={mailInputOpen} onClose={toggleSendMail} aria-labelledby="form-dialog-title" disableScrollLock={true}>
            <DialogTitle id="form-dialog-title">Senden an</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.otherFont}>
                    Gib die E-Mail addresse ein an wem die Rechnung gesendet werden soll
                </DialogContentText>
                <TextField
                    className={classes.otherFont}
                    autoFocus
                    margin="dense"
                    id="mail"
                    label="E-Mail Adresse"
                    type="email"
                    value={mail}
                    onChange={(event) => setMail(event.target.value)}
                    fullWidth
                />
                {mailSending && <LinearProgress/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleSendMail} color="primary" variant={"outlined"}>
                    Abbrechen
                </Button>
                <Button onClick={sendBillMailTo} color="primary" variant={"contained"} disabled={mailSending}>
                    Mail senden
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}));

export default Options;
