import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    boxStyle: {
        padding: '0.5em',
        margin: '0.25em'
    },
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    registerBtn: {
        margin: '0.25em'
    },
    textField:{
        margin: '0.5em 0',
    }
}));