import React, {useEffect, useState} from 'react';
import {database} from "common/firebase";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper, makeStyles, Tooltip, AppBar} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import {faCheck, faTimes, faClipboardList, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateTime} from "luxon";
import IconButton from "@material-ui/core/IconButton";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {observer, inject} from "mobx-react";
import {DeleteDialog} from "./DeleteDialog";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    hoverRow:{
        '&:hover' :{
            backgroundColor: '#eee'
        }
    },
    pointerRow: {
        cursor: 'pointer',
        borderLeft: 'solid 8px green'
    }
}))

const NewsletterList = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [list, setList] = useState([]);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedElement, setSelectedElement] = useState(null);

    useEffect(() => {
        database.ref('newsletterMailingList').on('value', snap => {
            if (snap.exists()) {
                const newList = []
                snap.forEach(personSnap => {
                    newList.push({...personSnap.val(), key: personSnap.key});
                })
                setList(newList);
            }
        })
    }, []);

    const getMailingList = () => {
        return list.reduce((a, m) => a + m.mail + '; ', "");
    }

    const setRead = (element) => {
        if (!!element.seenOnce) return;
        database.ref('newsletterMailingList').child(element.key).child('seenOnce').set(true).then();
    }

    const afterCopy = () => {
        list.forEach((element) => setRead(element));
        store.snackBarHandle.addMessages('E-Mail Adressen wurden in den Zwischenspeicher Copiert')
    }

    const triggerDelete = (element) => () => {
        setDeleteDialogOpen(true);
        setSelectedElement(element);
    }

    const handleDelete = () => {
        if (selectedElement?.key) database.ref('newsletterMailingList').child(selectedElement.key).set(null).then();
        setSelectedElement(null);
    }

    const handleCloseDialog = () => {
        setDeleteDialogOpen(false);
        setSelectedElement(null);
    }

    return <div>
        <AppBar position="static" className={classes.appBar}>Newsletter</AppBar>
        <TableContainer component={Paper} className={classes.otherFont}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                </TableHead>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Vorname</TableCell>
                        <TableCell align="right">Nachname</TableCell>
                        <TableCell align="right">E-Mail</TableCell>
                        <TableCell align="right">Datum</TableCell>
                        <TableCell align="right">Benutzer</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.sort((a, b) => (a.timestamp ?? 0) < (b.timestamp ?? 0) ? 1 : -1).map((person, index) => (
                        <TableRow key={index} className={classes.hoverRow + ' ' + (person.seenOnce ? '' : classes.pointerRow)} onClick={() => setRead(person)}>
                            <TableCell component="th" scope="row">{person.firstName}</TableCell>
                            <TableCell align="right">{person.lastName}</TableCell>
                            <TableCell align="right">{person.mail}</TableCell>
                            <TableCell align="right">{DateTime.fromMillis(person.timestamp ?? 0).toISODate()}</TableCell>
                            <TableCell align="right">{!!person.userId ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>}</TableCell>
                            <TableCell align="right">
                                <Tooltip title={'Eintrag löschen'}>
                                    <IconButton onClick={triggerDelete(person)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="right">
                            <Tooltip title={"Mail List Kopieren"}>
                                <CopyToClipboard text={getMailingList()} onCopy={afterCopy}>
                                    <IconButton color={"primary"}><FontAwesomeIcon icon={faClipboardList}/></IconButton>
                                </CopyToClipboard>
                            </Tooltip>
                        </TableCell>
                        <TableCell colSpan={4} align="right">Es sind {list.length} Leute für den Newsletter angemeldet</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <div style={{height: 24}}/>
        <DeleteDialog open={isDeleteDialogOpen} handleDelete={handleDelete} element={selectedElement} handleClose={handleCloseDialog}/>
    </div>
}));

export default NewsletterList;
