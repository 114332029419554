import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    gridProductStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr))',
        gridAutoRows: '450px',
        gridGap: '3em 1.5em',
        marginTop: '5em',
        marginBottom: '5em',
    },
    rowHeightBig:{
        gridAutoRows: 500,
    },
    btnCartStyle: {
        backgroundColor: '#333',
        color: '#f5f5dc',
        width: '100%',
        borderRadius: '0',

        '&:hover': {
            color: '#fff',
            backgroundColor: '#000',
        }
    },
    gridMinHeight: {
        minHeight: '80vh'
    },
    loading: {
        width: '100%',
        fontSize: '3em',
        minHeight: 200,
        marginTop: '2em'
    },
    addArticle: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'solid 1px #333',
        cursor: 'pointer',
        padding: '1em',
        '&:hover': {
            textDecoration: 'underline',
            boxShadow: '0 0 3px 0 #333'
        }
    },
    addArticleWrapper: {
        paddingBottom: '6em',

    }
}));