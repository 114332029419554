
export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function getRole(role) {
    switch (role) {
        case 0b0001 : return 'Kunde';
        case 0b0010 : return 'Wiederverkäufer';
        case 0b0100 : return 'Mitarbeiter';
        case 0b1000 : return 'Admin';
        default : return 'Gast';
    }
}

export const userFilterText = (filter) => user => {
    return (user.displayName || '').toLowerCase().includes(filter.toLowerCase()) ||
        (user.preferredAddress ? (user.preferredAddress.firstName || '') : '').toLowerCase().includes(filter.toLowerCase()) ||
        (user.preferredAddress ? (user.preferredAddress.lastName || '') : '').toLowerCase().includes(filter.toLowerCase()) ||
        (user.lastMailAddress || '').toLowerCase().includes(filter.toLowerCase()) ||
        (user.email || '').toLowerCase().includes(filter.toLowerCase());
}