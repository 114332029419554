import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    productImgStyle: {
        objectFit: 'contain',
        width: '100%',
        height: 300,
        filter: "drop-shadow(0px 0px 1px rgba(0,0,0,.9)) drop-shadow(0px 0px 1px rgba(0,0,0,.9))",
    },
    productImgStyleIcon: {
        objectFit: 'contain',
        width: '100%',
        height: 300,
        fontSize: '8em',
    },
    hoverEffect: {
        cursor: 'pointer',
        padding: '0.5em',
        marginBottom: '12px',
        '&:hover': {
            '& $productImgStyle': {
                filter: "drop-shadow(0px 0px 1px rgba(0,0,0,.9)) drop-shadow(0px 0px 7px rgba(0,0,0,.9))",

            },

            '& $productImgStyleIcon': {
                filter: "drop-shadow(0px 0px 1px rgba(0,0,0,.9)) drop-shadow(0px 0px 1px rgba(0,0,0,.9))",

            }
        }
    },
    price: {
        fontSize: '1.5em',
        fontWeight: 'bold',
    },
    infoPrice: {
        fontSize: '0.8em',
        overflow: 'hidden',
    },
    adminRow: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItem: 'stretch',
        padding: '0.25em'
    },
    menuBtn: {
        padding: '0.5em 0',
        fontSize: '1.3em'
    },
    articleObject: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    firstWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    textPosition: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '0.5em',
    },
    textTwoLines:{
        height: 40,
        overflow: "hidden",
    },
    textOneLine:{
        height: 17,
        overflow: "hidden",
    }
}));
