import React from 'react';
import {
 makeStyles, Backdrop
} from "@material-ui/core";
import DbImage from "../dbImage";

const useStyles = makeStyles(theme => ({
    imgBackdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    img:{
        maxWidth: '100%',
        maxHeight: '100%'
    }
}))

const ImageBackdrop = ({isOpen, handleClose, image, alt}) => {
    const classes = useStyles();

    return <Backdrop open={isOpen} onClick={handleClose} className={classes.imgBackdrop}>
        {isOpen && <DbImage src={image} alt={alt} className={classes.img} /> }
    </Backdrop>
};

export default ImageBackdrop;
