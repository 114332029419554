import React from "react";
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";

Modal.propTypes = {
    children: PropTypes.any,
    actions: PropTypes.any,
    title: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

function Modal ({children, title, open, handleClose, actions, size = "md"}) {
    return <Dialog open={open} onClose={handleClose} maxWidth={size} fullWidth disableScrollLock={true}>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
        {!!actions && <DialogActions>
            {actions}
        </DialogActions>}
    </Dialog>
}

export default Modal;
