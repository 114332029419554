import React, {useCallback, useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import {Link, useHistory} from 'react-router-dom';

import {Button, Container, IconButton, InputAdornment} from "@material-ui/core";
import {faCheck, faEdit, faExclamationTriangle, faFileInvoiceDollar, faGlassWhiskey, faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import PaypalButton from "components/paypalButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useStyles} from "./styling";
import {
    cleanPriceStringSpecial, getAboInfos, getEventList, getMWST, getShipping, getShippingAbo, getSumInkShipping, getSumPre, hasEvent, isAbo, isTooYoung,
    validateEmail
} from "common/statelessFunctions";
import TextField from "@material-ui/core/TextField/TextField";
import {Modal} from "../../components";
import {DatePicker} from "@material-ui/pickers";
import Backdrop from "@material-ui/core/Backdrop";
import {DateTime} from "luxon";
import {AddAddress} from "./addAddress";
import {auth, database} from "../../common/firebase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
//import AmazonPay from 'amazon-pay-react';
import {AgeInputDialog} from "../../components/AgeInputDialog";
import UnzerButton from "../../components/unzerButton";

const CartPayment = inject('store')(observer(({store}) => {
    const history = useHistory();
    const classes = useStyles();

    const {currentUser, loadingUser} = store.userStore;

    const {cart} = store.cartStore;
    const {addMessages} = store.snackBarHandle;
    const {lastOrder, coupon, handleAddCoupon, getCoupon, setCoupon} = store.orderStore;

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [displayTime, setDisplayTime] = useState();
    const [showButtons, setShowButtons] = useState(false);
    const [isPaying, setIsPaying] = useState(false);
    const [code, setCode] = useState('');
    const [infoMessagePayPal, setInfoMessagePayPal] = useState('');
    const [infoMessagePayPalError, setInfoMessagePayPalError] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [paymentEmail, setPaymentEmail] = useState('');
    const [birthday, setBirthday] = useState(DateTime.local());
    const [isAddAddressOpen, setIsAddAddressOpen] = useState(null);
    const [usersAddresses, setUsersAddresses] = useState([]);
    const [explicitShippingAddress, setExplicitShippingAddress] = useState(false);
    const [isEditMailOpen, setIsEditMailOpen] = useState(false);
    const [eventAttendees, setEventAttendees] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [agbChecked, setAgbChecked] = useState(false);
    const [highlightAgb, setHighlightAgb] = useState(false);

    const redirectTo = useCallback(url => {
        if (currentUser && currentUser.isAnonymous) auth.signOut();
        history.push(url)
    }, [history, currentUser]);

    //Birthdate
    const [birthdate, setBirthDate] = useState(DateTime.local());
    const setBirthDateHandler = (date) => {
        if (date.diffNow('years').years > -18) {
            store.snackBarHandle.addMessages('Sie sind leider nicht Alt genug um einen Einkauf auf dieser Seite abschließen zu dürfen,')
            history.push('/');
        } else {
            setBirthDate(date);
            lastOrder.birthday = date.toFormat('dd.LL.yyyy');
            setBirthday(date);
            if (currentUser.uid) {
                database.ref(`userdata/${currentUser.uid}/birthday`).set(lastOrder.birthday);
            }
        }
    }

    useEffect(() => {
        if (!!coupon) {
            setCode(coupon.code);
        }
    }, [coupon]);

    useEffect(() => {
        lastOrder.paymentEmail = paymentEmail;
    }, [paymentEmail])

    useEffect(() => {
        const interval = setInterval(() => {
            const {cart} = store.cartStore;
            if (cart && cart.length > 0) {
                const minTime = cart.slice().sort((a, b) => a.timestamp - b.timestamp)[0].timestamp;
                const diff = moment(minTime + (1000 * 60 * 60 * 2)).diff(moment(), 'seconds');
                setDisplayTime(moment("2020-01-01").startOf('day')
                .seconds(diff)
                .format('H:mm:ss'));
                if (minTime + (1000 * 60 * 60 * 2) - new Date().getTime() < 0) {
                    redirectTo('/warenkorb/');
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [store.cartStore.cart, redirectTo, store.cartStore]);

    useEffect(() => {
        if (Object.keys(lastOrder).length === 0 && lastOrder.constructor === Object) redirectTo('/warenkorb')
    }, [lastOrder, redirectTo]);

    useEffect(() => {
        if (currentUser) {
            if (lastOrder && !lastOrder.products) redirectTo('/warenkorb/');
            database.ref(`userdata/${currentUser.uid}`).once('value', snap => {
                const data = snap.val();
                if (data) {
                    const {addresses, lastMailAddress, birthday} = data;
                    setUsersAddresses(addresses || []);
                    if (!!addresses && addresses.length > 0) lastOrder.paymentAddress = addresses.filter(address => address.preferred)[0];
                    lastOrder.paymentEmail = (lastMailAddress || currentUser.email || 'keine hinterlegt');
                    setPaymentEmail(lastMailAddress || currentUser.email);
                    if (birthday) {
                        lastOrder.birthday = birthday;
                        setBirthday(DateTime.fromFormat(birthday, 'dd.LL.yyyy'))
                        setBirthDate(DateTime.fromFormat(birthday, 'dd.LL.yyyy'))
                    } else {
                        setBirthDate(null);
                    }
                }
            }).then();
        }
    }, [currentUser, loadingUser, lastOrder, redirectTo]);

    useEffect(() => {
        if (hasEvent(cart)) {
            const eventList = []
            getEventList(store.cartStore.cart).forEach((event, i) => {
                eventList.push({
                    event: event.key,
                    eventName: event.name,
                    year: event.year,
                    firstName: '',
                    lastName: '',
                    email: ''
                });
            });
            if ((explicitShippingAddress && lastOrder.shippingAddress) || (!explicitShippingAddress && lastOrder.paymentEmail)) {
                eventList.map(attendeesList => {
                    attendeesList.firstName = explicitShippingAddress ? lastOrder?.shippingAddress?.firstName ?? '' : lastOrder?.paymentAddress?.firstName ?? '';
                    attendeesList.lastName = explicitShippingAddress ? lastOrder?.shippingAddress?.lastName ?? '' : lastOrder?.paymentAddress?.lastName ?? '';
                    attendeesList.email = lastOrder?.paymentEmail ?? '';
                    return attendeesList
                })
            }
            setAttendeesListValidation(eventList);
            setEventAttendees(eventList);
        } else {
            setIsValid(true);
        }
    }, [lastOrder.shippingAddress, lastOrder.paymentAddress, explicitShippingAddress, cart, lastOrder.paymentEmail, store.cartStore.cart])

    const getSumForProduct = product => {
        return product.vk_net * product.amount;
    };

    const payBefore = async () => {
        if (!isTooYoung(birthday.toFormat('dd.LL.yyyy'))) {
            setInfoMessagePayPalError(false);
            setInfoMessagePayPal('Vorabzahlung gewählt.');
            setIsDialogOpen(false);
            setBackdropOpen(true);

            if (lastOrder.paymentEmail !== paymentEmail) {
                lastOrder.paymentEmail = paymentEmail;
                store.orderStore.updateOrder(lastOrder);
            }

            lastOrder.coupon = coupon;
            lastOrder.state = 'waiting';
            lastOrder.prePaid = "Vorabzahlung";
            lastOrder.billData.paymentMethod = "prepaid";
            lastOrder.birthday = birthday.toFormat('dd.LL.yyyy')

            await database.ref(`userdata/${currentUser.uid}/birthday`).set(lastOrder.birthday);

            delete lastOrder.paypal
            store.orderStore.updateOrder(lastOrder);
            store.orderStore.useCoupon(coupon);

            //setIsPaying(false);
            store.cartStore.clearCart();
            redirectTo('/warenkorb/order/');
        } else {
            setInfoMessagePayPalError(true);
            setInfoMessagePayPal('Sie müssen mindestens 18 Jahre alt sein um Alkohol auf dieser Seite kaufen zu dürfen.');
            alert('Sie sind leider zu Jung');
        }
    }

    const toggleInputPayBefore = _ => { setIsDialogOpen(!isDialogOpen); }

    const paymentApproval = async details => {
        setInfoMessagePayPalError(false);
        setInfoMessagePayPal('Zahlung erfolgreich');

        lastOrder.billData.paymentDay = "Bezahlt"
        lastOrder.billData.isPayed = true;
        lastOrder.billData.paymentMethod = 'paypal';
        lastOrder.coupon = coupon;
        lastOrder.state = 'payed';
        lastOrder.paypal = details;

        store.orderStore.updateOrder(lastOrder);
        store.orderStore.useCoupon(coupon);

        setIsPaying(false);
        store.cartStore.clearCart();
        redirectTo('/warenkorb/order/');
    };

    const triggerPayment = async () => {

        setMailError(false);

        if (explicitShippingAddress && !lastOrder.shippingAddress) {
            addMessages('Bitte Geben sie eine Lieferadresse an.')
            return;
        }

        if (lastOrder.paymentEmail === '' || !validateEmail(lastOrder.paymentEmail)) {
            setMailError(true);
            addMessages('Bitte geben sie eine valide E-Mail Adresse an welche wir weiter Informationen schicken können');
            return;
        }

        lastOrder.isWw = store.isWw;
        lastOrder.user = currentUser.uid;
        lastOrder.attendees = eventAttendees.length > 0 ? eventAttendees : null;
        lastOrder.shippingAddress = !explicitShippingAddress ? lastOrder.paymentAddress : lastOrder.shippingAddress;


        setInfoMessagePayPalError(false);
        setInfoMessagePayPal('Sollten sie nach der Zahlung nicht weitergeleitet werden, überprüfen sie bitte die Zahlung und versuchen es nocheinmal!');
        if (await store.orderStore.isCouponUsed(coupon)) {
            store.snackBarHandle.addMessages('Coupon wurde schon verwendet, geben sie einen anderen ein oder löschen sie diesen');
            return;
        }
        const payed = (getSumInkShipping(lastOrder.products) - getCoupon(lastOrder.products)).toFixed(2);
        lastOrder.coupon = coupon;
        lastOrder.timestamp = new Date().getTime();
        lastOrder.billData = {
            timestamp: new Date().getTime(),
            isPayed: false,
            withoutTax: getSumPre(lastOrder.products, 0.2),
            tax: getMWST(lastOrder.products, 0.2),
            shippingCost: getShipping(lastOrder.products) + getShippingAbo(lastOrder.products),
            payed,
            coupon: getCoupon(lastOrder.products),
            paymentDay: "Wir bitten um umgehende Überweisung",
        }
        lastOrder.payed = payed;

        const order = await store.orderStore.createNewOrder(lastOrder);
        order.billData.billNumber = 'BR' + DateTime.local().toFormat('LLyy') + order.orderNumber.slice(-5).toUpperCase();
        store.orderStore.updateOrder(order);
        setShowButtons(true);
    };

    const handlePayPalCancel = (data) => {
        data.wwState = 'canceled';
        setInfoMessagePayPalError(true);
        setInfoMessagePayPal('Sie habe die Zahlung abgebrochen, wollen sie doch zahlen, dann probieren sie es doch noch einmal, oder verwenden sie eine ander Zahlungsmethode');
        setIsPaying(false);
        lastOrder.paypal = data;
        // ToDo Set Bill PayPal Order Number
        store.orderStore.updateOrder(lastOrder);
    };

    const handlePayPalError = (data) => {
        data.wwState = 'error';
        setInfoMessagePayPalError(true);
        setInfoMessagePayPal('In der Abbuchung ist etwas schiefgegengen, probieren sie es doch noch einmal, oder verwenden sie eine ander Zahlungsmethode');
        setIsPaying(false);
        lastOrder.paypal = data;
        // ToDo Set Bill PayPal Order Number
        store.orderStore.updateOrder(lastOrder);
    };

    const selectAddressFor = (addressType) => (address) => {
        lastOrder[addressType] = address;
        setShowButtons(false);
    }

    const setValue = (i) => event => {
        const {name, value} = event.target;
        eventAttendees[i][name] = value;
        setEventAttendees([...eventAttendees]);
        setAttendeesListValidation(eventAttendees);
        setShowButtons(false);
    }

    const setAttendeesListValidation = (list) => {
        setIsValid(list.flatMap(l => l)
        .reduce((a, attendee) => a && attendee.firstName !== '' && attendee.lastName !== '' && validateEmail(attendee.email), true));
    }

    return <Container maxWidth={'md'}>
        <h3> Noch {displayTime} bis der erste Artikel im Warenkorb wieder freigebgen wird </h3>
        {!(Object.keys(lastOrder).length === 0 && lastOrder.constructor === Object) &&
        <Box>
            <Box className={classes.boxStyle}>
                <h3>{explicitShippingAddress ? 'Rechnungsaddresse' : 'Addresse'} <IconButton
                    onClick={() => setIsAddAddressOpen('paymentAddress')}><FontAwesomeIcon icon={faEdit}/></IconButton></h3>
                {lastOrder.paymentAddress ? <Box className={classes.alignLeft + ' ' + classes.otherFont}>
                        {!isEditMailOpen ?
                            <Typography>
                                {mailError &&
                                <Tooltip title={'Bitte geben sie eine valide E-Mail Adresse an welche wir weiter Informationen schicken können'}
                                         classes={{tooltip: classes.otherFont}}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} className={classes.errorIcon}/>
                                </Tooltip>
                                } Email Adresse: {lastOrder.paymentEmail} <IconButton onClick={() => setIsEditMailOpen(true)}><FontAwesomeIcon
                                icon={faEdit}/></IconButton></Typography>
                            :
                            <TextField
                                variant={'outlined'}
                                placeholder={'email@adresse.at'}
                                fullWidth
                                value={paymentEmail}
                                onChange={(event) => setPaymentEmail(event.target.value)}
                                label={'Rechnungsemail'}
                                helperText={"Diese E-Mail-Adresse wird verwendet um ihnen die Rechnung zu zusenden."}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" style={{cursor: 'pointer'}} onClick={() => setIsEditMailOpen(false)}>
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </InputAdornment>
                                    ),
                                }}/>
                        }
                        <Typography>{lastOrder.paymentAddress.firstName} {lastOrder.paymentAddress.lastName}</Typography>
                        <Typography>{lastOrder.paymentAddress.address1}</Typography>
                        <Typography>{lastOrder.paymentAddress.address2}</Typography>
                        <Typography>{lastOrder.paymentAddress.plz} {lastOrder.paymentAddress.region}</Typography>
                        <Typography>{lastOrder.paymentAddress.land}</Typography>
                    </Box> :
                    <Box className={classes.otherFont}>
                        <div>Noch wurde keine Adresse hinterlegt, fügen sie eine hinzu um den Einkauf fortzusetzen.</div>
                        <div><IconButton onClick={() => setIsAddAddressOpen('paymentAddress')}><FontAwesomeIcon icon={faPlus}/></IconButton></div>

                    </Box>}
                {lastOrder.paymentAddress && <Box className={classes.alignLeft + ' ' + classes.otherFont}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={explicitShippingAddress}
                                onChange={(event) => setExplicitShippingAddress(event.target.checked)}
                                name="shippingCheck"
                                color="primary"
                            />
                        }
                        label="Abweichende Lieferadresse"
                    />
                </Box>}
                {explicitShippingAddress && <>
                    <h3>Lieferadresse <IconButton onClick={() => setIsAddAddressOpen('shippingAddress')}><FontAwesomeIcon icon={faEdit}/></IconButton></h3>
                    {lastOrder.shippingAddress ? <Box className={classes.alignLeft + ' ' + classes.otherFont}>
                            <Typography>{lastOrder.shippingAddress.firstName} {lastOrder.shippingAddress.lastName}</Typography>
                            <Typography>{lastOrder.shippingAddress.address1}</Typography>
                            <Typography>{lastOrder.shippingAddress.address2}</Typography>
                            <Typography>{lastOrder.shippingAddress.plz} {lastOrder.shippingAddress.region}</Typography>
                            <Typography>{lastOrder.shippingAddress.land}</Typography>
                        </Box> :
                        <Box className={classes.otherFont}>
                            <div>Noch wurde keine Adresse hinterlegt, fügen sie eine hinzu um den Einkauf fortzusetzen.</div>
                            <div><IconButton onClick={() => setIsAddAddressOpen('shippingAddress')}><FontAwesomeIcon icon={faPlus}/></IconButton></div>

                        </Box>}
                </>}
                <AddAddress
                    title={'Adressen'}
                    databaseRef={'addresses'}
                    isOpen={!!isAddAddressOpen}
                    toggleOpen={() => setIsAddAddressOpen(null)}
                    handleSelectAddress={selectAddressFor(isAddAddressOpen)}
                    addressList={usersAddresses}/>
            </Box>
            {hasEvent(store.cartStore.cart) && eventAttendees.length > 0 && getEventList(store.cartStore.cart).map((event, i) => (
                <Box className={classes.alignLeft + ' ' + classes.boxStyle} key={i}>
                    <h3 className={classes.boxHeader}>{event.name}</h3>
                    <Typography component={'p'} className={classes.otherFont}>
                        Der hier eingetragene Empfänger bekommt die Eventkarten zugeschickt und auch alle weiteren Updates zum Event.
                        Die Daten Werden Automatisch aus der Lieferadresse und der Rechnungs-Email-Adresse zusammen gesetzt und können im hier noch bearbeitet
                        werden.
                    </Typography>
                    <form id={event.key + i}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><h4>Empfänger</h4></Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    className={classes.textFieldStyle}
                                    label={'Vorname'}
                                    variant={'outlined'}
                                    type={'text'}
                                    required
                                    value={eventAttendees[i].firstName}
                                    onChange={setValue(i)}
                                    name={'firstName'}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    className={classes.textFieldStyle}
                                    label={'Nachname'}
                                    variant={'outlined'}
                                    type={'text'}
                                    required
                                    value={eventAttendees[i].lastName}
                                    onChange={setValue(i)}
                                    name={'lastName'}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className={classes.textFieldStyle}
                                    label={'EMail-Adresse'}
                                    variant={'outlined'}
                                    value={eventAttendees[i].email}
                                    type={'email'}
                                    required
                                    onChange={setValue(i)}
                                    name={'email'}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            ))}
            <Box className={classes.boxStyle}>
                <h3>Eingelöste Rabatte</h3>
                <Grid container className={classes.otherFont}>
                    <Grid item xs={12} md={5}>
                        <TextField
                            id="coupon"
                            label="Gutschein Code"
                            variant="outlined"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            type="text"
                            fullWidth
                            size={'small'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start" style={{cursor: 'pointer'}} onClick={(e) => {
                                        setShowButtons(false);
                                        handleAddCoupon(code)(e);
                                    }}
                                    >
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={9} md={4} className={classes.alignRight}>
                        <Typography component={'div'}>Rabatte:</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.alignRight}>
                        <Typography component={'div'}
                                    className={classes.paddingRight}>{cleanPriceStringSpecial(getCoupon(lastOrder.products, 0.2))}</Typography>
                    </Grid>
                    <Grid item xs={1}>€</Grid>
                </Grid>
                {!!coupon &&
                <Grid container className={classes.otherFont}>
                    <Grid item xs={1} className={classes.alignLeft} style={{cursor: 'pointer'}} onClick={() => setCoupon(null)}>
                        <FontAwesomeIcon icon={faMinus}/>
                    </Grid>
                    <Grid item xs={8} className={classes.alignLeft}>
                        <Typography component={'div'} className={classes.smallText}>{coupon.description}</Typography>
                        <Typography component={'div'} className={classes.smallText}>{coupon.conditionDescription}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.alignRight}/>
                </Grid>
                }
            </Box>
            {lastOrder.products && <Box className={classes.boxStyle}>
                <h3>Bestellte Artikel</h3>
                <Box className={classes.alignLeft + ' ' + classes.otherFont}>
                    {lastOrder.products.map((product, index) => (
                        <Grid key={index} container>
                            <Grid item xs={1} className={classes.amount}>{product.amount} <FontAwesomeIcon icon={faTimes}/></Grid>
                            <Grid item xs={12} md={8} className={classes.description}>
                                <Grid container spacing={1} className={classes.description}>
                                    <Grid item xs={12} md={6} className={classes.alignCenter}>
                                        {isAbo(product.articleNumber) && <Typography className={classes.spacing}>Whisky Abo</Typography>}
                                        {!isAbo(product.articleNumber) &&
                                        <Typography className={classes.spacing}>{product.producer || product.distillery}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.alignCenter}>
                                        {!isAbo(product.articleNumber) && <Typography className={classes.spacing}>{product.name}</Typography>}
                                        {isAbo(product.articleNumber) && <Typography className={classes.spacing}>{getAboInfos(product)[0]} <FontAwesomeIcon
                                            icon={faTimes}/> {getAboInfos(product)[1]} Whisky</Typography>}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={11} md={2} className={classes.alignRight}>
                                <Typography>{cleanPriceStringSpecial(getSumForProduct(product))}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>€</Typography>
                            </Grid>
                        </Grid>
                    ))}
                    <hr/>
                    <Grid container>
                        <Grid item xs={9} className={classes.alignRight}>SUMME</Grid>
                        <Grid item xs={2} className={classes.alignRight}>{cleanPriceStringSpecial(getSumPre(lastOrder.products, 0.2))}</Grid>
                        <Grid item xs={1}>€</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={9} className={classes.alignRight}>MwSt:</Grid>
                        <Grid item xs={2} className={classes.alignRight}>{cleanPriceStringSpecial(getMWST(lastOrder.products, 0.2))}</Grid>
                        <Grid item xs={1}>€</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={9} className={classes.alignRight}>Versandkosten</Grid>
                        <Grid item xs={2}
                              className={classes.alignRight}>{cleanPriceStringSpecial(getShipping(lastOrder.products) + getShippingAbo(lastOrder.products))}</Grid>
                        <Grid item xs={1}>€</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={9} className={classes.alignRight}>Rabatte: </Grid>
                        <Grid item xs={2} className={classes.alignRight}>- {cleanPriceStringSpecial(getCoupon(lastOrder.products, 0.2))}</Grid>
                        <Grid item xs={1}>€</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={9} className={classes.alignRight}>Endsumme</Grid>
                        <Grid item xs={2}
                              className={classes.alignRight}>{cleanPriceStringSpecial(getSumInkShipping(lastOrder.products) - getCoupon(lastOrder.products, 0.2))}</Grid>
                        <Grid item xs={1}>€</Grid>
                    </Grid>
                </Box>
            </Box>}
        </Box>
        }
        {!showButtons &&
        <Box className={classes.boxStyle}>
            <FormControlLabel
                className={classes.otherFont}
                control={
                    <Checkbox
                        checked={agbChecked}
                        color={"primary"}
                        onChange={e => setAgbChecked(e.target.checked)}
                        name="agbChecked"
                    />
                }
                label={<span style={{display: 'block', fontSize: '1em', textAlign: 'justify', textDecoration: highlightAgb ? 'underline' : 'none'}}>Mit einem Klick auf den „Jetzt kaufen“-Button bestätigen Sie, die <Link
                    to={"/terms/"}>AGB</Link> gelesen zu haben und zu akzeptieren</span>}
            />
            <Tooltip classes={{tooltip: classes.otherFont}} title={'Es muss mindestens eine Adresse angegeben sein und alle Benötigten Felder ausgefüllt.'}>
                <div style={{padding: '0.1em', display: 'flex', justifyContent: 'flex-end'}}

                >
                    <span
                        onMouseEnter={(e) => setHighlightAgb(!agbChecked)}
                        onMouseLeave={(e) => setHighlightAgb(false)}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={triggerPayment}
                        className={[classes.payNowBtn, classes.otherFont].join(" ")}
                        disabled={!isValid || !lastOrder.paymentAddress || !agbChecked}>
                        <span>Jetzt kaufen</span>
                    </Button>
                        </span>
                </div>
            </Tooltip>
        </Box>
        }
        {showButtons && <Box className={classes.boxStyle}>
            <h3> Zahlungsform wählen </h3>
            <Button className={[classes.payLaterBtn, classes.otherFont].join(' ')} variant={'contained'} color={'primary'} onClick={toggleInputPayBefore}
                    fullWidth> <FontAwesomeIcon icon={faFileInvoiceDollar} style={{marginRight: '0.5em'}}/> Vorabzahlung</Button>
            <PaypalButton className={[classes.payLaterBtn, classes.otherFont].join(' ')} order={lastOrder} coupon={getCoupon(lastOrder.products)}
                          onOrder={() => setIsPaying(true)}
                          callback={(details => paymentApproval(details))}
                          onCancel={handlePayPalCancel} onError={handlePayPalError}/>
            <UnzerButton className={[classes.payLaterBtn, classes.otherFont].join(' ')} order={lastOrder}/>

            {infoMessagePayPal !== '' && <h5 className={[classes.otherFont, (infoMessagePayPalError ? classes.error : '')].join(' ')}>{infoMessagePayPal}</h5>}
        </Box>}
        {isPaying && <div className={classes.overlay}>
            <h5> Ihre Zahlung wird verarbeitet haben sie ein bisschen Geduld bis der Prozess abgeschlossen ist, Sie werden dann weiter geleitet. Danke. </h5>
            <FontAwesomeIcon icon={faGlassWhiskey} spin/>
        </div>}

        <Modal open={isDialogOpen} handleClose={() => setIsDialogOpen(false)} title={'Weiter angaben zur Vorabzahlung'} size={'sm'}
               actions={
                   <div>
                       <Button variant={'outlined'} color={'primary'} onClick={() => setIsDialogOpen(false)}>Abbrechen</Button>
                       <Button variant={'contained'} color={'primary'} onClick={payBefore} style={{marginLeft: '0.75em'}}>Fertig</Button>
                   </div>
               }>
            <div className={classes.otherFont}>
                <p><b>Info:</b> Vorbzahlung bedeutet, dass Ihnen von uns eine Rechnung mit den Zahlungsdaten und dem Überweisungsbetrag an die von Ihnen
                    angegebene Mailadresse zugesendet wird, welche Sie dann auf das angegebene Konto überweisen. Nach Erhalt des Betrages werden wir Ihre
                    Bestellung unmittelbar an Sie versenden.</p>
                <div>
                    <TextField
                        variant={'outlined'}
                        placeholder={'email@adresse.at'}
                        fullWidth
                        value={paymentEmail}
                        onChange={(event) => setPaymentEmail(event.target.value)}
                        label={'Rechnungsemail'}
                        helperText={"Diese E-Mail-Adresse wird verwendet um ihnen die Rechnung zu zusenden."}/>
                </div>
                <div>
                    <DatePicker label={'Geburtsdatum'}
                                style={{marginTop: '1.5em'}}
                                inputVariant={'outlined'}
                                fullWidth
                                openTo={'year'}
                                format={'dd.LL.yyyy'}
                                value={birthday}
                                onChange={(v) => setBirthday(v)}
                                helperText={'Wird benötigt um die Rechtmäßigkeit des Kaufes zu überprüfen.'}/>
                </div>
            </div>
        </Modal>

        <AgeInputDialog open={false} submitBirthdate={setBirthDateHandler}/>
        <Backdrop className={classes.backdrop} open={backdropOpen}>
            <div>
                <Typography>Wir verarbeiten ihre bestellung haben sie etwas Geduld sie werden gleich weitergeleitet.</Typography>
                <div className={classes.spinner}>
                    <FontAwesomeIcon icon={faGlassWhiskey} spin/>
                </div>
            </div>
        </Backdrop>
    </Container>
}));

export default CartPayment;


/*
            <h5> Nachfoglende Zahlungsmethoden werden durch Amazon durchgeführt.</h5>
            <AmazonPay
                clientId='amzn1.application-oa2-client.22922afb7ad34c1b8f2a2703a9e50a79'
                sellerId='amzn1.application-oa2-client.22922afb7ad34c1b8f2a2703a9e50a79'
                agreementType={'BillingAgreement'}
                scope='profile payments:widget'
                region={'eu'}
                btnType='PwA'
                btnColor='Gold'
                btnSize='medium'
                onConsentChange={(hasConsent) => setHasConsent(hasConsent)}
                handleBillingAgreementId={(billingAgreementId) => setBillingAgreementId(billingAgreementId)}
                billingAgreementId={billingAgreementId}
                useAmazonAddressBook={false}
                isSandbox={true}
            />
 */
