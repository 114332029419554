import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    wrapperCookieBanner: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'hsla(0,0%,0%,0.7)',
        padding: '2em 0',
        color: '#f5f5dc',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    closeIcon:{
        position: 'absolute',
        top: '0.25em',
        right: '0.25em',
        color: '#f5f5dc'
    },
    linkStyle:{
        color: '#f5f5dc'
    }
}));
