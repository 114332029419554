import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getSumInkShipping} from "../../common/statelessFunctions";
import {Button} from "@material-ui/core";
import {faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";

const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
//create button here
let PayPalButton = null;

// next create the class and Bind React and ReactDom to window
//as we will be needing them later

class PaypalButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showButtons: false,
            loading: true,
        };

        window.React = React;
        window.ReactDOM = ReactDOM;
    }

    componentDidMount() {
        const {isScriptLoaded, isScriptLoadSucceed} = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {
            PayPalButton = window.paypal.Buttons.driver("react", {React, ReactDOM});
            this.setState({loading: false, showButtons: true});
        }
    }

    componentWillReceiveProps(nextProps) {
        const {isScriptLoaded, isScriptLoadSucceed} = nextProps;

        const scriptJustLoaded =
            !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
            if (isScriptLoadSucceed) {
                PayPalButton = window.paypal.Buttons.driver("react", {
                    React,
                    ReactDOM
                });
                this.setState({loading: false, showButtons: true});
            }
        }
    }

    createOrder = (data, actions) => {
        const {order, coupon} = this.props;
        const value = parseFloat((getSumInkShipping(order.products, 0.2) - coupon).toFixed(2));
        /*const items = order.products.map(p => ({
            name: p.articleNumber,
            unit_amount: {
                currency_code: "EUR",
                value: p.vk_pre
            },
            quantity: p.amount
        }));*/
        /*
                            items,

                                breakdown: {
                            item_total:{
                                currency_code: "EUR",
                                value,
                            }
                        }
                        invoice_number: bill.billNumber,
         */
        return actions.order.create({
            payer: {
                name: {
                    given_name: order.shippingAddress.firstName,
                    surname: order.shippingAddress.lastName,
                },
                email_address: order.paymentEmail || null,
                address: {
                    address_line_1: order.paymentAddress.address1,
                    address_line_2: order.paymentAddress.address2,
                    admin_area_2: order.paymentAddress.region,
                    admin_area_1: order.paymentAddress.land,
                    postal_code: order.paymentAddress.plz,
                    country_code: 'AT',
                }
            },
            purchase_units: [
                {
                    reference_id: order.orderNumber,
                    description: "Worldwhisky.at",
                    amount: {
                        currency_code: "EUR",
                        value: value,
                    },
                    shipping: {
                        name: {
                            fullName: order.shippingAddress.firstName + ' ' + order.shippingAddress.lastName,
                        },
                        address: {
                            address_line_1: order.shippingAddress.address1,
                            address_line_2: order.shippingAddress.address2,
                            admin_area_2: order.shippingAddress.region,
                            admin_area_1: order.shippingAddress.land,
                            postal_code: order.shippingAddress.plz,
                            country_code: 'AT',
                        }
                    }
                }
            ]
        });
    };

    onApprove = (data, actions) => {
        this.props.onOrder();
        actions.order.capture().then(details => {
            this.props.callback(details);
        });
    };

    onCancel = (data) => {
        this.props.onCancel(data);
    };

    onError = (data) => {
        this.props.onError(data);
    };

    render() {
        const {showButtons, loading} = this.state;

        return (
            <div className="main" style={{marginBottom: '0.45em'}}>
                {loading && <Button
                                    className={this.props.className}
                                    variant={'contained'}
                                    color={'primary'}
                                    fullWidth disabled>
                    <FontAwesomeIcon icon={faGlassWhiskey} spin/> Pay Pal
                </Button>
                }

                {showButtons && (
                    <div>
                        <PayPalButton
                            createOrder={(data, actions) => this.createOrder(data, actions)}
                            onApprove={(data, actions) => this.onApprove(data, actions)}
                            onCancel={(data, actions) => this.onCancel(data, actions)}
                            onError={(data, actions) => this.onError(data, actions)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR&disable-funding=sofort,card,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo`)(PaypalButton);


