import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {Link, useHistory, useParams} from "react-router-dom";
import {Box, Container, FormControl, Hidden, LinearProgress, MenuItem, Select, Tab, Tabs, useTheme} from '@material-ui/core';
import {useStyles} from "./styling";
import {faThumbsUp} from "@fortawesome/free-regular-svg-icons";
import {faLongArrowAltLeft, faLongArrowAltRight, faWineBottle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import ReactMarkdown from "react-markdown";
import {getProductGrain} from "common/statelessFunctions";
import {database} from "../../common/firebase";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import DbImage from "../../components/dbImage";
import {Helmet} from "react-helmet";

const WhiskyDetail = inject('store')(observer(({store}) => {
    const {
        headerNameBlockStyle, headerElementStyle, subHeaderElementStyle,
        overviewLikeBlockStyle, overviewLikeBlockInfoTextStyle, overviewBlockInfoGridStyle, overviewBlockLinkStyle,
        imageBlockStyle, selectedImageStyle, selectedImageElementStyle, imageListStyle, imageListElementStyle, overviewBlockStyle, detailBlockStyle,
        detailBlockInfoStyle, likeBtnLiked, detailBlockInfoStyleSmall, subHeaderElementStyleSmall, headerElementStyleSmall, priceInfo, priceSize, otherFont
    } = useStyles();
    const history = useHistory();
    const {id} = useParams();
    const [product, setProduct] = useState(null);
    const [selectedImage, setSelectedImage] = useState('/');
    const [tab, setTab] = useState(0);
    const [amount, setAmount] = useState(1);
    const [reservedArticles, setReservedArticles] = useState(0);
    const [maxAmount, setMaxAmount] = useState(1);
    const [setProducts, setSetProducts] = useState([]);
    const [subTitle, setSubTitle] = useState('');
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        try {
            if (!id) throw new Error('id not valid');
            store.articleStore.getArticleWithNumber(id).then(article => {
                if (!article) throw new Error('article not found error');
                if (!!article.images && article.images.length > 0) setSelectedImage(article.images[0]);
                if (!!article.overviewImages && article.overviewImages.length > 0) setSelectedImage(article.overviewImages[0]);
                if (article.isSet) {
                    const cache = [];
                    article.set.forEach((a) => {
                        database.ref('articles').child(a).once('value', snap => {
                            if (!snap.exists()) return;
                            cache.push(snap.val());
                            setSetProducts([...cache]);
                        })
                    })
                }
                setProduct(article);
                createSubtitle(article)
                database.ref(`articleCart/${id}`).on('value', snap => {
                    const data = snap.val();
                    let sum = 0;
                    if (data) {
                        for (const key of Object.keys(data)) {
                            sum += data[key];
                        }
                        setReservedArticles(sum);
                    }
                });
                database.ref(`batches/${id}/${article.activeBatch}`).on('value', snap => {
                    if (!snap.exists()) return;
                    const activeBatch = snap.val();
                    const maxAmountLocal = activeBatch.currentAmount || activeBatch.amount;
                    setMaxAmount(maxAmountLocal);
                });
            });
        } catch (e) {
            console.error(e);
            history.push('/whisky/')
        }
    }, [id, history, store.articleStore]);

    useEffect(() => {
        setTab(matches ? 0 : 1);
    }, [matches]);

    const createSubtitle = (prod) => {
        let subTitle = '';
        if (prod.isSet) {
            subTitle = 'Geschenk-Set'
        } else if (prod.types.indexOf('whisky') > -1) {
            subTitle = getProductGrain(prod) + ' - ' + (prod.producer || prod.distillery);
        } else {
            subTitle = prod.productType;
        }
        setSubTitle(subTitle);
    }

    const handleChangeTab = (event, newValue) => setTab(newValue);

    const addToWishList = product => e => {
        if (!store.userStore.isSignedIn) {
            store.snackBarHandle.addMessages(`Nur Angemeldete Benutzer können diese Funktion zurzeit benutzen.`);
            return;
        }
        if (!store.wishListStore.isInWishList(product)) {
            store.snackBarHandle.addMessages(`${product.articleNumber} zu den Favoriten hinzugefügt`);
            store.wishListStore.addArticleToWishList(product);
        } else {
            store.snackBarHandle.addMessages(`${product.articleNumber} aus den Favoriten entfernt`);
            store.wishListStore.removeArticleFromWishList(product);
        }
    };

    const addToCart = () => {
        let fullName = '';
        fullName += product.name ? (product.name + ' - ') : '';
        fullName += product.age ? (product.age + 'J. - ') : '';
        fullName += product.volume ? (product.volume + 'L. ') : '';
        product.fullName = fullName;
        let weight = 0;
        if (!!product.volume && parseFloat(product.volume) > 0.5) {
            weight = 1.5
        } else if (!!product.volume && parseFloat(product.volume) > 0.2) {
            weight = 1
        } else {
            weight = 0.2
        }
        product.weight = weight;
        store.cartStore.writeArticleInCart(product, amount);
    };

    const clearNumber = number => {
        if (!number) return null;
        return number.toString().replace('.', ',') + (number.toString().includes('.') ? '' : ',-')
    };

    const getImageTag = () => {
        if (!!product.overviewImages) {
            return <DbImage className={selectedImageElementStyle} alt={`Whisky flaschen ${product.articleNumber}`}
                            src={['images', product.articleNumber, selectedImage]}/>
        }
        if (!!product.images) return <img className={selectedImageElementStyle} alt={'whisky flaschen bild'} src={selectedImage}/>
        return <FontAwesomeIcon className={selectedImageElementStyle} style={{fontSize: '8em'}} icon={faWineBottle}/>
    }

    const getImageListTag = () => {
        if (!!product.overviewImages) {
            return product.overviewImages.length > 1 ? <div className={imageListStyle}>{product.overviewImages.map((name, key) => (
                <DbImage key={key} className={imageListElementStyle} onClick={() => setSelectedImage(name)} src={['images', product.articleNumber, name]}
                         alt={"Produkt Bild"}/>
            ))}</div> : null
        }
        if (!!product.images) {
            return product.images.length > 1 ? <div className={imageListStyle}>
                {product.images.map((url, key) => (
                    <img className={imageListElementStyle} key={key} src={url} onClick={() => setSelectedImage(url)} alt={"Produkt Bild"}/>
                ))}
            </div> : null
        }
        return null;
    }

    return !product ? <LinearProgress/> : <Container maxWidth={"md"}>
        <Helmet>
            <title>Worldwhisky
                - {product.types.indexOf('sample') > -1 ? 'Kostprobe -' : ''}{product.name} | {!product.isSet && product.types.indexOf('whisky') > -1 ? ` - ${product.volume} - ${product.alcVolume}%` : ''} </title>
            <meta name="description"
                  content={`Kaufe ${product.types.indexOf('sample') > -1 ? 'Kostprobe -' : ''}${product.name} | Hersteller: ${product.producer ?? 'unbekannt'} | Distillery: ${product.distillery ?? 'unbekannt'} | Herkunft: ${product.origin ?? 'unbekannt'} | Basisfass: ${product.barrel ?? 'unbekannt'} | Finish: ${product.finish ?? 'unbekannt'} |  Alkoholgehalt: ${product.alcVolume ?? 'unbekannt'}% | Inhalt: ${product.volume ?? 'unbekannt'}L | Alter: ${product.age ?? 'unbekannt'} Jahre`}
            />
        </Helmet>

        <Grid container spacing={1}>
            <Grid item sm={12} md={9}>
                <div className={headerNameBlockStyle}>
                    <div className={matches ? headerElementStyleSmall : headerElementStyle}>
                        {product.types.indexOf('sample') > -1 ? 'Kostprobe -' : ''}{product.name}
                        {!product.isSet && product.types.indexOf('whisky') > -1 ? ` - ${product.volume} - ${product.alcVolume}%` : ''}
                    </div>
                    <div
                        className={matches ? subHeaderElementStyleSmall : subHeaderElementStyle}>
                        {subTitle}
                    </div>
                </div>
            </Grid>
            <Grid item sm={12} md={3}>
                <div style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                    <div className={overviewLikeBlockStyle + (store.wishListStore.isInWishList(product) ? ' ' + likeBtnLiked : '')}
                         onClick={addToWishList(product)}>
                        <FontAwesomeIcon icon={faThumbsUp}/>
                        {!store.wishListStore.isInWishList(product) ?
                            <div className={overviewLikeBlockInfoTextStyle}>als Favorit speichern</div> :
                            <div className={overviewLikeBlockInfoTextStyle}>als Favorit gespeichert</div>
                        }

                    </div>
                </div>
            </Grid>
        </Grid>
        <hr style={{margin: '1.5em 0'}}/>
        <Grid container spacing={1}>
            <Grid item sm={12} md={8}>
                <div className={imageBlockStyle}>
                    <div className={selectedImageStyle}>
                        {getImageTag()}
                    </div>
                    {getImageListTag()}
                </div>
            </Grid>
            <Grid item sm={12} md={4}>
                <Hidden smDown>
                    <div className={overviewBlockStyle}>
                        <Hidden smDown>
                            <div className={[overviewBlockInfoGridStyle, otherFont].join(' ')}>
                                {!product.isSet && !!product.producer && <div>Hersteller</div>}
                                {!product.isSet && !!product.producer && <div>{product.producer}</div>}
                                {!product.isSet && !!product.distillery && <div>Distillery</div>}
                                {!product.isSet && !!product.distillery && <div>{product.distillery}</div>}
                                {!product.isSet && !!product.origin && <div>Herkunft</div>}
                                {!product.isSet && !!product.origin && <div>{product.origin}</div>}
                                {!product.isSet && !!product.barrel && <div>Basisfass</div>}
                                {!product.isSet && !!product.barrel && <div>{product.barrel}</div>}
                                {!product.isSet && !!product.finish && <div>Finish</div>}
                                {!product.isSet && !!product.finish && <div>{product.finish}</div>}
                                {!product.isSet && !!product.alcVolume && <div>Alkoholgehalt</div>}
                                {!product.isSet && !!product.alcVolume && <div>{product.alcVolume} %</div>}
                                {!product.isSet && !!product.volume && <div>Inhalt</div>}
                                {!product.isSet && !!product.volume && <div>{product.volume}L</div>}
                                {!product.isSet && !!product.age && <div>Alter</div>}
                                {!product.isSet && !!product.age && <div>{product.age} Jahre</div>}
                                {product.isSet && <div>Artikel</div>}
                                {product.isSet && <div>{setProducts.map((p, i) => (<div key={i}>
                                    <Link style={{color: '#333', textDecoration: 'none'}} to={`/whisky/article/${p.articleNumber}/${p.name.replace(' ', '_')}`}
                                          key={i}>{p.name}</Link>
                                </div>))}</div>}
                                {!!product.price_lt && product.price_lt > -1 && <div>Preis</div>}
                                {!!product.price_lt && product.price_lt > -1 && <div>
                                    <div className={priceSize}>€{clearNumber(product.vk_net)}</div>
                                    {<div className={priceInfo}>excl. Versand</div>}
                                    {!product.isSet && !!product.price_lt && product.price_lt > -1 && <div className={priceInfo}>1 L | €{clearNumber(product.price_lt)}</div>}
                                </div>}
                                <div>
                                    <FormControl fullWidth variant={'outlined'} margin={"dense"}>
                                        <Select
                                            id={'amount-id-' + product.articleNumber}
                                            value={amount}
                                            MenuProps={{
                                                disableScrollLock: true
                                            }}
                                            onChange={(event) => setAmount(event.target.value)}
                                        >
                                            {Array.from({length: maxAmount - reservedArticles + 1}, (_, i) => i).map((v, k) => (
                                                <MenuItem key={k} value={v}>{v}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}><Button variant={"contained"} color={'primary'}
                                                                                             onClick={addToCart}>Warenkorb</Button></div>
                                {product.sample && <div style={{display: 'flex', alignItems: 'center'}}>Wollen sie eine Kostprobe bestellen ?</div>}
                                {product.sample && <div><Button variant={"contained"} color={'primary'} component={Link}
                                                                to={`/whisky/article/${product.sample}/${product.name.replace(' ', '_')}`}>zur
                                    Kostprobe</Button></div>}
                            </div>

                            <Link to={'/termsDelivery/'} className={overviewBlockLinkStyle}>
                                <FontAwesomeIcon icon={faLongArrowAltRight}/> Versandkosteninfo <FontAwesomeIcon icon={faLongArrowAltLeft}/>
                            </Link>
                        </Hidden>
                    </div>
                </Hidden>
            </Grid>
        </Grid>

        <div className={[detailBlockStyle, otherFont].join(' ')}>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Tabs for Detailed Information">

                {matches && <Tab label="Details" value={0} id={"0"}/>}
                {product.description !== '' && <Tab value={1} label="Beschreibung" id={"1"}/>}
                {product.rating !== '' && <Tab value={2} label="Unsere Bewertung" id={"2"}/>}
                {/*<Tab label="Dazu passendes" id={"2"}/>*/}
                {/*<Tab label="Kunden Kauften Auch" id={"3"}/>*/}

            </Tabs>
            <hr style={{margin: 0}}/>
            {tab === 0 &&
            <Box className={matches ? detailBlockInfoStyleSmall : detailBlockInfoStyle}>
                <div className={overviewBlockInfoGridStyle}>
                    {!product.isSet && !!product.producer && <div>Hersteller</div>}
                    {!product.isSet && !!product.producer && <div>{product.producer}</div>}
                    {!product.isSet && !!product.distillery && <div>Distillery</div>}
                    {!product.isSet && !!product.distillery && <div>{product.distillery}</div>}
                    {!product.isSet && !!product.origin && <div>Herkunft</div>}
                    {!product.isSet && !!product.origin && <div>{product.origin}</div>}
                    {!product.isSet && !!product.barrel && <div>Basisfass</div>}
                    {!product.isSet && !!product.barrel && <div>{product.barrel}</div>}
                    {!product.isSet && !!product.finish && <div>Finish</div>}
                    {!product.isSet && !!product.finish && <div>{product.finish}</div>}
                    {!product.isSet && !!product.alcVolume && <div>Alkoholgehalt</div>}
                    {!product.isSet && !!product.alcVolume && <div>{product.alcVolume} %</div>}
                    {!product.isSet && !!product.volume && <div>Inhalt</div>}
                    {!product.isSet && !!product.volume && <div>{product.volume}L</div>}
                    {!product.isSet && !!product.age && <div>Alter</div>}
                    {!product.isSet && !!product.age && <div>{product.age} Jahre</div>}
                    {product.isSet && <div>Artikel</div>}
                    {product.isSet && <div>{setProducts.map((p, i) => (<div key={i}>
                        <Link style={{color: '#333', textDecoration: 'none'}} to={`/whisky/article/${p.articleNumber}/${p.name.replace(' ', '_')}`}
                              key={i}>{p.name}</Link>
                    </div>))}</div>}
                    {!!product.price_lt && product.price_lt > -1 && <div>Preis</div>}
                    {!!product.price_lt && product.price_lt > -1 && <div>
                        <div className={priceSize}>€{clearNumber(product.vk_net)}</div>
                        {<div className={priceInfo}>excl. Versand</div>}
                        {!product.isSet && !!product.price_lt && product.price_lt > -1 && <div className={priceInfo}>1 L | €{clearNumber(product.price_lt)}</div>}
                    </div>}
                    <div>
                        <FormControl fullWidth variant={'outlined'} margin={"dense"}>
                            <Select
                                id={'amount-id-' + product.articleNumber}
                                value={amount}
                                MenuProps={{
                                    disableScrollLock: true
                                }}
                                onChange={(event) => setAmount(event.target.value)}
                            >
                                {Array.from({length: maxAmount - reservedArticles + 1}, (_, i) => i).map((v, k) => (
                                    <MenuItem key={k} value={v}>{v}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}><Button variant={"contained"} color={'primary'}
                                                                                 onClick={addToCart}>Warenkorb</Button></div>
                </div>
                {product.sample && <div><Button variant={"contained"} color={'primary'} component={Link}
                                                to={`/whisky/article/${product.sample}/${product.name.replace(' ', '_')}`}>zur Kostprobe</Button></div>}
                <Link to={'/termsDelivery/'} className={overviewBlockLinkStyle}>
                    <FontAwesomeIcon icon={faLongArrowAltRight}/> Versandkosteninfo <FontAwesomeIcon icon={faLongArrowAltLeft}/>
                </Link>
            </Box>
            }

            {tab === 1 &&
            <Box className={matches ? detailBlockInfoStyleSmall : detailBlockInfoStyle}>
                <ReactMarkdown source={product.description}/>
            </Box>
            }

            {tab === 2 &&
            <Box className={matches ? detailBlockInfoStyleSmall : detailBlockInfoStyle}>
                <ReactMarkdown source={product.rating}/>
            </Box>
            }

            {tab === 3 &&
            <Box className={detailBlockInfoStyle}>
                Weiter Produkte
            </Box>
            }

            {tab === 4 &&
            <Box className={detailBlockInfoStyle}>
                Kunden Kauften Auch
            </Box>
            }
        </div>
    </Container>
}));

export default WhiskyDetail
