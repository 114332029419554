import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import MarkdownEditor from "@uiw/react-markdown-editor";
import {AppBar, Button, CardContent, Card} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import {makeStyles} from "@material-ui/core/styles";
import {database} from "common/firebase";
import {inject, observer} from "mobx-react";


export const useStyles = makeStyles(theme => ({
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    root: {
        textAlign: 'left',
    },
    blocksatz: {
        textAlign: 'justify',
    },
    btn: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
    },
    btnWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}));

const REF_MAPPING_TABLE = {
    "/admin/contact/": "contact",
    "/admin/termsWithdraw/": "retour",
    "/admin/dataSecurity/": "security",
    "/admin/termsDelivery/": "delivery",
    "/admin/terms/": "AGB",
}

const LABEL_MAPPING_TABLE = {
    "/admin/contact/": "Impressum / Kontakt",
    "/admin/termsWithdraw/": "Wiederrufsrecht",
    "/admin/dataSecurity/": "Datenschutz",
    "/admin/termsDelivery/": "Lieferkonditionen",
    "/admin/terms/": "AGB",
}

const HEADER_MAPPING_TABLE = {
    "/admin/contact/": "Impressum / Kontakt",
    "/admin/termsWithdraw/": "Wiederrufsrecht",
    "/admin/dataSecurity/": "Datenschutz",
    "/admin/termsDelivery/": "Lieferkonditionen",
    "/admin/terms/": "Allgemeine Geschäftsbedingungen",
}

export const Editor = inject("store")(observer(({store}) => {
    const classes = useStyles();
    const {pathname} = useLocation();

    const [data, setData] = useState('');
    const [newData, setNewData] = useState('');

    useEffect(() => {
        const ref = database.ref(REF_MAPPING_TABLE[pathname]).child(store.isWw ? 'ww' : 'st');
        ref.on('value', snap => {
            setData(snap.val());
            setNewData(snap.val());
        });

    }, [store.isWw, pathname]);

    const saveData = _ => {
        database.ref(REF_MAPPING_TABLE[pathname]).child(store.isWw ? 'ww' : 'st').set(newData).catch(e => console.error(e));
    };

    return <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
            <label htmlFor={REF_MAPPING_TABLE[pathname]}> {LABEL_MAPPING_TABLE[pathname]} </label>
        </AppBar>
        <MarkdownEditor
            className={classes.otherFont}
            id={REF_MAPPING_TABLE[pathname]}
            value={data}
            onChange={(a, b, value) => setNewData(value)}
            visible={false}
            height={250}
        />
        <div className={classes.btnWrapper}>
            <Button variant={'contained'} color={'primary'} onClick={saveData} className={classes.btn}> Save </Button>
        </div>
        <Card>
            <CardContent>
                <ReactMarkdown source={newData} className={classes.otherFont + ' ' + classes.blocksatz}/>
            </CardContent>
        </Card>
    </div>
}));
