import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {database} from "common/firebase";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper, makeStyles, IconButton, AppBar} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import {SoldCount} from "./SoldCount";
import {faCalendarPlus, faCogs, faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Options from "./Options";
import {OnlineMark} from "./OnlineMark";
import {DateTime} from "luxon";

const useStyles = makeStyles( theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        }
    },
    hoverRow:{
        cursor: 'pointer',
        '&:hover' :{
            backgroundColor: '#eee'
        }
    },
    underline:{
        textDecoration: "underline",
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}))

const WhiskyEventList = () => {
    const classes = useStyles();
    const history = useHistory();
    const [list, setList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [year, setYear] = useState(DateTime.local().toFormat('yy'));

    useEffect(() => {
        database.ref('events').child(year).on('value', snap => {
            const cacheList = [];
            if (snap.exists()) {
                snap.forEach(childSnap => {
                    const whiskyEvent = childSnap.val();
                    whiskyEvent.key = childSnap.key;
                    cacheList.push(whiskyEvent);
                });
            }
            setList(cacheList);
        })
    }, [year]);

    useEffect(() => {
        database.ref('events').on('value', snap => {
            if(snap.exists()){
                const list = []
                const currentYear = DateTime.local().toFormat('yy')
                snap.forEach( yearSnap => {
                    list.push(yearSnap.key);
                })
                if(list.indexOf(currentYear) === -1) list.push(currentYear);
                setYearList(list.sort((a, b) => b-a));
            }
        })
    }, []);

    const startEdit = (whiskyEvent) => () => {
        history.push(`/admin/event/${whiskyEvent.year}/${whiskyEvent.key}`)
    }

    const createNewEvent = () => {
        const nextYear = DateTime.local().toFormat('yy');
        const nextNum = ("0000" + list.length).slice(-4);
        const nexRef = database.ref('events').child(nextYear).push({
            bookingMail:"",
            bookingTel: "",
            capacity:0,
            description: "",
            eventDate: DateTime.local().toFormat('dd.LL.yyyy HH:mm'),
            images: [],
            location: "",
            location_link: "",
            name: "",
            number: `WWEVENT${nextYear}${nextNum}`,
            subTitle: "",
            till: DateTime.local().toFormat('dd.LL.yyyy HH:mm'),
            vk_net: 0,
            hasShipping: false,
            year: nextYear,
            thumbnail: null,
            shippingCost: 0,
        })
        history.push(`/admin/event/${nextYear}/${nexRef.key}`)
    }

    return <div>
        <AppBar position="static" className={classes.appBar}>Whisky Events</AppBar>
        <TableContainer component={Paper} className={classes.otherFont}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} >
                            <span>Jahr:</span>
                            { yearList.map((y,i) => (<IconButton key={i} onClick={() => setYear(y)} className={ y === year ? classes.underline : ''}>{y}</IconButton>))}

                        </TableCell>
                        <TableCell>
                            <IconButton onClick={createNewEvent}>
                                <FontAwesomeIcon icon={faCalendarPlus} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow>
                        <TableCell><FontAwesomeIcon icon={faGlobeEurope} /></TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="right">Maximale Teilnehmer</TableCell>
                        <TableCell align="right">Verkauft</TableCell>
                        <TableCell align="right">Datum</TableCell>
                        <TableCell align="right">Verkauf Bis</TableCell>
                        <TableCell align="right">Preis</TableCell>
                        <TableCell align="right"><FontAwesomeIcon icon={faCogs} /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((whiskyEvent, index) => (
                        <TableRow key={index} className={classes.hoverRow} >
                            <TableCell component="th" scope="row" onClick={startEdit(whiskyEvent)}><OnlineMark eventKey={whiskyEvent.key} /></TableCell>
                            <TableCell component="th" scope="row" onClick={startEdit(whiskyEvent)}>{whiskyEvent.name}</TableCell>
                            <TableCell align="right" onClick={startEdit(whiskyEvent)}>{whiskyEvent.capacity}</TableCell>
                            <TableCell align="right" onClick={startEdit(whiskyEvent)}><SoldCount eventKey={whiskyEvent.key} /></TableCell>
                            <TableCell align="right" onClick={startEdit(whiskyEvent)}>{whiskyEvent.eventDate}</TableCell>
                            <TableCell align="right" onClick={startEdit(whiskyEvent)}>{whiskyEvent.till}</TableCell>
                            <TableCell align="right" onClick={startEdit(whiskyEvent)}>€ {whiskyEvent.vk_net}</TableCell>
                            <TableCell align="right"><Options event={whiskyEvent} till={whiskyEvent.till} className={classes.otherFont} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6} align="right">Hier sollen dann Summierungen stehen</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <div style={{height: 24}}/>
    </div>
};

export default WhiskyEventList;
