export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const articleTextFilter = (filterString) => (article) => {
    return article.name.toLowerCase().includes(filterString.toLowerCase()) ||
        article.articleNumber.toLowerCase().includes(filterString.toLowerCase()) ||
        article.distillery.toLowerCase().includes(filterString.toLowerCase()) ||
        article.producer.toLowerCase().includes(filterString.toLowerCase()) ||
        article.productType.toLowerCase().includes(filterString.toLowerCase()) ||
        article.description.toLowerCase().includes(filterString.toLowerCase());
}
