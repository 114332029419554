import {observable, action, decorate} from "mobx"
import localStorage from 'mobx-localstorage';
import {auth, database} from "common/firebase";
import Store from "common/store";

const LOCAL_STORAGE_NAME = 'worldwhisky-card';

const TWOHOURES = 1000 * 60 * 60 * 2 // 1 Sekunde * 60 * 60 * 2 = 2 Stunden;

class CartStore {

    constructor() {
        this.setStore(localStorage.getItem(LOCAL_STORAGE_NAME));

        auth.onAuthStateChanged(user => {
            //ToDo check Localstorage with uid!
            if (!!user) {
                const cartRef = database.ref(`userCart/${user.uid}`);
                cartRef.once('value', snap => {
                    let onlineCart = snap.val() || [];
                    if (this.cart.length > 0) {
                        for (const localCartEntry of this.cart) {
                            const i = onlineCart.findIndex(cartEntry => cartEntry.articleNumber === localCartEntry.articleNumber);
                            if (i === -1) {
                                onlineCart.push(localCartEntry);
                            } else {
                                onlineCart[i] = localCartEntry;
                            }
                        }
                    }
                    for (const cartEntry of onlineCart) {
                        this.addEntryToCart(cartEntry);
                    }
                    this.saveCart();
                });
            } else {
                const store = {cart: this.cart};
                localStorage.setItem(LOCAL_STORAGE_NAME, store);
            }
        });
    }

    cart = [];

    addEntryToCart = (entry, save = true) => {
        const index = this.cart.findIndex(cartEntry => cartEntry.articleNumber === entry.articleNumber);
        entry.amount = new Date().getTime() - TWOHOURES > entry.timestamp ? 0 : entry.amount; // remove if timestamp is over 2 hours

        if (index > -1) {
            this.cart[index] = entry;
        } else {
            this.cart = [...this.cart, entry];
        }
        if (save) this.saveCart();
    };

    removeEntryFromCart = (entry, save = true) => {
        const index = this.cart.findIndex(cartEntry => cartEntry.articleNumber === entry.articleNumber);
        if (index > -1) this.cart[index].amount = 0;
        this.cart = [...this.cart];
        if (save) this.saveCart();
    };

    updateEntryInCart = (entry, amount, save = true) => {
        const index = this.cart.findIndex(cartEntry => cartEntry.articleNumber === entry.articleNumber);
        if (index > -1) this.cart[index].amount = amount;
        this.cart = [...this.cart];
        if (save) this.saveCart();
    };

    writeEventInCart = (product, attendees, save = true) => {
        database.ref('events').child(product.year).child(product.key).once('value', snap => {
           if(snap.exists()){
               const event = snap.val();
               event.key = snap.key;
               let attendeesCount = 0;
               let reserveCount = 0;
               database.ref('events_attendees').child(product.key).child('count').once('value', snap=> {
                   if(snap.exists()){
                       attendeesCount = parseInt(snap.val());
                   }
                   database.ref(`articleCart/${product.number}`).once('value', snap => {
                       if(snap.exists()){
                           const data = snap.val();
                           let sum = 0;
                           if (data) {
                               for (const key of Object.keys(data)) {
                                   sum += data[key];
                               }
                               reserveCount = sum;
                           }
                       }

                       if(event.capacity <= attendeesCount + reserveCount) {
                           Store.snackBarHandle.addMessages('Dieser Artikel ist zurzeit vergriffen');
                       }else {

                           const index = this.cart.findIndex(cartEntry => cartEntry.articleNumber === product.number)
                           console.log(index);
                           if (index > -1) {
                               this.cart[index].amount = this.cart[index].amount + 1;
                               this.cart[index].year = event.year;
                               this.cart[index].vk_net = event.vk_net;
                               this.cart[index].thumbnail = event.thumbnail ?? event.thumbnails?.[0] ?? null;
                               this.cart[index].image = event.image ?? event.images?.[0] ?? null;
                               this.cart[index].name = event.name || null;
                               this.cart[index].key = event.key || null;
                               this.cart[index].shippingCost = event.shippingCost ?? null;
                           } else {
                               const newEntry = {
                                   articleNumber: event.number,
                                   amount: 1,
                                   timestamp: new Date().getTime(),
                                   vk_net: event.vk_net,
                                   name: event.name,
                                   thumbnail: event.thumbnail ?? event.thumbnails?.[0] ?? null,
                                   image: event.image ?? event.images?.[0] ?? null,
                                   key: event.key,
                                   year: event.year,
                                   shippingCost: event.shippingCost ?? null,
                               };

                               this.cart.push(newEntry)
                           }
                           Store.snackBarHandle.addMessages(`1 mal das ${event.name}, zum warenkorb hinzugefügt`);
                           if (save) this.saveCart();
                       }
                   });
               });

           } else {
               Store.snackBarHandle.addMessages('Beim Hinzufügen ist ein Fehler passiert');
           }
        });
    }

    writeArticleInCart = (article, amount, total = true, save = true) => {
        if (!article.activeBatch) {
            Store.snackBarHandle.addMessages('Dieser Artikel ist zurzeit vergriffen');
            return;
        }
        database.ref(`batches/${article.articleNumber}/${article.activeBatch}`).once('value', snap => {
            if (!snap.exists()) {
                Store.snackBarHandle.addMessages('Dieser Artikel ist zurzeit vergriffen');
                return;
            }
            const currentAmount = parseInt(snap.val().currentAmount) || 0;

            if (currentAmount > 0) {
                database.ref(`articleCart/${article.articleNumber}`).once("value", snapReserv => {
                    if (!snapReserv.exists()) {
                        this.helperWriteArticleInCart(article, amount, total, save);
                    } else {
                        const data = snapReserv.val();
                        const keys = Object.keys(data);
                        const filteredKeys = keys.filter((k) => parseFloat(k) + TWOHOURES > new Date().getTime());
                        const reservedList = filteredKeys.map(k => data[k]);
                        const reserved = reservedList.reduce((a, n) => a + parseInt(n), 0);
                        const carryover = currentAmount - reserved;
                        if (carryover > 0) {
                            if (amount < carryover) {
                                this.helperWriteArticleInCart(article, amount, total, save);
                            } else {
                                this.helperWriteArticleInCart(article, carryover, total, save);
                            }
                        } else {
                            Store.snackBarHandle.addMessages('Dieser Artikel ist zurzeit vergriffen');
                            return;
                        }
                    }

                });
            } else {
                Store.snackBarHandle.addMessages('Dieser Artikel ist zurzeit vergriffen');
                return;
            }
        });
    };

    helperWriteArticleInCart = (article, amount, total = true, save = true) => {
        const index = this.cart.findIndex(cartEntry => cartEntry.articleNumber === article.articleNumber);
        if (index > -1) {
            this.cart[index].amount = total || !this.cart[index].amount ? amount : this.cart[index].amount + amount;
            this.cart[index].timestamp = new Date().getTime();
            this.cart[index].vk_net = article.vk_net;
            this.cart[index].image = article.image ?? article.images?.[0] ?? null;
            this.cart[index].thumbnail = article.thumbnail ?? article.thumbnails?.[0] ?? null;
            this.cart[index].fullName = article.fullName || null;
            this.cart[index].distillery = article.distillery || null;
            this.cart[index].producer = article.producer || null;
            this.cart[index].name = article.name || null;
            this.cart[index].activeBatch = article.activeBatch || null;
            this.cart[index].weight = article.weight || null;
        } else {

            const newEntry = {
                articleNumber: article.articleNumber,
                amount: amount,
                timestamp: new Date().getTime(),
                vk_net: article.vk_net,
                name: article.name,
                producer: article.producer,
                image: article.image ?? article.images?.[0] ?? null,
                thumbnail: article.thumbnail ?? article.thumbnails?.[0] ?? null,
                fullName: article.fullName || null,
                distillery: article.distillery || null,
                activeBatch: article.activeBatch || null,
                weight: article.weight || null,
            };

            this.cart.push(newEntry)
        }
        if (save) this.saveCart();
    };

    saveCart = () => {
        const cart = this.cart.filter(e => e.amount > 0);
        if (Store.userStore.isSignedIn) {
            database.ref(`userCart/${Store.userStore.currentUser.uid}`).set(cart)
            .catch(e => console.error(e));
            for (const entry of this.cart) {
                database.ref(`articleCart/${entry.articleNumber}/${entry.timestamp}`).set(entry.amount > 0 ? entry.amount : null);
            }

        }

        const store = {cart};
        localStorage.setItem(LOCAL_STORAGE_NAME, store);
        this.cart = [...cart];
    };

    setStore = store => {
        if (!!store) {
            this.wishList = store.wishList || this.wishList;
        }
    };

    clearCart = () => {
        for (const entry of this.cart) {
            database.ref(`articleCart/${entry.articleNumber}/${entry.timestamp}`).set(null);
        }
        if (Store.userStore.isSignedIn) {
            database.ref(`userCart/${Store.userStore.currentUser.uid}`).set(null);
        }
        this.cart = [];
    }

}

decorate(CartStore, {
    cart: observable,
    addEntryToCart: action,
    updateEntryInCart: action,
    removeEntryFromCart: action,
    populateArticleInCart: action,
    saveCart: action,
    setStore: action,
    clearCart: action,
});

export default CartStore;
