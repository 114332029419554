import React from "react";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const filter = createFilterOptions();
export const EditWhisky = ({
                               classes, producer, setProducer, producersList, distillery, setDistillery, distilleriesList,
                               name, setName, age, setAge, volume, setVolume, alcVolume, setAlcVolume, finish, setFinish, finishsList, origin, setOrigin,
                               originsList, region, setRegion, peated, setPeated, peatedList, barrel, setBarrel, barrelList, grain, setGrain,
                               productType, setProductType, productTypeList, types, size, setSize
                           }) => {
    return <div>
        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative' || t.value === 'pairing' || t.value === 'present' || t.value === 'lights' || t.value === 'accessory') > -1 &&
        <TextField
            className={classes.textFieldStyle}
            id="article-name"
            label="Name"
            defaultValue={name}
            type="string"
            name={'name'}
            onChange={(e) => setName(e.target.value)}
            fullWidth
        />
        }
        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative' || t.value === 'pairing' || t.value === 'present' || t.value === 'lights' || t.value === 'accessory') > -1 &&
        <Autocomplete
            className={classes.textFieldStyle}
            value={producer}
            onChange={(event, newValue) => {
                if (!newValue) {
                    setProducer('');
                } else if (typeof newValue === 'string') {
                    setProducer(newValue);
                } else if (newValue && newValue.inputValue) {
                    setProducer(newValue.inputValue);
                } else {
                    setProducer(newValue.title);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="article-producer"
            options={producersList.map(a => ({title: a}))}
            getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
            freeSolo
            autoHighlight
            renderOption={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Hersteller" variant="standard"/>
            )}
        />
        }

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative') > -1 &&
        <Autocomplete
            className={classes.textFieldStyle}
            value={distillery}
            onChange={(event, newValue) => {
                if (!newValue) {
                    setDistillery('');
                } else if (typeof newValue === 'string') {
                    setDistillery(newValue);
                } else if (newValue && newValue.inputValue) {
                    setDistillery(newValue.inputValue);
                } else {
                    setDistillery(newValue.title);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="article-distillery"
            options={distilleriesList.map(a => ({title: a}))}
            getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
            freeSolo
            autoHighlight
            renderOption={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Distillery" variant="standard"/>
            )}
        />}

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative') > -1 &&
        <TextField
            className={classes.textFieldStyle}
            id="article-age"
            label="Alter des Whiskys"
            defaultValue={age}
            type="number"
            name={'age'}
            onChange={(e) => setAge(e.target.value)}
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">Jahre</InputAdornment>,
                inputProps: {min: 0, step: 1}
            }}
        />
        }

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative' || t.value === 'lights') > -1 &&
        <TextField
            className={classes.textFieldStyle}
            id="article-volume"
            label="Flaschen Größe in L"
            defaultValue={volume}
            type="number"
            name={'volume'}
            onChange={(e) => setVolume(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">L</InputAdornment>,
                inputProps: {min: 0, step: 0.1}
            }}
            fullWidth
        />
        }

        {types.findIndex(t => t.value === 'pairing' || t.value === 'present') > -1 &&
        <TextField
            className={classes.textFieldStyle}
            id="article-volume"
            label="Menge"
            defaultValue={size}
            type="text"
            name={'size'}
            onChange={(e) => setSize(e.target.value)}
            fullWidth
        />
        }

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative' || t.value === 'pairing' || t.value === 'present') > -1 &&
        <TextField
            className={classes.textFieldStyle}
            id="article-alc"
            label="Alkoholgehalt in %"
            defaultValue={alcVolume}
            type="number"
            name={'alcVolume'}
            onChange={(e) => setAlcVolume(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: {min: 0, step: 1}
            }}
            fullWidth
        />
        }

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative') > -1 &&
        <Autocomplete
            className={classes.textFieldStyle}
            value={finish}
            onChange={(event, newValue) => {
                if (!newValue) {
                    setFinish('');
                } else if (typeof newValue === 'string') {
                    setFinish(newValue);
                } else if (newValue && newValue.inputValue) {
                    setFinish(newValue.inputValue);
                } else {
                    setFinish(newValue.title);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="article-finish"
            options={finishsList.map(a => ({title: a}))}
            getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
            freeSolo
            autoHighlight
            renderOption={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Finish" variant="standard"/>
            )}
        />}

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative' || t.value === 'pairing' || t.value === 'present') > -1 &&
        <Autocomplete
            className={classes.textFieldStyle}
            value={origin}
            onChange={(event, newValue) => {
                if (!newValue) {
                    setOrigin('');
                } else if (typeof newValue === 'string') {
                    setOrigin(newValue);
                } else if (newValue && newValue.inputValue) {
                    setOrigin(newValue.inputValue);
                } else {
                    setOrigin(newValue.title);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="article-origin"
            options={originsList.map(a => ({title: a}))}
            getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
            freeSolo
            autoHighlight
            renderOption={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Herkunft" variant="standard"/>
            )}
        />
        }

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative' || t.value === 'pairing' || t.value === 'present') > -1 && origin.toLowerCase() === 'schottland' &&
        <FormControl className={classes.formControl}>
            <InputLabel id="article-type-label">Region</InputLabel>
            <Select
                labelId="article-type-label"
                id="article-type"
                MenuProps={{
                    disableScrollLock: true
                }}
                name={'region'}
                value={region}
                onChange={(e) => setRegion(e.target.value)}
            >
                <MenuItem value={'highlands'}>Highlands</MenuItem>
                <MenuItem value={'speyside'}>Speyside</MenuItem>
                <MenuItem value={'lowlands'}>Lowlands</MenuItem>
                <MenuItem value={'islands'}>Islands</MenuItem>
                <MenuItem value={'islay'}>Islay</MenuItem>
            </Select>
        </FormControl>
        }

        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative') > -1 &&
        <Autocomplete
            className={classes.textFieldStyle}
            value={peated}
            onChange={(event, newValue) => {
                if (!newValue) {
                    setPeated('');
                } else if (typeof newValue === 'string') {
                    setPeated(newValue);
                } else if (newValue && newValue.inputValue) {
                    setPeated(newValue.inputValue);
                } else {
                    setPeated(newValue.title);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="article-peated"
            options={peatedList.map(a => ({title: a}))}
            getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
            freeSolo
            autoHighlight
            renderOption={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Mälzung" variant="standard"/>
            )}
        />
        }
        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative') > -1 &&
        <Autocomplete
            className={classes.textFieldStyle}
            value={barrel}
            onChange={(event, newValue) => {
                if (!newValue) {
                    setBarrel('');
                } else if (typeof newValue === 'string') {
                    setBarrel(newValue);
                } else if (newValue && newValue.inputValue) {
                    setBarrel(newValue.inputValue);
                } else {
                    setBarrel(newValue.title);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="article-barrel"
            options={barrelList.map(a => ({title: a}))}
            getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
            freeSolo
            autoHighlight
            renderOption={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Basis Fass" variant="standard"/>
            )}
        />
        }
        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative') > -1 &&
        <FormControl className={classes.formControl}>
            <InputLabel id="article-grain-label">Getreide</InputLabel>
            <Select
                labelId="article-grain-label"
                id="article-grain"
                name={'grain'}
                MenuProps={{
                    disableScrollLock: true
                }}
                value={grain}
                onChange={(e) => setGrain(e.target.value)}
            >
                <MenuItem value={'blend'}>Blend</MenuItem>
                <MenuItem value={'grain'}>Grain</MenuItem>
                <MenuItem value={'postStill'}>PostStill</MenuItem>
                <MenuItem value={'singleGrain'}>Single Grain</MenuItem>
                <MenuItem value={'singleMalt'}>Single Malt</MenuItem>
            </Select>
        </FormControl>
        }
        {types.findIndex(t => t.value === 'whisky' || t.value === 'relative' || t.value === 'pairing' || t.value === 'present') > -1 &&
        <Autocomplete
            className={classes.textFieldStyle}
            value={productType}
            onChange={(event, newValue) => {
                if (!newValue) {
                    setProductType('');
                } else if (typeof newValue === 'string') {
                    setProductType(newValue);
                } else if (newValue && newValue.inputValue) {
                    setProductType(newValue.inputValue);
                } else {
                    setProductType(newValue.title);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="article-product-type"
            options={productTypeList.map(a => ({title: a}))}
            getOptionLabel={(option) => typeof option !== 'string' ? (!!option.inputValue ? option.inputValue : option.title) : option}
            freeSolo
            autoHighlight
            renderOption={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Artikel Type" variant="standard"/>
            )}
        />
        }
    </div>
};
