import React, {useEffect, useState} from 'react';
import {database} from "common/firebase";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {AppBar, makeStyles, Paper} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}))

const transactionTable = {
    ad: 'Werbung',
    sold: 'Verkauft',
    sample: 'Kostprobe',
    newArticle: 'Geschenktsset',
    tasting: 'Verkostung',
    restock: 'Nachfüllen',
    release: 'Auflösen'
}

const TransactionList = () => {
    const classes = useStyles();
    const [list, setList] = useState([]);
    const [filterString, setFilterString] = useState('');

    useEffect(() => {
        database.ref('transaction').on('value', snap => {
            if (!snap.exists()) return;
            const cacheList = [];
            snap.forEach(childSnap => {cacheList.push(childSnap.val())});
            setList(cacheList);
        })
    }, []);

    const filterFunctionTransactions = (element) => {
        return element.articleNumber.toLowerCase().includes(filterString.toLowerCase());
    }

    return <div>
        <AppBar position="static" className={classes.appBar}>Transaktionen</AppBar>
        <TableContainer component={Paper} className={classes.otherFont}>
            <Table style={{width: '100%'}} size="medium" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={6}>
                            <TextField
                                value={filterString}
                                onChange={(e) => setFilterString(e.target.value)}
                                variant={"outlined"}
                                label={"filter"}
                                fullWidth
                        />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Menge</TableCell>
                        <TableCell align="right">Artikelnummer</TableCell>
                        <TableCell align="right">Transaktionstyp</TableCell>
                        <TableCell align="right">Einkaufspreis</TableCell>
                        <TableCell align="right">Verkauspreis</TableCell>
                        <TableCell align="right">Marge</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.filter(filterFunctionTransactions).map((transaction, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {transaction.amount}
                            </TableCell>
                            <TableCell align="right">{transaction.articleNumber}</TableCell>
                            <TableCell align="right">{transactionTable[transaction.type]}</TableCell>
                            <TableCell align="right">€ {transaction.ek}</TableCell>
                            <TableCell align="right">€ {transaction.vk_net}</TableCell>
                            <TableCell align="right">€ {transaction.vk_net - transaction.ek}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6} align="right">Hier sollen dann Summierungen stehen</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <div style={{height: 24}}/>
    </div>
};

export default TransactionList;
