import { makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    otherFont:{
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *':{
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    textFieldStyle:{
        marginBottom: '1em'
    },
    formControl:{
        width: '100%',
        marginBottom: '1em'
    },
    dropZoneStyle:{
        width: 100,
        height: 150,
        textAlign: 'center',
        margin: '0.5rem',
        padding: '0.5em',
        fontSize: '0.75em',
        transition: 'all ease 500ms',
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        border: 'solid 1px #333',
        borderRadius: '0',
    },
    dropZoneStyleOnDrag: {
        border: 'dashed 1px black',
        borderRadius: '0.5em',
    },
    tabStyle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: '1.75em',
        textAlign: 'left',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
}));
