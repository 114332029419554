import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import img from "common/images/home/5_Whiskyveranstaltungen.jpg";
import {TextField, Button} from "@material-ui/core";
import {useStyles} from "./styling";
import {functions} from "../../common/firebase";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";

const ContactForm = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [datum, setDatum] = useState('');
    const [info, setInfo] = useState('');
    const [errorObject, setErrorObject] = useState({});
    const [loading,setLoading] = useState(false);

    const sendMessage = () => {
        setErrorObject({});
        const error = {
            name: name.length === 0 ? 'Darf nicht Leer sein' : null,
            tel: tel.length === 0 ? 'Darf nicht Leer sein' : null,
            address: address.length === 0 ? 'Darf nicht Leer sein' : null,
            email: email.length === 0 ? 'Darf nicht Leer sein' : null,
            datum: datum.length === 0 ? 'Darf nicht Leer sein' : null,
        };
        if(!error.name) delete error.name;
        if(!error.tel) delete error.tel;
        if(!error.address) delete error.address;
        if(!error.email) delete error.email;
        if(!error.datum) delete error.datum;
        if (Object.keys(error).length > 0) {
            setErrorObject(error);
        } else {
            setLoading(true);
            const message = {
                name,
                tel,
                address,
                email,
                datum,
                info,
                isRead: false
            };
            functions.httpsCallable('createAppointment')(message).then(res => {
                store.snackBarHandle.addMessages('Nachricht wurde erfolgreich versendet');
                setName('');
                setTel('');
                setAddress('');
                setEmail('');
                setDatum('');
                setInfo('');
                setErrorObject({});
                setLoading(false);
            }).catch( e => {
                store.snackBarHandle.addMessages('Beim versenden der Nachricht gab es einen Fehler');
            });
        }
    };

    return <Container maxWidth={'md'}>
        <Helmet>
            <title>Worldwhisky - Whisky online kaufen auf worldwhisky.at - Eigene Whiskyverkostung, Firmen-Degustationen, Hometastings</title>
        </Helmet>
        <div className={classes.imgWrapperStyle}>
            <img className={classes.imgStyle} src={img} alt={'info bild'}/>
        </div>
        <div className={classes.otherFont + ' ' + classes.infoText}>
            <p>
                Abgesehen von unseren monatlichen Veranstaltungen organisieren und gestalten wir gerne Ihr persönliches Degustationsevent. Das ist sowohl für
                Sie als Privatperson und Ihre Gäste bei Ihnen zu Hause, oder auch für Firmen und Feiern möglich.
                Ob zu einem Geburtstag oder als Weihnachtsfeier, die Gestaltungsmöglichkeiten sind breit gefächert. Aber nicht nur Tastings sondern auch
                "Pairings" (das heisst die Degustation ist in Kombination mit kleinen Speisen und Bier bzw. auch entsprechenden Süssspeisen) können organisiert
                werden. Jedes Programm wird vorher individuell zusammengestellt (unverbindlich bis zum Zeitpunkt der Bestellung).
            </p>
            <p>
                Hier können Sie uns eine unverbindliche Anfrage schicken.
                Natürlich wird jegliche Veranstaltung entsprechend der aktuellen Corona-Vorschriften abgehalten und gestaltet.
            </p>
        </div>
        <form className={classes.formStyle}>
            <TextField
                className={classes.textField}
                id="name"
                label="Name"
                variant={"outlined"}
                value={name}
                type="text"
                fullWidth
                error={!!errorObject.name}
                helperText={errorObject.name}
                disabled={loading}
                onChange={(e) => setName(e.target.value)}
            />
            <TextField
                className={classes.textField}
                id="tel"
                variant={"outlined"}
                label="Telefonnummer"
                value={tel}
                type="tel"
                fullWidth
                error={!!errorObject.tel}
                helperText={errorObject.tel}
                disabled={loading}
                onChange={(e) => setTel(e.target.value)}
            />
            <TextField
                className={classes.textField + ' ' + classes.multiline}
                id="address"
                variant={"outlined"}
                label="Veranstaltungsort / -adresse"
                value={address}
                type="text"
                fullWidth
                multiline
                rows={3}
                error={!!errorObject.address}
                helperText={errorObject.address}
                disabled={loading}
                onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
                className={classes.textField}
                id="email"
                variant={"outlined"}
                label="E-Mail Adresse"
                value={email}
                type="email"
                fullWidth
                error={!!errorObject.email}
                helperText={errorObject.name}
                disabled={loading}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                className={classes.textField}
                id="date"
                variant={"outlined"}
                label="Veranstaltungsdatum"
                value={datum}
                type="date"
                fullWidth
                error={!!errorObject.datum}
                helperText={errorObject.datum}
                disabled={loading}
                onChange={(e) => setDatum(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                className={classes.textField}
                id="info"
                variant={"outlined"}
                label="Zusatzinformationen"
                value={info}
                type="text"
                fullWidth
                multiline
                rows={3}
                disabled={loading}
                onChange={(e) => setInfo(e.target.value)}
            />
            <Button variant={'contained'} color={"primary"} onClick={sendMessage} disabled={loading}>Veranstaltung anfragen</Button>
            { loading && <FontAwesomeIcon icon={faSpinner} spin /> }
        </form>
    </Container>
}));
export default ContactForm;
