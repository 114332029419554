import React, {useEffect, useState} from 'react'
import Container from "@material-ui/core/Container";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import ReactMarkdown from "react-markdown";
import MarkdownEditor from "@uiw/react-markdown-editor";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}))

export const MailDialog = ({whiskyEvent, open, handleClose, changeMailContent}) => {
    const classes = useStyles();
    const [editMode, setEditMode] = useState(false);
    const [mailContent, setMailContent] = useState('');
    const {mail} = whiskyEvent;

    useEffect(() => {
        if (mail) {
            setMailContent(mail);
        }
    }, [mail]);

    const handleSetMail = (a, b, val) => {
        setMailContent(val);
    };

    const saveChanged = () => {
        changeMailContent(mailContent);
        setEditMode(false);
    }

    const cancelChange = () => {
        setMailContent(mail);
        setEditMode(false);
    }


    return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={'md'} disableScrollLock={true}>
        <DialogTitle id="form-dialog-title">Kaufbestätigung E-Mail Vorschau</DialogTitle>
        <DialogContent>
            <Container maxWidth={'md'} style={{backgroundColor: '#fff', padding: '24px'}} className={classes.otherFont}>
                <div style={{textAlign: 'left'}}>
                    <div style={{paddingBottom: 24, display: 'flex', justifyContent: 'center'}}>
                        <img style={{width: '75%'}}
                             src={'https://firebasestorage.googleapis.com/v0/b/worldwhisky-onlinestore.appspot.com/o/images%2Flogos%2FLogoHeader.png?alt=media&token=10094e33-4c86-4092-8f17-3cf7f876c6a5'}
                             alt="WorldWhisky Logo"/>
                    </div>

                    <h1>Wir freuen uns Dich bei unseren Event begrüßen zu dürfen</h1>
                    {(whiskyEvent.mail || editMode) && <h2>Weitere Informationen</h2> }
                    {(whiskyEvent.mail && !editMode )&& <ReactMarkdown source={whiskyEvent.mail}/> }
                    {editMode &&
                        <MarkdownEditor
                            visible={false}
                            className={classes.otherFont}
                            value={mail}
                            id={'description'}
                            onChange={handleSetMail}
                            height={250}
                        />
                    }
                    {whiskyEvent.description && <>
                        <h2>Beschreibung</h2>
                        <ReactMarkdown source={whiskyEvent.description}/>
                    </>}
                    <h2>Die Karten</h2>
                    <div style={{
                        border: 'solid 1px #333',
                        padding: '1em',
                        margin: '1em',
                        textAlign: 'left',
                        fontSize: '1.2em'
                    }}>
                        <h3>{'Vorname'} {'Nachname'} {'EventName'} Ticket Nr.{'Zahl'}</h3>
                    </div>
                </div>
            </Container>
        </DialogContent>
        <DialogActions>
            {!editMode ?
                <>
                    <Button onClick={handleClose} color="primary" variant={'outlined'}>
                        Senden an
                    </Button>
                    <Button onClick={() => setEditMode(true)} color="primary" variant={'outlined'}>
                        Zusatz barbeiten
                    </Button>
                </> :
                <>
                    <Button onClick={saveChanged} color="primary" variant={'outlined'}>
                        Speichern
                    </Button>
                    <Button onClick={cancelChange} color="primary" variant={'outlined'}>
                        Abbrechen
                    </Button>
                </>}

            <Button onClick={handleClose} color="primary" variant={'outlined'}>
                Schließen
            </Button>
        </DialogActions>
    </Dialog>
}
