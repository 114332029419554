import React, {useState, useEffect, useCallback} from 'react';
import {Avatar, Box, Button, Divider, FormControl, Grid, MenuItem, Select, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign, faGlassWhiskey, faTimes, faTrash, faWineBottle} from "@fortawesome/free-solid-svg-icons";
import {inject, observer} from "mobx-react";
import {useStyles} from "../styling";
import {database} from "common/firebase";
import * as moment from 'moment';
import {cleanPriceString, isEvent} from "common/statelessFunctions";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DbImage from "components/dbImage";

export const CartEntry = inject('store')(observer(({store, entry}) => {
    const classes = useStyles();
    const [reservedArticles, setReservedArticles] = useState(0);
    const [maxAmount, setMaxAmount] = useState(1);
    const [displayTime, setDisplayTime] = useState();
    const [isAbo, setIsAbo] = useState();
    const [times, setTimes] = useState(1);
    const [amount, setAmount] = useState(1);

    const removeEntryFromCart = useCallback(() => {
        store.cartStore.removeEntryFromCart(entry);
    }, [entry, store.cartStore]);

    const updateProductInCart = useCallback(event => {
        const {value} = event.target;
        store.cartStore.updateEntryInCart(entry, value);
    }, [entry, store.cartStore]);

    const getAboInfos = useCallback(() => {
        setTimes(entry.times);
        setAmount(parseInt(entry.articleNumber.slice(-3)[0]));
    }, [entry]);

    useEffect(() => {
        if (!!entry) {
            const _isAbo = entry.articleNumber.includes('ABO');
            setIsAbo(_isAbo);
            if (_isAbo) getAboInfos();
            database.ref(`articleCart/${entry.articleNumber}`).on('value', snap => {
                const data = snap.val();
                let sum = 0;
                if (data) {
                    for (const key of Object.keys(data)) {
                        sum += data[key];
                    }
                    setReservedArticles(sum - entry.amount);
                }
            });
            if (isEvent(entry.articleNumber)) {
                let maxAmountLocal = 1;
                database.ref('events').child(entry.year).child(entry.key).child('capacity').once('value', snap => {
                    if (snap.exists()) {
                        maxAmountLocal = parseInt(snap.val());
                        database.ref('events_attendees').child(entry.key).child('count').on('value', snapAtten => {
                            if (snapAtten.exists()) {
                                maxAmountLocal -= parseInt(snapAtten.val());
                            }
                            setMaxAmount(maxAmountLocal);
                        });
                    }
                })
            } else {
                database.ref(`batches/${entry.articleNumber}/${entry.activeBatch}`).on('value', snap => {
                    if (!snap.exists()) return;
                    const activeBatch = snap.val();
                    const maxAmountLocal = activeBatch.currentAmount || activeBatch.amount;
                    setMaxAmount(maxAmountLocal);
                    if (entry.amount > maxAmountLocal) {
                        updateProductInCart(entry, maxAmountLocal);
                        entry.amount = maxAmountLocal;
                    }
                });
            }
        }
    }, [entry, getAboInfos, updateProductInCart]);

    useEffect(() => {
        const interval = setInterval(() => {
            const diff = moment(entry.timestamp + (1000 * 60 * 60 * 2)).diff(moment(), 'seconds');
            setDisplayTime(moment("2020-01-01").startOf('day')
            .seconds(diff)
            .format('H:mm:ss'));
            if (entry.timestamp + (1000 * 60 * 60 * 2) - new Date().getTime() < 0) {
                removeEntryFromCart();
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [entry.timestamp, removeEntryFromCart]);

    const getThumbnailTag = () => {
        if(!!entry.thumbnail) return <Avatar style={{width: '4em', height: '4em', marginRight: '0.5em'}}>
            <DbImage style={{width: '100%', height: '100%'}} alt={`Whisky flaschen ${entry.articleNumber}`} src={['images', entry.articleNumber, entry.thumbnail]} />
        </Avatar>;
        if(!!entry.image) return <Avatar alt={'whisky bild'} src={entry.image} style={{width: '4em', height: '4em', marginRight: '0.5em'}} />;
        return <Avatar style={{width: '4em', height: '4em', marginRight: '0.5em'}}><FontAwesomeIcon icon={faWineBottle}/></Avatar>
    };

    return <> <ListItem>
        <ListItemIcon>
            <FormControl variant={"outlined"} style={{marginRight: '0.5em'}}>
                <Select
                    id={'amount-id-' + entry.articleNumber}
                    value={entry.amount}
                    onChange={updateProductInCart}
                    MenuProps={{
                        disableScrollLock: true
                    }}
                >
                    {Array.from({length: maxAmount - reservedArticles + 1}, (_, i) => i).map((v, k) => (
                        <MenuItem key={k} value={v}>{v}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ListItemIcon>
        <ListItemAvatar>
            { getThumbnailTag() }
        </ListItemAvatar>
        {!isAbo ? <ListItemText
                primary={entry.name}
                secondary={entry.producer || entry.distillery}
            /> :
            <ListItemText
                primary={"Whisky Abo"}
                secondary={<span>{times} <FontAwesomeIcon icon={faTimes}/> {amount} Whisky</span>}
            />

        }
        <ListItemSecondaryAction>
            <Typography style={{marginRight: '-13px'}}><FontAwesomeIcon icon={faEuroSign} /><span className={classes.moneySpan}>{cleanPriceString(entry.vk_net * entry.amount)}</span> </Typography>
        </ListItemSecondaryAction>

    </ListItem>
        <ListItem>
            <ListItemText primary={!isAbo ?
                <span> Artikel bleibt noch <span style={{minWidth: 60, display: 'inline-block'}}>{displayTime} </span> im Warenkorb.</span> :
                <span> Artikel bleibt bis zum neuladen der Seite im Warenkorb.</span>
            }/>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={removeEntryFromCart}>
                    <FontAwesomeIcon icon={faTrash}/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        <Divider />

    </>


}));
