import React, {useCallback, useEffect, useState} from "react";
import scriptLoader from "react-async-script-loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "@material-ui/core";
import {auth, database, functions} from "../../common/firebase";
import {faCreditCard, faGlassWhiskey} from "@fortawesome/free-solid-svg-icons";
import {DateTime} from "luxon";
import {inject, observer} from "mobx-react";
import * as Sentry from "@sentry/react";
import {useHistory} from "react-router-dom";
import eps from "common/images/paymentmethods-2/eps-dark.svg";
import maestro from "common/images/paymentmethods-2/maestro-dark.svg";
import mastercard from "common/images/paymentmethods-2/mastercard-dark.svg";
import visa from "common/images/paymentmethods-2/visa-dark.svg";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( theme => ({
    paymentIcon: {
        maxHeight: 25,
        maxWidth: 50,
        width: '100%',
    },
}))

const UnzerButton = inject('store')(observer( ({store, order, isScriptLoaded, isScriptLoadSucceed, className}) => {
    const history = useHistory();

    const classes = useStyles();
    const [loading, setIsLoading] = useState(true);
    const [loadingPopUp, setIsLoadingPopUp] = useState(false);
    const {currentUser, loadingUser} = store.userStore;
    const redirectTo = useCallback(url => {
        if (currentUser && currentUser.isAnonymous) auth.signOut();
        history.push(url)
    }, [history, currentUser]);

    useEffect(() => {
        if (loading && isScriptLoaded && isScriptLoadSucceed) {
            setIsLoading(false);
        }
    }, [isScriptLoaded, isScriptLoadSucceed, loading])


    const fetchTokenId = async () => {
        setIsLoadingPopUp(true);
        order.unzerCreated = DateTime.local().toMillis();
        order.unzer = true;
        try {
            console.log(order?.id ?? order);
            if(order.unzerId && order.id) await database.ref('unzerOrders').child(order.id).set(null);
            if(!!order.paypal) delete order.paypal;

            const orderRef = await database.ref('unzerOrders').push(order);
            order.id = orderRef.key;
            console.log(orderRef.key);
            await database.ref('unzerOrders').child(order.id).child('id').set(order.id);
            const res = await functions.httpsCallable('getUnzerToken')({order, baseUri: window.location.host});

            if (!loading && res?.data?.id) {
                const tokenId = res.data.id
                await database.ref('unzerOrders').child(order.id).child('unzerId').set(tokenId);
                const checkout = new window.checkout(tokenId);
                setIsLoadingPopUp(true);
                await checkout.init();
                checkout.open();

                // Abort
                checkout.abort(function() {
                    setIsLoadingPopUp(false);
                    console.log('Test Abort Unzer');
                    database.ref('unzerOrders').child(order.id).set(null);
                    store.snackBarHandle.addMessages("Zahlungsmethoden auswahl wurde abgebrochen");
                });

                // Success
                checkout.success(async function(data) {
                    console.log(data);
                    setIsLoadingPopUp(false);
                    redirectTo('/warenkorb/order/?unzer=' + order.id)
                })

                // Error
                checkout.error(function(error) {
                    setIsLoadingPopUp(false);
                    console.log('Test Error Unzer');
                    database.ref('unzerOrders').child(order.id).set(null);
                    store.snackBarHandle.addMessages("Fehler im Zahlungsprozess, versuchen sie es nocheinmal.");
                    Sentry.captureException(error);
                })
            }
        } catch (error) {
            setIsLoadingPopUp(false);
            store.snackBarHandle.addMessages("Beim laden der weiteren Zahlungsmethoden ist ein Fehler aufgetreten");
            Sentry.captureException(error);
        }
    }

    return <div>
        <Button
            className={className}
            variant={'contained'}
            color={'primary'}
            fullWidth
            onClick={fetchTokenId}
            disabled={loading || loadingPopUp}>
            {(loading || loadingPopUp) && <FontAwesomeIcon icon={faGlassWhiskey} spin style={{marginRight: '0.5em'}}/>}
            <FontAwesomeIcon icon={faCreditCard} style={{marginRight: '0.5em'}}/>
            <span>Weitere Zahlungsmethoden</span>
        </Button>
        <div>
            <img className={classes.paymentIcon} src={eps} alt={'EPS Logo'}/>
            <img className={classes.paymentIcon} src={maestro} alt={'maestro Logo'}/>
            <img className={classes.paymentIcon} src={mastercard} alt={'mastercard Logo'}/>
            <img className={classes.paymentIcon} src={visa} alt={'visa Logo'}/>
        </div>
    </div>
}))

export default scriptLoader(`https://static.unzer.com/v1/checkout.js`)(UnzerButton);
