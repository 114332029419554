import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
//Visa, Maestro, Mastercars, SOfort, EPS, Paypal
import eps from 'common/images/paymentmethods/eps.png';
import maestro from 'common/images/paymentmethods/maestro.png';
import mastercard from 'common/images/paymentmethods/mastercard.png';
import paypal from 'common/images/paymentmethods/paypal.png';
import visa from 'common/images/paymentmethods/visa.png';
import {useStyles} from "./styling";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopyright} from "@fortawesome/free-regular-svg-icons";
import {faFacebookF, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {inject, observer} from "mobx-react";
import {faFileInvoiceDollar, faMoneyBill} from "@fortawesome/free-solid-svg-icons";

function Footer({store}) {
    const location = useLocation();
    const {paymentHeader, iconWrapper, paymentIconWrapper, wrapperFooter, wrapperBlock, linkElement, linkIconElement, wrapperBlockEnd, wrapperFooterAdmin, paymentIcon} = useStyles();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (location) {
            const {pathname} = location;
            setIsAdmin(pathname.includes('admin'));
        }
    }, [location]);

    return <div className={!isAdmin ? wrapperFooter : wrapperFooterAdmin}>
        <div className={wrapperBlock}>
            <h3 className={paymentHeader}>Informationen </h3>
            <Link className={linkElement} to={'/terms/'}> Allgemeine Geschäftsbedingungen </Link>
            <Link className={linkElement} to={'/termsDelivery/'}> Lieferkonditionen </Link>
            <Link className={linkElement} to={'/contact/'}> Impressum / Kontakt </Link>
            <Link className={linkElement} to={'/dataSecurity/'}> Datenschutz </Link>
            <Link className={linkElement} to={'/termsWithdraw/'}> Widerrufsrecht </Link>
        </div>
        <div className={wrapperBlock}>
            <h3 className={paymentHeader}>Zahlungsmethoden </h3>
            <div className={iconWrapper}>
                <div className={paymentIconWrapper}><img className={paymentIcon} src={eps} alt={'EPS Logo'}/></div>
                <div className={paymentIconWrapper}><img className={paymentIcon} src={maestro} alt={'maestro Logo'}/></div>
                <div className={paymentIconWrapper}><img className={paymentIcon} src={mastercard} alt={'mastercard Logo'}/></div>
                <div className={paymentIconWrapper}><img className={paymentIcon} src={visa} alt={'visa Logo'}/></div>
                <div className={paymentIconWrapper}><img className={paymentIcon} src={paypal} alt={'paypal Logo'}/></div>
                <div className={paymentIconWrapper}><FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" style={{marginBottom: '0.25em'}}/> Vorkasse</div>
            </div>
        </div>
        <div className={wrapperBlock + ' ' + wrapperBlockEnd}>
            <div>
                <h3 className={paymentHeader}>Soziale Medien </h3>
                {store.isWw &&
                <a className={linkIconElement} href={'https://www.facebook.com/worldwhisky.at'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon
                    icon={faFacebookF}/></a>}
                {store.isWw &&
                <a className={linkIconElement} href={'https://youtube.com'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube}/>
                </a>}
                {store.isWw &&
                <a className={linkIconElement} href={'https://www.instagram.com/worldwhisky.at/'} target={'_blank'} rel="noopener noreferrer"><FontAwesomeIcon
                    icon={faInstagram}/> </a>}
            </div>
            <div style={{paddingRight: 24}}>
                <FontAwesomeIcon icon={faCopyright}/> {store.isWw ? 'WORLD WHISKY WW OG' : 'ST Logistik & Transfer GmbH'}
            </div>
        </div>
    </div>

}

export default inject('store')(observer(Footer));

