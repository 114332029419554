import React from 'react';
import {Button, List, ListSubheader} from "@material-ui/core";
import Modal from "../../../../components/Modal";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {DateTime} from "luxon";
import {functions} from "../../../../common/firebase";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    otherFont: {
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    tabStyle:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}));

const PAYMENT_METHOD_TEXT = {
    prepaid: 'Mit Vorkassa bezahlt',
    paypal: 'Mit Paypal bezahlt',
    card: 'Mit Zahlungssystem Unzer bezahlt'
};

export const OrderDetails = inject('store')(observer(({store, order, open, handleClose}) => {
    const classes = useStyles();

    const getPaymentInfo = (method) => {
        if(method === 'paypal') return <div>
            <div>{order.paypal.id}</div>
            <div>{order.paypal.status}</div>
        </div>

        if(method === 'card') return <div>{order.unzerCreated}</div>

        if(method === 'prepaid') return <div>{order.billData.isPayed ? 'Bezahlt': 'Ausständig'}</div>

        return <div>Unbekannt</div>
    }

    const sendAttendeeMail = () => {
        functions.httpsCallable('sendEventAttendeeInformation')({order}).then(res => {
            store.snackBarHandle.addMessages('Mail wurde versendet.');
        }).catch(e => {
            console.error(e);
            store.snackBarHandle.addMessages('Mail konnte nicht ordnungsgemäß versendet werden.');
        });
    }

    const getEventAmount = key => {
        return order.products.find( p => p.key === key).amount;
    }

    return order && <Modal open={open} handleClose={handleClose} title={"Bestellungsdetails"}
                  actions={
                          <Button color={"primary"} variant={"contained"} onClick={handleClose}>Schließen</Button>
                  }>
        <List className={classes.otherFont}>
            <Divider />
            <ListSubheader disableSticky >Metadaten</ListSubheader>
            <ListItem>
                <ListItemText primary={order.orderNumber} secondary={'Bestellungsnummer'} />
            </ListItem>
            <ListItem>
                <ListItemText primary={order.state} secondary={'Status'} />
            </ListItem>
            {!!order.paypal  && <ListItem>
                <ListItemText primary={<div>
                    <div>{order.paypal.id}</div>
                    <div>{order.paypal.status}</div>
                </div>} secondary={'Paypal'} />
            </ListItem> }
            <ListItem>
                <ListItemText primary={getPaymentInfo(order?.billData?.paymentMethod)} secondary={(order?.billData?.paymentMethod ? PAYMENT_METHOD_TEXT[order.billData.paymentMethod] : 'nicht vorhanden')} />
            </ListItem>
            <Divider />
            <ListSubheader disableSticky>Personenbezogene Daten</ListSubheader>
            {!!order.user && <ListItem>
                <ListItemText primary={order.user} secondary={'User Id vom Besteller'} />
            </ListItem> }
            {!!order.paymentEmail && <ListItem>
                <ListItemText primary={order.paymentEmail} secondary={'E-Mail Adresse'} />
            </ListItem>}
            {!!order.paymentAddress && <ListItem>
                <ListItemText primary={<div>
                    <div>{order.paymentAddress.firstName} {order.paymentAddress.lastName}</div>
                    <div>{order.paymentAddress.address1}</div>
                    <div>{order.paymentAddress.address2}</div>
                    <div>{order.paymentAddress.plz} {order.paymentAddress.region}</div>
                    <div>{order.paymentAddress.land}</div>
                </div>} secondary={'Rechnungsadresse'} />
            </ListItem> }
            {!!order.shippingAddress && <ListItem>
                <ListItemText primary={<div>
                    <div>{order.shippingAddress.firstName} {order.shippingAddress.lastName}</div>
                    <div>{order.shippingAddress.address1}</div>
                    <div>{order.shippingAddress.address2}</div>
                    <div>{order.shippingAddress.plz} {order.shippingAddress.region}</div>
                    <div>{order.shippingAddress.land}</div>
                </div>} secondary={'Lieferadresse'} />
            </ListItem> }
            <ListItem>
                <ListItemText primary={DateTime.fromMillis(order.timestamp).toFormat('dd.LL.yy HH:mm')} secondary={'Bestellungsdatum'} />
            </ListItem>
            <Divider />
            <ListSubheader disableSticky>Produkte</ListSubheader>
            {!!order.products && order.products.map( (p,i) => (
                <ListItem key={i}>
                    <ListItemText primary={p.amount + ' x ' + p.name} secondary={p.articleNumber} />
                </ListItem>
            ))}
            <Divider />
            {!!order.attendees && <ListSubheader disableSticky>Event Besucher:  {order.billData.isPayed && (<Button variant={"text"} color={"secondary"} onClick={sendAttendeeMail}>Event Daten nochmal schicken</Button>)}</ListSubheader>}
            {!!order.attendees && order.attendees.map( (p,i) => (
                <ListItem key={i}>
                    <ListItemText primary={p.eventName + ' ' + p.firstName + ' ' + p.lastName} secondary={getEventAmount(p.event)} />
                </ListItem>
            ))}
        </List>
    </Modal>
}));

/*

 */
