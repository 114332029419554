import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles( theme => ({
    gridProductStyle:{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr))',
        gridAutoRows: '430px',
        gridGap: '5em 1.5em',
        marginTop: '5em',
    },
    productImgStyle:{
        objectFit: 'contain',
        width: '100%',
        height: 300,
        filter: "drop-shadow(0px 0px 1px rgba(0,0,0,.9)) drop-shadow(0px 0px 1px rgba(0,0,0,.9))",
    },
    productImgStyleIcon:{
        objectFit: 'contain',
        width: '100%',
        fontSize: '8em',
        height: 300,
    },
    btnCartStyle:{
        backgroundColor: '#333',
        color: '#f5f5dc',
        width: '100%',
        borderRadius: '0',

        '&:hover': {
            color:'#fff',
            backgroundColor: '#000',
        }
    },
    gridMinHeight: {
        minHeight: 'calc( 100vh - 420px )',
    },
    loading: {
        width: '100%',
        fontSize: '3em',
        minHeight: 400,
    },
    btnBottom: {
      marginBottom: '0.5em',
    },
    hoverEffect:{
        cursor: 'pointer',
        padding: '0.5em',
        marginBottom: '12px',
        '&:hover': {
            '& $productImgStyle' : {
                filter: "drop-shadow(0px 0px 1px rgba(0,0,0,.9)) drop-shadow(0px 0px 7px rgba(0,0,0,.9))",

            },

            '& $productImgStyleIcon' : {
                filter: "drop-shadow(0px 0px 1px rgba(0,0,0,.9)) drop-shadow(0px 0px 1px rgba(0,0,0,.9))",

            }
        }
    },
    price: {
        fontSize: '1.5em',
        fontWeight: 'bold',
    },
    infoPrice:{
        fontSize: '0.8em',
    }
}));