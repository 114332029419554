import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

const DropZone = ({handleAcceptedFiles, className, dragClassName,dropText,startText}) => {
    const onDrop = useCallback((acceptedFiles) => {
        handleAcceptedFiles(acceptedFiles);
    }, [handleAcceptedFiles]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()} className={ className + ( isDragActive ? ' ' + dragClassName : '')}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>{dropText}</p> :
                    <p>{startText}</p>
            }
        </div>
    )
};

export default DropZone;