import {action, decorate, observable} from "mobx"
import firebase, {auth, database} from "common/firebase";


class UserStore {

    constructor() {
        auth.onAuthStateChanged(user => {
            this.setCurrentUser(user);
            this.setIsSignedIn(!!user);
            this.setCurrentRole(0);

            if (!user) return this.userLoginFinished();

            const myUserRef = database.ref(`users/${user.uid}`);
            myUserRef.on('value', snap => {
                if (!snap.exists()) return;
                this.setCurrentRole(snap.val().role);
                this.userLoginFinished();
                if (snap.val().role < 0b1000) return;
                const userRef = database.ref('users/');
                userRef.on('child_added', snap => {
                    this.addUserToList(snap.val(), snap.key);
                });

            })

        });
    }

    firebaseChecked = false;
    isSignedIn = false;
    currentUser = null;
    currentRole = 0;
    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ]
    };
    devSavedUsers = [];
    loadingUser = true;

    userLoginFinished = () => {
        this.setFirebaseChecked(true);
        this.setLoadingUser(false);
    }

    setCurrentUser = user => {
        if (!!user) this.devAddUserToList(user);
        this.currentUser = user;
    };

    setIsSignedIn = isSignedIn => {
        this.isSignedIn = isSignedIn;
    };

    setDevSavedUsers = savedUsers => {
        this.devSavedUsers = [...savedUsers];
    };

    setFirebaseChecked = firebaseChecked => {
        this.firebaseChecked = firebaseChecked;
    };

    setCurrentRole = role => {
        this.currentRole = role;
    };

    setLoadingUser = loading => this.loadingUser = loading;

    addUserToList = (newUser, key) => {
        newUser.uid = key;
        const index = this.devSavedUsers.findIndex(user => user.uid === key);
        if (index === -1) this.devSavedUsers = [...this.devSavedUsers, newUser];
    };

    changeUserData = changedUser => {
        const index = this.devSavedUsers.findIndex(user => user.uid === changedUser.uid);
        if (index === -1) {
            this.devSavedUsers = [...this.devSavedUsers, changedUser];
        } else {
            this.devSavedUsers[index] = changedUser;
            this.devSavedUsers = [...this.devSavedUsers];
        }
        const smallChangedUser = JSON.parse(JSON.stringify(changedUser));
        delete smallChangedUser.uid;
        database.ref(`users/${changedUser.uid}`).set(smallChangedUser);
    };

    getUiConfig = () => {
        return this.uiConfig
    };

    /**
     *  private käufer 0001
     *  geschäft käufer 0010
     *  verkäufer 0100
     *  admin 1000
     */
    devAddUserToList = user => {
        const path = `users/${user.uid}`;
        const newUserObject = {
            displayName: user.displayName,
            email: user.email,
            role: 0b0001,
        };
        const userRef = database.ref(path);
        userRef.once('value', snap => {
            if (!snap.val()) {
                database.ref(path).set(newUserObject).then(snapshot => {this.setCurrentRole(0b0001);});
            } else {
                this.setCurrentRole(snap.val().role);
            }
        });
    };
}

decorate(UserStore, {
    firebaseChecked: observable,
    isSignedIn: observable,
    currentUser: observable,
    currentRole: observable,
    devSavedUsers: observable,
    setCurrentUser: action,
    setFirebaseChecked: action,
    setLoadingUser: action,
    setIsSignedIn: action,
    setCurrentRole: action,
    devAddUserToList: action,
    setDevSavedUsers: action,
    addUserToList: action,
});

export default UserStore;
