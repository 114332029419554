import {makeStyles} from "@material-ui/styles";

const BASE_HEIGHT = 856;

export const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    btnBox:{
        display: 'flex',
        padding: theme.spacing(2),
        justifyContent: "flex-start",
    },
    btn:{
        margin: theme.spacing(1)
    },
    noPrint: {
        '@media print':{
            display: 'none'
        }
    }
}));
