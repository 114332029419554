import React, {useEffect, useState} from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import MarkdownEditor from "@uiw/react-markdown-editor";

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    }
}))

export const DescriptionDialog = ({open, handleClose, description, changeDescription}) => {
    const classes = useStyles();
    const [descriptionContent, setDescriptionContent] = useState('');

    useEffect(() => {
        if (description) {
            setDescriptionContent(description);
        }
    }, [description]);

    const handleSetDescription = (a, b, val) => {
        setDescriptionContent(val)
    }


    const saveChanged = () => {
        changeDescription(descriptionContent);
        handleClose();
    }

    const cancelChange = () => {
        setDescriptionContent(description);
        handleClose();
    }


    return <Dialog open={open} onClose={cancelChange} aria-labelledby="form-dialog-title" maxWidth={'md'} disableScrollLock={true}>
        <DialogTitle id="form-dialog-title">Beschreibung bearbeiten</DialogTitle>
        <DialogContent>
            <MarkdownEditor
                visible={false}
                className={classes.otherFont}
                value={description}
                id={'description'}
                onChange={handleSetDescription}
                height={350}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={saveChanged} color="primary" variant={'outlined'}>
                Speichern
            </Button>
            <Button onClick={cancelChange} color="primary" variant={'outlined'}>
                Schließen
            </Button>
        </DialogActions>
    </Dialog>
}
