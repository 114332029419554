import {useStyles} from "../styling";
import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import {Box, Button, InputAdornment} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faEdit} from "@fortawesome/free-regular-svg-icons";
import {faBox, faEuroSign, faHome, faWineBottle} from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const EMPTY_ARTICLE = {
    name: '',
    amount: '',
    price: '',
}

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.articleNumber + ' - ' + option.name + ' - ' + option.producer,
});

const filterOptions2 = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) =>  option.name,
});

export const AddProductModal = ({open, handleClose, articles, nextProduct, eventsList, email, firstName, lastName}) => {
    const classes = useStyles()
    const [tab, setTab] = React.useState(0);

    // SELECTED ARTICLE
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedArticleAmount, setSelectedArticleAmount] = useState(0);

    // FREITEXT
    const [createdArticle, setCreatedArticle] = useState(JSON.parse(JSON.stringify(EMPTY_ARTICLE)))

    // ABO
    const [amount, setAmount] = useState(2);
    const [times, setTimes] = useState(6);
    const [price, setPrice] = useState(0);

    // EVENT
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedEventAmount, setSelectedEventAmount] = useState(1);


    useEffect(() => {
        setPrice((amount !== 5 ? amount !== 4 ? amount !== 3 ? amount !== 2 ? 9 : 17 : 23 : 32 : 39) * times);
    }, [amount, times])

    const createProductAndSave = () => {
        const newProduct = {}
        if (tab === 0) {
            newProduct.activeBatch = null;
            newProduct.amount = createdArticle.amount;
            newProduct.articleNumber = "WWNEWEMPTYARTICLE";
            newProduct.thumbnail = null;
            newProduct.image = null;
            newProduct.name = createdArticle.name;
            newProduct.producer = null;
            newProduct.vk_net = createdArticle.price;
        } else if (tab === 1) {
            newProduct.activeBatch = selectedArticle.activeBatch;
            newProduct.amount = selectedArticleAmount;
            newProduct.articleNumber = selectedArticle.articleNumber;
            newProduct.thumbnail = selectedArticle.thumbnails?.[0] ?? null;
            newProduct.image = selectedArticle.images?.[0] ?? null;
            newProduct.name = selectedArticle.name;
            newProduct.producer = selectedArticle.producer;
            newProduct.vk_net = selectedArticle.vk_net;
        } else if (tab === 2) {
            newProduct.amount = selectedEventAmount;
            newProduct.articleNumber = selectedEvent.number;
            newProduct.thumbnail = null;
            newProduct.image = null;
            newProduct.timestamp = new Date().getTime();
            newProduct.name = selectedEvent.name;
            newProduct.key = selectedEvent.key;
            newProduct.year = selectedEvent.year;
            newProduct.shippingCost = selectedEvent.shippingCost;
            newProduct.vk_net = selectedEvent.vk_net;
            console.log(newProduct)
        } else if (tab === 3) {
            const longTimes = ('0' + times).slice(-2);
            newProduct.articleNumber = `WWABO${amount}${longTimes}`;
            newProduct.amount = 1;
            newProduct.vk_net = price;
            newProduct.image = null;
            newProduct.thumbnail = null;
            newProduct.times = times;
        }
        nextProduct(newProduct);
        handleClose();
    }

    const handleChangeCreatedArticle = (event) => {
        const name = event.target.name;
        createdArticle[name] = event.target.value;
        setCreatedArticle({...createdArticle});
    }

    const handleSetTab = (event, newValue) => {
        setTab(newValue);
    };
    return <Modal open={open} handleClose={handleClose} title={"Produkt hinzufügen"}
                  actions={
                      <div>
                          <Button color={"primary"} variant={"contained"} onClick={handleClose} className={classes.otherFont}
                                  style={{marginRight: '0.25em'}}>Abbrechen</Button>
                          <Button color={"primary"} variant={"contained"} onClick={createProductAndSave} className={classes.otherFont}>Hinzufügen</Button>
                      </div>
                  }>
        <Tabs
            value={tab}
            onChange={handleSetTab}
            variant="scrollable"
            style={{borderBottom: 'solid 1px #333'}}
            scrollButtons="off"
            aria-label="scrollable prevent tabs example"
        >
            <Tab icon={<FontAwesomeIcon icon={faEdit}/>} aria-label="open text"/>
            <Tab icon={<FontAwesomeIcon icon={faWineBottle}/>} aria-label="products"/>
            <Tab icon={<FontAwesomeIcon icon={faCalendar}/>} aria-label="events"/>
            <Tab icon={<FontAwesomeIcon icon={faHome}/>} aria-label="abo"/>
        </Tabs>
        {tab === 0 && <Box className={classes.boxStyle}>
            <TextField
                className={classes.textField}
                value={createdArticle.name}
                name={"name"}
                onChange={handleChangeCreatedArticle}
                variant="outlined"
                label={"Produktname"}
                fullWidth
            />
            <TextField
                className={classes.textField}
                value={createdArticle.price}
                name={"price"}
                onChange={handleChangeCreatedArticle}
                variant="outlined"
                type="number"
                label={"Preis"}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faEuroSign}/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                value={createdArticle.amount}
                name={"amount"}
                onChange={handleChangeCreatedArticle}
                variant="outlined"
                label={"Menge"}
                type="number"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faBox}/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                value={(createdArticle?.price ?? 0) * (createdArticle?.amount ?? 0)}
                onChange={(e) => setSelectedArticleAmount(e.target.value)}
                variant="outlined"
                label={"Summe"}
                type="number"
                fullWidth
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faEuroSign}/>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>}
        {tab === 1 && <Box className={classes.boxStyle}>
            <Autocomplete
                id="article-select"
                options={articles}
                autoHighlight
                getOptionLabel={(option) => option.articleNumber}
                filterOptions={filterOptions}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.articleNumber} - {option.name} - {option.producer}
                    </React.Fragment>
                )}
                value={selectedArticle}
                onChange={(event, newValue) => {
                    setSelectedArticle(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={classes.textField}
                        label="Artikel auswahl"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
            />
            <TextField
                className={classes.textField}
                value={selectedArticle?.vk_net ?? 0}
                onChange={(e) => setSelectedArticleAmount(e.target.value)}
                variant="outlined"
                label={"Preis"}
                type="number"
                fullWidth
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faEuroSign}/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                value={selectedArticleAmount}
                onChange={(e) => setSelectedArticleAmount(e.target.value)}
                variant="outlined"
                label={"Menge"}
                type="number"
                fullWidth
                helperText={"VORSICHT!, die Maximal mengen pro Produkt werden noch nicht berechnet!"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faBox}/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                value={(selectedArticle?.vk_net ?? 0) * (!isNaN(selectedArticleAmount) ? parseFloat(selectedArticleAmount) : 0)}
                onChange={(e) => setSelectedArticleAmount(e.target.value)}
                variant="outlined"
                label={"Summe"}
                type="number"
                fullWidth
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faEuroSign}/>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>}
        {tab === 2 && <Box className={classes.boxStyle}>
            <Autocomplete
                id="event-select"
                options={eventsList}
                autoHighlight
                getOptionLabel={(option) => option.name}
                filterOptions={filterOptions2}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                value={selectedEvent}
                onChange={(event, newValue) => {
                    setSelectedEvent(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={classes.textField}
                        label="Event auswahl"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                        }}
                    />
                )}
            />
            <TextField
                className={classes.textField}
                value={selectedEventAmount}
                onChange={(e) => setSelectedEventAmount(e.target.value)}
                variant="outlined"
                label={"Menge"}
                type="number"
                fullWidth
                disabled={!selectedEvent}
                helperText={"VORSICHT!, die Maximal Personen pro Event wird hier nicht berücksichtigt!"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faBox}/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                value={parseFloat(selectedEvent?.vk_net ?? 0) + parseFloat(selectedEvent?.shippingCost ?? 0)}
                variant="outlined"
                label={"Preis"}
                type="number"
                fullWidth
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faEuroSign}/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                value={email ?? ''}
                name={"email"}
                helperText={!email ? 'E-Mail adresse wird für ein Event benötigt bitte zuerst hinzufügen!': null}
                variant="outlined"
                label={"E-Mail Addresse"}
                error={!email}
                disabled
                fullWidth
            />
            <TextField
                className={classes.textField}
                value={firstName ?? ''}
                name={"firstName"}
                helperText={!firstName ? 'Vorname wird für ein Event benötigt bitte zuerst hinzufügen!': null}
                variant="outlined"
                label={"Vorname"}
                error={!firstName}
                disabled
                fullWidth
            />
            <TextField
                className={classes.textField}
                value={lastName ?? ''}
                name={"lastName"}
                helperText={!lastName ? 'Nachname für ein Event benötigt bitte zuerst hinzufügen!': null}
                variant="outlined"
                label={"Nachname"}
                error={!lastName}
                disabled
                fullWidth
            />
        </Box>}
        {tab === 3 && <Box className={classes.boxStyle}>
            <FormControl fullWidth variant={"outlined"} className={classes.textField}>
                <InputLabel id="amount-label">Anzahl Samples pro Lieferung</InputLabel>
                <Select
                    labelId="amount-label"
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth variant={"outlined"} className={classes.textField}>
                <InputLabel id="times-label">Wie oft soll geliefert werden</InputLabel>
                <Select
                    labelId="times-label"
                    id="times"
                    value={times}
                    onChange={(e) => setTimes(e.target.value)}
                >
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                </Select>
            </FormControl>
            <TextField
                className={classes.textField}
                value={price}
                onChange={(e) => setSelectedArticleAmount(e.target.value)}
                variant="outlined"
                label={"Summe"}
                type="number"
                fullWidth
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={faEuroSign}/>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>}
    </Modal>
}
