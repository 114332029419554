import {
    faAddressCard, faChevronLeft, faChevronRight, faColumns, faDollyFlatbed, faEnvelope, faFileInvoice, faFileInvoiceDollar, faFileSignature, faFunnelDollar,
    faGift, faHome, faMailBulk, faMask, faNewspaper, faShippingFast, faShoppingBag, faUsers, faWineBottle
} from "@fortawesome/free-solid-svg-icons";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons";
import {faAdversal} from "@fortawesome/free-brands-svg-icons";

export const routes = [
    {
        title: "zum Dashboard",
        route: "/admin/",
        icon: faColumns,
        text: "Dashboard"
    },
    {
        title: "zu den Bestellungen",
        route: "/admin/orders/",
        icon: faFileInvoice,
        text: "Bestellungen"
    },
    {
        title: "zu den persönlichen Anfragen",
        route: "/admin/appointments/",
        icon: faEnvelope,
        text: "Anfragen"
    },
    {
        title: "zu den Gutscheinen",
        route: "/admin/coupons/",
        icon: faGift,
        text: "Gutscheine"
    },
    {
        title: "zu den Benutzern",
        route: "/admin/users/",
        icon: faUsers,
        text: "Benutzer"
    },
    {
        title: "zu den Produkten",
        route: "/admin/articles/",
        icon: faWineBottle,
        text: "Produkte"
    },
    {
        title: "zu den Events",
        route: "/admin/whiskyevents/",
        icon: faCalendarAlt,
        text: "Whisky Events"
    },
    {
        title: "zu den Rechnungen",
        route: "/admin/bills/",
        icon: faFileInvoiceDollar,
        text: "Rechnungen"
    },
    {
        title: "zu den Newsletter einträgen",
        route: "/admin/newsletter/",
        icon: faMailBulk,
        text: "Newsletter"
    },
    {
        title: "zu den Transaktionen",
        route: "/admin/transaction/",
        icon: faFunnelDollar,
        text: "Transaktionen"
    },
    {
        isDivider: true,
    },
    {
        title: "zu Allgemeinen Geschäftsbedinung bearbeiten",
        route: "/admin/terms/",
        icon: faFileSignature,
        text: "Allgemeine Geschäftsbedinungen"
    },
    {
        title: "zu Lieferkonditionen bearbeiten",
        route: "/admin/termsDelivery/",
        icon: faDollyFlatbed,
        text: "Lieferkonditionen"
    },
    {
        title: "zu Datendschutz bearbeiten",
        route: "/admin/dataSecurity/",
        icon: faMask,
        text: "Datenschutz"
    },
    {
        title: "zu Wiederrufsrecht bearbeiten",
        route: "/admin/termsWithdraw/",
        icon: faShippingFast,
        text: "Wiederrufsrecht"
    },
    {
        title: "zum Impressum und Kontakt bearbeiten",
        route: "/admin/contact/",
        icon: faAddressCard,
        text: "Impressum/Kontakt"
    },
    {
        title: "zu Übersicht bearbeiten",
        route: "/admin/home/",
        icon: faHome,
        text: "Home"
    },
    {
        title: "zu News bearbeiten",
        route: "/admin/news/",
        icon: faNewspaper,
        text: "News"
    },
    {
        title: "zu Werbung bearbeiten",
        route: "/admin/adversal/",
        icon: faAdversal,
        text: "Werbung"
    },

]
