import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Modal from "components/Modal";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import {DateTime} from 'luxon';
import {getProductSum, isAbo} from 'common/statelessFunctions';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import {getCoupon} from "../../common/statelessFunctions";
import {inject, observer} from "mobx-react";

const STATE_LIST = {
    payed: 'Bezahlt',
    pending: 'In Verarbeitung',
    waiting: 'Ausstehend',
    canceled: 'Abgebrochen'
};

const PAYMENT_METHOD_TEXT = {
    prepaid: 'Mit Vorkassa bezahlt',
    paypal: 'Mit Paypal bezahlt',
    card: 'Mit Sofort überweisung, bzw Karte bezahlt'
}

const useStyles = makeStyles(theme => ({
    otherFont: {
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif',
        }
    },
    textFieldStyle: {
        marginBottom: '1em'
    },
    addAddressHover: {
        height: 125,
        width: 125,
        border: 'dashed 2px #333',
        borderRadius: '0.25em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        transition: 'border ease 0.5s',

        '&:hover': {
            border: 'solid 2px #333',
            transition: 'border ease 0.5s',
        }
    },
    addBillMetaHover: {
        height: 75,
        width: 175,
        border: 'dashed 2px #333',
        borderRadius: '0.25em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        transition: 'border ease 0.5s',

        '&:hover': {
            border: 'solid 2px #333',
            transition: 'border ease 0.5s',
        }
    },
    addArticleHover: {
        border: 'dashed 2px #333',
        borderRadius: '0.25em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        transition: 'border ease 0.5s',

        '&:hover': {
            border: 'solid 2px #333',
            transition: 'border ease 0.5s',
        }
    },
    tCFooter: {
        borderBottom: 'none',
        paddingBottom: 6,
        paddingTop: 6,
    },
    tCFooterTop: {
        borderTop: 'solid 2px #333',
        borderBottom: 'none',
        paddingBottom: 6,
        color: '#333',
        paddingTop: 6
    },
    tCFooterBottom: {
        borderTop: 'solid 1px #333',
        borderBottom: 'none',
        paddingBottom: 6,
        color: '#333',
        paddingTop: 6
    }
}));

const EditBill = inject ('store')(observer(({store, bill, open, handleClose}) => {
    const histroy = useHistory();
    const classes = useStyles();
    const [localBill, setLocalBill] = useState(null);

    useEffect(() => {
        if (bill && bill.coupon) bill.coupon = bill.coupon.abs
        if (bill) bill.shippingCost = 0;
        setLocalBill(bill);
    }, [bill]);

    const printBill = () => {
        store.billStore.setBill(null);
        histroy.push(`/print/bill/${localBill.billType || 'REO'}/${localBill.year}/${localBill.key}`);
    }

    return <Modal open={open} handleClose={handleClose} title={"Neues Artikel Set hinzufügen"}
                  actions={
                      <div>
                          <Button color={"primary"} variant={"contained"}
                                  onClick={handleClose} className={classes.otherFont}>Schließe</Button>
                          <Button color={"primary"} variant={"contained"} style={{marginLeft: '0.5em'}} className={classes.otherFont}
                                      onClick={printBill}>Drucken</Button>

                      </div>
                  }>
        {!!localBill &&
        <Container maxWidth={'md'} style={{backgroundColor: '#f7f7f7', padding: '24px', minHeight: '291mm', display: 'flex', alignItems: 'stretch'}}
                   className={classes.otherFont}>
            <div className={classes.otherFont} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                    <div style={{paddingBottom: 24, display: 'flex', justifyContent: 'flex-end'}}>
                        <img style={{width: '50%'}}
                             src={'https://firebasestorage.googleapis.com/v0/b/worldwhisky-onlinestore.appspot.com/o/images%2Flogos%2FLogoHeader.png?alt=media&token=10094e33-4c86-4092-8f17-3cf7f876c6a5'}
                             alt="WorldWhisky Logo"/>
                    </div>
                    <div style={{textAlign: 'left', width: '100%', fontSize: '1.25em'}}>
                        <div>{localBill.address.firstName} {localBill.address.lastName}</div>
                        <div>{localBill.address.address1}</div>
                        <div>{localBill.address.address2}</div>
                        <div>{localBill.address.plz}, {localBill.address.region}</div>
                        <div>{localBill.address.land}</div>
                    </div>
                    <div style={{textAlign: 'right', width: '100%'}}>
                        <div>
                            <span>Datum:</span>
                            <span style={{display: 'inline-block', width: '110px'}}>{DateTime.fromMillis(localBill.timestamp).toFormat('dd.LL.yyyy')}</span>
                        </div>
                        <div><span>{localBill.isPayed || localBill.state === 'payed' ? 'Rechnungsnummer' : 'Referenznummer'}:</span>
                            <span style={{display: 'inline-block', width: '110px'}}>{localBill.billNumber}</span>
                        </div>
                        <div><span>UID:</span><span style={{display: 'inline-block', width: '110px'}}>ATU75631247</span></div>
                    </div>
                    <div style={{textAlign: 'left', width: '100%'}}>
                        <p>Sehr geehrte / Sehr geehrter Kunde,</p>
                        <p>
                            wir wünschen Ihnen genußvolle Stunden mit unserer Lieferung und hoffen, Sie
                            bald wieder in unserem Webshop begrüßen zu dürfen.
                        </p>
                    </div>
                    <div style={{textAlign: 'left', width: '100%', minHeight: '350px'}}>
                        <Table style={{width: '100%', borderSpacing: 0}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Pos.</TableCell>
                                    <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Bezeichnung</TableCell>
                                    <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Menge</TableCell>
                                    <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Preis</TableCell>
                                    <TableCell component={'th'} style={{borderBottom: 'solid 2px #333'}}>Gesamt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!localBill.products && localBill.products.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{padding: '0.5em 0 0.5em 1em'}}>{index + 1}.</TableCell>
                                        <TableCell>{isAbo(product.articleNumber) ? product.articleNumber : (product.name || product.articleNumber)}</TableCell>
                                        <TableCell>{product.amount}</TableCell>
                                        <TableCell>€ {product.vk_net}</TableCell>
                                        <TableCell>€ {getProductSum(product)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                {!!localBill.withoutTax ? <TableRow>
                                    <TableCell colSpan={3} className={classes.tCFooterTop}/>
                                    <TableCell className={classes.tCFooterTop}>Netto:</TableCell>
                                    <TableCell className={classes.tCFooterTop}>€ {localBill.withoutTax}</TableCell>
                                </TableRow> : <TableRow>
                                    <TableCell colSpan={3} className={classes.tCFooter}/>
                                    <TableCell className={classes.tCFooter}>Netto:</TableCell>
                                    <TableCell className={classes.tCFooter}>€ 0,00</TableCell>
                                </TableRow>}
                                {!!localBill.tax ? <TableRow>
                                    <TableCell colSpan={3} className={classes.tCFooter}/>
                                    <TableCell className={classes.tCFooter}>20 % Ust:</TableCell>
                                    <TableCell className={classes.tCFooter}>€ {localBill.tax}</TableCell>
                                </TableRow> : <TableRow>
                                    <TableCell colSpan={3} className={classes.tCFooter}/>
                                    <TableCell className={classes.tCFooter}>20 % Ust:</TableCell>
                                    <TableCell className={classes.tCFooter}>€ 0,00</TableCell>
                                </TableRow>}
                                <TableRow>
                                    <TableCell colSpan={3} className={classes.tCFooter}/>
                                    <TableCell className={classes.tCFooter}>Lieferkosten:</TableCell>
                                    <TableCell className={classes.tCFooter}>€ {!!localBill.shippingCost ? localBill.shippingCost : '0.00'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} className={classes.tCFooter}/>
                                    <TableCell className={classes.tCFooter}>Rabatte:</TableCell>
                                    <TableCell className={classes.tCFooter}>€ {!!localBill.coupon ? getCoupon(localBill.coupon) : '0.00'}</TableCell>
                                </TableRow>
                                {!!localBill.payed ? <TableRow>
                                        <TableCell colSpan={3} className={classes.tCFooterBottom}/>
                                        <TableCell className={classes.tCFooterBottom}>Brutto:</TableCell>
                                        <TableCell className={classes.tCFooterBottom}>€ {localBill.payed}</TableCell>
                                    </TableRow> :
                                    <TableRow>
                                        <TableCell colSpan={3} className={classes.tCFooterBottom}/>
                                        <TableCell className={classes.tCFooterBottom}>Brutto:</TableCell>
                                        <TableCell className={classes.tCFooterBottom}>€ 0,00</TableCell>
                                    </TableRow>}
                            </TableFooter>
                        </Table>
                    </div>
                </div>
                <div>
                    <p>Zahlungsziel: <span>{bill?.paymentDay ?? STATE_LIST[bill?.state]}</span></p>
                    {bill?.paymentMethod && <p>Zahlungsmethode: {PAYMENT_METHOD_TEXT[bill.paymentType]} </p>}
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'inline-block', textAlign: 'left'}}>
                        <div>World Whisky WW OG</div>
                        <div>Im Fuchsloch 7</div>
                        <div>2104 Spillern</div>
                    </div>
                    <div style={{display: 'inline-block', textAlign: 'center'}}>
                        <div><a href={'mailto:office@worldwhisky.at'}>office@worldwhisky.at</a></div>
                        <div><a href={'www.worldwhisky.at'}>www.worldwhisky.at</a></div>
                        <div>FN: 534368i</div>
                    </div>
                    <div style={{display: 'inline-block', textAlign: 'right'}}>
                        <div>Erste Bank</div>
                        <div>IBAN: AT47 2011 1842 9178 7400</div>
                        <div>BIC: GIBAATWW</div>
                    </div>
                </div>
            </div>
        </Container>}
    </Modal>
}));

export default EditBill;
